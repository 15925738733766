import type { CSSAttribute } from 'goober';

import { colors } from '@/theme/colors';

const ellipsisMixin = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const lineClampMixin = (lineClamp: number) => `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lineClamp};
`;

const pseudoBorderMixin = ({
  color,
  size = 1,
  length = 3,
  radius = '20px',
  type,
}: {
  color: string;
  size?: number;
  length?: number;
  radius?: CSSAttribute['borderRadius'];
  type: 'dashed' | 'solid';
}) => `
    position: relative;
    border-radius: ${radius};

    &::after {
      position: absolute;
      top: -${size}px;
      bottom: -${size}px;
      left: -${size}px;
      right: -${size}px;
      border: ${length}px ${type} ${color};
      content: '';
      pointer-events: none;
      border-radius: ${radius};
    }
  `;

const scrollbarMixin = `
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: ${colors.gray.c2};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.gray.c7};
    border-radius: 20px;
  }
`;

const hideScrollbarMixin = `
  & {
    scrollbar-width: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
`;

const transitionMixin = (
  arr: string | string[],
  duration = '0.2s',
  effect = 'ease-in-out',
) => {
  return `transition: ${(Array.isArray(arr) ? arr : [arr])
    .map(style => `${style} ${duration} ${effect}`)
    .join(', ')};`;
};

export const mixins: Mixins = {
  ellipsis: ellipsisMixin,
  lineClamp: lineClampMixin,
  transition: transitionMixin,
  pseudoBorder: pseudoBorderMixin,
  scrollbar: scrollbarMixin,
  hideScrollbar: hideScrollbarMixin,
};

interface Mixins {
  ellipsis: typeof ellipsisMixin;
  lineClamp: typeof lineClampMixin;
  transition: typeof transitionMixin;
  pseudoBorder: typeof pseudoBorderMixin;
  scrollbar: typeof scrollbarMixin;
  hideScrollbar: typeof hideScrollbarMixin;
}
