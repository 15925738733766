import { useRecoilValue } from 'recoil';

import { useAllUsers } from '../../app-users/hooks/use-all-users.query';
import {
  settingsUsersSearchPhraseState,
  settingsUsersSortState,
} from '../../settings/settings.state';
import type { AllUsersQueryParams } from '@/api/v4/user.api';
import { QueryKey } from '@/config/query-client';
import { useQueryClient } from '@tanstack/react-query';

export const useActiveAndDisabledUsers = () => {
  const queryClient = useQueryClient();

  const { sort, direction } = useRecoilValue(settingsUsersSortState);
  const search = useRecoilValue(settingsUsersSearchPhraseState);

  const params: AllUsersQueryParams = {
    sort,
    direction,
    ...(search && { search }),
    statuses: ['active', 'disabled'],
  };

  const { data, isLoading } = useAllUsers(params);

  const refreshUsers = () =>
    queryClient.invalidateQueries([QueryKey.UsersAll, params]);

  const users = data ?? [];

  return { users, isLoading, refreshUsers };
};
