import { isPresent } from '@/helpers/is-present';
import {
  isInvitedUserOption,
  isTeamBasicOption,
  isUserOption,
} from '@/helpers/other';

import type { ShareWithData } from '../share-with-select/share-with';

export const mapNewUsersToPayload = (
  draftOptions: ShareWithData['draftOptions'],
) =>
  draftOptions
    ? draftOptions
        .filter(isInvitedUserOption)
        .map(u =>
          u.role && u.email ? { roleId: u.role.id, email: u.email } : null,
        )
        .filter(isPresent)
    : [];

export const mapUserIdsToPayload = (
  draftOptions: ShareWithData['draftOptions'],
) => (draftOptions ? draftOptions.filter(isUserOption).map(o => o.value) : []);

export const mapTeamIdsToPayload = (
  draftOptions: ShareWithData['draftOptions'],
) =>
  draftOptions ? draftOptions.filter(isTeamBasicOption).map(o => o.value) : [];
