import { Descope } from '@descope/react-sdk';
import { captureException } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { Loader } from '@/components/loader/loader';
import { QueryKey } from '@/config/query-client';
import { useDescopeFlow } from '@/hooks/use-descope-flow';
import { modalState } from '@/state/modal.state';
import { Modal as ModalComponent } from '@/ui/modal/modal';
import { VerificationMethod } from '@/user/verification-method/verification-method.type';

export const Set2FAMethodModal = () => {
  const [modal, setModal] = useRecoilState(modalState);
  const { isFlowLoading, onReady } = useDescopeFlow();

  const queryClient = useQueryClient();
  if (!modal || modal.state !== 'set2FAMethod') return null;

  return (
    <Modal>
      {isFlowLoading && <Loader />}
      <Descope
        flowId={
          modal.method === VerificationMethod.Otp
            ? window.runTimeEnvs.VITE_DESCOPE_ENABLE_TOTP_2FA_FLOW
            : window.runTimeEnvs.VITE_DESCOPE_ENABLE_OTP_2FA_FLOW
        }
        onSuccess={async () => {
          await queryClient.invalidateQueries([QueryKey.WhoAmI]);
          setModal(null);
        }}
        onError={event => {
          captureException(event);
        }}
        onReady={onReady}
      />
    </Modal>
  );
};

const Modal = styled(ModalComponent)`
  max-width: 700px;
`;
