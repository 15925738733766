import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { filterSearchTags } from '@/helpers/filter-search-tags';
import type { IGetRefs } from '@/hooks/use-multi-refs';
import { useMultiRefs } from '@/hooks/use-multi-refs';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import { GeneralTag } from '@/ui/tag/general-tag';
import { EmptyState } from '@/ui/typography/widgets';

import type { EntityTag } from '../../tags/tags';

import { getCategoryIcon } from './helpers/get-category-icon';

interface SelectedTagsSectionProps {
  tags: EntityTag[];
  searchPhrase: string;
  onTagRemove: (tagId: string) => void;
  getRefs?: IGetRefs;
}

export const SelectedTagsSection = ({
  tags,
  searchPhrase,
  onTagRemove,
  getRefs,
}: SelectedTagsSectionProps) => {
  const tagRefs = useMultiRefs({ getRefs });

  const { doesTagMatchSearchPhrase } = filterSearchTags({
    searchPhrase,
  });

  if (tags.length === 0) {
    return <EmptySelectedTags />;
  }

  return (
    <OverflowContainer
      name="selected-tabs-container"
      wrap="wrap"
      gap="8px"
      fullWidth
      data-testid="selected-tags-container"
    >
      {tags.map(tag => {
        const isSearched = doesTagMatchSearchPhrase(tag);
        return (
          <GeneralTag
            key={tag.id}
            variant={isSearched ? 'pink' : 'blue'}
            onRemove={onTagRemove.bind(null, tag.id)}
            ref={el => (tagRefs.current[tag.id] = el)}
            maxWidth="250px"
          >
            <EllipsisTextTooltip
              Component={<TagName />}
              text={tag.name.toLowerCase()}
            />
            {tag.category && <Icon icon={getCategoryIcon(tag.category.name)} />}
          </GeneralTag>
        );
      })}
    </OverflowContainer>
  );
};

const TagName = styled('div', forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis};
  max-width: 180px;
`;

const OverflowContainer = styled(Flexbox)`
  overflow-y: auto;
  overflow-x: hidden;
  ${({ theme }) => theme.mixins.scrollbar}
`;

const EmptySelectedTags = () => {
  const { t } = useTranslation('tags');

  return (
    <EmptyStateContainer
      name="selected-tags-empty"
      justify="center"
      alignItems="center"
      direction="column"
      gap="22px"
    >
      <Icon icon="BigHash" />
      <EmptyState>{t`clickOnTag`}</EmptyState>
    </EmptyStateContainer>
  );
};

const EmptyStateContainer = styled(Flexbox)`
  flex: 1 1 auto;
  color: ${({ theme }) => theme.colors.gray.c12};
  text-align: center;
`;
