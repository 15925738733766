import type {
  APIRequestBody,
  APIRequestQueryParams,
  APIResponse,
} from './base-fetch';
import { get, post, put, remove } from './base-fetch';

export type Meeting = APIResponse<'/api/v4/meetings'>[number];
export type Attendee = Meeting['attendees'][number];
type MeetingsRequestParams = APIRequestQueryParams<'/api/v4/meetings'>;

export const fetchMeetings = (params: MeetingsRequestParams) =>
  get<Meeting[]>('/v4/meetings', { params });

export type MeetingCreatePayload = APIRequestBody<'/api/v4/meetings/create'>;
type CreateMeetingResponse = APIResponse<'/api/v4/meetings/create'>;

export const createMeeting = (payload: MeetingCreatePayload) =>
  post<CreateMeetingResponse>('/v4/meetings/create', payload);

export type MeetingEditPayload = APIRequestBody<'/api/v4/meetings/edit'>;
type EditMeetingResponse = APIResponse<'/api/v4/meetings/edit'>;

export const editMeeting = (payload: MeetingEditPayload) =>
  put<EditMeetingResponse, MeetingEditPayload>('/v4/meetings/edit', payload);

export const deleteMeeting = (meetingId: string) =>
  remove(`/v4/meetings/delete/${meetingId}`);
