import { useCallback } from 'react';

import { useUpdateProject } from '@/features/projects/overview/use-update-project.mutation';
import type { ITagsModalType } from '@/state/modal.state';

import { useUpdateCompanyTags } from '../../companies/overview/tags/use-update-company-tags.mutation';
import { useUpdateTeamTags } from '../../teams/overview/team-tags/use-update-team-tags.mutation';

export const useUpdateTags = (type: ITagsModalType | null) => {
  const { updateTags } = useUpdateCompanyTags();
  const { editProject } = useUpdateProject();
  const { mutateAsync: updateTeamTags } = useUpdateTeamTags();

  const getUpdateEntity = useCallback(() => {
    switch (type) {
      case 'company':
        return async (organizationId: string, tagIds: string[]) => {
          await updateTags(organizationId, { tags: tagIds });
        };
      case 'project':
        return async (projectId: string, tagIds: string[]) => {
          await editProject({ projectId, tagIds });
        };
      case 'team':
        return async (team_id: string, tagIds: string[]) => {
          await updateTeamTags({
            teamId: team_id,
            payload: { tags: tagIds },
          });
        };

      default:
        return () => void 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return { updateEntity: getUpdateEntity() };
};
