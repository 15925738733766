export const ADOBE_EXTENSIONS = ['pdf'];
export const DOC_EXTENSIONS = [
  'doc',
  'docx',
  'rtf',
  'ppt',
  'pptx',
  'key',
  'keynote',
  'pages',
  'xls',
  'xlsx',
];
export const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png'];
export const AUDIO_EXTENSIONS = ['wav', 'mp3', 'ogg', 'flac'];
export const VIDEO_EXTENSIONS = ['avi', 'mp4', 'wmv', 'qt', 'mov'];
export const ZIP_EXTENSIONS = ['zip'];

export const AVAILABLE_EXTENSIONS = [
  ...ADOBE_EXTENSIONS,
  ...DOC_EXTENSIONS,
  ...IMAGE_EXTENSIONS,
  ...AUDIO_EXTENSIONS,
  ...VIDEO_EXTENSIONS,
  ...ZIP_EXTENSIONS,
];

export const MAX_FILE_SIZE =
  window.runTimeEnvs.VITE_INGRESS_PROXY_BODY_SIZE ?? '50m';
