import { useMemo } from 'react';
import { getSessionToken } from '@descope/react-sdk';
import { useRecoilValue } from 'recoil';

import { LocalStorageKey } from '@/config/local-storage';

import { jwtTokenState } from '../auth.state';

export const useAuthorization = () => {
  const jwtToken = useRecoilValue(jwtTokenState);
  const descopeToken = getSessionToken();

  const localStorageSessionToken = localStorage.getItem(
    LocalStorageKey.DescopeSessionToken,
  );

  const isUserAuthorized = useMemo(() => {
    return !!jwtToken && !!descopeToken && !!localStorageSessionToken;
  }, [jwtToken, descopeToken, localStorageSessionToken]);

  return {
    isUserAuthorized,
  };
};
