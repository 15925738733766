import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSidebars } from '@/hooks/use-sidebars';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { WithDropdown } from '@/ui/dropdown/dropdown';
import { Tooltip } from '@/ui/tooltip/tooltip';

import { NavMenuItem } from '../navbar.styles';
import { useCurrentUser } from '@/user/use-current-user.query';
import { Icon } from '@/ui/icons/icon';
import { Loader } from '@/components/loader/loader';
import { styled } from 'goober';

interface DropdownSettingsProps {
  onLogout: () => void;
}

export function DropdownSettings({ onLogout }: DropdownSettingsProps) {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const { isNavbarOpen } = useSidebars();

  const navigateTo = useNavigateTo();

  const { data: user, isLoading } = useCurrentUser();
  const { t } = useTranslation('settings');

  const accountSettingsItems = [
    { label: t`settings`, onClick: () => navigateTo.settings() },
    { label: t`logOut`, onClick: onLogout },
  ];

  if (isLoading) return <Loader />;
  return (
    <div data-testid="user-settings">
      <WithDropdown
        items={accountSettingsItems}
        options={{
          placement: 'right-start',
          modifiers: [{ name: 'offset', options: { offset: [0, 22] } }],
        }}
        setIsDropdownOpened={setIsDropdownOpened}
      >
        <Tooltip
          visible={!isNavbarOpen && !isDropdownOpened}
          content={t`Settings`}
          placement="right"
        >
          <NavMenuItem
            isActive={isButtonHovered}
            onMouseEnter={() => setIsButtonHovered(true)}
            onMouseLeave={() => setIsButtonHovered(false)}
          >
            <StyledIcon icon={'User'} />
            {isNavbarOpen && (
              <span>{`${user?.firstName} ${user?.lastName}`}</span>
            )}
          </NavMenuItem>
        </Tooltip>
      </WithDropdown>
    </div>
  );
}

const StyledIcon = styled(Icon)`
  margin: auto;
  .is-open & {
    margin: 0;
  }
`;
