import { useTranslation } from 'react-i18next';

import { colors } from '@/theme/colors';
import type { AsyncSelectProps } from '@/ui/select/async/async-select';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { getCustomSharedProps } from '@/ui/select/select-shared';
import { Tooltip } from '@/ui/tooltip/tooltip';

import { CommentDropdownOption } from './comment-dropdown-option';

export const CommentDropdown = (props: AsyncSelectProps) => {
  const { t } = useTranslation('default');

  const { components, styles } = getCustomSharedProps({
    variant: 'small',
    dropdownIndicatorColor: colors.gray.c7,
  });

  return (
    <Tooltip
      visible={props.isDisabled}
      content={t`actionPanel.comments.dropdown.noCompanies`}
    >
      <AsyncSelect
        placeholder={
          props.isDisabled ? '' : t`actionPanel.comments.dropdown.placeholder`
        }
        isClearable
        isSearchable
        components={{
          ...components,
          Option: CommentDropdownOption,
        }}
        styles={styles}
        {...props}
      />
    </Tooltip>
  );
};
