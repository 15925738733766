import type { APIRequestBody } from './base-fetch';
import { type APIResponse, get, post, put, remove } from './base-fetch';

export type TeamComment =
  APIResponse<'/api/v4/teams/{teamId}/comments'>[number];

export type TeamCommentPayload =
  APIRequestBody<'/api/v4/teams/{teamId}/comments/create'>;

export const fetchTeamComments = async (teamId: string) =>
  get<TeamComment[]>(`/v4/teams/${teamId}/comments`);

export const createTeamComment = async (
  teamId: string,
  payload: TeamCommentPayload,
) => post<TeamComment>(`/v4/teams/${teamId}/comments/create`, payload);

export const updateTeamComment = async (
  teamId: string,
  teamCommentId: string,
  payload: TeamCommentPayload,
) =>
  put<TeamComment, TeamCommentPayload>(
    `/v4/teams/${teamId}/comments/${teamCommentId}/update`,
    payload,
  );

export const deleteTeamComment = async (
  teamId: string,
  teamCommentId: string,
) => remove<void>(`/v4/teams/${teamId}/comments/${teamCommentId}/delete`);
