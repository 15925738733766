import { generatePath } from 'react-router-dom';
import { mapValues } from 'lodash-es';

const AUTH_ROUTES = {
  auth: '/auth',
  signIn: '/auth/signin',
  signUp: '/auth/signup',
  signUpInvitationExpired: '/auth/signup/invitation-expired',
};

const HOME_ROUTES = {
  home: '/home',
};

const PROJECT_ROUTES = {
  projects: '/projects',
  createProject: '/projects/create',
  project: '/projects/:projectId',
};

const TEAM_ROUTES = {
  teams: '/teams',
  createTeam: '/teams/create',
  team: '/teams/:teamId',
  teamNote: '/teams/:teamId/team_notes/:id',
};

const COMPANY_ROUTES = {
  companies: '/companies',
  company: '/companies/:companyId',
};

const EXPLORE_ROUTES = {
  explore: '/explore',
  exploreResults: '/explore/results/:requestId?',
};

const SETTINGS_ROUTE = {
  settings: '/settings',
};

const ERROR_ROUTE = {
  notFound: '/404',
};

export const ROUTES = {
  ...AUTH_ROUTES,
  ...HOME_ROUTES,
  ...PROJECT_ROUTES,
  ...TEAM_ROUTES,
  ...COMPANY_ROUTES,
  ...EXPLORE_ROUTES,
  ...SETTINGS_ROUTE,
  ...ERROR_ROUTE,
};

/**
 * Collection of functions that returns specific route compiled with params.
 *
 * Usage:
 *   `paths.project({projectId: 'abc-def'});` returns `/projects/abc-def`;
 */
export const paths = mapValues(ROUTES, route => {
  return (params?: PathParams) => generatePath(route, params);
});

export interface PathParams {
  [arg: string]: string;
}
