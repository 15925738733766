import { forwardRef } from 'react';
import { styled } from 'goober';

import { Icon } from '@/ui/icons/icon';

export const TextEditableWrapper = styled('div')<{ width?: number }>`
  display: flex;
  align-items: stretch;
  position: relative;
  ${({ width }) => width && `width: ${width}px;`}
`;

export const TextArea = styled('textarea', forwardRef)<{
  border?: boolean;
  resize?: string;
  flexGrow?: string | number;
}>`
  padding-top: 0;
  padding-bottom: 0;
  flex-grow: ${({ flexGrow = 1 }) => flexGrow};
  resize: ${({ resize = 'none' }) => resize};
  background-color: unset;
  border: unset;
  outline: none;
  padding-right: 20px;
  ${({ theme }) => theme.mixins.scrollbar}
  ${({ theme }) => theme.typography.widget.paragraphLarge}

  ${({ border, theme }) =>
    border &&
    `
      border: 2px solid ${theme.colors.gray.c2};
      border-radius: 10px;
      padding: 16px 20px;

      &:focus-within {
        border-color: ${theme.colors.blue.primaryA};
      }
  `}

  &:disabled {
    cursor: default;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.gray.c6};
  }
`;

export const EditIcon = styled(Icon)`
  cursor: pointer;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale3d(0, 0, 0);
  ${({ theme }) => theme.mixins.transition('transform')}

  .text-widget:not(.edit-mode):hover &,
  .text-editable-wrapper:has(textarea[readonly]):hover &,
  .company-page-header:not(.edit-mode):hover & {
    pointer-events: auto;
    transform: scale3d(1, 1, 1);
  }
`;
