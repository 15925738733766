import { styled } from 'goober';

import { useTopbarContent } from '@/features/topbar/use-topbar-content';
import { useSidebars } from '@/hooks/use-sidebars';
import { Flexbox } from '@/ui/flexbox/flexbox';

import { SuspenseFallback } from '../../components/suspense-fallback/suspense-fallback';
import {
  CLOSED_NAVBAR_WIDTH,
  OPEN_NAVBAR_WIDTH,
} from '../navbar/navbar.styles';

export const Topbar = () => {
  const TopbarContent = useTopbarContent();
  const { isNavbarOpen } = useSidebars();

  if (!TopbarContent) return null;

  return (
    <Container
      data-testid="page-topbar"
      name="topbar"
      justify="space-between"
      alignItems="center"
      sidebarWidth={isNavbarOpen ? OPEN_NAVBAR_WIDTH : CLOSED_NAVBAR_WIDTH}
    >
      <SuspenseFallback>
        <TopbarContent />
      </SuspenseFallback>
    </Container>
  );
};

export const TOPBAR_HEIGHT = '72px';

const Container = styled(Flexbox)<{ sidebarWidth: string }>`
  height: ${TOPBAR_HEIGHT};
  max-width: calc(100vw - ${({ sidebarWidth }) => sidebarWidth});
`;

export const TopbarWrapper = styled(Flexbox)<{
  bgColor?: string;
  shadow?: boolean;
}>`
  flex-grow: 1;
  padding: 20px 30px;
  height: 100%;

  background-color: ${({ bgColor, theme }) =>
    bgColor ?? theme.colors.basics.white};
  ${({ shadow, theme }) =>
    shadow && `box-shadow: ${theme.shadow.project.header}`}
`;
