import { useTranslation } from 'react-i18next';

import { Button } from '@/ui/button/button';
import { WithDropdownContent } from '@/ui/dropdown/with-dropdown-content';
import { PRODUCT } from '@/user/products/product.type';
import { useUserProducts } from '@/user/products/use-user-products';

import { AskXInfo } from './ask-x-info';

export const AskXButton = () => {
  const { t } = useTranslation('askX');

  const { hasRequiredProduct } = useUserProducts();
  if (!hasRequiredProduct(PRODUCT.ASK_X)) return null;

  return (
    <WithDropdownContent
      content={<AskXInfo />}
      options={{
        placement: 'bottom-start',
        modifiers: [{ name: 'offset', options: { offset: [-320, 14] } }],
      }}
    >
      <Button variant="black" startIcon="LightBulb">{t`actionTitle`}</Button>
    </WithDropdownContent>
  );
};
