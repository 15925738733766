import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { modalState } from '@/state/modal.state';
import { Modal } from '@/ui/modal/modal';

import { DealRadio } from '../projects/project-listings/stage-management-table/deal-radio/deal-radio';
import { useUpdateProjectListingStage } from '../projects/project-listings/use-update-project-listing-stage.mutation';

export const DealModal = () => {
  const { t } = useTranslation('projects');
  const [modal, setModalState] = useRecoilState(modalState);
  const [selectedDealType, setSelectedDealType] = useState<string | null>(null);
  const changeProjectListingStatus = useUpdateProjectListingStage();

  if (modal?.state !== 'deal') return null;

  const close = () => {
    if (modal.onClosed) modal.onClosed();
    setModalState(null);
  };

  const handleStatusChange = async () => {
    if (!selectedDealType) return;

    await changeProjectListingStatus({
      projectListingId: modal.listingId,
      newProjectListingStageId: selectedDealType,
      onSuccess: () => {
        modal.onSuccess?.(selectedDealType);
        close();
      },
    });
  };

  return (
    <Modal
      data-testid="project-listing-status-deal-modal"
      header={t`itsDeal`}
      onClose={close}
      mainAction={{
        label: t`Done`,
        onClick: handleStatusChange,
      }}
      secondaryAction={{
        label: t`Cancel`,
        onClick: close,
      }}
      disabled={!selectedDealType}
      variant="info"
    >
      <Content>
        <Description>{t`listings.whatKindOfDeal`}</Description>
        <DealRadio value={selectedDealType} onSelect={setSelectedDealType} />
      </Content>
    </Modal>
  );
};

const Content = styled('div')`
  padding: 0 0 90px 0;
`;

const Description = styled('p')`
  ${({ theme }) => theme.typography.acrossPlatform.small};
  color: ${({ theme }) => theme.colors.gray.c12};
  margin-bottom: 36px;
`;
