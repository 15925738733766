import { useQuery } from '@tanstack/react-query';

import type { OrganizationsSavvySearchParams } from '@/api/v4/organizations-search-savvy.api';
import { fetchOrganizationsSavvySearchResults } from '@/api/v4/organizations-search-savvy.api';
import { QueryKey } from '@/config/query-client';

export const useOrganizationsSavvySearchQuery = (
  params: OrganizationsSavvySearchParams,
) => {
  return useQuery([QueryKey.CompaniesSearchSavvy, params], () => {
    return fetchOrganizationsSavvySearchResults(params);
  });
};
