import i18n from '@/lib/i18n/i18n';
import { fetchLocations } from '@/api/v4/locations.api';
import { notify } from '@/ui/snackbar/notify';
import { mapCompanyLocationOption } from './map-company-location-option';

export const getCompanyHeadquarterLocationOptionList = async (
  search: string,
) => {
  try {
    const response = await fetchLocations({
      ...(search && { search }),
      type: ['city'],
    });

    return response.map(mapCompanyLocationOption);
  } catch (error) {
    notify({
      message: i18n.t`default:unknownError`,
    });
    return [];
  }
};
