import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { QueryKey } from '@/config/query-client';
import { useHandleDeleteMeeting } from '@/features/action-panel/meetings/hooks/use-handle-delete-meeting';
import { MeetingsTab } from '@/features/action-panel/meetings/meetings-tab';
import { companyIdState } from '@/features/companies/overview/company.state';

import { useCompanyMeetings } from './meetings/use-company-meetings.query';

export const CompanyMeetingsTab = () => {
  const { data: meetings, isFetching } = useCompanyMeetings();

  const queryClient = useQueryClient();
  const companyId = useRecoilValue(companyIdState);

  const refreshData = async () => {
    await queryClient.invalidateQueries([QueryKey.CompanyMeetings, companyId]);
  };

  const { onDeleteHandler, isDeleting } = useHandleDeleteMeeting({
    onRefreshData: refreshData,
  });

  return (
    <MeetingsTab
      meetingsData={meetings ?? []}
      refreshHandler={refreshData}
      isLoading={isFetching || isDeleting}
      deleteMeetingHandler={onDeleteHandler}
      newMeetingFormDefaultValues={{ relatedCompanyId: companyId ?? undefined }}
      withProjectField
      withTeamField
      context="company"
    />
  );
};
