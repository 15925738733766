import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import { ActionButton } from '@/components/actions-button/actions-button';
import { useActionPanel } from '@/features/action-panel/use-action-panel';
import { AskXButton } from '@/features/ask-x/ask-x-button';
import { useVerifyCompany } from '@/features/companies/hooks/use-verify-company';
import { useCompanyActionsInfo } from '@/features/companies/overview/action-panel/use-company-actions-info.query';
import { useSectionsPane } from '@/features/companies/overview/sections-pane/use-sections-pane';
import { useCompany } from '@/features/companies/overview/use-company.query';
import { PinItemButton } from '@/features/home/recent-items/pinned-items/pin-item-button';
import { useTogglePinnedItem } from '@/features/home/recent-items/pinned-items/use-toggle-pinned-item.mutation';
import { modalState } from '@/state/modal.state';
import { Button } from '@/ui/button/button';
import { IconButton } from '@/ui/button/icon-button';
import type { DropdownOption } from '@/ui/dropdown/dropdown';
import { WithDropdown } from '@/ui/dropdown/dropdown';
import { Inline } from '@/ui/line/line';
import { MenuButton } from '@/ui/menu-button/menu-button';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import { PRODUCT } from '@/user/products/product.type';
import { useUserProducts } from '@/user/products/use-user-products';
import { useOptimisticPinCompanyUpdate } from './use-optimistic-pin-company-update';

export const CompanyHeaderActions = () => {
  const { t } = useTranslation('companies');
  const { data: company } = useCompany();
  const { hasRequiredPermission } = useUserPermissions();
  const { hasRequiredProduct } = useUserProducts();
  const { data: companyActions } = useCompanyActionsInfo();

  const setModal = useSetRecoilState(modalState);
  const { handleVerify, isAlreadyVerified } = useVerifyCompany();
  const { toggleSectionsPane, isSectionsPaneOpen } = useSectionsPane();
  const { toggleActionPanel } = useActionPanel();
  const { handleOptimisticChange, rollbackToPreviousData } =
    useOptimisticPinCompanyUpdate();
  const { toggleItem } = useTogglePinnedItem({
    onMutate: handleOptimisticChange,
    onRollback: rollbackToPreviousData,
  });

  if (!company) return null;

  const onToggleActionPanelClick = () =>
    toggleActionPanel({
      context: 'company',
      title: company.name.value ?? '',
    });

  const openShareCompany = () => {
    if (!company) return;
    setModal({ state: 'shareCompany', companyId: company.id });
  };

  const hasPermissionToShareCompany = hasRequiredPermission(
    PERMISSION.SHARE_A_COMPANY_TO_EXISTING_Q_USERS,
  );

  const menu: DropdownOption[] = [
    {
      label: t`company.share`,
      isVisible: hasPermissionToShareCompany,
      onClick: openShareCompany,
      icon: 'ArrowUpFromSquare',
    },
    {
      label: t`company.delete`,
      isVisible: hasRequiredPermission(PERMISSION.DELETE_ORGANIZATION),
      onClick: () =>
        setModal({
          state: 'deleteCompany',
          company: {
            id: company.id,
            name: company.name.value ?? '',
          },
        }),
      icon: 'Bin',
    },
  ];

  return (
    <Inline alignItems="center" gap="20px">
      <AskXButton />

      {hasRequiredProduct(PRODUCT.VERIFY_COMPANY) && (
        <Button
          startIcon="DoubleCheckMark"
          variant={isAlreadyVerified ? 'navy' : 'secondary'}
          onClick={handleVerify}
        >
          {isAlreadyVerified ? t`company.reVerify` : t`company.verify`}
        </Button>
      )}

      {hasPermissionToShareCompany && (
        <IconButton
          onClick={openShareCompany}
          icon="ArrowUpFromSquare"
          variant="ghost"
          size="16px"
        />
      )}

      <PinItemButton
        itemId={company.id}
        itemType="company"
        emptyUnpinnedIcon
        onToggle={toggleItem}
        isPinned={company.isPinned}
      />
      <Button
        onClick={toggleSectionsPane}
        startIcon="Sections"
        variant={isSectionsPaneOpen ? 'secondary' : 'ghost'}
      >
        {t`company.sections.title`}
      </Button>
      <ActionButton
        totalValue={companyActions?.total || 0}
        onClick={onToggleActionPanelClick}
      />
      <WithDropdown items={menu} options={{ placement: 'bottom-end' }}>
        <Button variant="text" data-testid="company-action-dropdown-button">
          <MenuButton />
        </Button>
      </WithDropdown>
    </Inline>
  );
};
