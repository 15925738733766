import { useTranslation } from 'react-i18next';

import {
  isInvitedUserOption,
  isTeamBasicOption,
  isUserOption,
} from '@/helpers/other';
import { InvitedUserOption } from '@/ui/select/async/components/option/invited-user-option';
import type { AsyncSelectOptionProps } from '@/ui/select/async/components/option/option';
import { TeamBasicOption } from '@/ui/select/async/components/option/team-basic-option';
import { UserOption } from '@/ui/select/async/components/option/user-option';

export function ShareWithUserOrTeamOption({
  withCheckIconOnSelect = true,
  data,
  ...props
}: AsyncSelectOptionProps) {
  const { t } = useTranslation('default');

  if (isInvitedUserOption(data)) {
    return (
      <InvitedUserOption
        {...props}
        data={data}
        withCheckIconOnSelect={withCheckIconOnSelect}
      />
    );
  }

  if (isUserOption(data)) {
    return (
      <UserOption
        {...props}
        data={data}
        isDisabled={props.isDisabled}
        withCheckIconOnSelect={withCheckIconOnSelect}
        disabledOptionMessage={t`share.userAlreadyOnList`}
      />
    );
  }

  if (isTeamBasicOption(data)) {
    return (
      <TeamBasicOption
        {...props}
        data={data}
        isDisabled={props.isDisabled}
        withCheckIconOnSelect={withCheckIconOnSelect}
      />
    );
  }

  return null;
}
