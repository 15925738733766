import { atom, selector } from 'recoil';

import type {
  ICompanyListedResponse,
  PoweredByAPI,
  PoweredValue,
  RatingContentResponse,
} from '@/api/v3/organizations-api';
import type { OrganizationLocation } from '@/api/v3/types/locations';
import type {
  CompanyContact,
  CompanyContactList,
} from '@/api/v4/organization-contacts.api';
import { fetchCompanyContacts } from '@/api/v4/organization-contacts.api';
import type { RenameByT } from '@/helpers/other';
import type { IEntityType } from '@/types';
import type { IPageState } from '@/ui/table/table.types';

import { MAX_VISIBLE_CONTACTS_NUMBER } from './contacts/use-company-contacts';
import type { CreatedCompany } from '@/api/v4/organization.api';

interface IPoweredBy extends Camelize<PoweredByAPI> {
  id: string;
  type: IEntityType;
}

type IEmployeesRange = IPoweredBy & {
  rangeValues: string;
};

type IOwnershipStatus = IPoweredBy & {
  name: string;
};

type IActivityStatus = IPoweredBy & {
  name: CompanyActivityStatus;
};

export interface EnrichedFromCrunchbaseCompanyData {
  name: Camelize<PoweredValue<string>>;
  activityStatus?: IActivityStatus;
  logoUrl: Camelize<PoweredValue<string | null>>;
  hqLocation: Camelize<PoweredValue<OrganizationLocation | null>>;
  linkedinUrl: Camelize<PoweredValue<string | null>>;
  website: Camelize<PoweredValue<string | null>>;
  ownershipStatus?: IOwnershipStatus;
  ticker: Camelize<PoweredValue<string | null>>;
  yearEstablished: Camelize<PoweredValue<number>>;
  employeesRange?: IEmployeesRange;
  keyOffering: Camelize<PoweredValue<string | null>>;
  ownedByIrm: Camelize<PoweredValue<string | null>>;
  detailedDescription: Camelize<PoweredValue<string>>;
  lastFundingDate: Camelize<PoweredValue<string | null>>;
  capitalRaised: Camelize<PoweredValue<string | null>>;
  stockExchangeSymbol?: Camelize<PoweredValue<string | null>>;
  fundingStage: Camelize<PoweredValue<string | null>>;
  tags: {
    poweredBy?: string;
  };
}

export type ICompanyListed = RenameByT<
  {
    average_rating: 'averageRating';
    listings_count: 'listingsCount';
    logo_url: 'logoUrl';
    from_cb: 'fromCb';
    year_established: 'yearEstablished';
    cb_permalink: 'cbPermalink';
  },
  ICompanyListedResponse
>;

export const companyIdState = atom<string | null>({
  key: 'companyIdState',
  default: null,
});

export const addCompanyInProgressState = atom({
  key: 'addCompanyInProgressState',
  default: false,
});

export const companiesSearchPhraseState = atom<string>({
  key: 'companiesSearchPhraseState',
  default: '',
});

export const companiesAddedState = atom<CreatedCompany[]>({
  key: 'companiesAddedState',
  default: [],
});

type IRatingContentType = RenameByT<
  {
    average_rating: 'averateRating';
    user_rating: 'userRating';
  },
  RatingContentResponse
>;

export type ICompanyRanking = {
  overallAverageRating: string;
  technology: IRatingContentType;
  team: IRatingContentType;
  financial: IRatingContentType;
};

export enum CompanyRatingType {
  Technology = 'technology',
  Team = 'team',
  Financial = 'financial',
}

export enum CompanyActivityStatus {
  ACTIVE = 'Active',
  CLOSED = 'Closed',
  STEALTH = 'Stealth',
}

export enum OwnershipStatus {
  PRIVATE = 'Private',
  PUBLIC = 'Public',
}

/** Company Contacts */
export const companyContactsPageState = atom<IPageState>({
  key: 'companyContactsPageState',
  default: { page: 1, pageSize: 20 },
});

export const companyContactsState = selector<CompanyContactList>({
  key: 'companyContactsState',
  get: async ({ get }) => {
    const companyId = get(companyIdState);

    if (!companyId)
      return {
        contacts: [],
        poweredBy: null,
        lastEditedAt: null,
      };
    const { page, pageSize } = get(companyContactsPageState);

    const response = await fetchCompanyContacts(companyId, {
      page,
      items: pageSize,
    });

    return {
      ...response,
      contacts: response.contacts.map(contact => ({
        ...contact,
        synced: true,
      })),
    };
  },
});

export const companyContactsAtomState = atom<CompanyContact[]>({
  key: 'companyContactsAtomState',
  default: [],
});

export const firstSixCompanyContactsAtomState = selector<CompanyContact[]>({
  key: 'firstSixCompanyContactsAtomState',
  get: ({ get }) => {
    const contacts = get(companyContactsAtomState);
    return contacts.slice(0, 6);
  },
});

export const companyContactsAddCounterState = atom({
  key: 'companyContactsAddCounterState',
  default: 0,
});

export const companyContactsEditModeState = atom({
  key: 'companyContactsEditModeState',
  default: false,
});

export const isMoreContactsThanMaxVisibleState = selector({
  key: 'isMoreContactsThanMaxVisibleState',
  get: ({ get }) => {
    const contacts = get(companyContactsAtomState);
    return Number(contacts.length) > MAX_VISIBLE_CONTACTS_NUMBER;
  },
});

export const companyContactsForceSubmitState = atom({
  key: 'companyContactsForceSubmitState',
  default: false,
});

export const companyContactDeletedIdState = atom<string | null>({
  key: 'companyContactDeletedIdState',
  default: null,
});

/** End of Company Contacts */
