import { useRef } from 'react';
import { styled } from 'goober';

import { colors } from '@/theme/colors';
import { Z_INDEX_LOADER } from '@/theme/z-index';
import { Flexbox } from '@/ui/flexbox/flexbox';

import { Spinner } from './spinner';

interface LoaderProps {
  size?: 'normal' | 'small';
  isFullPage?: boolean;
}

export const Loader = ({ size = 'normal', isFullPage }: LoaderProps) => {
  const sizes = useRef({
    ['normal']: '40px',
    ['small']: '20px',
  });

  return (
    <Container
      name="loader"
      fullHeight
      fullWidth
      justify="center"
      alignItems="center"
      isFullPage={isFullPage}
    >
      <SpinnerWrapper>
        <Spinner size={sizes.current[size]} color={colors.blue.primaryA} />
      </SpinnerWrapper>
    </Container>
  );
};

const Container = styled(Flexbox)<{ isFullPage?: boolean }>`
  ${({ isFullPage, theme }) =>
    isFullPage &&
    `position: fixed;
    background: ${theme.palettes.component.loader.background};
    zIndex: ${Z_INDEX_LOADER};
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
  `};
`;

const SpinnerWrapper = styled('div')`
  position: 'fixed',
  top: '50%', 
  left: '50%',
  transform: 'translate(-50%)',
`;
