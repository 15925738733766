import type { MouseEvent } from 'react';
import { useState } from 'react';
import { styled } from 'goober';

import { classList } from '@/helpers/class-list';

import { Flexbox } from '../flexbox/flexbox';
import { Icon } from '../icons/icon';
import { Stack } from '../line/line';

import type { DropdownOption } from './dropdown';
import { DropdownItem } from './dropdown.styles';

export const DropdownListItem = ({
  item,
  onClick,
  children,
}: {
  item: DropdownOption;
  onClick: (event: MouseEvent<HTMLElement>) => void;
  children?: React.ReactNode;
}) => {
  const [subMenuOpened, setIsSubMenuOpened] = useState(false);

  return (
    <DropdownItem
      className={classList(item.isDisabled && 'disabled')}
      onClick={onClick}
      onMouseEnter={() => !!children && setIsSubMenuOpened(true)}
      onMouseLeave={() => !!children && setIsSubMenuOpened(false)}
    >
      <ItemComponent item={item} />
      {subMenuOpened && !!children && children}
    </DropdownItem>
  );
};

const ItemComponent = ({ item }: { item: DropdownOption }) => (
  <Stack gap="15px">
    <Flexbox
      name="dropdown-item-label"
      justify="space-between"
      gap="15px"
      fullWidth
    >
      {item.label}
      {!!item.icon && <Icon icon={item.icon} />}
    </Flexbox>
    {!!item.subtext && <SubText>{item.subtext}</SubText>}
  </Stack>
);

const SubText = styled('div')`
  ${({ theme }) => theme.typography.companyInfo.tableHeader}
`;
