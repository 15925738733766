import { cloneElement, useLayoutEffect, useRef, useState } from 'react';

import { isEllipsis } from '@/helpers/other';
import { Tooltip } from '@/ui/tooltip/tooltip';

export const EllipsisTextTooltip = ({
  Component,
  text = '',
  onClick,
}: {
  text?: string | null;
  Component: React.ReactElement;
  onClick?: () => void;
}) => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useLayoutEffect(() => {
    if (!headerRef.current) return;
    setShowTooltip(isEllipsis(headerRef.current));
  }, [headerRef.current]);

  return (
    <Tooltip
      content={text}
      visible={showTooltip}
      placement="top-start"
      maxWidth="none"
    >
      {cloneElement(Component, { onClick, ref: headerRef, children: text })}
    </Tooltip>
  );
};
