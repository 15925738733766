import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Stack } from '@/ui/line/line';

import { useCompanies } from '../../companies/hooks/use-companies.query';

export const DeleteCompaniesNotification = ({
  deleted,
  undeletableCompanies,
}: {
  deleted: number;
  undeletableCompanies: string[];
}) => {
  const { t } = useTranslation('companies');
  const { data } = useCompanies();

  const companies = data ? data.pages.flat() : [];

  return (
    <Container>
      {deleted > 0 ? (
        <Description>
          {t(`deleteCompanyModal.multipleDeleteSuccess`, {
            count: deleted,
          })}
        </Description>
      ) : null}
      <Stack margin="12px 0px">
        <Description>
          {t(
            deleted === 0
              ? `deleteCompanyModal.someCompaniesCannotBeDeletedDescription`
              : `deleteCompanyModal.allCompaniesCannotBeDeletedDescription`,
          )}
        </Description>
        <CompanyList margin="12px 0px">
          {undeletableCompanies.map(companyId => {
            const companyMeta = companies.find(c => c.id === companyId);
            if (!companyMeta) return null;
            return (
              <Description key={companyId}>{companyMeta?.name}</Description>
            );
          })}
        </CompanyList>
      </Stack>
    </Container>
  );
};

const CompanyList = styled(Stack)`
  position: relative;
  max-height: 240px;
  overflow-y: auto;
`;
const Container = styled('div')`
  padding: 8px 0px;
`;

const Description = styled('p')`
  ${({ theme }) => theme.typography.acrossPlatform.small};
  color: ${({ theme }) => theme.colors.gray.c12};
`;
