import { useTranslation } from 'react-i18next';

import { Flexbox } from '@/ui/flexbox/flexbox';
import { NoResults } from '@/ui/table/no-results';

interface AddCompanyToProjectsModalNoResultsProps {
  onClick: () => void;
}

export function AddCompanyToProjectsModalNoResults({
  onClick,
}: AddCompanyToProjectsModalNoResultsProps) {
  const { t } = useTranslation('companies');
  return (
    <Flexbox
      name="add-company-modal-no-results-container"
      direction="column"
      justify="center"
      alignItems="center"
      gap="30px"
      fullWidth
      grow={1}
    >
      <NoResults
        header={t`addToProject.noResultsHeader`}
        text={t`addToProject.noResultsInfo`}
        buttonText={t`addToProject.noResultsButtonText`}
        buttonIcon="ArrowGoBack"
        onButtonClick={onClick}
      />
    </Flexbox>
  );
}
