import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { ActionPanelDate } from '@/features/action-panel/action-panel-date';
import { WithLabel } from '@/features/companies/overview/header/edit-form/with-label';
import { getFileIcon } from '@/helpers/files';
import { useBoolean } from '@/hooks/use-boolean';
import { colors } from '@/theme/colors';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { colorIcon } from '@/ui/icons/functions';
import { Input } from '@/ui/input/input';
import { useUserAccount } from '@/user/use-user-account';

import type { File } from './file.interface';
import { FileShareWith } from './file-share-with';
import {
  mapTeamIdsToPayload,
  mapUserIdsToPayload,
} from '@/features/modals/share-modal/helpers/map-share-payload';
import type { ShareWithData } from '@/features/modals/share-modal/share-with-select/share-with';
import type { ShareOrganizationFileBody } from '@/api/v4/organization-files.api';
import type { ShareTeamFileBody } from '@/api/v4/team-files.api';
import { useUsersAndTeamsWithAccessToFile } from './use-users-and-teams-with-access-to-file';

export type ShareFilePayload =
  | (ShareOrganizationFileBody | ShareTeamFileBody) & { fileId: string };

interface EditFileProps {
  file: File;
  onCancel: () => void;
  onShareFile: (payload: ShareFilePayload) => Promise<void>;
}

export function EditFile({ file, onCancel, onShareFile }: EditFileProps) {
  const { t } = useTranslation('default');
  const [disableUpdate, setDisableUpdate] = useBoolean(false);

  const userAccount = useUserAccount();

  const { isLoading, usersAndTeamsWithAccess } =
    useUsersAndTeamsWithAccessToFile(file.id);

  const [shareWithData, setShareWithData] = useState<ShareWithData>({
    draftOptions: [],
    removedOptions: [],
    isPublic: file.public,
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setShareWithData(prevState => ({
      ...prevState,
      draftOptions: usersAndTeamsWithAccess,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleSubmit = async () => {
    setDisableUpdate.on();
    const { draftOptions } = shareWithData;

    const payload: ShareFilePayload = {
      fileId: file.id,
      isPublic: shareWithData.isPublic ?? file.public,
      teamIds: mapTeamIdsToPayload(draftOptions),
      userIds: mapUserIdsToPayload(draftOptions),
    };

    await onShareFile(payload);
    setDisableUpdate.off();
    onCancel();
  };

  return (
    <Flexbox name="edit-file-container" direction="column" grow={1} gap="39px">
      <WithLabel
        name="filename"
        labelText={t`actionPanel.files.fileInputLabel`}
        grow={1}
      >
        <FileNameInput
          value={file.name}
          padding="4px 8px"
          rightIcon={getFileIcon(file.name) || 'FileDoc'}
          iconColor={colors.basics.black}
          readOnly
        />
      </WithLabel>

      <ShareWithContainer>
        <PeopleHeader>{t`share.peopleWithAccess`}:</PeopleHeader>
        <FileShareWith
          isLoading={isLoading}
          entitiesWithAccess={usersAndTeamsWithAccess}
          shareWithData={shareWithData}
          privacyDefaultValue={file.public}
          searchInputSize="s"
          separateDefaultValue={false}
          onChange={setShareWithData}
          privacyPublicLabel={t('share.filePublicLabel', {
            accountName: userAccount?.name,
          })}
          privacyPrivateLabel={t`share.filePrivateLabel`}
        />
      </ShareWithContainer>

      <Flexbox
        name="edit-file-footer"
        alignItems="center"
        justify="space-between"
      >
        <ActionPanelDate date={file.createdDate} relative />
        <Flexbox name="edit-file-buttons" justify="flex-end" gap="20px">
          <Button
            variant="underlined"
            onClick={onCancel}
            disabled={disableUpdate}
          >{t`cancel`}</Button>
          <Button
            onClick={handleSubmit}
            disabled={disableUpdate}
          >{t`actionPanel.files.update`}</Button>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
}

const FileNameInput = styled(Input, forwardRef)`
  ${({ theme }) => theme.typography.actionsPanel.acrossPanel.smallText}
  ${({ theme }) => colorIcon(theme.colors.basics.black)}
`;

const PeopleHeader = styled('div')`
  ${({ theme }) => theme.typography.actionsPanel.files.fileName}
  margin-bottom: 12px;
`;

const ShareWithContainer = styled('div')`
  padding: 0 10px;
`;
