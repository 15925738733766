import { Helmet } from 'react-helmet-async';

import favicon16 from '../assets/favicons/favicon-16x16.png';
import favicon32 from '../assets/favicons/favicon-32x32.png';

export const HtmlFontsConfig = () => (
  <Helmet>
    <link
      rel="preload"
      as="font"
      type="font/woff2"
      href="/fonts/wallop-regular.woff2"
      crossOrigin=""
    />
    <link
      rel="preload"
      as="font"
      type="font/woff2"
      href="/fonts/wallop-medium.woff2"
      crossOrigin=""
    />
    <link
      rel="preload"
      as="font"
      type="font/woff2"
      href="/fonts/wallop-semibold.woff2"
      crossOrigin=""
    />
    <link
      rel="preload"
      as="font"
      type="font/otf"
      href="/fonts/neue-haas-unica-pro-regular.otf"
      crossOrigin=""
    />
    <link
      rel="preload"
      as="font"
      type="font/otf"
      href="/fonts/neue-haas-unica-pro-medium.otf"
      crossOrigin=""
    />
    <link rel="stylesheet" href="/fonts.css" />
    <link rel="icon" type="image/png" href={favicon16} sizes="16x16" />
    <link rel="icon" type="image/png" href={favicon32} sizes="32x32" />
  </Helmet>
);
