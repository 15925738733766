import type { MouseEventHandler, ReactElement, ReactNode } from 'react';
import React, { forwardRef, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { usePopper } from 'react-popper';
import { styled } from 'goober';

import { classList } from '@/helpers/class-list';

import type { IconVariant } from '../icons/icon';
import { Portal } from '../portal/portal';

import { DropdownList } from './dropdown.styles';
import { DropdownItemSubList } from './dropdown-item-sub-list';
import { DropdownListItem } from './dropdown-list-item';
import { Tooltip } from '../tooltip/tooltip';

interface DropdownBaseOption {
  label: ReactElement | string;
  subtext?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  isVisible?: boolean;
  icon?: IconVariant;
  tooltipContent?: ReactNode;
  isTooltipVisible?: boolean;
}

export interface DropdownOption extends DropdownBaseOption {
  options?: DropdownBaseOption[];
}

interface WithDropdownProps {
  disabled?: boolean;
  testId?: string;
  items: DropdownOption[];
  children: React.ReactNode;
  options?: Parameters<typeof usePopper>[2];
  fullWidth?: boolean;
  hideOnNoItems?: boolean;
  setIsDropdownOpened?: (isOpened: boolean) => void;
  maxWidth?: string;
}
const DEFAULT_MAX_WIDTH = '210px';

export function WithDropdown({
  disabled,
  testId,
  children,
  items,
  options,
  fullWidth = false,
  hideOnNoItems = false,
  setIsDropdownOpened,
  maxWidth = DEFAULT_MAX_WIDTH,
}: WithDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    options,
  );

  useEffect(() => {
    setIsDropdownOpened?.(isOpen);
  }, [isOpen, setIsDropdownOpened]);

  const toggleDropdown: MouseEventHandler<HTMLDivElement> = event => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleOnMenuItemClick = (callback?: () => void) => {
    callback?.();
    setIsOpen(false);
  };

  const handleClickOutside = () => {
    setIsOpen(false);
  };
  const visibleItems = items?.filter(item => item.isVisible !== false);

  if (!visibleItems?.length && hideOnNoItems === false) {
    return null;
  }

  if (disabled) return <>{children}</>;

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <MenuButtonContainer
        ref={setReferenceElement}
        onClick={toggleDropdown}
        className={classList(isOpen ? 'is-open' : 'is-closed')}
      >
        {children}
      </MenuButtonContainer>
      <Portal>
        <DropdownList
          data-testid={testId}
          className={classList(!isOpen && 'hidden')}
          ref={setPopperElement}
          style={{
            ...styles.popper,
            ...(fullWidth
              ? { width: `${referenceElement?.offsetWidth}px` }
              : { maxWidth }),
          }}
          {...attributes.popper}
        >
          {visibleItems?.map((item, index) => {
            const subMenu =
              item.options?.filter(subItem => subItem.isVisible !== false) ??
              [];
            return (
              <Tooltip
                key={`${index}`}
                content={item.tooltipContent}
                visible={!!item.isTooltipVisible}
              >
                <DropdownListItem
                  item={item}
                  onClick={event => {
                    event.stopPropagation();
                    handleOnMenuItemClick(item.onClick);
                  }}
                >
                  <DropdownItemSubList
                    items={subMenu}
                    onClick={handleOnMenuItemClick}
                  />
                </DropdownListItem>
              </Tooltip>
            );
          })}
        </DropdownList>
      </Portal>
    </OutsideClickHandler>
  );
}

const MenuButtonContainer = styled('div', forwardRef)`
  &:hover {
    cursor: pointer;
  }
`;
