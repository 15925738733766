import { Flexbox } from '@/ui/flexbox/flexbox';
import { SmallText } from '@/ui/typography/widgets';
import { styled } from 'goober';
import type { SearchResultsFilter } from './search.state';

export const FilterButton = ({
  label,
  type,
  quantity,
  onClick,
  isActive,
}: {
  label: string;
  type: SearchResultsFilter;
  quantity: number;
  onClick: (filter: SearchResultsFilter) => void;
  isActive: boolean;
}) => {
  return (
    <FilterContainer
      name="search-filter-container"
      alignItems="center"
      gap="6px"
      onClick={() => onClick(type)}
    >
      <Label isActive={isActive}>{`${label} (${quantity})`}</Label>
    </FilterContainer>
  );
};

const FilterContainer = styled(Flexbox)`
  cursor: pointer;
`;

const Label = styled(SmallText)<{ isActive: boolean }>`
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.blue.primaryA : theme.colors.gray.c10};
  font-weight: ${({ isActive }) => (isActive ? '600' : '400')};
  position: relative;

  ${({ isActive, theme }) =>
    isActive &&
    `
    &:after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: ${theme.colors.blue.primaryA};
    }
  `}
`;
