import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import type { TeamComment } from '@/api/v4/team-comments.api';
import { fetchTeamComments } from '@/api/v4/team-comments.api';
import { queryClient, QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

import { teamIdState } from '../../../team.state';

export const useTeamComments = () => {
  const { t } = useTranslation('default');
  const teamId = useRecoilValue(teamIdState);

  const fetchTeamMeetings = async (): Promise<TeamComment[]> => {
    if (!teamId) return [];
    return fetchTeamComments(teamId);
  };

  return useQuery([QueryKey.TeamComments, teamId], fetchTeamMeetings, {
    onError: () =>
      notify({
        message: t`unknownError`,
      }),
    enabled: !!teamId,
    staleTime: 1000 * 60 * 5,
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.TeamActionsInfo, teamId]);
    },
  });
};
