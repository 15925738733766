import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Loader } from '@/components/loader/loader';
import { UsersMentionTextField } from '@/features/inputs/users-mention-text-field';
import { modalState } from '@/state/modal.state';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Input } from '@/ui/input/input';
import { Inline, Stack } from '@/ui/line/line';

import type { MeetingFormFields } from '../hooks/use-meeting-form';
import { activeMeetingFormState } from '../meetings.state';
import type { MeetingTabContext } from '../meetings-tab';
import { WithLabel } from '../ui/with-label';

import { AttendeesSelect } from './attendees-select';
import { MeetingDateSelect } from './meeting-date-select';
import { RelatedCompanySelect } from './related-company-select';
import { RelatedProjectSelect } from './related-project-select';
import { RelatedTeamSelect } from './related-team-select';

interface MeetingFormProps {
  onCancel: () => void;
  onSubmit: () => void;
  withCompanyField?: boolean;
  withProjectField?: boolean;
  withTeamField?: boolean;
  submitButtonLabel: string;
  isCompanyRequired?: boolean;
  context: MeetingTabContext;
}

export const MeetingForm = ({
  onCancel,
  onSubmit,
  withCompanyField,
  withProjectField,
  withTeamField,
  submitButtonLabel,
  isCompanyRequired,
  context,
}: MeetingFormProps) => {
  const { t } = useTranslation('default');
  const {
    register,
    watch,
    getFieldState,
    reset,
    setValue,
    formState,
    formState: { isSubmitting, isDirty, isValid },
  } = useFormContext<MeetingFormFields>();

  const title = watch('title');
  const relatedCompanyId = watch('relatedCompanyId');
  const relatedTeamId = watch('relatedTeamId');
  const relatedProjectId = watch('relatedProjectId');
  const titleState = getFieldState('title', formState);

  const setModal = useSetRecoilState(modalState);
  const [activeMeetingForm, setActiveMeetingForm] = useRecoilState(
    activeMeetingFormState,
  );

  const [formHeight, setFormHeight] = useState<number | undefined>(undefined);
  const ref = useRef<HTMLDivElement>(null);

  const projectSelectDependencyKey = useMemo(() => {
    if (withCompanyField) {
      return relatedCompanyId;
    }

    if (withTeamField) {
      return relatedTeamId;
    }
    return;
  }, [relatedCompanyId, relatedTeamId, withCompanyField, withTeamField]);

  useEffect(() => {
    return () => {
      reset();
      setActiveMeetingForm({
        ...activeMeetingForm,
        isDirty: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    const height = ref.current?.clientHeight;
    setFormHeight(height);
  }, []);

  const handleCancel = useCallback(() => {
    if (isDirty) {
      setModal({
        state: 'discardMeetingFormChanges',
        mainAction: onCancel,
      });
      return;
    }

    onCancel();
  }, [isDirty, onCancel, setModal]);

  if (isSubmitting) {
    return (
      <LoaderContainer height={formHeight ? formHeight + 'px' : null}>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <Flexbox
      name="meeting-content"
      gap="24px"
      defaultRef={ref}
      direction="column"
      fullWidth
    >
      <Stack gap="16px">
        <Inline gap="6px" templateColumns="3fr 2fr">
          <WithLabel
            name="meeting-name"
            labelText={t`actionPanel.meetings.meetingName`}
          >
            <Input
              padding="4px 8px"
              fontSize="12px"
              placeholder={t`actionPanel.meetings.meetingNamePlaceholder`}
              value={title}
              error={titleState.error?.message}
              isErrorAbsolute
              errorAlign="right"
              {...register('title')}
            />
          </WithLabel>
          <MeetingDateSelect />
        </Inline>

        {withProjectField && (
          <RelatedProjectSelect
            dependencyKey={projectSelectDependencyKey}
            withTeamField={withTeamField}
          />
        )}

        {withCompanyField && (
          <RelatedCompanySelect isRequired={isCompanyRequired} />
        )}

        {withTeamField && <RelatedTeamSelect />}

        <AttendeesSelect context={context} />
      </Stack>

      <WithLabel
        data-name="with-label"
        name="meeting-summary"
        labelText={t`actionPanel.meetings.meetingSummary`}
      >
        <Controller
          data-name="controller"
          name="summary"
          render={({ field: { value, onChange } }) => (
            <UsersMentionTextField
              defaultValue={value}
              onChange={(value, mentionUsers) => {
                setValue('mentionedUsers', mentionUsers);
                onChange(value);
              }}
              organizationId={relatedCompanyId}
              teamId={relatedTeamId}
              projectId={relatedProjectId}
              dataTestId="meetings-tab-new-meeting-summary-input"
            />
          )}
        />
      </WithLabel>

      <Flexbox name="meeting-form-buttons" justify="flex-end">
        <Inline gap="10px">
          <Button
            variant="underlined"
            onClick={handleCancel}
          >{t`cancel`}</Button>
          <Button
            onClick={onSubmit}
            isLoading={isSubmitting}
            disabled={!isDirty || !isValid}
          >
            {submitButtonLabel}
          </Button>
        </Inline>
      </Flexbox>
    </Flexbox>
  );
};

const LoaderContainer = styled('div')<{ height?: string | null }>`
  ${({ height }) => Boolean(height) && `height: ${height}`};
`;
