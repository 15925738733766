import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/ui/button/button';
import { FlexContainer } from '@/ui/flexbox/flexbox';

import { AskXButton } from '../ask-x/ask-x-button';
import { DropdownCreate } from '../navbar/dropdowns/dropdown-create';
import { TopbarWrapper } from '../topbar/topbar';

export const HomeHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('projects');

  const toggleDropdownMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <TopbarWrapper name="home-header" bgColor="transparent">
      <FlexContainer gap="28px" grow={1} justify="flex-end">
        <AskXButton />
        <DropdownCreate
          triggerButton={
            <Button
              variant="secondary"
              startIcon="Plus"
              onClick={toggleDropdownMenu}
            >{t`Create`}</Button>
          }
          options={{
            placement: 'bottom',
            modifiers: [{ name: 'offset', options: { offset: [0, 7] } }],
          }}
          setIsDropdownOpen={setIsOpen}
        />
      </FlexContainer>
    </TopbarWrapper>
  );
};
