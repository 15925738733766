import type { APIRequestBody, APIRequestPathParams } from './base-fetch';
import { patch } from './base-fetch';

export type GenAIRequestResultParams =
  APIRequestPathParams<'/api/v4/genai-request/{requestId}/result/{resultNumber}'>;
export type GenAIRequestResultBody =
  APIRequestBody<'/api/v4/genai-request/{requestId}/result/{resultNumber}'>;

export const updateGenAIRequestResult = async (
  params: GenAIRequestResultParams,
  body: GenAIRequestResultBody,
) =>
  patch(
    `/v4/genai-request/${params.requestId}/result/${params.resultNumber}`,
    body,
  );

export type GenAIRequestResultOrganizationBody =
  APIRequestBody<'/api/v4/genai-request/{requestId}/result/{resultNumber}/organization'>;

export const updateGenAIRequestResultOrganization = async (
  params: GenAIRequestResultParams,
  body: GenAIRequestResultOrganizationBody,
) =>
  patch(
    `/v4/genai-request/${params.requestId}/result/${params.resultNumber}/organization`,
    body,
  );
