import {
  get,
  type APIRequestQueryParams,
  type APIResponse,
} from './base-fetch';

export type SharedCompany = APIResponse<'/api/v4/shared-organizations'>[number];
export type SharedCompaniesParams =
  APIRequestQueryParams<'/api/v4/shared-organizations'>;

export const fetchSharedCompanies = (params: SharedCompaniesParams) => {
  return get<SharedCompany[]>('/v4/shared-organizations', {
    params,
  });
};
