import type { RefObject } from 'react';
import { useRef } from 'react';
import { styled } from 'goober';

import { SearchOptionItem } from '@/components/search/search-option-item';
import { isEllipsis } from '@/helpers/other';
import { colors } from '@/theme/colors';
import type { IOption, OrganizationOption } from '@/types';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import {
  OptionsList,
  OptionsListItem,
} from '@/ui/select/async/components/selected-options-list/selected-options-list';
import { Tooltip } from '@/ui/tooltip/tooltip';

interface ISelectedOptionsList {
  height?: string;
  list?: IOption[] | OrganizationOption[];
  listRef?: RefObject<HTMLDivElement>;
  isListExpanded?: boolean;
  onRemove?: (item: IOption | OrganizationOption) => void;
}

const OPTION_ITEM_HEIGHT = 60;

export function SelectedOptionsEntitiesList({
  list,
  listRef,
  height,
  onRemove,
  isListExpanded = false,
}: ISelectedOptionsList) {
  const ref = useRef<HTMLDivElement>(null);
  const editMode = Boolean(onRemove);

  return (
    <OptionsListEntities
      height={height}
      name="options-list"
      direction="column"
      defaultRef={listRef}
      editMode={editMode}
      isScrollable={Boolean(isListExpanded || onRemove)}
      maxHeight="360px"
    >
      {list?.map(item => {
        return (
          <OptionsListEntitiesItem
            key={item.value}
            name="options-list-item"
            justify="space-between"
            alignItems="center"
          >
            <Tooltip
              placement="left"
              content={item.label}
              visible={ref && isEllipsis(ref.current)}
            >
              <SearchOptionItem ref={ref} data={item} />
            </Tooltip>
            <Actions name="actions" gap="20px" alignItems="center">
              {onRemove && (
                <Icon
                  icon="Close"
                  width="10px"
                  height="10px"
                  color={colors.gray.c13}
                  onClick={() => onRemove(item)}
                />
              )}
            </Actions>
          </OptionsListEntitiesItem>
        );
      })}
    </OptionsListEntities>
  );
}

const Actions = styled(Flexbox)`
  visibility: hidden;
`;

const OptionsListEntities = styled(OptionsList)`
  ${({ editMode }) => editMode && 'margin-top: 12px;'}
  max-height: calc(${OPTION_ITEM_HEIGHT}px * 3);
`;

const OptionsListEntitiesItem = styled(OptionsListItem)`
  min-height: ${OPTION_ITEM_HEIGHT}px;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.c2};

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue.c8};
    ${Actions} {
      visibility: visible;
    }
  }
`;
