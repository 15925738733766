import { Inline } from '@/ui/line/line';
import { FilterButton } from './filter-button';
import type { SearchResultsFilter } from './search.state';
import { styled } from 'goober';

interface SearchResultsFilterData {
  label: string;
  type: SearchResultsFilter;
  resultsQuantity: number;
  isActive: boolean;
}

export const SearchResultsFilters = ({
  filters,
  onFilterClick,
}: {
  filters: SearchResultsFilterData[];
  onFilterClick: (filter: SearchResultsFilter) => void;
}) => {
  return (
    <FiltersContainer>
      {filters.map(filter => (
        <FilterButton
          key={filter.label}
          type={filter.type}
          label={filter.label}
          quantity={filter.resultsQuantity}
          isActive={filter.isActive}
          onClick={onFilterClick}
        />
      ))}
    </FiltersContainer>
  );
};

const FiltersContainer = styled(Inline)`
  padding: 5px;
`;
