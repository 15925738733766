import { atom } from 'recoil';

export const organizationSearchResultsAddedCompaniesState = atom({
  key: 'organizationSearchResultsTableAddedCompaniesState',
  default: [] as string[],
});

export const organizationSearchResultsCompaniesBusyState = atom({
  key: 'organizationSearchResultsTableCompaniesBusyState',
  default: [] as string[],
});
