import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import {
  forwardRef,
  useEffect,
  useMemo,
  useState,
  useDeferredValue,
} from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Loader } from '@/components/loader/loader';
import { filterSearchTags } from '@/helpers/filter-search-tags';
import { Input } from '@/ui/input/input';
import type { TabConfig } from '@/ui/tabs/tabs';
import { Tabs } from '@/ui/tabs/tabs';

import type { EntityTag } from '../../tags/tags';

import { filterTagsByCategory } from './helpers/filter-tags-by-category';
import { TagCategoryTab } from './tag-category-tab';
import { basicTagTabConfig, type TagTab } from './tag-tabs.config';
import { TagsTabControl } from './tags-tab-control';
import { useNotSelectedTags } from './use-not-selected-tags';

interface AddTagsSectionProps {
  selectedTags: EntityTag[];
  isLoading: boolean;
  searchPhrase: string;
  setSearchPhrase: Dispatch<SetStateAction<string>>;
  onTagAdd: (tagId: string) => void;
}

export const AddTagsSection = forwardRef<HTMLInputElement, AddTagsSectionProps>(
  function AddTagsSection(
    { selectedTags, searchPhrase, setSearchPhrase, onTagAdd, isLoading },
    inputRef,
  ) {
    const { t } = useTranslation('tags');
    const [activeTab, setActiveTab] = useState<TagTab>('all');

    const phrase = useDeferredValue(searchPhrase);

    const tags = useNotSelectedTags({ selectedTags });
    const { phrasesParts, doesTagMatchSearchPhrase } = filterSearchTags({
      searchPhrase: phrase,
    });

    useEffect(() => {
      if (activeTab === 'all' || searchPhrase === '') return;

      setSearchPhrase('');
    }, [activeTab, searchPhrase, setSearchPhrase]);

    const searchedTags = useMemo(() => {
      if (phrasesParts.length === 0) return tags;

      return tags.filter(doesTagMatchSearchPhrase);
    }, [tags, doesTagMatchSearchPhrase, phrasesParts]);

    const tabsConfig: TabConfig<TagTab>[] = basicTagTabConfig.map(tab => ({
      id: tab.id,
      title: <TagsTabControl name={t(tab.id)} icon={tab.icon} />,
      content: (
        <TagCategoryTab
          tags={filterTagsByCategory(searchedTags, tab.category)}
          onTagClick={onTagAdd}
        />
      ),
    }));

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
      setSearchPhrase(e.target.value);
      setActiveTab('all');
    };

    if (tags.length === 0 && isLoading) {
      return <Loader />;
    }

    return (
      <>
        <Input
          ref={inputRef}
          value={searchPhrase}
          placeholder={t`searchForTags`}
          onChange={onSearch}
        />
        <TagsTab
          tabsConfig={tabsConfig}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          noShadow
          variant="moon"
        />
      </>
    );
  },
);

const TagsTab = styled(Tabs<TagTab>)`
  height: 100%;
`;
