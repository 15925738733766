import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';

import { mapUsersToOptions } from '@/api/v4/mappers/user-option.mapper';
import type { Team } from '@/api/v4/team.api';
import { fetchUsers } from '@/api/v4/user.api';
import { prepareSafeInput } from '@/helpers/prepare-safe-input';
import { useRoles } from '@/hooks/queries/use-roles.query';
import { EMAIL_REGEX } from '@/regexes';
import type { InvitedUserOption, UserOption } from '@/types';
import { APP_USER_ROLES } from '@/types';
import type { AsyncSelectProps } from '@/ui/select/async/async-select';

export const useInvitedUsersOptions = ({
  invitedUsersList,
  team,
}: {
  invitedUsersList: Array<UserOption | InvitedUserOption>;
  team?: Team;
}) => {
  const { t } = useTranslation('default');

  const { data: appRoles } = useRoles();

  const setOptions: AsyncSelectProps['setOptions'] =
    async originalSearchPhrase => {
      const safeSearchPhrase = prepareSafeInput(originalSearchPhrase);

      const users = await fetchUsers({
        search: safeSearchPhrase,
        sort: 'teamNullFirst',
        direction: 'asc',
      });

      const managerRole = appRoles?.find(
        role => role.name === APP_USER_ROLES.MANAGER,
      );

      if (!managerRole) return [];

      const options: Array<UserOption | InvitedUserOption> =
        mapUsersToOptions(users);

      const emailOnInvitedList = invitedUsersList.find(
        item => item.email === safeSearchPhrase,
      );

      if (emailOnInvitedList) {
        const falseUserOption: UserOption = {
          firstName: null,
          lastName: null,
          team: team ? { id: team.id, name: team.name } : null,
          email: safeSearchPhrase,
          role: emailOnInvitedList.role ?? managerRole,
          status: 'invited',
          value: Math.random(),
          label: safeSearchPhrase,
        };
        options.push(falseUserOption);
      }

      if (
        safeSearchPhrase.match(EMAIL_REGEX) &&
        !options.some(item => item.email === safeSearchPhrase) &&
        !invitedUsersList.some(item => item.email === safeSearchPhrase)
      ) {
        options.push({
          value: safeSearchPhrase,
          label: originalSearchPhrase,
          email: safeSearchPhrase,
          type: 'invited',
          role: managerRole
            ? { id: managerRole.id, name: managerRole.name }
            : null,
        });
      }

      if (isEmpty(options) && safeSearchPhrase.includes('@')) {
        options.push({
          label: t`invite.keepTyping`,
          value: '',
          email: null,
          role: null,
          type: 'invited',
        });
      }

      return options;
    };

  return { setOptions };
};
