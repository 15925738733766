import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { useCreateCompany } from '@/hooks/queries/use-create-company.mutation';
import { notify } from '@/ui/snackbar/notify';

import {
  organizationSearchResultsAddedCompaniesState,
  organizationSearchResultsCompaniesBusyState,
} from './organization-search-results.state';

type UseCreateNewCompanyProps = {
  onCreated?: (companyId: string) => void;
};

export const useCreateNewCompany = ({
  onCreated,
}: UseCreateNewCompanyProps) => {
  const { t } = useTranslation('projects');
  const { createCompanyFromCrunchbase } = useCreateCompany();
  const [addedCompanies, setAddedCompanies] = useRecoilState(
    organizationSearchResultsAddedCompaniesState,
  );
  const setBusyCompanies = useSetRecoilState(
    organizationSearchResultsCompaniesBusyState,
  );

  const addCrunchbaseSnapshot = useCallback(
    async (crunchbaseId: string) => {
      try {
        setBusyCompanies(companyIds => [...companyIds, crunchbaseId]);
        const result = await createCompanyFromCrunchbase({
          cbId: crunchbaseId,
          source: 'SOSA Q',
        });
        setAddedCompanies(companyIds => [...companyIds, crunchbaseId]);
        return result.id;
      } catch (err) {
        notify({
          message: t`addCompany.errorAddingCompany`,
          withIcon: true,
          icon: 'CloseCircle',
        });
        if (err instanceof Error) console.error(err.message);
        return undefined;
      } finally {
        setBusyCompanies(companyIds =>
          companyIds.filter(id => id !== crunchbaseId),
        );
      }
    },
    [setAddedCompanies, setBusyCompanies],
  );

  return useCallback(async ({ crunchbaseId }: { crunchbaseId?: string }) => {
    if (
      !crunchbaseId ||
      (crunchbaseId && addedCompanies.includes(crunchbaseId))
    )
      return;

    const newOrganizationId = await addCrunchbaseSnapshot(crunchbaseId);
    if (newOrganizationId) {
      onCreated?.(newOrganizationId);
    }
  }, []);
};
