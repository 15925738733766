import type { CurrentUser } from '@/api/v4/auth.api';
import { DropdownItem, DropdownList } from '@/ui/dropdown/dropdown.styles';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Stack } from '@/ui/line/line';
import { styled } from 'goober';
import { useTranslation } from 'react-i18next';

type DropdownAccountsContentProps = {
  items: CurrentUser['userAccounts'];
  onClick: (userInAccountId: number) => void;
};

export const DropdownAccountsContent = (
  props: DropdownAccountsContentProps,
) => {
  const { t } = useTranslation('default');

  return (
    <Container width="200px">
      <Flexbox
        name="dropdown-accounts-content-headings"
        justify="space-between"
        alignItems="center"
        padding="12px"
      >
        <Heading>{t`selectAccount`}</Heading>
        <Icon icon="CaretUp" />
      </Flexbox>

      <DropdownList>
        {props.items.map(item => (
          <DropdownItem
            key={item.account.name}
            onClick={() => props.onClick(item.userInAccountId)}
          >
            <span>{item.account.name}</span>
          </DropdownItem>
        ))}
      </DropdownList>
    </Container>
  );
};

const Heading = styled('h4')`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray.c8};
`;

const Container = styled(Stack)`
  box-shadow: ${({ theme }) => theme.shadow.widgets};

  background-color: ${({ theme }) => theme.colors.basics.white};
  ul {
    position: static;
  }
`;
