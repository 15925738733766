export enum DealStageName {
  implementation = 'Implementation',
  investment = 'Investment',
  otherTransaction = 'Other transaction',
}

export enum ListingStageName {
  discovery = 'Discovery',
  potentialFit = 'Potential fit',
  contacted = 'Contacted',
  dueDiligence = 'Due diligence',
  pilot = 'Pilot',
  notRelevant = 'Not relevant',
  deal = 'Deal',
}

export const isDealStage = (stageName: string) => {
  const dealStageNames: string[] = Object.values(DealStageName);

  return dealStageNames.includes(stageName);
};
