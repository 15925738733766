import React, { useEffect } from 'react';

import { useErrorHandlers } from '../error/use-error-handlers';
import { api } from '..';

interface Props {
  children: React.ReactNode;
}

/** @deprecated
 * Delete this component after ruby v3 is fully migrated to v4
 *
 */
export function ApiProvider({ children }: Props) {
  const { handleAuthenticationError, handleNotFoundError } = useErrorHandlers();

  useEffect(() => {
    api.init({
      onAuthenticationError: handleAuthenticationError,
      on404Error: handleNotFoundError,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
}
