import { atom } from 'recoil';

export type ActionPanelTab = 'updates' | 'comments' | 'meetings' | 'files';

export const actionPanelActiveTabState = atom<ActionPanelTab>({
  key: 'actionPanelActiveTabState',
  default: 'updates',
});

export type ActionPanelContext = 'team' | 'project' | 'company';
export type ActionPanel = { context: ActionPanelContext; title: string };

export const actionPanelState = atom<ActionPanel | null>({
  key: 'actionPanelState',
  default: null,
});

export const actionPanelOpenOnLoadState = atom<boolean>({
  key: 'actionPanelOpenOnLoadState',
  default: false,
});

export const actionPanelScrollToParamState = atom<boolean>({
  key: 'actionPanelScrollToParamState',
  default: false,
});
