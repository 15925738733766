import type { ForwardedRef, HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import type { CSSAttribute } from 'goober';
import { styled } from 'goober';

type Gap = string;
type RowGap = string;
type Justify = CSSAttribute['justifyContent'];
type AlignItems = CSSAttribute['alignItems'];
type AlignContent = CSSAttribute['alignContent'];
type Direction = CSSAttribute['flexDirection'];
type Height = CSSAttribute['height'];
type Wrap = CSSAttribute['flexWrap'];
type Grow = CSSAttribute['flexGrow'];
type Padding = CSSAttribute['padding'];
type Margin = CSSAttribute['margin'];
type Flex = CSSAttribute['flex'];

interface FlexContainerProps {
  gap?: Gap;
  rowGap?: RowGap;
  justify?: Justify;
  alignItems?: AlignItems;
  alignContent?: AlignContent;
  direction?: Direction;
  fullWidth?: boolean;
  fullHeight?: boolean;
  height?: Height;
  wrap?: Wrap;
  grow?: Grow;
  padding?: Padding;
  margin?: Margin;
  flex?: Flex;
}

export type FlexBoxProps = FlexContainerProps &
  HTMLAttributes<HTMLDivElement> & {
    name: string;
    dataTestId?: string;
    children: React.ReactNode;
    className?: string;
    defaultRef?: React.RefObject<HTMLDivElement> | ForwardedRef<HTMLDivElement>;
  };

export const Flexbox = ({
  name,
  dataTestId,
  onClick,
  children,
  className,
  defaultRef,
  ...flexProperties
}: FlexBoxProps) => (
  <FlexContainer
    className={className}
    data-name={name}
    data-testid={dataTestId}
    onClick={onClick}
    ref={defaultRef}
    {...flexProperties}
  >
    {children}
  </FlexContainer>
);

export const FlexContainer = styled('div', forwardRef)<FlexContainerProps>`
  display: flex;
  ${({ justify }) => justify && `justify-content: ${justify};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ alignContent }) => alignContent && `align-content: ${alignContent};`}
  ${({ direction }) => direction && `flex-direction: ${direction};`}
  ${({ wrap }) => wrap && `flex-wrap: ${wrap};`}
  ${({ gap }) => gap && `gap: ${gap};`}
  ${({ rowGap }) => rowGap && `row-gap: ${rowGap};`}
  ${({ grow }) => grow && `flex-grow: ${grow};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  ${({ fullHeight }) => fullHeight && `height: 100%;`}
  ${({ height }) => height && `min-height: ${height}; height: ${height};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ margin }) => margin && `margin: ${margin};`}
`;
