import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { sendInquiry } from '@/api/v4/ask-x.api';
import { notify } from '@/ui/snackbar/notify';

export const useSendInquiry = () => {
  const { t } = useTranslation('default');

  return useMutation(sendInquiry, {
    onError: () => {
      notify({
        message: t`default:unknownError`,
      });
    },
  });
};
