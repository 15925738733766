import { useRecoilValue } from 'recoil';

import { modalState } from '@/state/modal.state';

import { ConfirmDeleteModal } from '../confirm-delete-modal';

import { useDeleteTeamFile } from './use-delete-team-file';

export function DeleteTeamFileModal() {
  const modal = useRecoilValue(modalState);

  const { deleteTeamFile } = useDeleteTeamFile();

  if (!modal || modal.state !== 'deleteTeamFile') return null;

  return (
    <ConfirmDeleteModal onConfirm={deleteTeamFile.bind(null, modal.file.id)} />
  );
}
