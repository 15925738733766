import { styled } from 'goober';
import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { OrganizationSearchInitial } from '@/components/organization-search-results/organization-search-initial';
import { OrganizationSearchResults } from '@/components/organization-search-results/organization-search-results';
import { OrganizationSearchResultsEmpty } from '@/components/organization-search-results/organization-search-results-empty';
import { CreateCompanyTable } from '@/features/companies/create-company-table';
import { companiesSearchPhraseState } from '@/features/companies/overview/company.state';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { modalState } from '@/state/modal.state';
import { colors } from '@/theme/colors';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Input } from '@/ui/input/input';
import { Modal } from '@/ui/modal/modal';
import { notify } from '@/ui/snackbar/notify';
import { SubHeaderBold } from '@/ui/typography/widgets';

import type { CreatedCompany } from '@/api/v4/organization.api';
import { useDebounce } from 'use-debounce';
import {
  organizationSearchResultsAddedCompaniesState,
  organizationSearchResultsCompaniesBusyState,
} from '../../companies/search-results/organization-search-results.state';
import { useCreateNewCompany } from '../../companies/search-results/use-create-new-company';
import { useOrganizationsResults } from '../../companies/search-results/use-organizations-results';

type CreateNewCompanyModalProps = {
  closeOnCreate?: boolean;
  defaultSearchPhrase?: string;
  defaultWebsite?: string;
  navigateToPageOnCreated?: boolean;
  onClose?: (createdCompanies: string[]) => Promise<void>;
};

export function CreateNewCompanyModal({
  closeOnCreate = true,
  defaultSearchPhrase = '',
  defaultWebsite = '',
  navigateToPageOnCreated = true,
  onClose,
}: CreateNewCompanyModalProps) {
  const { t } = useTranslation('projects');
  const navigateTo = useNavigateTo();
  const [modalConfig, setModalConfig] = useRecoilState(modalState);
  const [showNewCompanyRow, setShowNewCompanyRow] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const companiesSearchPhraseRecoilState = useRecoilState(
    companiesSearchPhraseState || defaultSearchPhrase,
  );

  const [typingSearchPhrase, setTypingCompaniesSearchPhrase] = useState('');

  const [addedCompanies, setAddedCompanies] = useRecoilState(
    organizationSearchResultsAddedCompaniesState,
  );
  const setBusyCompanies = useSetRecoilState(
    organizationSearchResultsCompaniesBusyState,
  );
  const createNewCompany = useCreateNewCompany({
    onCreated: async companyId => {
      const companyIds = [...addedCompanies, companyId];
      setAddedCompanies(companyIds);
      if (closeOnCreate) {
        setModalConfig(null);
        if (onClose) await onClose(companyIds);
      }
      if (navigateToPageOnCreated) {
        return navigateTo.company({ companyId });
      }
      if (ref.current) ref.current.focus();
    },
  });

  const {
    savvyCompaniesResults,
    crunchbaseCompaniesResults,
    isCrunchbaseCompaniesResultsLoading,
    isSavvyCompaniesResultsLoading,
  } = useOrganizationsResults({
    checkIsAdded: (id, fromCb) => (fromCb ? Boolean(id) : false),
    checkIsActionButtonHidden: fromCb => !fromCb,
  });

  const [debouncedPhrase] = useDebounce(typingSearchPhrase, 450);

  useEffect(() => {
    companiesSearchPhraseRecoilState[1](debouncedPhrase);
  }, [debouncedPhrase, companiesSearchPhraseRecoilState]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setTypingCompaniesSearchPhrase('');
    }

    if (e.target.value) {
      setTypingCompaniesSearchPhrase(e.target.value);
    }
  };

  useEffect(() => {
    if (debouncedPhrase && showNewCompanyRow) {
      setShowNewCompanyRow(false);
    }
  }, [debouncedPhrase, showNewCompanyRow, companiesSearchPhraseRecoilState]);

  const handleCloseModal = useCallback(async () => {
    setModalConfig(null);
    if (addedCompanies.length > 0)
      notify({
        message: t('addCompany.wereAdded', {
          companiesCount: addedCompanies.length,
        }),
        withIcon: true,
      });
    if (onClose) await onClose(addedCompanies);
  }, [addedCompanies, setModalConfig, t]);

  const openNewCompanyForm = useCallback(() => {
    setTypingCompaniesSearchPhrase('');
    setShowNewCompanyRow(true);
  }, []);

  useEffect(() => {
    setAddedCompanies([]);
    setTypingCompaniesSearchPhrase(defaultSearchPhrase);
    setShowNewCompanyRow(false);
  }, []);

  useEffect(() => {
    setAddedCompanies([]);
    setBusyCompanies([]);
  }, [typingSearchPhrase, setAddedCompanies, setBusyCompanies]);

  const onCompanyCreated = (company: CreatedCompany) => {
    navigateTo.company({ companyId: company.id });
    setModalConfig(null);
  };

  const isInitialView = typingSearchPhrase.trim().length === 0;
  const isEmptyView =
    typingSearchPhrase.length > 0 &&
    savvyCompaniesResults?.length === 0 &&
    crunchbaseCompaniesResults?.length === 0 &&
    !isSavvyCompaniesResultsLoading &&
    !isCrunchbaseCompaniesResultsLoading;

  if (!modalConfig) return null;

  return (
    <Modal
      onClose={handleCloseModal}
      CustomHeaderComponent={SubHeaderBold}
      header={t`addCompany.createCompanyProfile`}
    >
      <ContentContainer
        name="add-company-modal-container"
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        gap="28px"
        width="987px"
        height="575px"
      >
        <Flexbox name="modal-input" direction="column" gap="18.5px">
          <Input
            value={typingSearchPhrase}
            placeholder={t`addCompany.searchCompanyPlaceholder`}
            onChange={handleChange}
            autoFocus
            ref={ref}
          />
        </Flexbox>

        <Content
          name="projects-list"
          fullWidth
          justify="flex-start"
          grow={1}
          data-testid="search-companies-results"
        >
          {showNewCompanyRow ? (
            <CreateCompanyTable
              defaultWebsite={defaultWebsite}
              onCompanyCreated={onCompanyCreated}
            />
          ) : isInitialView ? (
            <OrganizationSearchInitial
              addCompanyText={t`addCompany.searchAndCreateSnapshot`}
            />
          ) : isEmptyView ? (
            <OrganizationSearchResultsEmpty
              addCompanyText={t`addCompany.createOwnProfile`}
              noResultText={t`addCompany.couldNotFindCompany`}
              onAddCustomCompanyClick={openNewCompanyForm}
            />
          ) : (
            <OrganizationSearchResults
              buttonAddText={t`addCompany.create`}
              buttonAddedText={t`addCompany.created`}
              savvyResultsHeader={t`addCompany.alreadyOnSavvy`}
              crunchbaseResultsHeader={t`addCompany.createFromCrunchbase`}
              onAddCrunchbaseCompanyClick={createNewCompany}
              savvyCompaniesData={savvyCompaniesResults}
              isSavvyCompaniesLoading={isSavvyCompaniesResultsLoading}
              crunchbaseCompaniesData={crunchbaseCompaniesResults}
              isCrunchbaseCompaniesLoading={isCrunchbaseCompaniesResultsLoading}
              rowClicksRedirectsToWebsite
            />
          )}
        </Content>

        <Flexbox name="modal-footer" justify="flex-end" alignItems="flex-end">
          {!showNewCompanyRow && (
            <Button
              startIcon="Plus"
              variant="white"
              color={colors.blue.primaryA}
              onClick={openNewCompanyForm}
            >
              {t`addCompany.createOwnProfile`}
            </Button>
          )}
        </Flexbox>
      </ContentContainer>
    </Modal>
  );
}

const ContentContainer = styled(Flexbox)<{ width: string }>`
  width: ${({ width }) => width};
  margin-top: 16px;
`;

const Content = styled(Flexbox)`
  ${({ theme }) => theme.mixins.scrollbar}
  overflow: auto;
  & > div {
    width: 100%;
    ${({ theme }) => theme.mixins.scrollbar}
  }
`;
