import { forwardRef, useRef } from 'react';
import { styled } from 'goober';

import { isEllipsis } from '@/helpers/other';

import { Tooltip } from '../tooltip/tooltip';

export const Tab = styled('h2', forwardRef)`
  ${({ theme }) => theme.typography.actionsPanel.acrossPanel.tab}
`;

const EllipsisTabHeader = styled(Tab, forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis};
`;

export const EllipsisTab = ({
  label,
  className,
}: {
  label: string;
  className?: string;
}) => {
  const headerRef = useRef<HTMLHeadingElement | null>(null);
  return (
    <Tooltip
      visible={isEllipsis(headerRef.current)}
      content={label}
      placement="bottom"
    >
      <EllipsisTabHeader className={className} ref={headerRef}>
        {label}
      </EllipsisTabHeader>
    </Tooltip>
  );
};

export const SmallText = styled('h3', forwardRef)<{ color?: string }>`
  ${({ theme }) => theme.typography.actionsPanel.acrossPanel.smallText}
  ${({ theme, color }) => color && `color: ${color ?? theme.colors.gray.c11};`}
`;
