import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { differenceInMonths } from 'date-fns';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useMetadata } from '@/features/companies/hooks/use-metadata';
import { componentsPalette } from '@/theme/colors';
import { notify } from '@/ui/snackbar/notify';

import { companyIdState } from '../overview/company.state';
import { useCompanyTags } from '../overview/tags/use-company-tags.query';
import { useCompany } from '../overview/use-company.query';
import { useCompanyFundings } from '../overview/funding/use-company-fundings.query';
import { useVerifyCompanyMutation } from './use-verify-company.mutation';
import { QueryKey } from '@/config/query-client';
import { modalState } from '@/state/modal.state';

export const useVerifyCompany = () => {
  const { t } = useTranslation('default');
  const { data: company } = useCompany();
  const { data: companyFundings } = useCompanyFundings();
  const queryClient = useQueryClient();
  const companyId = useRecoilValue(companyIdState);

  const { mutateAsync: verifyCompany } = useVerifyCompanyMutation();

  const { hqLocation } = useMetadata();
  const { data: companyTags } = useCompanyTags();
  const setModal = useSetRecoilState(modalState);

  const errorFields = useMemo(() => {
    if (!company) return [];

    const fields = [];
    if (!company.name.value) fields.push('companyName');
    if (!company.activityStatus?.value?.id) fields.push('activityStatus');
    if (!company.keyOffering?.value) fields.push('keyOffering');
    if (!company.website.value) fields.push('website');
    if (!company.linkedInUrl.value) fields.push('linkedin');
    if (!company.yearEstablished.value) fields.push('yearEstablished');
    if (!company.employeesRange?.value?.id) fields.push('employees');
    if (!companyFundings?.fundingStage?.value?.id) fields.push('fundingStage');
    if (!companyFundings?.capitalRaised.value) fields.push('capitalRaised');
    if (!company.detailedDescription.value) fields.push('description');
    if (!company.ownershipStatus?.value?.id) fields.push('ownershipStatus');
    if (!hqLocation?.value?.city?.id) fields.push('headquartersLocation');
    if (!companyTags?.tags || companyTags.tags.length === 0)
      fields.push('tags');
    return fields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, companyTags?.tags, hqLocation?.value?.city?.id]);

  const handleVerify = async () => {
    if (!company) return;

    if (errorFields.length > 0) {
      setModal({
        state: 'verifyCompany',
        errorFields,
      });

      return;
    }

    await verifyCompany(company.id, {
      onError: () => {
        notify({ message: t`unknownError` });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKey.Company, companyId]);
      },
    });
  };

  const isAlreadyVerified = useMemo(() => {
    if (!company) {
      return false;
    }

    return Boolean(company.lastVerifiedAt);
  }, [company]);

  const verificationIconColor = useMemo(() => {
    if (!company?.lastVerifiedAt) {
      return 'transparent';
    }

    const { verified, aboutToExpire, expired } =
      componentsPalette.company.verification;

    const lastVerifiedDifferenceInMonths = differenceInMonths(
      new Date(),
      new Date(company.lastVerifiedAt),
    );

    if (lastVerifiedDifferenceInMonths < 3) {
      return verified;
    }

    if (lastVerifiedDifferenceInMonths < 6) {
      return aboutToExpire;
    }

    return expired;
  }, [company?.lastVerifiedAt]);

  return {
    handleVerify,
    isAlreadyVerified,
    verificationIconColor,
  };
};
