import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import type { Location } from '@/api/v4/locations.api';
import { fetchLocations } from '@/api/v4/locations.api';
import { isDefaultOptionArray } from '@/helpers/other';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { sharedProps } from '@/ui/select/select-shared';
import { BorderedTextArea } from '@/ui/textarea/bordered-text-area';
import { Tooltip } from '@/ui/tooltip/tooltip';

import { OverflowContainer } from '../project-listing-suggestion-form-modal';
import { useProjectListingSuggestionState } from '../use-project-listing-suggestion.state';
export const TEXTAREA_MAX_LENGTH = 2000;

export const EnhanceProjectConfigStep1 = () => {
  const { t } = useTranslation('projects');
  const state = useProjectListingSuggestionState();

  const setOptions = async (search: string) => {
    const results = await fetchLocations({
      search,
      page: 1,
      type: ['continent', 'country'],
    });

    return results.map((location: Location) => ({
      value: JSON.stringify({
        id: location.id,
        type: location.type,
      }),
      label: location.shortDescription,
    }));
  };
  const isStatementOverflow =
    state.problemStatement.length > TEXTAREA_MAX_LENGTH;

  return (
    <OverflowContainer
      name="enhance-project-config1-body"
      height="455px"
      data-testid="tags-results"
      direction="column"
    >
      <Flexbox direction="column" name="enhance-project-config-q1" gap="8px">
        <Question>{t`enhanceConfigQuestionObjective`}</Question>
        <Tooltip
          content={t`enhanceConfigQuestionObjectivePlaceholder`}
          padding="32px"
          maxWidth="250px"
          withArrow
          visible
          placement="right-start"
          showOnHovered={false}
          hideOnClick={false}
          showOnFocused
          disabled={state.problemStatement.length === 0}
          theme="sky"
        >
          <BorderedTextArea
            id="project-listing-suggestion-problem-textarea"
            height={141}
            maxHeight={392}
            resize="vertical"
            maxLength={TEXTAREA_MAX_LENGTH}
            placeholder={t`enhanceConfigQuestionObjectivePlaceholder`}
            onChange={state.setProblemStatement}
            value={state.problemStatement}
            error={isStatementOverflow}
          />
        </Tooltip>
        <ProblemStatementCounter
          isError={isStatementOverflow}
        >{`${state.problemStatement.length}/${TEXTAREA_MAX_LENGTH}`}</ProblemStatementCounter>
      </Flexbox>
      <Flexbox
        direction="column"
        name="enhance-project-config-q2"
        gap="8px"
        margin="0px 0px 20px 0px"
      >
        <Question>{t`enhanceConfigQuestionDesiredSolution`}</Question>
        <Tooltip
          content={t`enhanceConfigQuestionDesiredSolutionPlaceholder`}
          padding="32px"
          maxWidth="250px"
          withArrow
          visible
          placement="right-start"
          showOnHovered={false}
          hideOnClick={false}
          showOnFocused
          disabled={state.desiredSolution.length === 0}
          theme="sky"
        >
          <BorderedTextArea
            id="project-listing-suggestion-desired-solution-textarea"
            height={141}
            maxHeight={392}
            resize="vertical"
            maxLength={TEXTAREA_MAX_LENGTH}
            placeholder={t`enhanceConfigQuestionDesiredSolutionPlaceholder`}
            onChange={state.setDesiredSolution}
            value={state.desiredSolution}
          />
        </Tooltip>
      </Flexbox>
      <Flexbox direction="column" name="enhance-project-config-q3" gap="8px">
        <Question>{t`enhanceConfigQuestionLocation`}</Question>
        <AsyncSelect
          id="project-listing-suggestion-location"
          data-testid="project-listing-suggestion-location-dropdown"
          defaultValue={state.locations}
          isMulti
          isSearchable
          hideSelectedOptions
          setOptions={setOptions}
          onChange={option => {
            if (!isDefaultOptionArray(option) || option.length < 0) return;
            state.setLocations(option);
          }}
          {...sharedProps}
          menuPosition="fixed"
          menuPlacement="auto"
          maxMenuHeight={220}
          value={state.locations}
        />
      </Flexbox>
    </OverflowContainer>
  );
};

const Question = styled('h2')`
  ${({ theme }) => theme.typography.inputs.select.label}
  padding-left: 10px;
`;

const ProblemStatementCounter = styled('p')<{ isError: boolean }>`
  ${({ theme }) => theme.typography.widget.smallText}
  color: ${({ theme, isError }) =>
    isError ? theme.colors.system.lowFit : theme.colors.gray.c12};
  display: flex;
  justify-content: end;
`;
