import { forwardRef } from 'react';
import { styled } from 'goober';

export const PilotHeader = styled('h1')`
  ${({ theme }) => theme.typography.widget.pilotHeader}
`;

export const Header = styled('h3', forwardRef)<{ color?: string }>`
  ${({ theme }) => theme.typography.widget.header}
  ${({ color }) => color && `color: ${color};`}
`;
export const SubHeader = styled('h4', forwardRef)<{ color?: string }>`
  ${({ theme }) => theme.typography.widget.subHeader}
  ${({ color }) => color && `color: ${color};`}
`;
export const SubHeaderBold = styled('h4', forwardRef)<{ color?: string }>`
  ${({ theme }) => theme.typography.widget.subHeaderBold}
  ${({ color }) => color && `color: ${color};`}
`;
export const CapsuleHeader = styled('h5', forwardRef)<{
  color?: string;
  display?: string;
}>`
  ${({ theme }) => theme.typography.widget.capsuleHeader}
  ${({ color }) => color && `color: ${color};`}
  ${({ display }) => display && `display: ${display};`}
`;
export const EmptyState = styled('h6')`
  ${({ theme }) => theme.typography.widget.emptyState}
`;
export const ParagraphLarge = styled('p')`
  ${({ theme }) => theme.typography.widget.paragraphLarge}
  ${({ color }) => color && `color: ${color};`}
`;
export const ParagraphMedium = styled('p', forwardRef)<{ color?: string }>`
  ${({ theme }) => theme.typography.widget.paragraphMedium}
  ${({ color }) => color && `color: ${color};`}
`;
export const ParagraphSmall = styled('p')<{ color?: string }>`
  ${({ theme }) => theme.typography.widget.paragraphSmall}
  ${({ color }) => color && `color: ${color};`}
`;
export const SmallText = styled('p', forwardRef)<{ color?: string }>`
  ${({ theme }) => theme.typography.widget.smallText}
  ${({ color }) => color && `color: ${color};`}
`;
