import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchOrganizationFileScanningStatus } from '@/api/v4/organization-files.api';
import { QueryKey } from '@/config/query-client';
import type { File } from '@/features/action-panel/files/file.interface';
import { notify } from '@/ui/snackbar/notify';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { companyIdState } from '../../company.state';

export const useCompanyFileScanningStatus = (file: File) => {
  const { t } = useTranslation('default');
  const queryClient = useQueryClient();
  const companyId = useRecoilValue(companyIdState);
  const [isError, setError] = useState(false);
  const { hasRequiredPermission } = useUserPermissions();

  const fetchFileScanningStatus = async () => {
    if (!companyId) return;
    return fetchOrganizationFileScanningStatus({
      organizationId: companyId,
      fileId: file.id,
    });
  };

  return useQuery(
    [QueryKey.CompanyFileScanningStatus, companyId, file.id],
    fetchFileScanningStatus,
    {
      onSuccess: async data => {
        if (!data) return;

        if (data.scanningStatus !== 'unscanned') {
          await queryClient.invalidateQueries([
            QueryKey.CompanyFiles,
            companyId,
          ]);
        }

        if (data.scanningStatus === 'infected') {
          await queryClient.invalidateQueries([
            QueryKey.CompanyActionsInfo,
            companyId,
          ]);
          notify({
            message: t('actionPanel.files.infected', { name: file.name }),
          });
        }
        if (data.scanningStatus === 'error') {
          await queryClient.invalidateQueries([
            QueryKey.CompanyActionsInfo,
            companyId,
          ]);
          notify({
            message: t('actionPanel.files.scanningError', { name: file.name }),
          });
        }
      },
      onError: () => {
        setError(true);
        notify({ message: t`unknownError` });
      },
      enabled:
        !!companyId &&
        hasRequiredPermission(PERMISSION.VIEW_FILES) &&
        file.scanningStatus === 'unscanned',
      staleTime: Infinity,
      refetchInterval: file =>
        !isError && file && file.scanningStatus === 'unscanned' ? 1000 : false,
    },
  );
};
