import { styled } from 'goober';

interface CircleIconProps {
  label: string;
  color?: string;
  bgColor?: string;
  width?: string;
  height?: string;
}

export function CircleIcon(props: CircleIconProps) {
  const { label, ...rest } = props;
  return <CircleIconWrapper {...rest}>{label.trim()}</CircleIconWrapper>;
}

const CircleIconWrapper = styled('div')<Omit<CircleIconProps, 'label'>>`
  width: ${props => props.width ?? '30px'};
  height: ${props => props.height ?? '30px'};
  color: ${({ color, theme }) => color ?? theme.colors.basics.white};
  background-color: ${({ bgColor, theme }) => bgColor ?? theme.colors.gray.c12};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  font-size: 12px;
  font-weight: 500;
  user-select: none;
`;
