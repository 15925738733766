import { useRef } from 'react';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import { isEllipsis, isOption } from '@/helpers/other';
import { Icon } from '@/ui/icons/icon';
import { Tooltip } from '@/ui/tooltip/tooltip';

import { OptionContainer, OptionLabel } from '../../async-select.styles';
import type { CustomOptionProps } from '../../use-select-type';

export interface AsyncSelectOptionProps extends OptionProps<unknown, boolean> {
  withCheckIconOnSelect?: CustomOptionProps['withCheckIconOnSelect'];
  hideDisabled?: CustomOptionProps['hideDisabled'];
}

export function AsyncSelectOption({
  withCheckIconOnSelect = true,
  hideDisabled,
  ...props
}: AsyncSelectOptionProps) {
  const ref = useRef<HTMLDivElement>(null);

  if (!isOption(props.data) || (props.isDisabled && hideDisabled)) {
    return <></>;
  }

  return (
    <components.Option {...props}>
      <Tooltip
        placement="left"
        content={props.data.label}
        visible={isEllipsis(ref.current)}
      >
        <OptionContainer isSelected={props.isSelected}>
          <OptionLabel ref={ref}>{props.data.label}</OptionLabel>
          {withCheckIconOnSelect && props.isSelected && (
            <Icon icon="Check" width="18px" />
          )}
        </OptionContainer>
      </Tooltip>
    </components.Option>
  );
}
