import type { OrganizationLocation } from '../api/v3/types/locations';

type LocationNames = Pick<
  OrganizationLocation,
  'cityName' | 'regionName' | 'countryName'
>;

type LocationFormatOptions = {
  compact?: boolean;
};

export const formatLocation = (
  location: LocationNames,
  options?: LocationFormatOptions,
) =>
  (options?.compact
    ? [location?.cityName, location?.countryName]
    : [location?.cityName, location?.regionName, location?.countryName]
  )
    .filter(name => !!name)
    .join(', ');
