import { useTranslation } from 'react-i18next';

import { colors } from '@/theme/colors';
import type { AsyncSelectProps } from '@/ui/select/async/async-select';
import { AsyncSelect } from '@/ui/select/async/async-select';
import { getCustomSharedProps } from '@/ui/select/select-shared';

interface FormSelectProps extends AsyncSelectProps {
  withError?: boolean;
  placeholderColor?: string;
}

export const FormSelect = ({
  withError,
  placeholderColor,
  components: customComponents,
  ...props
}: FormSelectProps) => {
  const { t } = useTranslation('default');
  const { components, styles } = getCustomSharedProps({
    variant: 'small',
    dropdownIndicatorColor: colors.gray.c7,
    withError,
    placeholderColor,
    menuListHeight: '280px',
  });

  return (
    <AsyncSelect
      placeholder={t`select`}
      isClearable
      isSearchable
      components={{ ...components, ...customComponents }}
      styles={styles}
      menuPlacement="auto"
      {...props}
    />
  );
};
