import type { OrganizationOption } from '@/types/controls';

import type { SearchResultsResponse } from '../types';

export const mapSearchApiToCompanies = (
  response: SearchResultsResponse,
): OrganizationOption[] =>
  response.organizations.data.map(org => ({
    value: org.id,
    label: org.attributes.name,
    logo: org.attributes.logo_url,
    domain: org.attributes.domain,
    type: 'company',
  }));
