import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import type { CompanySortOptions } from '@/api/v4/organization.api';
import type { IOption } from '@/types';
import type { ISort } from '@/ui/table/table.types';

import type { ExploreSuggestionData } from './results/use-explore-results-table-config';

const { persistAtom } = recoilPersist();

export interface ExampleCompany {
  id: string;
  name: string;
  website: string;
  logoUrl: string;
}

export const exploreFormDescriptionState = atom<string>({
  key: 'exploreFormDescriptionState',
  default: '',
  effects: [persistAtom],
});

export const exploreFormLocationsState = atom<IOption[]>({
  key: 'exploreFormLocationsState',
  default: [],
  effects: [persistAtom],
});

export const exploreFormExampleCompaniesState = atom<ExampleCompany[]>({
  key: 'exploreFormExampleCompaniesState',
  default: [],
  effects: [persistAtom],
});

export const exploreResultsSelectedCompaniesState = atom<
  ExploreSuggestionData[]
>({
  key: 'exploreResultsSelectedCompaniesState',
  default: [],
});

export const exploreResultsCreatingCompaniesState = atom<
  ExploreSuggestionData[]
>({
  key: 'exploreResultsCreatingCompaniesState',
  default: [],
});

export const exploreResultsCreatedCompaniesState = atom<
  ExploreSuggestionData[]
>({
  key: 'exploreResultsCreatedCompaniesState',
  default: [],
});

export const exploreResultsSortState = atom<ISort<CompanySortOptions | null>>({
  key: 'exploreResultsSortState',
  default: {
    sort: null,
    direction: 'desc',
  },
});

export const isExploreRequestHistoryPaneOpenState = atom<boolean>({
  key: 'isExploreRequestHistoryPaneOpenState',
  default: false,
});

export const generatedShortDescriptionsState = atom<Record<string, string>>({
  key: 'generatedShortDescriptionsState',
  default: {},
});
