import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { AddUserRecentSearchRecordPayload } from '@/api/v4/search.api';
import { addUserRecentSearchRecord as addUserRecentSearchRecordAPI } from '@/api/v4/search.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';
import { useCurrentUser } from '@/user/use-current-user.query';

export const useAddRecentSearchRecord = () => {
  const { t } = useTranslation('default');
  const { data: user } = useCurrentUser();
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation(
    async (data: AddUserRecentSearchRecordPayload) =>
      await addUserRecentSearchRecordAPI(data),
    {
      onError: (error: Error) => {
        notify({
          message: t`unknownError`,
        });
        console.error(error.message);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          [QueryKey.UserRecentSearchRecords, user?.id],
          {
            refetchType: 'inactive',
          },
        );
      },
    },
  );

  const addRecentSearchRecord = (data: AddUserRecentSearchRecordPayload) =>
    mutateAsync(data);

  return {
    addRecentSearchRecord,
    isLoading,
  };
};
