import { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import type { EditCommentCallback } from '@/features/action-panel/comments/comment.type';
import { CommentsTab } from '@/features/action-panel/comments/comments-tab';
import { companyIdState } from '@/features/companies/overview/company.state';
import { modalState } from '@/state/modal.state';

import { useCompanyComments } from './use-company-comments.query';
import { useCreateCompanyComment } from './use-create-company-comment.mutation';
import { useDeleteCompanyComment } from './use-delete-company-comment.mutation';
import { useEditCompanyComment } from './use-edit-company-comment.mutation';

export const CompanyCommentsTab = () => {
  const companyId = useRecoilValue(companyIdState);
  const [loading, setLoading] = useState<boolean>(false);

  const setModal = useSetRecoilState(modalState);

  const { data, isFetching } = useCompanyComments();
  const { createComment } = useCreateCompanyComment();
  const { editComment } = useEditCompanyComment();
  const { deleteComment } = useDeleteCompanyComment();
  if (!companyId) return null;

  const submitComment = async (comment: string, mentionedUsers: number[]) => {
    const data = await createComment({
      organizationId: companyId,
      comment,
      mentionedUsers,
    });

    return data.id;
  };

  const handleDeleteComment = async (commentId: string) => {
    setLoading(true);
    await deleteComment(commentId);

    setLoading(false);
  };

  const handleEditComment: EditCommentCallback<string> = async (
    commentId,
    data,
  ) => {
    setLoading(true);

    const comment = await editComment({
      commentId,
      comment: data.comment,
      mentionedUsers: data.mentionedUsers,
    });

    setLoading(false);

    return comment.id;
  };

  const openDeleteModal = async (commentId: string) => {
    setModal({
      state: 'confirmDelete',
      onConfirm: () => handleDeleteComment(commentId),
    });
  };

  return (
    <CommentsTab
      onSubmit={submitComment}
      comments={data || []}
      onClickDelete={openDeleteModal}
      onEditSubmit={handleEditComment}
      loading={loading || isFetching}
    />
  );
};
