import { forwardRef } from 'react';
import { styled } from 'goober';

import type { IAlign } from '../table.types';

export const TablePrimaryCell = styled('div', forwardRef)<{
  maxWidth?: number;
}>`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
  ${({ theme }) => theme.mixins.ellipsis}
  ${({ theme }) => theme.typography.widget.smallText}
  color: ${({ theme }) => theme.colors.basics.black};

  a {
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
    ${({ theme }) => theme.mixins.ellipsis}
    ${({ theme }) => theme.typography.widget.smallText}
    color: inherit;
    display: inline-block;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const TableSecondaryCell = styled('div', forwardRef)<{
  maxWidth?: number;
  textAlign?: IAlign;
}>`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  ${({ theme }) => theme.mixins.ellipsis}
  ${({ theme }) => theme.typography.companyInfo.subHeader}
  color: ${({ theme }) => theme.colors.gray.c12};

  a {
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
    ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
    ${({ theme }) => theme.mixins.ellipsis}
    ${({ theme }) => theme.typography.companyInfo.subHeader}
    color: inherit;
    display: inline-block;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
