import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash-es';
import { useRecoilState } from 'recoil';

import { useSidebars } from '@/hooks/use-sidebars';

import { scrollToSection } from '../../helpers/scroll-to-section';
import { useFreeTextFields } from '../free-text-fields/use-free-text-fields';
import { useOfficeLocations } from '../office-locations/use-office-locations';

import { CompanySection, selectedSectionState } from './sections-pane.state';
import { useCompany } from '../use-company.query';

/* sectionsList sort order according to UI requirements https://docs.google.com/spreadsheets/d/1U5N6BDJWZQiZm__xTnCDBOfa401k9jYRVzl1NZldpwQ/edit#gid=415003847 */

interface SectionListItem {
  order: number;
  name: string;
  section: CompanySection;
  isHidden?: boolean;
}

export const useSectionsPane = () => {
  const { t } = useTranslation('companies');
  const { isSectionsPaneOpen, toggleSectionsPane } = useSidebars();
  const [selectedSection, setSelectedSection] =
    useRecoilState(selectedSectionState);
  const { isFreeTextFieldAdded } = useFreeTextFields();
  const { isOfficeLocationsWidgetAdded } = useOfficeLocations();
  const { isCrunchbaseCompany } = useCompany();

  const sectionsList: SectionListItem[] = useMemo(() => {
    const companySections: SectionListItem[] = [
      { order: 2, name: t`company.about`, section: CompanySection.ABOUT },
      {
        order: 3,
        name: t`company.editorNote`,
        section: CompanySection.EDITOR_NOTE,
      },
      { order: 4, name: t`funding.header`, section: CompanySection.FUNDING },
      {
        order: 5,
        name: t`investors.header`,
        section: CompanySection.INVESTORS,
      },
      { order: 6, name: t`clients.header`, section: CompanySection.CLIENTS },
      {
        order: 8,
        name: t`company.listedIn`,
        section: CompanySection.LISTED_IN,
      },
      { order: 9, name: t`tags.tags`, section: CompanySection.TAGS },
      { order: 10, name: t`ranking.header`, section: CompanySection.RANKING },
      { order: 11, name: t`products.product`, section: CompanySection.PRODUCT },
      {
        order: 13,
        name: t`newsFeed.header`,
        section: CompanySection.NEWS_FEED,
        isHidden: !isCrunchbaseCompany,
      },
      {
        order: 14,
        name: t`contacts:widgetHeader`,
        section: CompanySection.CONTACTS,
      },
      {
        order: 15,
        name: t`company.sections.officeLocations`,
        section: CompanySection.OFFICE_LOCATIONS,
        isHidden: !isOfficeLocationsWidgetAdded,
      },
      {
        order: 16,
        name: t`company.sections.freeText`,
        section: CompanySection.FREE_TEXT,
        isHidden: !isFreeTextFieldAdded,
      },
    ];
    return sortBy(companySections, 'order').filter(
      (section: SectionListItem) => !section?.isHidden,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFreeTextFieldAdded, isOfficeLocationsWidgetAdded, isCrunchbaseCompany]);

  const closeSectionPane = () => {
    toggleSectionsPane();
    setSelectedSection(null);
  };

  const onSectionClick = (section: CompanySection) => {
    setSelectedSection(section);
    scrollToSection(section);
  };

  return {
    sectionsList,
    closeSectionPane,
    toggleSectionsPane,
    isSectionsPaneOpen,
    selectedSection,
    onSectionClick,
  };
};
