import { forwardRef } from 'react';
import type { DefaultTheme } from 'goober';
import { styled } from 'goober';

import { BetaSign, Logo, LogoText } from '@/assets/icons';
import { Z_INDEX_SIDEBAR } from '@/theme/z-index';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { CircleIcon } from '@/ui/icons/circle';
import { colorIcon } from '@/ui/icons/functions';
import { Icon } from '@/ui/icons/icon';
import { RouteLink } from '@/ui/link/route-link';

export const CLOSED_NAVBAR_WIDTH = '75px';
export const OPEN_NAVBAR_WIDTH = '180px';

export const NavContainer = styled(Flexbox)`
  background-color: ${({ theme }) => theme.colors.basics.white};
  border-right: 1px solid #cdcfd0;
  min-width: ${CLOSED_NAVBAR_WIDTH};
  width: ${CLOSED_NAVBAR_WIDTH};
  height: 100vh;
  overflow-x: hidden;
  ${({ theme }) => theme.mixins.transition(['width', 'min-width'])}

  &.is-open {
    min-width: ${OPEN_NAVBAR_WIDTH};
    width: ${OPEN_NAVBAR_WIDTH};
  }

  &.is-fixed {
    position: fixed;
    left: 0;
    top: 0;
    z-index: ${Z_INDEX_SIDEBAR};
  }
`;

export const NavMenuContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const NavButtonDefault = (theme: DefaultTheme) => `
  display: flex;
  flex-grow: 1;
  gap: 4px;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  color: ${theme.colors.basics.black};
  ${theme.mixins.transition(['background-color', 'color'])}

  svg * {
    ${theme.mixins.transition(['fill', 'stroke'])}
  }

  span {
    opacity: 0;
    ${theme.mixins.transition('opacity')}

    .is-open & {
      opacity: 1;
    }
  }
 
  `;

export const NavButton = styled('div', forwardRef)<{ selected?: boolean }>`
  ${({ theme }) => NavButtonDefault(theme)}
  ${({ theme }) => theme.typography.acrossPlatform.buttonText}

  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  gap: 4px;
  &:hover {
    ${({ theme, selected }) =>
      !selected &&
      `
      background-color: ${theme.colors.blue.c9};
      color: ${theme.colors.blue.primaryB};
      ${colorIcon(theme.colors.blue.primaryB)}
    `};
  }

  ${({ theme, selected }) =>
    selected &&
    `
      background-color: ${theme.colors.blue.c8};
      color: ${theme.colors.blue.primaryA};
      ${colorIcon(theme.colors.blue.primaryA)}
  `};

  .is-open & {
    span {
      opacity: 1;
    }
  }
`;

export const NavMenuItem = styled('div')<{ isActive: boolean }>`
  ${({ theme }) => NavButtonDefault(theme)}
  ${({ theme }) => theme.typography.acrossPlatform.buttonText}

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue.c9};
    color: ${({ theme }) => theme.colors.blue.primaryB};

    ${({ theme, isActive }) =>
      !isActive && colorIcon(theme.colors.blue.primaryB)}
  }

  ${({ theme, isActive }) =>
    isActive &&
    `background-color: ${theme.colors.accent.pink.c1};
    color: ${theme.colors.accent.pink.c3};`}
`;

export const NavToggle = styled(Icon)`
  width: 18px;
  height: 18px;
  margin-left: 9px;
  cursor: pointer;
  ${({ theme }) => theme.mixins.transition('transform')}

  .is-open & {
    transform: rotate3d(0, 0, 1, 180deg);
  }
`;

export const NavSeparator = styled('div')`
  background-color: ${({ theme }) => theme.colors.gray.c7};
  height: 1px;
  margin: 24px 0;
`;

export const LogoContainer = styled(RouteLink)`
  display: flex;
  height: 37px;
  margin-bottom: 50px;
`;

export const LogoIcon = styled(Logo)`
  width: 37px;
  height: 37px;
  ${({ theme }) => theme.mixins.transition(['transform', 'width', 'height'])}

  .is-open & {
    transform: translate3d(8px, 3px, 0);
  }
`;

export const LogoTextIcon = styled(LogoText)`
  opacity: 0;
  width: 0;
  overflow: hidden;
  transform: scale(0);
  ${({ theme }) => theme.mixins.transition(['opacity', 'transform', 'width'])}

  .is-open & {
    opacity: 1;
    width: 66.91px;
    transform: translate3d(8px, 8px, 0) scale3d(1, 1, 1);
  }
`;

export const AccountSettingsIcon = styled(CircleIcon, forwardRef)<{
  selected: boolean;
  isButtonHovered: boolean;
}>`
  margin: 3px;
  text-transform: uppercase;

  ${({ theme, isButtonHovered, selected }) =>
    isButtonHovered &&
    !selected &&
    `
       background-color: ${theme.colors.blue.primaryB};
   `};

  ${({ theme, selected }) =>
    selected &&
    `
     background-color: ${theme.colors.blue.primaryA};
 `};
`;

export const BetaIcon = styled(BetaSign)`
  position: absolute;
  top: 2px;
  right: 0;
`;
