import { useQuery } from '@tanstack/react-query';
import { HttpStatusCode } from 'axios';
import { useRecoilValue } from 'recoil';

import { isBaseError } from '@/api/v4/base-fetch';
import { fetchTeam } from '@/api/v4/team.api';
import { QueryKey } from '@/config/query-client';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { teamIdState } from '../team.state';

export const useTeam = () => {
  const { hasAtLeastOnePermission } = useUserPermissions();
  const teamId = useRecoilValue(teamIdState);
  const navigateTo = useNavigateTo();

  const getTeam = () => {
    if (!teamId) return;
    return fetchTeam(teamId);
  };

  return useQuery([QueryKey.Team, teamId], getTeam, {
    onError: (error: unknown) => {
      if (isIdNotRecognizedError(error)) {
        navigateTo.notFound();
      }
    },
    retry: false,
    enabled:
      !!teamId && hasAtLeastOnePermission([PERMISSION.VIEW_TEAM_HOME_PAGE]),
  });
};

const isIdNotRecognizedError = (error: unknown) => {
  return (
    isBaseError(error) &&
    (error.response.status === HttpStatusCode.NotFound ||
      error.response.status === HttpStatusCode.BadRequest)
  );
};
