import { ProjectStatus } from '@/api/v4/projects.api';
import i18n from '@/lib/i18n/i18n';

export const projectStatusOptions = [
  {
    label: i18n.t('projects:projectStatus.active'),
    value: ProjectStatus.active,
  },
  {
    label: i18n.t('projects:projectStatus.onHold'),
    value: ProjectStatus.onHold,
  },
  {
    label: i18n.t('projects:projectStatus.stuck'),
    value: ProjectStatus.stuck,
  },
  {
    label: i18n.t('projects:projectStatus.completed'),
    value: ProjectStatus.done,
  },
];
