import { stringToDraftJSContentStateString } from '@/helpers/draftjs';
import type {
  DraftEditorCommand,
  DraftHandleValue,
  RawDraftContentState,
} from 'draft-js';
import {
  getDefaultKeyBinding,
  RichUtils,
  EditorState,
  convertFromRaw,
} from 'draft-js';
import { useCallback, useEffect, useState } from 'react';
import { isJSON } from '@/helpers/is-json';

type RichTextKeyEvent =
  | 'enter'
  | 'new-line'
  | 'submit'
  | 'bold'
  | 'italic'
  | 'underline'
  | 'ordered-list'
  | 'unordered-list'
  | 'link';

export const useEditor = ({
  onSubmit,
  defaultValue,
  readOnly,
}: {
  onSubmit?: () => void;
  defaultValue: string | null;
  readOnly?: boolean;
}) => {
  const [editor, setEditor] = useState<EditorState | null>(null);

  const resetEditor = useCallback(() => {
    if (!editor) return;
    setEditor(RichUtils.onDelete(editor));
  }, [editor]);

  const parseEditorValue = (summary: string) => {
    if (isJSON(summary)) return summary;
    return stringToDraftJSContentStateString(summary);
  };

  useEffect(() => {
    setEditor(currentEditor => {
      if (!readOnly && currentEditor) return currentEditor;

      if (defaultValue === '' || !defaultValue)
        return EditorState.createEmpty();

      const parsedData = JSON.parse(
        parseEditorValue(defaultValue),
      ) as RawDraftContentState;

      const fromRawData = convertFromRaw(parsedData);

      return EditorState.createWithContent(fromRawData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, readOnly]);

  const handleOnTab = (e: React.KeyboardEvent) => {
    e.preventDefault();
    if (editor) {
      setEditor(RichUtils.onTab(e, editor, 4));
    }
  };

  const handleKeyCommand = useCallback(
    (command: RichTextKeyEvent): DraftHandleValue => {
      if (command === 'submit') {
        onSubmit?.();

        if (onSubmit) resetEditor();

        return 'handled';
      }

      if (editor) {
        switch (command) {
          case 'new-line':
            setEditor(RichUtils.insertSoftNewline(editor));
            return 'handled';
          case 'bold':
            setEditor(RichUtils.toggleInlineStyle(editor, 'BOLD'));
            return 'handled';
          case 'italic':
            setEditor(RichUtils.toggleInlineStyle(editor, 'ITALIC'));
            return 'handled';
          case 'underline':
            setEditor(RichUtils.toggleInlineStyle(editor, 'UNDERLINE'));
            return 'handled';
          case 'ordered-list':
            setEditor(RichUtils.toggleBlockType(editor, 'ordered-list-item'));
            return 'handled';
          case 'unordered-list':
            setEditor(RichUtils.toggleBlockType(editor, 'unordered-list-item'));
            return 'handled';
          default:
            return 'not-handled';
        }
      }

      return 'not-handled';
    },
    [editor, onSubmit, resetEditor],
  );

  const myKeyBindingFn = useCallback(
    (
      event: React.KeyboardEvent<HTMLElement>,
    ): RichTextKeyEvent | DraftEditorCommand | null => {
      if (event.key === 'Enter') {
        if (event.shiftKey) {
          return 'new-line';
        }

        if (event.ctrlKey || event.metaKey) {
          return 'submit';
        }
      }
      if (event.ctrlKey || event.metaKey) {
        if (event.shiftKey && (event.key === '7' || event.key === '&'))
          return 'ordered-list';
        if (event.shiftKey && (event.key === '8' || event.key === '*'))
          return 'unordered-list';

        switch (event.key.toLowerCase()) {
          case 'b':
            return 'bold';
          case 'i':
            return 'italic';
          case 'u':
            return 'underline';
          default:
            return getDefaultKeyBinding(event);
        }
      }

      return getDefaultKeyBinding(event);
    },
    [],
  );

  return {
    resetEditor,
    editor,
    setEditor,
    handleKeyCommand,
    myKeyBindingFn,
    handleOnTab,
  };
};
