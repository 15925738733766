import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useSetRecoilState } from 'recoil';

import { api, isKnownError } from '@/api';
import { mapSearchApiToCompanies } from '@/api/mappers/search-companies.mapper';
import { mapSearchApiToProjects } from '@/api/mappers/search-projects.mapper';
import { SearchEntitySelect } from '@/components/search/search-entity-select';
import { isDefaultEntityArray, isDefaultOption } from '@/helpers/other';
import type { IndexParams } from '@/types';
import { APP_USER_ROLES } from '@/types';
import { Stack } from '@/ui/line/line';
import type { CustomAsyncSelectProps } from '@/ui/select/async/use-select-type';

import { SelectedOptionsEntitiesList } from '../selected-options-entities-list';
import type { Entity, UserRoleOption } from '../settings.state';
import { settingsChangeUserRoleFormState } from '../settings.state';

type ChangeUserRoleProps = {
  userId: number;
  userDisplayName: string;
  currentRole: UserRoleOption;
  newRole: UserRoleOption;
  hasLinkedEntities: boolean;
  setActionDisable: (value: boolean) => void;
};

export const ChangeUserRole = (props: ChangeUserRoleProps) => {
  const { t } = useTranslation(['default', 'settings']);

  const [selectedOptions, setSelectedOptions] = useState<Entity[]>([]);
  const setUserRoleForm = useSetRecoilState(settingsChangeUserRoleFormState);

  useEffect(() => {
    setUserRoleForm({
      userId: props.userId,
      newRoleId: props.newRole.id,
      entities: selectedOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.newRole.id, props.userId, selectedOptions]);

  const setOptions: CustomAsyncSelectProps['setOptions'] = async (
    search,
    page,
  ) => {
    if (search.length < 1) return [];
    const params: IndexParams = { page, search };

    const response = await api.searchForAutocomplete(params);

    if (isKnownError(response.data)) throw Error(response.data);

    const groupedOptions = [
      {
        label: t`default:companies`,
        options: mapSearchApiToCompanies(response.data),
      },
      {
        label: t`default:projects`,
        options: mapSearchApiToProjects(response.data),
      },
    ];

    return groupedOptions;
  };

  const handleOnChange: CustomAsyncSelectProps['onChange'] = (
    newValue,
    actionMeta,
  ) => {
    if (actionMeta.action === 'select-option') {
      isDefaultEntityArray(newValue) && setSelectedOptions(newValue);
    }

    if (
      actionMeta.action === 'remove-value' &&
      isDefaultOption(actionMeta.removedValue)
    ) {
      setSelectedOptions((old: Entity[]) =>
        old.filter(
          item =>
            isDefaultOption(actionMeta.removedValue) &&
            item.value !== actionMeta.removedValue.value,
        ),
      );
    }
  };

  const selectedNewRoleIsGuest = props.newRole.label === APP_USER_ROLES.GUEST;

  return (
    <Content gap="16px">
      <Stack gap="0px">
        <Description>
          <Trans
            i18nKey={`settings:modal.changeRole.warning.${props.currentRole.label.toLowerCase()}.${props.newRole.label.toLowerCase()}${
              props.hasLinkedEntities && selectedNewRoleIsGuest
                ? 'WithLinkedEntities'
                : ''
            }`}
            values={{ name: props.userDisplayName }}
            components={{ bold: <strong /> }}
          />
        </Description>
      </Stack>

      {selectedNewRoleIsGuest && !props.hasLinkedEntities && (
        <SearchEntitySelect
          placeholder={t`settings:searchCompanyOrProject`}
          setOptions={setOptions}
          onChange={handleOnChange}
          defaultValue={selectedOptions}
          SelectedOptionsListComponent={<SelectedOptionsEntitiesList />}
        />
      )}
    </Content>
  );
};

const Content = styled(Stack)`
  padding: 24px 0 70px 0;
`;

const Description = styled('p')`
  ${({ theme }) => theme.typography.acrossPlatform.small};
  color: ${({ theme }) => theme.colors.gray.c12};
  width: 640px;

  /* Workaround to make the strong font-weight enabled inside the trans component */
  strong {
    font-weight: 500;
  }
`;
