import { createContext } from 'react';
import type Select from 'react-select/dist/declarations/src/Select';

interface AsyncSelectWithListContextValue {
  selectRef?: Select<unknown, boolean> | null;
  setSelectRef?: React.Dispatch<
    React.SetStateAction<Select<unknown, boolean> | null>
  >;
}

const AsyncSelectWithListContext =
  createContext<AsyncSelectWithListContextValue>({});

export { AsyncSelectWithListContext };
