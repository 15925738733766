export function formatEmpty<T>(nullable: T | null | undefined): T | '-' {
  if (!nullable) {
    return '-';
  }
  return nullable;
}

export function formatNotApplicable<T>(
  nullable: T | null | undefined,
): T | 'N/A' {
  if (!nullable) {
    return 'N/A';
  }
  return nullable;
}
