import i18n from 'i18next';
import { z } from 'zod';

import { URL_REGEX } from '@/regexes';

export const schemaNewCompany = z.object({
  name: z
    .string()
    .max(20)
    .trim()
    .min(1, i18n.t`projects:addCompany.errors.nameRequired`),
  website: z.string().refine(value => URL_REGEX.test(value), {
    message: i18n.t`projects:addCompany.errors.websiteInvalid`,
  }),
});
