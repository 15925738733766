import type {
  APIRequestBody,
  APIRequestQueryParams,
  APIResponse,
} from './base-fetch';
import { get, post, put, remove } from './base-fetch';

export interface ErrorResponseV4 {
  response: {
    data: {
      statusCode: number;
      message: string;
    };
    status: number;
    statusText: string;
  };
}

type UploadOrganizationLogo =
  APIResponse<'/api/v4/organization/{organizationId}/upload-logo'>;

export type CompanyFreeTextField =
  APIResponse<'/api/v4/organization/{organizationId}/free-text-fields'>[number];

type CheckOrganizationDomainUsageResponse =
  APIResponse<'/api/v4/organization/check-domain'>;

export type Company = APIResponse<'/api/v4/organization/list'>[number];

export type CompanyFreeTextFieldInput = Optional<CompanyFreeTextField, 'id'>;

export type CompanyOfficeLocationPayload =
  APIRequestBody<'/api/v4/organization/{organizationId}/office-locations/add'>;

type CompanyCreatorQueryParams =
  APIRequestQueryParams<'/api/v4/organization/creators'>;

export type CompaniesKPIs = APIResponse<'/api/v4/organization/kpi'>;

export type DeleteCompaniesPayload = {
  organizationIds: string[];
};

export type DeleteCompaniesResponse =
  APIResponse<'/api/v4/organization/delete'>;

type CompanyListQueryParams = Omit<
  APIRequestQueryParams<'/api/v4/organization/list'>,
  'min' | 'max'
>;

export type CompanySortOptions = NonNullable<CompanyListQueryParams['sort']>;

export type CompaniesFilters = Pick<
  CompanyListQueryParams,
  | 'tags'
  | 'createdBy'
  | 'employeesRange'
  | 'fundingStages'
  | 'hqCities'
  | 'listingStatuses'
  | 'ranking'
  | 'createdBy'
  | 'verified'
  | 'capitalRaised'
  | 'search'
> & {
  'yearFounded[min]'?: string | null;
  'yearFounded[max]'?: string | null;
};

export type CompanyOfficeLocation =
  APIResponse<'/api/v4/organization/{organizationId}/office-locations'>[number];

export const fetchCompanies = async (params: CompanyListQueryParams) =>
  get<Company[]>('v4/organization/list', {
    params,
  });

export const deleteCompanies = async (params: DeleteCompaniesPayload) =>
  remove<DeleteCompaniesResponse>('v4/organization/delete', {
    params,
  });

export const fetchCompaniesKPIs = async (params: CompaniesFilters) =>
  get<CompaniesKPIs>(`v4/organization/kpi`, {
    params,
  });

export const uploadOrganizationLogo = async (
  organizationId: string,
  logo: File,
) => {
  const formData = new FormData();
  formData.append('file', logo);

  return post<UploadOrganizationLogo>(
    `/v4/organization/${organizationId}/upload-logo`,
    formData,
  );
};

export const fetchCompanyFreeTextFields = async (organizationId: string) =>
  get<CompanyFreeTextField[]>(
    `v4/organization/${organizationId}/free-text-fields`,
  );

export const createCompanyFreeTextField = async ({
  organizationId,
  data,
}: {
  organizationId: string;
  data: CompanyFreeTextFieldInput;
}) =>
  post<CompanyFreeTextField, CompanyFreeTextFieldInput>(
    `v4/organization/${organizationId}/free-text-fields/create`,
    data,
  );

export const updateCompanyFreeTextField = async ({
  organizationId,
  data,
}: {
  organizationId: string;
  data: CompanyFreeTextField;
}) =>
  put<CompanyFreeTextField, CompanyFreeTextField>(
    `v4/organization/${organizationId}/free-text-fields/update`,
    data,
  );

export const deleteCompanyFreeTextField = async ({
  organizationId,
  fieldId,
}: {
  organizationId: string;
  fieldId: string;
}) =>
  remove<void>(
    `v4/organization/${organizationId}/free-text-fields/delete/${fieldId}`,
  );

type CompanyCreator = APIResponse<'/api/v4/organization/creators'>[number];

export const fetchCompanyCreators = async (params: CompanyCreatorQueryParams) =>
  get<CompanyCreator[]>(`v4/organization/creators`, {
    params,
  });

export type EmployeeRange =
  APIResponse<'/api/v4/organization/employee-ranges'>[number];

export const fetchEmployeeRanges = async () =>
  get<EmployeeRange[]>(`v4/organization/employee-ranges`);

export type OrganizationOption =
  APIResponse<'/api/v4/organization/options'>[number];
export type OrganizationOptionQueryPayload =
  APIRequestQueryParams<'/api/v4/organization/options'>;

export const fetchOrganizationOptions = (
  params: OrganizationOptionQueryPayload,
) => get<OrganizationOption[]>('v4/organization/options', { params });

export const fetchOrganizationOptionsCount = (
  params: OrganizationOptionQueryPayload,
) => get<number>('v4/organization/options/count', { params });

export const fetchOrganizationOptionById = (organizationId: string) =>
  get<OrganizationOption>(`v4/organization/options/${organizationId}`);

export const fetchCompanyOfficeLocations = async (organizationId: string) =>
  get<CompanyOfficeLocation[]>(
    `v4/organization/${organizationId}/office-locations`,
  );

export const addCompanyOfficeLocation = async (
  organizationId: string,
  data: CompanyOfficeLocationPayload,
) =>
  post<{ id: string }, CompanyOfficeLocationPayload>(
    `v4/organization/${organizationId}/office-locations/add`,
    data,
  );

export const deleteCompanyOfficeLocation = async (
  organizationId: string,
  officeLocationId: string,
) =>
  remove<void>(
    `v4/organization/${organizationId}/office-locations/delete/${officeLocationId}`,
  );

export type ShareOrganizationBody =
  APIRequestBody<'/api/v4/organization/{organizationId}/share'>;

export const shareOrganization = async (
  organizationId: string,
  body: ShareOrganizationBody,
) => post<void>(`v4/organization/${organizationId}/share`, body);

export const checkOrganizationDomainUsage = async (
  url: string,
): Promise<CheckOrganizationDomainUsageResponse> => {
  return get<CheckOrganizationDomainUsageResponse>(
    `v4/organization/check-domain?url=${encodeURIComponent(url)}`,
  );
};
export type CreateCompanyPayload =
  APIRequestBody<'/api/v4/organization/create'>;
export type CreatedCompany = APIResponse<'/api/v4/organization/create'>;

export const createCompany = async (payload: CreateCompanyPayload) => {
  return post<CreatedCompany>('v4/organization/create', payload);
};

export type CreateCompanyFromCBPayload =
  APIRequestBody<'/api/v4/organization/create-from-cb'>;

export type CreatedCompanyFromCB =
  APIResponse<'/api/v4/organization/create-from-cb'>;

export const createCompanyFromCB = async (
  payload: CreateCompanyFromCBPayload,
) => {
  return post<CreatedCompanyFromCB>('v4/organization/create-from-cb', payload);
};

type OrganizationInvestorResponse =
  APIResponse<'/api/v4/organization/{organizationId}/investors'>;

export type OrganizationInvestor =
  OrganizationInvestorResponse['investors'][number];

export const fetchOrganizationInvestors = async (organizationId: string) => {
  return get<OrganizationInvestorResponse>(
    `v4/organization/${organizationId}/investors`,
  );
};

export type EditOrganizationInvestorsPayload =
  APIRequestBody<'/api/v4/organization/{organizationId}/investors/edit'>;

export const editOrganizationInvestors = async (
  organizationId: string,
  payload: EditOrganizationInvestorsPayload,
) => {
  return put<void, EditOrganizationInvestorsPayload>(
    `v4/organization/${organizationId}/investors/edit`,
    payload,
  );
};

export type CompanyData = APIResponse<'/api/v4/organization/{organizationId}'>;

export const fetchCompany = async (companyId: string) => {
  return get<CompanyData>(`v4/organization/${companyId}`);
};

export type CompanyProducts =
  APIResponse<'/api/v4/organization/{organizationId}/products'>;

export const fetchCompanyProducts = async (companyId: string) => {
  return get<CompanyProducts>(`v4/organization/${companyId}/products`);
};

export type EditCompanyClientsPayload =
  APIRequestBody<'/api/v4/organization/{organizationId}/clients/edit'>;

export const editCompanyClients = async (
  companyId: string,
  payload: EditCompanyClientsPayload,
) => {
  return put<void, EditCompanyClientsPayload>(
    `v4/organization/${companyId}/clients/edit`,
    payload,
  );
};

export type EditCompanyFundingsPayload =
  APIRequestBody<'/api/v4/organization/{organizationId}/fundings/edit'>;

export const editCompanyFundings = async (
  companyId: string,
  payload: EditCompanyFundingsPayload,
) => {
  return put<void, EditCompanyFundingsPayload>(
    `v4/organization/${companyId}/fundings/edit`,
    payload,
  );
};

export type EditCompanyPayload =
  APIRequestBody<'/api/v4/organization/{organizationId}/edit'>;

export const editCompany = async (
  companyId: string,
  payload: EditCompanyPayload,
) => {
  return put<void, EditCompanyPayload>(
    `v4/organization/${companyId}/edit`,
    payload,
  );
};

export type EditCompanyProductsPayload =
  APIRequestBody<'/api/v4/organization/{organizationId}/products/edit'>;

export const editCompanyProducts = async (
  companyId: string,
  payload: EditCompanyProductsPayload,
) => {
  return put<void, EditCompanyProductsPayload>(
    `v4/organization/${companyId}/products/edit`,
    payload,
  );
};

export const verifyCompany = async (companyId: string) => {
  return post<void>(`v4/organization/${companyId}/verify`);
};
