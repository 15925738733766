import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import type { ShareOrganizationBody } from '@/api/v4/organization.api';
import { modalState } from '@/state/modal.state';
import { useUserAccount } from '@/user/use-user-account';

import {
  mapNewUsersToPayload,
  mapTeamIdsToPayload,
  mapUserIdsToPayload,
} from '../helpers/map-share-payload';
import { ShareModal } from '../share-modal';
import type { ShareWithData } from '../share-with-select/share-with';

import { useShareCompany } from './use-share-company';
import { useUsersAndTeamsWithAccessToOrganization } from './use-users-and-teams-with-access-to-organization';

export const ShareCompanyModal = () => {
  const { t } = useTranslation('default');

  const modal = useRecoilValue(modalState);

  const modalCompanyStateId =
    modal?.state === 'shareCompany' ? modal.companyId : null;

  const { shareCompany } = useShareCompany();
  const { isLoading, usersAndTeamsWithAccessToOrganization } =
    useUsersAndTeamsWithAccessToOrganization(modalCompanyStateId);

  const userAccount = useUserAccount();

  const [shareWithData, setShareWithData] = useState<ShareWithData>({
    draftOptions: [],
    removedOptions: [],
    isPublic: null,
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }
    setShareWithData(prevState => ({
      ...prevState,
      draftOptions: usersAndTeamsWithAccessToOrganization,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleSubmit = async () => {
    const { draftOptions } = shareWithData;

    const payload: ShareOrganizationBody = {
      newUsers: mapNewUsersToPayload(draftOptions),
      teamIds: mapTeamIdsToPayload(draftOptions),
      userIds: mapUserIdsToPayload(draftOptions),
    };

    await shareCompany(payload);
  };

  return (
    <ShareModal
      shareWithData={shareWithData}
      entitiesWithAccess={usersAndTeamsWithAccessToOrganization}
      title={t`share.shareCompany`}
      isLoading={isLoading}
      privacyPublicLabel={
        <Trans
          ns="default"
          i18nKey="share.companyPublicLabel"
          values={{ accountName: userAccount?.name }}
          components={{ u: <u /> }}
        />
      }
      onChange={setShareWithData}
      onSubmit={handleSubmit}
    />
  );
};
