import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { modalState } from '@/state/modal.state';

import { useRevokeInvitation } from '../settings/invited-users/use-revoke-invitation.mutation';

import { ConfirmDeleteModal } from './confirm-delete-modal';

export const RevokeInvitationModal = () => {
  const modal = useRecoilValue(modalState);
  const { t } = useTranslation('settings');
  const { revokeInvitation, isLoading } = useRevokeInvitation();

  if (!modal || modal.state !== 'revokeInvitation') return null;

  return (
    <ConfirmDeleteModal
      description={t`revokeDescription`}
      onConfirm={() => revokeInvitation(modal.userId)}
      confirmLabel={t`Revoke`}
      isLoading={isLoading}
    />
  );
};
