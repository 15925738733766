export const getInitials = (name?: string, singleLetter = false) => {
  if (!name) return '';
  if (singleLetter) return name.charAt(0);

  const initials = name.split(' ').map(item => item.charAt(0));

  if (initials.length > 1 && !/^[a-zA-Z0-9]$/.test(initials[1])) {
    return initials[0];
  }

  return initials.slice(0, 2).join('');
};
