import type { ListingStage } from '@/api/v4/project-listings.api';
import type { ProjectListing } from '@/api/v4/projects.api';
import { ListingStageName } from '@/features/listing-stage/types';

export const getListingsByStage = (
  stage: ListingStage,
  listings: ProjectListing[],
) => {
  return listings.filter(listing => {
    if (stage.name === ListingStageName.notRelevant) {
      return listing.notRelevant;
    }
    return listing.status.id === stage.id && !listing.notRelevant;
  });
};
