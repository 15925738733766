import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useSetRecoilState } from 'recoil';

import { ReactComponent as AskXLogo } from '@/assets/svg/ask-x-logo.svg';
import { modalState } from '@/state/modal.state';
import { Z_INDEX_MODAL } from '@/theme/z-index';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';

import { AskXFooterActions } from './ask-x-footer-actions';

export const AskXInfo = () => {
  const { t } = useTranslation('askX');
  const setModal = useSetRecoilState(modalState);

  return (
    <Container>
      <InnerWrapper gap="30px">
        <Flexbox
          name="ask-x-image"
          justify="center"
          alignItems="center"
          direction="column"
          grow={1}
        >
          <AskXLogo />
        </Flexbox>
        <Title>{t`dropdown.title`}</Title>
        <Content>{t`dropdown.content`}</Content>

        <Flexbox name="ask-x-actions" justify="right">
          <Button
            variant="black"
            startIcon="ArrowRight"
            onClick={() => {
              setModal({
                state: 'askXModal',
              });
            }}
          >{t`dropdown.next`}</Button>
        </Flexbox>
      </InnerWrapper>

      <AskXFooterActions padding="28px" />
    </Container>
  );
};

const InnerWrapper = styled(Stack)`
  padding: 30px;
`;

const Container = styled(Stack)`
  background-color: ${({ theme }) => theme.colors.basics.black};
  color: ${({ theme }) => theme.colors.basics.white};
  border-radius: 20px;
  width: 420px;
  line-height: 23.36px;
  z-index: ${Z_INDEX_MODAL};
`;

const Title = styled('h3')`
  ${({ theme }) => theme.typography.acrossPlatform.minimizedHeader}
  color: ${({ theme }) => theme.colors.basics.white};
`;

const Content = styled('span')`
  ${({ theme }) => theme.typography.acrossPlatform.userName};
  color: ${({ theme }) => theme.colors.basics.white};
  white-space: pre-line;
  line-height: 23px;
`;
