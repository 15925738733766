import type { ChangeEvent } from 'react';
import { forwardRef, useRef } from 'react';
import { styled } from 'goober';

import { inputContainerMixin, inputMixin } from './input-mixin';

interface TextFieldProps {
  dataCy?: string;
  value: string;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  className?: string;
}

export const TextField = ({
  dataCy,
  value,
  placeholder,
  onChange,
  disabled,
  className,
}: TextFieldProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  return (
    <Container
      aria-label={dataCy}
      onClick={() => inputRef.current?.focus()}
      className={className}
    >
      <TextArea
        ref={inputRef}
        name={dataCy}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </Container>
  );
};

const Container = styled('div')`
  ${({ theme }) => inputContainerMixin(theme)}
  height: 204px;
`;

const TextArea = styled('textarea', forwardRef)`
  ${({ theme }) => inputMixin(theme)}
  width: 100%;
  height: 100%;
  resize: none;
`;
