import { useEffect } from 'react';

export function useFocusOnMount(ref: React.RefObject<HTMLElement>) {
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.focus();
  }, []);
}
