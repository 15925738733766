import { fonts } from '@/theme/typography';
import { SkeletonText } from '@/ui/table/infinite-table/skeleton-row';
import { SmallText } from '@/ui/typography/widgets';
import { styled } from 'goober';

export const TextCell = ({
  value,
  isLoading,
}: {
  value: string | number | null;
  isLoading: boolean;
}) => {
  return isLoading ? <SkeletonText /> : <TextValue>{value ?? ''}</TextValue>;
};

const TextValue = styled(SmallText)`
  ${({ theme }) => theme.typography.enhance.tableSmallText};
  font-family: ${fonts.wallop};
`;
