import type { ReactNode } from 'react';
import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';
import type { IconVariant } from '@/ui/icons/icon';
import { Icon } from '@/ui/icons/icon';
import { Logo } from '@/ui/logo/logo';
import { SmallText } from '@/ui/typography/widgets';

export const SEARCH_ITEM_HEIGHT = 52;

export const SearchItem = ({
  label,
  onClick,
  logoUrl,
  defaultIcon,
  customLogo,
}: {
  label: string;
  logoUrl?: string;
  onClick?: () => void;
  defaultIcon?: IconVariant;
  customLogo?: ReactNode;
}) => {
  const renderLeftElement = () => {
    if (defaultIcon) {
      return (
        <Icon
          icon={defaultIcon}
          color="gray.c5"
          height="24px"
          width="24px"
          centered
        />
      );
    }
    if (customLogo) {
      return customLogo;
    }
    return (
      <Logo
        border="none"
        name={label}
        logoUrl={logoUrl}
        size="24px"
        singleLetter
        fontSize="12px"
      />
    );
  };
  return (
    <Container
      name="search-item-container"
      gap="16px"
      alignItems="center"
      isClickable={Boolean(onClick)}
      onClick={onClick ? () => onClick() : undefined}
    >
      {renderLeftElement()}
      <SmallText>{label}</SmallText>
    </Container>
  );
};

const Container = styled(Flexbox)<{ isClickable?: boolean }>`
  height: ${SEARCH_ITEM_HEIGHT}px;
  padding: 0 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.c3};
  background-color: ${({ theme }) => theme.colors.basics.white};
  overflow: hidden;
  ${({ isClickable, theme }) =>
    isClickable &&
    `
      cursor: pointer;
      &:hover {
        background-color: ${theme.colors.blue.c9};
      }
  `}
`;
