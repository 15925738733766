import { useMutation, useQueryClient } from '@tanstack/react-query';

import { MutationKey, QueryKey } from '@/config/query-client';
import { useCompanies } from '@/features/companies/hooks/use-companies.query';
import type {
  CreateCompanyFromCBPayload,
  CreatedCompanyFromCB,
} from '@/api/v4/organization.api';
import { createCompany, createCompanyFromCB } from '@/api/v4/organization.api';

export const useCreateCompany = () => {
  const queryClient = useQueryClient();
  const { refetch: refreshCompanies } = useCompanies();

  const onCreateSuccess = async () => {
    await refreshCompanies();
    await Promise.all([
      queryClient.invalidateQueries([QueryKey.CompaniesKpi]),
      queryClient.invalidateQueries([QueryKey.Companies]),
      queryClient.invalidateQueries([QueryKey.CompanyOptionsCount]),
      queryClient.invalidateQueries([QueryKey.CompaniesStatistics]),
      queryClient.invalidateQueries([QueryKey.LastActivities]),
      queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
    ]);
  };

  const createCompanyFromCrunchbaseMutation = useMutation(createCompanyFromCB, {
    mutationKey: [MutationKey.CreateCompanyFromCrunchbase],
  });

  const createCompanyFromCrunchbase = (
    payload: CreateCompanyFromCBPayload,
    onSuccessHandler?: (data: CreatedCompanyFromCB) => void,
  ) => {
    return createCompanyFromCrunchbaseMutation.mutateAsync(payload, {
      onSuccess: async companyResponse => {
        onSuccessHandler?.(companyResponse);
        await onCreateSuccess();
      },
    });
  };

  const createOwnCompanyProfile = useMutation(createCompany, {
    onSuccess: onCreateSuccess,
  });

  return {
    createCompanyFromCrunchbase,
    isCreatingCompanyFromCrunchbaseLoading:
      createCompanyFromCrunchbaseMutation.isLoading,
    createOwnCompanyProfile,
  };
};
