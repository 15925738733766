import type { TippyProps } from '@tippyjs/react';
import Tippy from '@tippyjs/react';
import { styled } from 'goober';
import type { ReactElement, ReactNode } from 'react';
import { forwardRef, useState } from 'react';

type TooltipThemes = 'default' | 'sky';

export interface ITooltipProps extends TippyProps {
  children: ReactElement;
  content: ReactNode;
  delay?: number;
  withArrow?: boolean;
  maxWidth?: string;
  padding?: string;
  showOnFocused?: boolean;
  showOnHovered?: boolean;
  hideOnClick?: boolean;
  theme?: TooltipThemes;
}
export const Tooltip = ({
  children,
  content,
  maxWidth = '300px',
  delay,
  withArrow = false,
  visible,
  padding = '14.5px 44.5px',
  showOnFocused = false,
  showOnHovered = true,
  hideOnClick = true,
  theme = 'default',
  ...rest
}: ITooltipProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <Tip
      maxWidth={maxWidth}
      delay={delay}
      visible={visible && isTooltipVisible}
      content={content}
      withArrow={withArrow}
      padding={padding}
      themeVersion={theme}
      {...rest}
    >
      <TooltipTriggerContainer
        onMouseEnter={() => showOnHovered && setIsTooltipVisible(true)}
        onMouseLeave={() => showOnHovered && setIsTooltipVisible(false)}
        onClick={() => hideOnClick && setIsTooltipVisible(false)}
        onFocus={() => showOnFocused && setIsTooltipVisible(true)}
        onBlur={() => showOnFocused && setIsTooltipVisible(false)}
        visible={Boolean(visible)}
      >
        {children}
      </TooltipTriggerContainer>
    </Tip>
  );
};

interface ITipProps extends TippyProps {
  withArrow: boolean;
  padding?: string;
  themeVersion: TooltipThemes;
}

const Tip = styled(({ ...props }: ITipProps) => <Tippy {...props} />)<{
  withArrow: boolean;
  padding?: string;
  themeVersion: TooltipThemes;
}>`
  ${({ theme }) => theme.typography.atoms.tooltip};

  color: ${({ theme, themeVersion }) =>
    themeVersion === 'default'
      ? theme.colors.basics.white
      : theme.colors.basics.black};
  background-color: ${({ theme, themeVersion }) =>
    themeVersion === 'default' ? theme.colors.gray.c12 : theme.colors.blue.c8};
  box-shadow: ${({ theme }) => theme.shadow.actionsPanel};
  border-radius: 4px;
  padding: ${({ padding }) => padding};
  text-align: center;
  width: fit-content;

  position: relative;
  &.tippy-box[data-placement^='right'] {
    ${({ themeVersion, placement }) =>
      themeVersion === 'sky' &&
      ['right', 'right-start', 'right-end'].includes(placement || '') &&
      `left: 10px;`};
  }

  &.tippy-box[data-placement^='right'] > .tippy-arrow:before {
    ${({ themeVersion, placement }) =>
      themeVersion === 'sky' &&
      ['right', 'right-start', 'right-end'].includes(placement || '') &&
      `
        top: ${
          placement === 'right-start'
            ? '-30'
            : placement === 'right-end'
            ? 30
            : 0
        }px;
        left: -17px; 
        border-width: ${
          placement === 'right-start'
            ? '0px 18px 18px 0px'
            : '18px 18px 0px 0px'
        };
      `};
  }

  .tippy-arrow {
    ${({ withArrow }) => !withArrow && 'display: none'};
    color: ${({ theme, themeVersion }) =>
      themeVersion === 'default'
        ? theme.colors.gray.c12
        : theme.colors.blue.c8};
  }
  .tippy-content {
    padding: 0;
  }
`;

const TooltipTriggerContainer = styled('div', forwardRef)<{ visible: boolean }>`
  position: relative;
`;
