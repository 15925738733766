import { forwardRef } from 'react';
import { styled } from 'goober';

export const OptionContainer = styled('div', forwardRef)<{
  isSelected: boolean;
  height?: string;
  isDisabled?: boolean;
}>`
  ${({ theme }) => theme.typography.acrossPlatform.userName};
  height: ${({ height }) => height ?? '54px'};
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  :not(:last-child) > &,
  :not(:last-child) > div > & {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.c2};
  }

  :hover > & {
    background-color: ${({ theme }) => theme.colors.blue.c7};
  }

  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    pointer-events: none;
    opacity: 0.5;
    :hover > & {
      background-color: ${theme.colors.basics.white};
    }
  `};

  ${({ isSelected, theme }) =>
    isSelected &&
    `
      cursor: default;
      background-color: ${theme.colors.blue.c7};
  `}
`;

export const OptionLabel = styled('div', forwardRef)<{ color?: string }>`
  ${({ theme }) => theme.mixins.ellipsis}
  ${({ color }) => color && `color: ${color};`}
  flex-grow: 1;

  &::after {
    content: attr(data-team);
    display: block;
    clear: left;
    margin-top: 2px;
    width: 100%;
    ${({ theme }) => theme.typography.widget.smallText}
    ${({ theme }) => theme.mixins.ellipsis}
  }
`;

export const ControlContainer = styled('div')<{
  isFocused: boolean;
  isInvalid?: boolean;
}>`
  ${({ theme }) => theme.typography.acrossPlatform.small}
  display: flex;
  height: 30px;
  min-width: 120px;
  align-items: baseline;
  cursor: pointer;

  border-radius: 10px;
  border-color: 2px solid
    ${({ isFocused, theme }) =>
      isFocused ? theme.colors.blue.primaryA : 'transparent'};
  border-color: 2px solid
    ${({ isInvalid, theme }) =>
      isInvalid ? theme.colors.system.lowFit : 'transparent'};
  box-shadow: ${({ isFocused, theme }) =>
    isFocused ? `0 0 0 2px ${theme.colors.blue.primaryA}` : 'none'};
  background-color: ${({ isFocused, theme }) =>
    isFocused ? theme.colors.basics.white : 'transparent'};

  :not(:focus-within):hover {
    border-color: transparent;
  }
`;

export const SingleValueContainer = styled('div', forwardRef)`
  ${({ theme }) => theme.typography.acrossPlatform.small}
  ${({ theme }) => theme.mixins.ellipsis}
  font-size: inherit;
`;

export const SelectContainer = styled('div')<{ isFocused: boolean }>`
  border-bottom: 2px solid transparent;

  ${({ theme, isFocused }) =>
    !isFocused &&
    `
    &:hover {
      border-bottom-color: ${theme.colors.gray.c3};
    }
  `};
`;
