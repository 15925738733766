export enum PERMISSION {
  VIEW_PROJECT_LIST = 'View project list',
  ENABLE_ACCOUNT_DETAILS = 'Enable account details',
  VIEW_ACCOUNT_DETAILS = 'View account details',
  MANAGE_TEAMS = 'Manage teams',
  VIEW_TEAM_HOME_PAGE = 'View team home page',
  VIEW_PROJECT_HOME_PAGE = 'View project home page',
  EDIT_PROJECT = 'Edit project',
  ADD_PROJECT = 'Add project',
  DELETE_PROJECT = 'Delete project',
  ADD_COMPANIES_TO_PROJECTS = 'Add companies to projects',
  REMOVE_COMPANIES_FROM_PROJECTS = 'Remove companies from projects',
  VIEW_PROJECT_LISTING = 'View project listing',
  UPDATE_COMPANY_LISTING_STATUS = 'Update company listing status',
  VIEW_ORGANIZATION_LIST = 'View organization list',
  VIEW_ORGANIZATION_HOME_SCREEN = 'View organization home screen',
  ADD_ORGANIZATION = 'Add organization',
  EDIT_ORGANIZATION = 'Edit organization',
  DELETE_ORGANIZATION = 'Delete organization',
  VIEW_USER_MANAGEMENT = 'View user management',
  ADD_MEETING = 'Add meeting',
  EDIT_MEETING = 'Edit meeting',
  DELETE_MEETING = 'Delete meeting',
  VIEW_MEETING = 'View meeting',
  VIEW_STREAM_NOTES = 'View stream notes',
  VIEW_ORGANIZATION_NOTES = 'View organization notes',
  ADD_STREAM_NOTE = 'Add stream note',
  ADD_ORGANIZATION_NOTE = 'Add organization note',
  EDIT_STREAM_NOTE = 'Edit stream note',
  EDIT_ORGANIZATION_NOTE = 'Edit organization note',
  DELETE_STREAM_NOTE = 'Delete stream note',
  DELETE_ORGANIZATION_NOTE = 'Delete organization note',
  VIEW_FILES = 'View files',
  REMOVE_FILE = 'Remove file',
  ADD_FILE = 'Add file',
  RATE_A_COMPANY = 'Rate a company',
  ADD_COMPANY_TO_STREAM = 'Add company to stream',
  SHARE_A_COMPANY_TO_EXISTING_Q_USERS = 'Share a company to existing Q users',
  SHARE_A_STREAM_TO_EXISTING_Q_USERS = 'Share a stream to existing Q users',
  INVITE_NEW_USER_TO_Q_VIA_THE_COMPANY_SHARE_BUTTON = 'Invite new user to Q via the company "share" button',
  INVITE_NEW_USER_TO_Q_VIA_THE_STREAM_SHARE_BUTTON = 'Invite new user to Q via the stream "share" button',
  DUPLICATE_STREAM = 'Duplicate stream',
  SHARE_FILE = 'Share file',
  VOTE_USE_CASE_FIT = 'Vote use case fit',
  EXPORT_PROJECT = 'Export project',
  MANAGE_TEAM_NOTES = 'Manage team notes',
  VIEW_TEAM_NOTES = 'View team notes',
}
