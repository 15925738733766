import type { MouseEvent } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate, parseDateRange } from '@/helpers/format-date';
import { AsyncSelect } from '@/ui/select/async/async-select';
import type { CustomAsyncSelectProps } from '@/ui/select/async/use-select-type';

interface CustomInputAsyncSelectProps
  extends Pick<
    CustomAsyncSelectProps,
    | 'components'
    | 'controlWidth'
    | 'labelPlacement'
    | 'placeholder'
    | 'readOnly'
    | 'readOnlyValue'
    | 'styles'
  > {
  displayFormat?: string;
  value?: string;
  label?: string;
  isTimeframe?: boolean;
  disabled?: boolean;
  onClick?: (event: MouseEvent) => void;
}

export const CustomInputAsyncSelect = ({
  value,
  displayFormat,
  label,
  disabled,
  readOnly,
  placeholder,
  controlWidth,
  components,
  isTimeframe = false,
  styles,
  onClick,
  ...props
}: CustomInputAsyncSelectProps) => {
  const { t } = useTranslation('default');

  const [timeframeStart, timeframeEnd] = value
    ? value.split(' - ')
    : [null, null];

  const formattedLabel = useMemo(() => {
    if (isTimeframe)
      return parseDateRange(timeframeStart, timeframeEnd, displayFormat);

    if (value && displayFormat)
      return formatDate(value, { format: displayFormat });

    return value;
  }, [displayFormat, isTimeframe, timeframeEnd, timeframeStart, value]);

  return (
    <AsyncSelect
      {...props}
      defaultValue={value ? { label: formattedLabel, value } : null}
      components={components}
      styles={styles}
      label={label}
      placeholder={placeholder ?? t`select`}
      isDisabled={disabled}
      readOnly={readOnly}
      readOnlyValue={formattedLabel}
      controlWidth={controlWidth}
      menuIsOpen={false}
      onClick={onClick}
    />
  );
};

CustomInputAsyncSelect.displayName = 'CustomInputAsyncSelect';
