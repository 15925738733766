import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import type { UseCaseFitPayload } from '@/api/v3/use-case-fit-api';
import { updateUseCaseFit } from '@/api/v3/use-case-fit-api';
import { notify } from '@/ui/snackbar/notify';

import { useInvalidateProjectListings } from '../../use-invalidate-project-listings';

export const useUpdateUseCaseFit = () => {
  const { t } = useTranslation('default');
  const invalidateProjectListings = useInvalidateProjectListings();

  const mutation = useMutation(updateUseCaseFit, {
    onSuccess: async () => await invalidateProjectListings(),
    onError: () => {
      notify({
        message: t('unknownError'),
      });
    },
  });

  return (projectListingId: string, payload: UseCaseFitPayload) =>
    mutation.mutateAsync({ projectListingId, payload });
};
