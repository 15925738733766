import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilValue } from 'recoil';

import type { RichTextFieldRef } from '@/components/rich-text-field/rich-text-field-with-mention';
import { useCurrentUserInitials } from '@/hooks/use-current-user-initials';
import { useFocusOnMount } from '@/hooks/use-focus-on-mount';
import { colors } from '@/theme/colors';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { Logo } from '@/ui/logo/logo';

import { companyIdState } from '../../companies/overview/company.state';
import { UsersMentionTextField } from '../../inputs/users-mention-text-field';
import { projectIdState } from '../../projects/project.state';
import { teamIdState } from '../../teams/team.state';

enum InputHeightEnum {
  sm = '46',
  lg = '82',
}

export const InsertCommentSection = ({
  onSubmit,
}: {
  onSubmit: (comment: string, mentionedUsers: number[]) => void;
}) => {
  const { t } = useTranslation('default');
  const userInitials = useCurrentUserInitials();
  const richTextEditorRef = useRef<RichTextFieldRef>(null);
  const textFieldRef = useRef<HTMLTextAreaElement>(null);

  const companyId = useRecoilValue(companyIdState);
  const projectId = useRecoilValue(projectIdState);
  const teamId = useRecoilValue(teamIdState);

  const [newComment, setNewComment] = useState<string | null>();
  const [mentionedUsers, setMentionedUsers] = useState<number[]>([]);

  const [isExpanded, setIsExpanded] = useState(false);

  useFocusOnMount(textFieldRef);

  const submit = useCallback(() => {
    newComment && onSubmit(newComment, mentionedUsers);
    setNewComment('');
    setIsExpanded(false);
    setMentionedUsers([]);
    richTextEditorRef.current?.reset();
    textFieldRef.current?.focus();
  }, [mentionedUsers, newComment, onSubmit]);

  return (
    <InsertCommentContainer
      name="action-panel-wrapper"
      justify="center"
      gap="12px"
    >
      <CommentLogo bgColor={colors.blue.primaryA} name={userInitials} />
      <TextFieldContainer fullWidth>
        <UsersMentionTextField
          defaultValue={newComment ?? ''}
          onChange={(value, mentionedUsers) => {
            setNewComment(value);
            if (!value) setIsExpanded(false);
            setMentionedUsers(mentionedUsers);
          }}
          placeholder={t`actionPanel.comments.placeholder`}
          onSubmit={submit}
          organizationId={companyId ?? undefined}
          projectId={projectId ?? undefined}
          teamId={teamId ?? undefined}
          height={`${isExpanded ? InputHeightEnum.lg : InputHeightEnum.sm}px`}
          onFocus={() => setIsExpanded(true)}
          onBlur={() => !newComment && setIsExpanded(false)}
          richTextEditorRef={richTextEditorRef}
          dataTestId="comments-tab-comment-text-input"
        />
        {isExpanded || (newComment && newComment.length > 0) ? (
          <Flexbox name="action-button" direction="row-reverse">
            <Button startIcon="Send" onClick={submit}>
              Comment
            </Button>
          </Flexbox>
        ) : undefined}
      </TextFieldContainer>
      <IntercomPlaceholder />
    </InsertCommentContainer>
  );
};

const InsertCommentContainer = styled(Flexbox)`
  background-color: ${({ theme }) => theme.colors.blue.c9};
  border-top: 1px solid ${({ theme }) => theme.colors.gray.c3};
  padding: 20px 36px;
`;

const TextFieldContainer = styled(Stack)`
  position: relative;
  max-height: 200px;
`;

const CommentLogo = styled(Logo)`
  font-size: 12px;
  margin-top: 4px;
`;

const IntercomPlaceholder = styled('div')`
  width: 30px;
  height: 30px;
`;
