import type { IconVariant } from '@/ui/icons/icon';

export type TagTab =
  | 'all'
  | 'sector'
  | 'industry'
  | 'sub-industry'
  | 'technology'
  | 'other';

export type TagCategory =
  | 'Sector'
  | 'Industry'
  | 'Sub Industry'
  | 'Technology'
  | 'Other';

export const basicTagTabConfig: {
  id: TagTab;
  icon?: IconVariant;
  category?: TagCategory;
}[] = [
  {
    id: 'all',
  },
  {
    id: 'sector',
    icon: 'TagCategorySector',
    category: 'Sector',
  },
  {
    id: 'industry',
    icon: 'TagCategoryIndustry',
    category: 'Industry',
  },
  {
    id: 'sub-industry',
    icon: 'TagCategorySubIndustry',
    category: 'Sub Industry',
  },
  {
    id: 'technology',
    icon: 'TagCategoryTechnology',
    category: 'Technology',
  },
  {
    id: 'other',
    icon: 'TagCategoryOther',
    category: 'Other',
  },
];
