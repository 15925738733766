import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import type { TeamBasicOption } from '@/types';

import type { MeetingFormFields } from './use-meeting-form';
import { fetchTeamOptionById } from '@/api/v4/team.api';

export const useMeetingDefaultTeamOption = () => {
  const { watch } = useFormContext<MeetingFormFields>();

  const [defaultTeamOption, setDefaultTeamOption] =
    useState<TeamBasicOption | null>(null);
  const relatedTeamId = watch('relatedTeamId');

  useEffect(() => {
    const setDefaultTeam = async () => {
      if (relatedTeamId) {
        const teamOption = await fetchTeamOptionById(relatedTeamId);
        setDefaultTeamOption({
          label: teamOption.name,
          value: teamOption.id,
          type: 'team',
        });
        return;
      }

      setDefaultTeamOption(null);
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setDefaultTeam();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedTeamId]);

  return {
    defaultTeamOption,
  };
};
