import type { ChangeEvent, ReactNode } from 'react';
import { styled } from 'goober';

export interface RadioProps {
  name: string;
  checked?: boolean;
  disabled?: boolean;
  inputHidden?: boolean;
  label?: string | ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Radio = ({ name, label, inputHidden, ...props }: RadioProps) => {
  return (
    <Label htmlFor={name}>
      <Input
        type="radio"
        id={name}
        name={name}
        hidden={inputHidden}
        {...props}
      />
      {label && label}
    </Label>
  );
};

const Label = styled('label')`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 6px;
`;

const Input = styled('input')`
  appearance: none;
  cursor: pointer;

  width: 14px;
  height: 14px;
  margin: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.colors.blue.primaryA};
  background-color: ${({ theme }) => theme.colors.basics.white};
  border: 1px solid ${({ theme }) => theme.colors.blue.primaryA};
  border-radius: 50%;

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    cursor: pointer;
    border-radius: 50%;
    transform: scale3d(0, 0, 0);
    background-color: ${({ theme }) => theme.colors.blue.primaryA};
  }

  &:checked::before {
    transform: scale3d(1, 1, 1);
  }

  ${({ hidden }) => hidden && 'display: none'}
`;
