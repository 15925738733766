import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { deleteOrganizationFile } from '@/api/v4/organization-files.api';
import { QueryKey } from '@/config/query-client';

import { companyIdState } from '../../companies/overview/company.state';

export const useDeleteCompanyFile = () => {
  const queryClient = useQueryClient();
  const companyId = useRecoilValue(companyIdState);

  const mutation = useMutation(deleteOrganizationFile, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([QueryKey.CompanyFiles]),
        queryClient.invalidateQueries([QueryKey.CompanyActionsInfo]),
        queryClient.invalidateQueries([QueryKey.Company, companyId]),
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);
    },
  });

  const deleteCompanyFile = (fileId: string) => {
    if (!companyId) return;

    return mutation.mutateAsync({ organizationId: companyId, fileId });
  };

  return {
    deleteCompanyFile,
    isError: mutation.isError,
  };
};
