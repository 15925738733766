import { createBrowserRouter, Navigate } from 'react-router-dom';
import { ProtectedAppLayout } from './protect/protected-app-layout';
import { useAuthorization } from '@/auth/hooks/use-authorization';
import protect from './protect/protect';
import { PRODUCT } from '@/user/products/product.type';
import { PERMISSION } from '@/user/permissions/permission.type';
import { AuthPageLayout } from '@/auth/ui/auth-page-layout';
import { PageNotFound } from './page-not-found';
import { ApiProvider } from '@/api/provider/provider';
import { AppConfigProvider } from './app-config-provider';
import { userLoader } from '@/user/user-loader';
import { useQueryClient } from '@tanstack/react-query';
import { lazyLoad } from '@/helpers/lazy-load';

const HomePage = lazyLoad(() =>
  import('../features/home/home.page').then(module => ({
    default: module.HomePage,
  })),
);

const Companies = lazyLoad(() =>
  import('./companies').then(module => ({
    default: module.Companies,
  })),
);

const Projects = lazyLoad(() =>
  import('./projects').then(module => ({
    default: module.Projects,
  })),
);

const Teams = lazyLoad(() =>
  import('./teams').then(module => ({ default: module.Teams })),
);

const ExploreResultsPage = lazyLoad(() =>
  import('../features/explore/explore-results.page').then(module => ({
    default: module.ExploreResultsPage,
  })),
);

const ExploreFormPage = lazyLoad(() =>
  import('../features/explore/explore-form.page').then(module => ({
    default: module.ExploreFormPage,
  })),
);

const SettingsPage = lazyLoad(() =>
  import('../features/settings/settings.page').then(module => ({
    default: module.SettingsPage,
  })),
);

const SignInPage = lazyLoad(() =>
  import('../auth/sign-in.page').then(module => ({
    default: module.SignInPage,
  })),
);
const SignUpPage = lazyLoad(() =>
  import('../auth/sign-up.page').then(module => ({
    default: module.SignUpPage,
  })),
);
const SignUpInvitationExpiredPage = lazyLoad(() =>
  import('../auth/sign-up-invitation-expired.page').then(module => ({
    default: module.SignUpInvitationExpiredPage,
  })),
);

export const useRouter = () => {
  const { isUserAuthorized } = useAuthorization();
  const queryClient = useQueryClient();

  const router = createBrowserRouter([
    {
      element: <AppConfigProvider />,
      errorElement: <PageNotFound />,
      children: [
        {
          path: '/',
          element: (
            <ApiProvider>
              <ProtectedAppLayout isUserAuthorized={isUserAuthorized} />
            </ApiProvider>
          ),
          children: [
            { index: true, element: <Navigate to="home" /> },
            {
              path: 'home',
              element: <HomePage />,
              loader: isUserAuthorized && userLoader(queryClient),
            },
            {
              path: 'companies/*',
              element: <Companies />,
            },
            {
              path: 'projects/*',
              element: <Projects />,
            },
            {
              path: 'teams/*',
              element: <Teams />,
            },
            {
              path: 'explore/results/:requestId?',
              element: protect(<ExploreResultsPage />, {
                products: [PRODUCT.EXPLORE],
              }),
            },
            {
              path: 'settings',
              element: <SettingsPage />,
            },
          ],
        },
        {
          element: (
            <ProtectedAppLayout
              isUserAuthorized={isUserAuthorized}
              withTopbar={false}
            />
          ),
          children: [
            {
              path: 'explore',
              element: protect(
                <ExploreFormPage />,
                {
                  permissions: [PERMISSION.ADD_COMPANY_TO_STREAM],
                  products: [PRODUCT.CRUNCHBASE, PRODUCT.EXPLORE],
                },
                true,
              ),
            },
          ],
        },
        {
          path: 'auth',
          element: <AuthPageLayout isUserAuthorized={isUserAuthorized} />,
          children: [
            {
              path: 'signin',
              element: <SignInPage />,
            },
            {
              path: 'signup',
              element: <SignUpPage />,
            },
            {
              path: 'invitation-expired',
              element: <SignUpInvitationExpiredPage />,
            },
          ],
        },
      ],
    },
  ]);

  return router;
};
