import { useTranslation } from 'react-i18next';

import SvgImpact from '@/assets/widgets/empty-state-other.svg';
import { ActionPanelItem } from '@/features/action-panel/action-panel';
import { ActionPanelDate } from '@/features/action-panel/action-panel-date';
import { ActionPanelEmpty } from '@/features/action-panel/action-panel-empty';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';

import { AddToProject } from './add-to-project';
import { ChangeStatus } from './change-status';
import { Meeting } from './meeting';
import { useProjectUpdates } from './use-project-updates.query';
import {
  isProjectMeetingUpdate,
  isProjectListingStageUpdate,
  isProjectListingUpdate,
} from './helpers';

export const ProjectUpdates = () => {
  const { t } = useTranslation('default');
  const { data: updates = [] } = useProjectUpdates();

  if (!updates?.length) {
    return (
      <ActionPanelEmpty
        image={SvgImpact}
        label={t`actionPanel.updates.empty`}
      />
    );
  }

  return (
    <Stack gap="0px" fullWidth>
      {updates.map(statusUpdate => (
        <ActionPanelItem
          key={`${statusUpdate.createdAt}-${statusUpdate.action}`}
          name="action-panel-updates-item"
          gap="16px"
        >
          <Flexbox name="action-panel-text" gap="16px" grow={1}>
            {isProjectListingStageUpdate(statusUpdate) && (
              <ChangeStatus update={statusUpdate} />
            )}
            {isProjectListingUpdate(statusUpdate) && (
              <AddToProject update={statusUpdate} />
            )}
            {isProjectMeetingUpdate(statusUpdate) && (
              <Meeting update={statusUpdate} />
            )}
          </Flexbox>
          <ActionPanelDate date={statusUpdate.createdAt} relative />
        </ActionPanelItem>
      ))}
    </Stack>
  );
};
