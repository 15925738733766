import { useQueryClient } from '@tanstack/react-query';

import type { EditTeamPayload } from '@/api/v4/team.api';
import {
  editTeam,
  removeTeamMember as removeTeamMemberAPI,
} from '@/api/v4/team.api';
import { QueryKey } from '@/config/query-client';

import { useTeam } from './overview/use-team.query';

export const useUpdateTeam = () => {
  const { refetch } = useTeam();

  const queryClient = useQueryClient();

  const updateTeamData = async (teamId: string, payload: EditTeamPayload) => {
    await editTeam(teamId, payload);
    await queryClient.invalidateQueries([QueryKey.LastActivities]);
    await queryClient.invalidateQueries([QueryKey.LastActivitiesCount]);
    await queryClient.invalidateQueries([QueryKey.Teams]);

    await refetch();
  };

  const removeTeamMember = async (teamId: string, userId: number) => {
    await removeTeamMemberAPI(teamId, userId);
    await refetch();
  };

  return {
    updateTeamData,
    removeTeamMember,
  };
};
