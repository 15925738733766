import { styled } from 'goober';

import { getCompanyCrunchbaseUrl } from '@/features/companies/helpers/get-company-crunchbase-url';
import { colors } from '@/theme/colors';
import { Icon } from '@/ui/icons/icon';

import { Tooltip } from '../tooltip/tooltip';

type SavvyIconButtonCrunchbaseProps = {
  permalink: string;
  hideTooltip?: boolean;
  size?: string;
};

export const SavvyIconButtonCrunchbase = ({
  permalink,
  hideTooltip,
  size = '20px',
}: SavvyIconButtonCrunchbaseProps) => {
  const url = getCompanyCrunchbaseUrl(permalink);
  return (
    <Tooltip content={url} disabled={hideTooltip}>
      <StyledIcon
        icon="FromCb"
        size={size}
        color={colors.blue.primaryA}
        onClick={() => window.open(url, '_blank')}
      />
    </Tooltip>
  );
};

const StyledIcon = styled(Icon)<{ size: string }>`
  display: block;
  width: ${({ size }) => size};
`;
