import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import type { TeamActionResponse } from '@/api/v4/team-actions-info';
import { fetchTeamActionsInfo } from '@/api/v4/team-actions-info';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

import { teamIdState } from '../../team.state';

export const useTeamActionsInfo = () => {
  const { t } = useTranslation('default');
  const teamId = useRecoilValue(teamIdState);

  const FetchTeamActionsInfo = async (): Promise<TeamActionResponse> => {
    if (!teamId) return { comments: 0, meetings: 0, files: 0, total: 0 };

    return fetchTeamActionsInfo(teamId);
  };

  return useQuery([QueryKey.TeamActionsInfo, teamId], FetchTeamActionsInfo, {
    onError: () =>
      notify({
        message: t`default:unknownError`,
      }),
    enabled: !!teamId,
    staleTime: 1000 * 60 * 5,
  });
};
