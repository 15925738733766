import type { SearchSuggestion } from '@/api/v4/search.api';
import { SearchResults } from './search-results';
import type { SearchResultsFilter } from './search.state';
import { searchResultsFilters, searchResultsFilterState } from './search.state';
import { useRecoilState } from 'recoil';
import { Stack } from '@/ui/line/line';
import { useCallback, useMemo } from 'react';
import { SearchResultsFilters } from './search-results-filters';
import { useTranslation } from 'react-i18next';
import { CompanySuggestionsTable } from './search-suggestions/company-suggestions-table/company-suggestions-table';

export const SearchResultsContent = ({
  results: searchSuggestions,
}: {
  results: SearchSuggestion[];
}) => {
  const { t } = useTranslation('default', { keyPrefix: 'globalSearch' });
  const [selectedFilter, setSelectedFilter] = useRecoilState(
    searchResultsFilterState,
  );
  const isCompanyFilterActive = selectedFilter === 'company';

  const getResultsQuantityPerType = useCallback(
    (type: SearchSuggestion['type']) => {
      return searchSuggestions.filter(result => result.type === type).length;
    },
    [searchSuggestions],
  );

  const filtersConfig = useMemo(
    () =>
      searchResultsFilters.map(filter => ({
        label: t(`filters.${filter}`),
        type: filter,
        isActive: selectedFilter === filter,
        resultsQuantity:
          filter === 'all'
            ? searchSuggestions.length
            : getResultsQuantityPerType(filter),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getResultsQuantityPerType, searchSuggestions.length, selectedFilter],
  );

  const filteredResults = useMemo(() => {
    if (selectedFilter === 'all') {
      return searchSuggestions;
    }

    return searchSuggestions.filter(
      suggestion => suggestion.type === selectedFilter,
    );
  }, [searchSuggestions, selectedFilter]);

  const onFilterClick = (filter: SearchResultsFilter) =>
    setSelectedFilter(filter);

  return (
    <Stack gap="30px">
      <SearchResultsFilters
        filters={filtersConfig}
        onFilterClick={onFilterClick}
      />
      {isCompanyFilterActive ? (
        <CompanySuggestionsTable data={filteredResults} />
      ) : (
        <SearchResults results={filteredResults} />
      )}
    </Stack>
  );
};
