import { styled } from 'goober';

import { paths } from '@/routes/helpers/paths';
import { colors } from '@/theme/colors';
import { IconButton } from '@/ui/button/icon-button';

import { Tooltip } from '../tooltip/tooltip';

type SavvyIconButtonOrganizationURLProps = {
  companyId: string;
  hideTooltip?: boolean;
};

export const SavvyIconButtonOrganizationURL = ({
  companyId,
  hideTooltip,
}: SavvyIconButtonOrganizationURLProps) => {
  const url = paths.company({
    companyId,
  });

  return (
    <Tooltip content={url} disabled={hideTooltip}>
      <StyledIconButton
        icon="VectorUp"
        variant="ghost"
        color={colors.basics.black}
        onClick={() => window.open(url, '_blank')}
      />
    </Tooltip>
  );
};

const StyledIconButton = styled(IconButton)`
  padding: 0px;
  &:hover {
    background-color: transparent;
  }
`;
