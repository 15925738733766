import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchProjectFiles as fetchProjectFilesAPI } from '@/api/v4/project-files.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { projectIdState } from '../../project.state';

export const useProjectFiles = () => {
  const { t } = useTranslation('default');
  const projectId = useRecoilValue(projectIdState);

  const { hasRequiredPermission } = useUserPermissions();

  const fetchProjectFiles = async () => {
    if (!projectId) return [];

    return fetchProjectFilesAPI(projectId);
  };

  return useQuery([QueryKey.ProjectFiles, projectId], fetchProjectFiles, {
    onError: () =>
      notify({
        message: t`default:unknownError`,
      }),
    enabled: !!projectId && hasRequiredPermission(PERMISSION.VIEW_FILES),
    staleTime: 1000 * 60 * 5,
  });
};
