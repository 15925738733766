import { TopbarWrapper } from '../../topbar';

import { CompanyHeaderActions } from './company-actions';

export const CompanyHeader = () => (
  <TopbarWrapper
    name="company-header"
    justify="flex-end"
    alignItems="center"
    gap="16px"
    bgColor="white"
  >
    <CompanyHeaderActions />
  </TopbarWrapper>
);
