import { useRemoveSession } from '@/auth/hooks/use-remove-session';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';

export const useErrorHandlers = () => {
  const navigateTo = useNavigateTo();
  const removeSession = useRemoveSession();

  const handleAuthenticationError = async () => {
    await removeSession();
    navigateTo.signIn();
  };

  const handleNotFoundError = () => {
    navigateTo.notFound();
  };

  return {
    handleAuthenticationError,
    handleNotFoundError,
  };
};
