import { styled } from 'goober';

import SvgNoResults from '@/assets/svg/no-results-add-company.svg';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { ParagraphMedium } from '@/ui/typography/widgets';
type OrganizationSearchResultsStartProps = {
  addCompanyText: string;
};

export const OrganizationSearchInitial = ({
  addCompanyText,
}: OrganizationSearchResultsStartProps) => {
  return (
    <NoInputContainer
      direction="column"
      name="organization-search-initial-view"
      justify="center"
      alignItems="center"
    >
      <LogoContainer src={SvgNoResults} />
      <ParagraphMedium>{addCompanyText}</ParagraphMedium>
    </NoInputContainer>
  );
};

const LogoContainer = styled('img')`
  justify-self: center;
  width: 250px;
`;

const NoInputContainer = styled(Flexbox)`
  width: 100%;
  height: 100%;
  justify-content: start;
  padding-top: 50px;
`;
