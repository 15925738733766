import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { modalState } from '@/state/modal.state';
import { colors } from '@/theme/colors';
import { Input } from '@/ui/input/input';
import { Inline, Stack } from '@/ui/line/line';
import { Modal } from '@/ui/modal/modal';
import { Switch } from '@/ui/switch/switch';
import { ParagraphSmall, SubHeaderBold } from '@/ui/typography/widgets';

import { duplicateProjectValidationSchema } from '../projects/duplicate/duplicate-project-validation-schema';
import { useDuplicateProject } from '../projects/duplicate/use-duplicate-project';
import { useProject } from '../projects/overview/use-project.query';

interface DuplicateProjectForm {
  name: string;
  withDetailsCopied: boolean;
}

interface DuplicationOption {
  fieldName: keyof DuplicateProjectForm;
  fieldValue: boolean;
  text: string;
  isHidden?: boolean;
}

export const DuplicateProjectModal = () => {
  const { t } = useTranslation(['projects', 'default']);
  const [modal, setModalState] = useRecoilState(modalState);
  const { data: project } = useProject();
  const duplicateProject = useDuplicateProject();

  const navigateTo = useNavigateTo();
  const {
    register,
    watch,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<DuplicateProjectForm>({
    defaultValues: {
      name: project?.name
        ? t('projectOverview.copyOf', {
            name: project.name,
          })
        : '',
      withDetailsCopied: true,
    },
    resolver: zodResolver(duplicateProjectValidationSchema),
  });
  const withDetailsCopied = watch('withDetailsCopied');

  if (modal?.state !== 'duplicateProject') {
    return null;
  }

  const DuplicationOptionsConfig: DuplicationOption[] = [
    {
      fieldName: 'withDetailsCopied',
      fieldValue: withDetailsCopied,
      text: t`projectOverview.copyDetails`,
    },
  ];

  const createDuplicatedProject = async (data: DuplicateProjectForm) => {
    if (!project) {
      return;
    }

    const { id: duplicatedProjectId } = await duplicateProject({
      id: project.id,
      name: data.name,
      ...(project.team?.id && { created_for: project.team.id }),
      copy_problem_statement: data.withDetailsCopied,
    });

    if (duplicatedProjectId) {
      navigateTo.project({ projectId: duplicatedProjectId });
    }

    setModalState(null);
  };

  return (
    <Modal
      CustomHeaderComponent={SubHeaderBold}
      header={t`projectOverview.duplicateProject`}
      mainAction={{
        label: t`default:save`,
        onClick: handleSubmit(createDuplicatedProject),
      }}
      secondaryAction={{
        label: t`default:cancel`,
      }}
      disabled={!isValid || isSubmitting}
    >
      <Content gap="28px">
        <Input {...register('name')} />
        <OptionsBox gap="25px">
          {DuplicationOptionsConfig.map(option => {
            if (option?.isHidden) {
              return null;
            }

            return (
              <Inline
                gap="12px"
                templateColumns="max-content"
                key={option.fieldName}
              >
                <Switch
                  {...register(option.fieldName)}
                  checked={option.fieldValue}
                />
                <ParagraphSmall color={colors.gray.c13}>
                  {option.text}
                </ParagraphSmall>
              </Inline>
            );
          })}
        </OptionsBox>
      </Content>
    </Modal>
  );
};

const Content = styled(Stack)`
  margin: 12px 0 8px;
`;

const OptionsBox = styled(Stack)`
  padding: 0 15px;
`;
