import { colors } from '@/theme/colors';
import { IconButton } from '@/ui/button/icon-button';
import { notify } from '@/ui/snackbar/notify';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { useTranslation } from 'react-i18next';
import type { ActionItemType } from './use-action-panel-search-params';
import { useActionPanelSearchParams } from './use-action-panel-search-params';
import { copyTextToClipboard } from '@/helpers/copy-text-to-clipboard';
import type { MouseEvent } from 'react';
import { generateUrlWithSearchParams } from '@/helpers/generate-url-with-search-params';

interface CopyActionItemUrlButtonProps {
  actionItemType: ActionItemType;
  actionItemId: string;
  className?: string;
  noPropagation?: boolean;
}

export const CopyActionItemUrlButton = ({
  actionItemType,
  actionItemId,
  className,
  noPropagation = true,
}: CopyActionItemUrlButtonProps) => {
  const { t } = useTranslation('default', { keyPrefix: 'actionPanel' });
  const { generateActionItemSearchParam } = useActionPanelSearchParams();

  const handleOnClick = async (event: MouseEvent<HTMLButtonElement>) => {
    const searchParams = generateActionItemSearchParam(
      actionItemId,
      actionItemType,
    );
    const url = generateUrlWithSearchParams(searchParams);

    if (noPropagation) event.stopPropagation();

    try {
      await copyTextToClipboard(url);
      notify({ message: t('copiedItemSuccess', { item: actionItemType }) });
    } catch (error) {
      notify({ message: t('copiedItemError') });
    }
  };

  return (
    <Tooltip
      content={t('copyItemUrl', { item: actionItemType })}
      showOnHovered
      padding="10px"
    >
      <IconButton
        color={colors.basics.black}
        icon="Link"
        size="13px"
        variant="ghost"
        className={className}
        onClick={handleOnClick}
      />
    </Tooltip>
  );
};
