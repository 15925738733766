import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { useCreateProjectListings } from '@/features/projects/project-listings/use-create-project-listings.mutation';
import { useCreateCompany } from '@/hooks/queries/use-create-company.mutation';
import { notify } from '@/ui/snackbar/notify';

import {
  organizationSearchResultsAddedCompaniesState,
  organizationSearchResultsCompaniesBusyState,
} from './organization-search-results.state';

export const useAddCompanyToProject = (
  projectId: string | null,
  onSuccess?: () => void,
) => {
  const { t } = useTranslation('projects');

  const { createCompanyFromCrunchbase } = useCreateCompany();
  const { createProjectListing } = useCreateProjectListings();

  const setBusyCompanies = useSetRecoilState(
    organizationSearchResultsCompaniesBusyState,
  );
  const [addedCompanies, setAddedCompanies] = useRecoilState(
    organizationSearchResultsAddedCompaniesState,
  );

  const addToProjectFromCrunchbase = useCallback(
    async (
      projectId: string,
      crunchbaseId: string,
      organizationId?: string,
    ) => {
      try {
        setBusyCompanies(companyIds => [...companyIds, crunchbaseId]);

        if (!organizationId) {
          const result = await createCompanyFromCrunchbase({
            cbId: crunchbaseId,
            source: 'SOSA Q',
          });
          organizationId = result.id;
        }
        await createProjectListing(projectId, {
          companyIds: [organizationId],
        });
        setAddedCompanies(companyIds => [...companyIds, crunchbaseId]);
      } catch (err) {
        if (err instanceof Error) console.error(err.message);
        notify({
          message: t`addCompany.errorAddingToProject`,
          withIcon: true,
          icon: 'CloseCircle',
        });
      } finally {
        setBusyCompanies(companyIds =>
          companyIds.filter(id => id !== crunchbaseId),
        );
      }
    },
    [createProjectListing, setAddedCompanies, setBusyCompanies],
  );

  const addToProjectFromSavvy = useCallback(
    async (projectId: string, organizationId: string) => {
      try {
        setBusyCompanies(companyIds => [...companyIds, organizationId]);
        await createProjectListing(projectId, {
          companyIds: [organizationId],
        });
        setAddedCompanies(companyIds => [...companyIds, organizationId]);
      } catch (err) {
        if (err instanceof Error) console.error(err.message);
        notify({
          message: t`addCompany.errorAddingToProject`,
          withIcon: true,
          icon: 'CloseCircle',
        });
      } finally {
        setBusyCompanies(companyIds =>
          companyIds.filter(id => id !== organizationId),
        );
      }
    },
    [createProjectListing, setAddedCompanies, setBusyCompanies],
  );

  return useCallback(
    async ({
      fromCB,
      crunchbaseId,
      organizationId,
    }: {
      crunchbaseId?: string;
      organizationId?: string;
      fromCB: boolean;
    }) => {
      if (
        (!crunchbaseId && !organizationId) ||
        (crunchbaseId && addedCompanies.includes(crunchbaseId)) ||
        (organizationId && addedCompanies.includes(organizationId)) ||
        !projectId
      ) {
        return;
      }

      if (fromCB && crunchbaseId) {
        await addToProjectFromCrunchbase(
          projectId,
          crunchbaseId,
          organizationId,
        );
      }

      if (!fromCB && organizationId) {
        await addToProjectFromSavvy(projectId, organizationId);
      }

      onSuccess?.();
    },
    [
      addToProjectFromCrunchbase,
      addToProjectFromSavvy,
      addedCompanies,
      onSuccess,
      projectId,
    ],
  );
};
