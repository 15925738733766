import { Outlet } from 'react-router-dom';
import { useSosaAppConfig } from '@/use-sosa-app-config';
import { Suspense } from 'react';
import { Loader } from '@/components/loader/loader';
import { HtmlConfigProvider } from '../html-config/html-config-provider';

export const AppConfigProvider = () => {
  useSosaAppConfig();

  return (
    <HtmlConfigProvider>
      <Suspense fallback={<Loader isFullPage />}>
        <Outlet />
      </Suspense>
    </HtmlConfigProvider>
  );
};
