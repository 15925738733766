import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import type { UpdateCompanyTagsPayload } from '@/api/v4/organization-tags.api';
import { updateOrganizationTags } from '@/api/v4/organization-tags.api';
import { queryClient, QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

export const useUpdateCompanyTags = () => {
  const { t } = useTranslation('default');

  const updateTagsMutation = useMutation({
    mutationFn: ({
      organizationId,
      payload,
    }: {
      organizationId: string;
      payload: UpdateCompanyTagsPayload;
    }) => {
      return updateOrganizationTags(organizationId, payload);
    },
    onError: (error: Error) => {
      notify({
        message: t`unknownError`,
      });
      captureException(error);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([QueryKey.CompanyTags]),
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);
    },
  });

  const updateTags = (
    organizationId: string,
    payload: UpdateCompanyTagsPayload,
  ) =>
    updateTagsMutation.mutateAsync({
      organizationId,
      payload,
    });

  return {
    updateTags,
    isError: updateTagsMutation.isError,
  };
};
