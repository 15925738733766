import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { colors } from '@/theme/colors';
import type { Variant } from '@/ui/button/button';
import { Button } from '@/ui/button/button';

import { useValidateUploadedFiles } from './use-validate-uploaded-files';

export interface UploadFileInputProps {
  onSubmit: (files: File[]) => void;
  variant?: Variant;
  acceptedFiles: string[];
}

export const UploadFileInput = ({
  acceptedFiles,
  variant,
  onSubmit,
}: UploadFileInputProps) => {
  const { t } = useTranslation('default');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { validateUploadedFiles } = useValidateUploadedFiles();

  const clearInputValue = () => {
    if (!fileInputRef?.current) return;
    fileInputRef.current.value = '';
  };

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = validateUploadedFiles(event.target.files || []);
    if (!files) return;

    onSubmit(files);
    clearInputValue();
  };

  return (
    <>
      <input
        name="new-file"
        type="file"
        onChange={onFileInputChange}
        multiple
        ref={fileInputRef}
        accept={acceptedFiles.map(file => `.${file}`).join(',')}
        hidden
      />
      <UploadButton
        variant={variant ?? 'text'}
        startIcon="ArrowDownFromBracket"
        color={!variant ? colors.blue.primaryA : undefined}
        onClick={() => fileInputRef.current?.click()}
      >
        {t`actionPanel.files.uploadFile`}
      </UploadButton>
    </>
  );
};
const UploadButton = styled(Button)`
  height: fit-content;
`;
