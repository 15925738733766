import type { CSSAttribute } from 'goober';
import { styled } from 'goober';

import { ReactComponent as SavvyLogo } from '@/assets/svg/savvy-logo.svg';
import { Flexbox } from '@/ui/flexbox/flexbox';

type AskXFooterActionsProps = {
  actionComponent?: React.ReactNode;
  padding?: CSSAttribute['padding'];
};

export const AskXFooterActions = ({
  actionComponent,
  padding,
}: AskXFooterActionsProps) => {
  return (
    <AskXFooter name="ask-x-footer" justify="space-between" alignItems="center">
      <Flexbox
        name="ask-x-logo"
        padding={padding}
        alignItems="center"
        gap="7px"
      >
        <StyledSavvyLogo />
      </Flexbox>
      {actionComponent}
    </AskXFooter>
  );
};

const StyledSavvyLogo = styled(SavvyLogo)`
  margin-top: -50px;
`;

const AskXFooter = styled(Flexbox)`
  width: 100%;
  background-repeat: repeat;
`;
