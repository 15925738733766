export enum PRODUCT {
  VERIFY_COMPANY = 'Verify company',
  EXPORT_TO_PPT = 'Export project to ppt',
  EXPORT_STREAM = 'Export stream',
  TEAM_CONTACTS = 'Team contacts',
  KPI_ORGANIZATION_VERIFIED_LIST = 'View KPI organization verified list',
  CRUNCHBASE = 'Crunchbase',
  EXPLORE = 'Explore',
  ENHANCE_PROJECT = 'Enhance project',
  ASK_X = 'Ask X',
}
