import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import type { ErrorResponseV4 } from '@/api/v4/organization.api';
import {
  addCompanyOfficeLocation,
  type CompanyOfficeLocationPayload,
} from '@/api/v4/organization.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

import { companyIdState } from '../../company.state';

export const useAddOfficeLocation = () => {
  const companyId = useRecoilValue(companyIdState);
  const { t } = useTranslation('default');
  const queryClient = useQueryClient();

  const addOfficeLocationMutation = useMutation(
    async (payload: CompanyOfficeLocationPayload) => {
      if (!companyId) {
        return;
      }
      return addCompanyOfficeLocation(companyId, payload);
    },
    {
      onError: (err: unknown) => {
        const error = err as ErrorResponseV4;

        if (error.response.status === 422) {
          return notify({ message: error.response.data.message });
        }
        return notify({ message: t`default:unknownError` });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          QueryKey.CompanyOfficeLocations,
          companyId,
        ]);
      },
    },
  );

  const addOfficeLocation = async ({
    payload,
    onSuccess,
  }: {
    payload: CompanyOfficeLocationPayload;
    onSuccess: () => void;
  }) => {
    await addOfficeLocationMutation.mutateAsync(payload, { onSuccess });
  };

  return {
    addOfficeLocation,
  };
};
