import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchOrganizationComments } from '@/api/v4/organization-comments.api';
import { queryClient, QueryKey } from '@/config/query-client';
import { projectIdState } from '@/features/projects/project.state';
import { teamIdState } from '@/features/teams/team.state';
import { notify } from '@/ui/snackbar/notify';

import { companyIdState } from '../../company.state';

export const useCompanyComments = () => {
  const { t } = useTranslation('default');
  const companyId = useRecoilValue(companyIdState);
  const projectId = useRecoilValue(projectIdState);
  const teamId = useRecoilValue(teamIdState);

  const fetchCompanyComments = async () => {
    if (!companyId) return [];

    return fetchOrganizationComments({ organizationId: companyId });
  };

  return useQuery(
    [QueryKey.CompanyComments, companyId, projectId, teamId],
    fetchCompanyComments,
    {
      onError: () =>
        notify({
          message: t`default:unknownError`,
        }),
      enabled: !!companyId,
      staleTime: 1000 * 60 * 5,
      onSuccess: () =>
        queryClient.invalidateQueries([QueryKey.CompanyActionsInfo, companyId]),
    },
  );
};
