import type { ChangeEvent } from 'react';
import { forwardRef } from 'react';
import { styled } from 'goober';

interface SwitchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  dataTestId?: string;
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(function Switch(
  { checked = false, dataTestId, onChange, ...rest },
  inputRef,
) {
  return (
    <SwitchStyledContainer checked={checked}>
      <label>
        <HiddenInput
          data-testid={dataTestId}
          ref={inputRef}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          {...rest}
        />
        <Slider checked={checked} />
      </label>
    </SwitchStyledContainer>
  );
});

const Slider = styled('span')<{ checked?: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  background-color: ${({ theme }) => theme.colors.gray.c5};
  border-radius: 32px;

  &:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    transition: 0.4s;
    border-radius: 50%;
    background-color: ${({ checked, theme }) =>
      checked ? theme.colors.blue.primaryA : theme.colors.basics.white};
  }
`;

const HiddenInput = styled('input', forwardRef)`
  display: none;

  &:checked + ${Slider} {
    background-color: ${({ theme }) => theme.colors.blue.c5};
  }

  &:checked + ${Slider}:before {
    transform: translateX(26px);
  }
`;

const SwitchStyledContainer = styled('div')<{ checked?: boolean }>`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;

  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;
