import { forwardRef, useRef } from 'react';
import { styled } from 'goober';

import { isOrganizationOption } from '@/helpers/other';
import { Logo } from '@/ui/logo/logo';
import { CustomOptionWrapper } from '@/ui/select/async/components/option/custom-option-wrapper';
import type { AsyncSelectOptionProps } from '@/ui/select/async/components/option/option';

export const CommentDropdownOption = ({ ...props }: AsyncSelectOptionProps) => {
  const ref = useRef<HTMLDivElement>(null);

  if (!isOrganizationOption(props.data)) {
    return <></>;
  }

  return (
    <CustomOptionWrapper
      {...props}
      tooltipContent={props.data.label}
      optionHeight="40px"
    >
      <OrganizationLogo
        size="16px"
        name={props.data.label ?? ''}
        logoUrl={props.data.logo}
        fontSize="9px"
        singleLetter
      />

      <Label ref={ref}>{props.data.label}</Label>
    </CustomOptionWrapper>
  );
};

const OrganizationLogo = styled(Logo)`
  background-color: ${({ theme }) => theme.colors.accent.green.c3};
  border: none;
`;

const Label = styled('div', forwardRef)`
  flex-grow: 1;

  display: block;
  clear: left;
  width: 100%;
  ${({ theme }) => theme.typography.widget.smallText}
  ${({ theme }) => theme.mixins.ellipsis}
  color: ${({ theme }) => theme.colors.basics.black};
  font-size: 14px;
`;
