import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import type { UserRecentSearchRecord } from '@/api/v4/search.api';
import { assertNever } from '@/helpers/assert-never';
import { Stack } from '@/ui/line/line';

import { OrganizationSuggestion } from '../organization-suggestion';
import { ProjectSuggestion } from '../project-suggestion';
import { TeamSuggestion } from '../team-suggestion';
import { useKeyboardNavigationOnSearchedListItems } from '../use-keyboard-navigation-on-searched-list-items';
import { useSearchedItemClick } from '../use-searched-item-click';

export const RecentSearchRecords = ({
  records,
}: {
  records: UserRecentSearchRecord[];
}) => {
  const { t } = useTranslation('default');
  const handleSearchedItemClick = useSearchedItemClick();
  const { selectedListItem } = useKeyboardNavigationOnSearchedListItems(
    records.map(record => ({
      id: record.recordId,
      type: record.type,
    })),
  );

  const mapRecentRecords = (recentRecord: UserRecentSearchRecord) => {
    switch (recentRecord.type) {
      case 'company':
        return (
          <OrganizationSuggestion
            key={recentRecord.recordId}
            id={recentRecord.recordId}
            isSelected={recentRecord.recordId === selectedListItem?.id}
            name={recentRecord.name}
            type={recentRecord.type}
            logoUrl={recentRecord.logoUrl ?? undefined}
            onClick={() =>
              handleSearchedItemClick({
                type: recentRecord.type,
                id: recentRecord.recordId,
                isRecent: true,
              })
            }
          />
        );
      case 'project':
        return (
          <ProjectSuggestion
            key={recentRecord.recordId}
            id={recentRecord.recordId}
            isSelected={recentRecord.recordId === selectedListItem?.id}
            name={recentRecord.name}
            type={recentRecord.type}
            listingsCount={recentRecord.listingsCount ?? undefined}
            onClick={() =>
              handleSearchedItemClick({
                type: recentRecord.type,
                id: recentRecord.recordId,
                isRecent: true,
              })
            }
          />
        );
      case 'team':
        return (
          <TeamSuggestion
            key={recentRecord.recordId}
            id={recentRecord.recordId}
            isSelected={recentRecord.recordId === selectedListItem?.id}
            name={recentRecord.name}
            type={recentRecord.type}
            onClick={() =>
              handleSearchedItemClick({
                type: recentRecord.type,
                id: recentRecord.recordId,
                isRecent: true,
              })
            }
          />
        );

      default:
        assertNever(recentRecord.type);
    }
  };
  return (
    <Container gap="0">
      <Title>{t`globalSearch.recent`}</Title>
      {records.map(mapRecentRecords)}
    </Container>
  );
};

const Container = styled(Stack)`
  max-height: 475px;
  padding-right: 8px;
  ${({ theme }) => theme.mixins.scrollbar};
  overflow-y: auto;
  margin-top: 18px;
`;

const Title = styled('h5')`
  ${({ theme }) => theme.typography.acrossPlatform.buttonText}
`;
