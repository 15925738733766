import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { modalState } from '../../state/modal.state';
import { Modal } from '../../ui/modal/modal';

export const ConfirmDiscardChangesModal = () => {
  const { t } = useTranslation('default');
  const [modal, setModalState] = useRecoilState(modalState);

  if (!modal || modal.state !== 'confirmDiscardChanges') return null;

  const close = () => {
    setModalState(null);
  };

  return (
    <Modal
      header={t`areYouSure`}
      onClose={close}
      mainAction={{
        label: t`discard`,
        onClick: () => {
          modal.mainAction();
          close();
        },
      }}
      secondaryAction={{
        label: t`cancel`,
        onClick: () => {
          close();
        },
      }}
      variant="danger"
    >
      <Container>
        <Description>{t`unsavedChangesWarning`}</Description>
      </Container>
    </Modal>
  );
};

const Container = styled('div')`
  height: 100px;
`;

const Description = styled('p')`
  ${({ theme }) => theme.typography.acrossPlatform.small};
  color: ${({ theme }) => theme.colors.gray.c12};
`;
