import type { FC, ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { HtmlFontsConfig } from './html-fonts-config';
import { HtmlTitleConfig } from './html-title-config';

interface Props {
  children: ReactNode;
}

export const HtmlConfigProvider: FC<Props> = ({ children }) => (
  <HelmetProvider>
    <HtmlFontsConfig />
    <HtmlTitleConfig title="Savvy" />
    {children}
  </HelmetProvider>
);
