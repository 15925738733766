import type { Tag } from '@/api/v4/organization-enrich.api';
import { Inline } from '@/ui/line/line';
import { styled } from 'goober';
import { TagWithEllipsis } from './tag-with-ellipsis';
import { useState } from 'react';
import { Button } from '@/ui/button/button';
import { SkeletonText } from '@/ui/table/infinite-table/skeleton-row';
import { paths } from '@/routes/helpers/paths';
import { parseSearchParams } from '@/routes/helpers/parse-search-params';
import { Link } from '@/ui/link/link';

export const TagsCell = ({
  tags,
  isLoading,
  stopPropagation,
}: {
  tags: Tag[];
  isLoading: boolean;
  stopPropagation?: boolean;
}) => {
  const [allVisible, setAllVisible] = useState(false);

  const visibleTags = allVisible ? tags : tags.slice(0, 3);
  const hiddenTags = tags.length > 3 ? tags.slice(3) : [];

  const showMoreButtonText = allVisible
    ? 'Show less'
    : `+${hiddenTags?.length}`;

  return (
    <TagsContainer alignItems="center">
      {isLoading ? (
        <SkeletonText width="80px" />
      ) : (
        <>
          {visibleTags.map(tag => (
            <Link
              key={tag.id}
              to={
                paths.companies() +
                parseSearchParams({
                  tags: tag.id,
                })
              }
            >
              <TagWithEllipsis
                smallScreenMaxWidth="80px"
                maxWidth="120px"
                text={tag.name}
                variant="blue"
                size="s"
              />
            </Link>
          ))}
          {hiddenTags.length > 0 && (
            <ShowMoreButton
              variant="text"
              onClick={e => {
                if (stopPropagation) e.stopPropagation();
                setAllVisible(value => !value);
              }}
            >
              {showMoreButtonText}
            </ShowMoreButton>
          )}
        </>
      )}
    </TagsContainer>
  );
};

const TagsContainer = styled(Inline)`
  white-space: nowrap;
`;

const ShowMoreButton = styled(Button)`
  color: ${({ theme }) => theme.colors.gray.c13};
`;
