import { useSetRecoilState } from 'recoil';

import { trackSearchResultClicked } from '@/pendo';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { modalState } from '@/state/modal.state';

import { useAddRecentSearchRecord } from './user-recent-search/use-add-recent-search-record.mutation';

export type SearchedListItem = {
  id: string;
  type: 'company' | 'team' | 'project' | 'tag';
  isRecent?: boolean;
};

export const useSearchedItemClick = () => {
  const navigateTo = useNavigateTo();
  const setModal = useSetRecoilState(modalState);
  const { addRecentSearchRecord } = useAddRecentSearchRecord();

  return async ({ type, id, isRecent }: SearchedListItem) => {
    setModal(null);

    if (type !== 'tag') {
      trackSearchResultClicked({ type, id, isRecent: isRecent ?? false });
      await addRecentSearchRecord({
        searchRecordId: id,
        type,
      });
    }

    switch (type) {
      case 'company':
        navigateTo.company({ companyId: id });
        break;
      case 'project':
        navigateTo.project({ projectId: id });
        break;
      case 'team':
        navigateTo.team({ teamId: id });
        break;
    }
  };
};
