import { isEllipsis } from '@/helpers/other';
import type { GeneralTagProps } from '@/ui/tag/general-tag';
import { GeneralTag } from '@/ui/tag/general-tag';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { styled } from 'goober';
import { forwardRef, useEffect, useRef, useState } from 'react';

interface TagWithEllipsisProps extends GeneralTagProps {
  text: string;
}

export const TagWithEllipsis = ({ text, ...props }: TagWithEllipsisProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setIsEllipsisActive(isEllipsis(ref.current));
    }
  }, [text]);

  return (
    <Tooltip content={text} visible={isEllipsisActive}>
      <GeneralTag {...props}>
        <EllipsisBox ref={ref}>{text}</EllipsisBox>
      </GeneralTag>
    </Tooltip>
  );
};

const EllipsisBox = styled('div', forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis};
`;
