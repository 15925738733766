import { useMemo } from 'react';
import { useRecoilState } from 'recoil';

import {
  exploreFormDescriptionState,
  exploreFormExampleCompaniesState,
  exploreFormLocationsState,
} from '../explore.state';

export const useExploreForm = () => {
  const [locations, setLocations] = useRecoilState(exploreFormLocationsState);
  const [exampleCompanies, setExampleCompanies] = useRecoilState(
    exploreFormExampleCompaniesState,
  );
  const [description, setDescription] = useRecoilState(
    exploreFormDescriptionState,
  );
  const isValid = !!description.length;

  const isDirty = useMemo(() => {
    return !!locations.length || !!exampleCompanies.length || !!description;
  }, [locations, exampleCompanies, description]);

  const resetForm = () => {
    setLocations([]);
    setDescription('');
    setExampleCompanies([]);
  };

  return {
    locations,
    setLocations,
    description,
    setDescription,
    exampleCompanies,
    setExampleCompanies,
    isDirty,
    isValid,
    resetForm,
  };
};
