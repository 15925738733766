import { styled } from 'goober';

import { SpinnerIcon } from '@/assets/icons';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { strokeColorIcon } from '@/ui/icons/functions';

interface SpinnerProps {
  size?: string;
  color: string;
}

export const Spinner = ({ size = '18px', color }: SpinnerProps) => {
  return (
    <Wrapper name="spinner" justify="center" alignItems="center" color={color}>
      <SpinnerIcon width={size} height={size} />
    </Wrapper>
  );
};

const Wrapper = styled(Flexbox)<{ color: string }>`
  ${({ color }) => strokeColorIcon(color, 'transparent')};
`;
