import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';
import { InfiniteScrollTable } from '@/ui/table/infinite-table/infinite-scroll-table';

import { OverflowContainer } from '../project-listing-suggestion-form-modal';
import { useProjectListingSuggestionState } from '../use-project-listing-suggestion.state';

import { useEnhanceProjectConfigTable } from './use-enhance-project-config-table';
import type {
  ProjectCompaniesSort,
  ProjectListing,
} from '@/api/v4/projects.api';

export const EnhanceProjectConfigStep2 = () => {
  const { t } = useTranslation('projects');
  const state = useProjectListingSuggestionState();
  const { headers, rows } = useEnhanceProjectConfigTable();

  return (
    <>
      <Subheader>{t`enhanceConfigCompaniesSubheader1`}</Subheader>
      <Subheader>{t`enhanceConfigCompaniesSubheader2`}</Subheader>
      <OverflowContainer
        name="enhance-project-config1-body"
        height="410px"
        data-testid="tags-results"
        direction="column"
      >
        <Flexbox direction="column" name="enhance-project-config-q1" gap="8px">
          <InfiniteScrollTable<ProjectListing, ProjectCompaniesSort>
            id="project-listing-suggestion-companies-filter"
            hasMore={false}
            isLoading={false}
            rows={rows}
            data={state.projectListings || []}
            sortState={state.companyFilterSortState}
            height="100%"
            headers={headers}
          />
        </Flexbox>
      </OverflowContainer>
    </>
  );
};

const Subheader = styled('div')`
  ${({ theme }) => theme.typography.enhance.subheader}
  padding-left: 22px;
`;
