import type { IconVariant } from '@/ui/icons/icon';

export const getCategoryIcon = (category: string): IconVariant => {
  switch (category) {
    case 'Sector':
      return 'TagCategorySector';

    case 'Industry':
      return 'TagCategoryIndustry';

    case 'Sub Industry':
      return 'TagCategorySubIndustry';

    case 'Technology':
      return 'TagCategoryTechnology';

    default:
      return 'TagCategoryOther';
  }
};
