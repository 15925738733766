import { mapTeamsToBasicTeamOptions } from '@/api/v4/mappers/team-basic-option';
import type { TeamOptionsQueryParams } from '@/api/v4/team.api';
import { fetchTeamOptions } from '@/api/v4/team.api';
import { QueryKey } from '@/config/query-client';
import { useQuery } from '@tanstack/react-query';

export const useTeamOptions = (params?: TeamOptionsQueryParams) => {
  return useQuery(
    [QueryKey.TeamOptions, params],
    () => fetchTeamOptions(params),
    {
      select: mapTeamsToBasicTeamOptions,
      staleTime: 1000 * 10 * 5,
    },
  );
};
