import type { Namespace } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '../../checkbox/checkbox';
import type { HeaderCellConfig, IHeaderRow } from '../table.types';

export const useTranslateHeaders = <T,>(
  headers: HeaderCellConfig<T>[],
  translationNamespace: Namespace,
  translationKey?: string,
): IHeaderRow<T> => {
  const { t } = useTranslation(translationNamespace);
  const prefix = translationKey ? `${translationKey}.` : '';

  const prepareValue = (header: HeaderCellConfig<T>) => {
    const content = header.columnKey !== '' ? t(prefix + header.columnKey) : '';
    if (!header.showCheckbox) return content;

    return (
      <>
        <Checkbox
          id={`${header.id}-checkbox` || header.columnKey}
          name="select-all-checkbox"
          label=""
          checked={header.isChecked}
          icon={header.checkboxIcon}
          onChange={event => header.onCheckboxChange?.(event.target.checked)}
        />
        {content}
      </>
    );
  };

  return {
    cells: headers.map(header => ({
      ...header,
      value: prepareValue(header),
    })),
  };
};
