import { styled } from 'goober';

export const AmountIndicator = ({
  amount,
  className,
}: {
  amount?: number;
  className?: string;
}) => {
  return <Base className={className}>{amount}</Base>;
};

const Base = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  background-color: ${({ theme }) => theme.colors.blue.c4};
  border-radius: 50%;

  ${({ theme }) => theme.typography.companyInfo.amountIndicator};
`;
