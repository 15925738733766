import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { TextArea } from '@/ui/text-editable/text-editable.styles';
import { PRODUCT } from '@/user/products/product.type';
import { useUserProducts } from '@/user/products/use-user-products';

import { AskXFooterActions } from './ask-x-footer-actions';
import { useSendInquiry } from './use-send-inquiry.mutation';

type AskXProps = {
  onCancel?: () => void;
  onSuccess?: () => void;
  showResult?: boolean;
};

export const AskX = ({ onCancel, onSuccess, showResult }: AskXProps) => {
  const { t } = useTranslation('askX');
  const [text, setText] = useState<string>('');

  const { isLoading, mutateAsync } = useSendInquiry();

  const { hasRequiredProduct } = useUserProducts();
  if (!hasRequiredProduct(PRODUCT.ASK_X)) return null;

  const sendInquiry = async () => {
    await mutateAsync(
      { message: text },
      {
        onSuccess: () => {
          setText('');
          onSuccess?.();
        },
      },
    );
  };

  return (
    <Stack fullWidth gap="26px">
      {!showResult ? (
        <>
          <Description>{t`form.description`}</Description>
          <StyledTextArea
            placeholder={t`form.placeholder`}
            onChange={e => setText(e.target.value)}
            rows={6}
            resize="vertical"
            disabled={isLoading}
            value={text}
          />
        </>
      ) : (
        <Flexbox
          name="result-message"
          margin="0 0 64px 0"
        >{t`form.result`}</Flexbox>
      )}
      <AskXFooterActions
        padding="28px 0"
        actionComponent={
          <Flexbox name="actions" gap="18px">
            {!showResult ? (
              <>
                <Button
                  variant="black"
                  onClick={onCancel}
                  disabled={isLoading}
                >{t`modal.cancel`}</Button>
                <Button
                  variant="primary"
                  disabled={text.length < 1}
                  isLoading={isLoading}
                  onClick={sendInquiry}
                >{t`modal.send`}</Button>
              </>
            ) : (
              <Button
                variant="black"
                onClick={onCancel}
                disabled={isLoading}
              >{t`modal.close`}</Button>
            )}
          </Flexbox>
        }
      />
    </Stack>
  );
};

const StyledTextArea = styled(TextArea)`
  color: ${({ theme }) => theme.colors.basics.white};
  background-color: ${({ theme }) =>
    theme.palettes.component.askX.input.background};
  padding: 12px 16px 12px 16px;
  border-radius: 6px;
  border: ${({ theme }) => theme.palettes.component.askX.input.border} 2px solid;
  font-size: 12px;
`;

const Description = styled('p')`
  line-height: 20.44px;
`;
