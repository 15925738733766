import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import type { IGetRefs } from '@/hooks/use-multi-refs';
import { modalState } from '@/state/modal.state';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Modal as ModalComponent } from '@/ui/modal/modal';
import { GeneralTag } from '@/ui/tag/general-tag';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { SubHeader } from '@/ui/typography/widgets';

import { AddTagsSection } from './add-tags-section';
import { SelectedTagsSection } from './selected-tags-section';
import { useEntityTags } from './use-entity-tags';
import { useUpdateTags } from './use-update-tags';
import { useTags } from '@/features/tags/use-tags.query';

export const ManageTagsModal = () => {
  const { t } = useTranslation('tags');
  const [modal, setModalState] = useRecoilState(modalState);
  const [newTag, setNewTag] = useState<string | null>(null);
  const { isLoading } = useTags();
  const [searchPhrase, setSearchPhrase] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const type = modal?.state === 'manageTags' ? modal.type : null;
  const refetchTags = modal?.state === 'manageTags' ? modal.refetchTags : null;

  const { updateEntity } = useUpdateTags(type);

  const entityTags = useEntityTags(type);

  const entityTagIds = entityTags.map(tag => tag.id);

  const close = () => {
    setModalState(null);
  };

  if (!modal || modal.state !== 'manageTags') return null;

  const addTag = async (tagId: string) => {
    setNewTag(tagId);
    await updateEntity(modal.entityId, [...entityTagIds, tagId]);

    refetchTags?.();
  };

  const scrollToAddedTag: IGetRefs = refs => {
    if (!newTag) return;

    const newTagNode = refs[newTag];
    if (!newTagNode) return;

    newTagNode.focus();
    newTagNode.scrollIntoView({
      block: 'nearest',
      behavior: 'smooth',
      inline: 'nearest',
    });
    setNewTag(null);
    inputRef.current?.focus();
    inputRef.current?.select();
  };

  const onRemoveTag = async (tagId: string) => {
    const tagIds = entityTagIds.filter(tag => tag !== tagId);
    await updateEntity(modal.entityId, tagIds);

    refetchTags?.();
  };

  return (
    <Modal onClose={close} closeIcon={false}>
      <Flexbox name="tags-modal-container" gap="0" height="575px">
        <ContentContainer
          name="tags-modal-add-tags"
          direction="column"
          gap="24px"
          width="810px"
          withBorder
        >
          <Flexbox name="add-tags-header" gap="5px" alignItems="center">
            <SubHeader>{t`addTags`}</SubHeader>{' '}
            <Tooltip
              content={t`addTagsTooltip`}
              placement="bottom"
              withArrow={false}
            >
              <Icon icon="Info" />
            </Tooltip>
          </Flexbox>
          <AddTagsSection
            isLoading={isLoading}
            ref={inputRef}
            selectedTags={entityTags}
            onTagAdd={addTag}
            searchPhrase={searchPhrase}
            setSearchPhrase={setSearchPhrase}
          />
        </ContentContainer>
        <ContentContainer
          name="tags-modal-selected-tags"
          direction="column"
          width="346px"
          gap="30px"
        >
          <Flexbox
            name="modal-header-container"
            justify="space-between"
            alignItems="baseline"
            data-testid="modal-header-container"
          >
            <Flexbox
              name="manage-modals-selected-header"
              justify="flex-start"
              gap="7px"
            >
              <SubHeader>{t`selectedTags`}</SubHeader>
              <GeneralTag size="xs" variant="blue">
                {entityTags.length}
              </GeneralTag>
            </Flexbox>
            <Icon role="button" icon="Close" onClick={close} />
          </Flexbox>
          <SelectedTagsSection
            tags={entityTags}
            onTagRemove={onRemoveTag}
            getRefs={scrollToAddedTag}
            searchPhrase={searchPhrase}
          />
        </ContentContainer>
      </Flexbox>
    </Modal>
  );
};

const Modal = styled(ModalComponent)`
  padding: 0;
`;

const ContentContainer = styled(Flexbox)<{
  withBorder?: boolean;
  width: string;
}>`
  padding: 38px 44px;

  width: ${({ width }) => width};

  ${({ theme, withBorder }) =>
    withBorder &&
    `
      border-right: 2px solid ${theme.colors.gray.c2};
  `};
`;
