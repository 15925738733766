import { getYear, isValid } from 'date-fns';
import {
  format,
  formatDistanceToNowStrict,
  parse,
  parseISO,
  secondsToHours,
  secondsToMinutes,
} from 'date-fns/esm';
import { enGB } from 'date-fns/locale';

import { CALENDAR_DATE_FORMAT, GENERAL_DATE_FORMAT } from '@/constants';
import i18n from '@/lib/i18n/i18n';
import { parseStringToNumber } from './parse-string-to-number';

const { t } = i18n;

export function formatCalendarDate(
  date: Date | string | null,
  dateFormat = 'MMM yyyy',
) {
  if (!date) return null;
  return format(typeof date === 'string' ? new Date(date) : date, dateFormat);
}

export function formatCalendarDateAsOptionValue(date: Date | null) {
  if (!date) return null;
  return format(date, CALENDAR_DATE_FORMAT);
}

export const parseNullishStringToDate = (
  date?: string | null,
  format = CALENDAR_DATE_FORMAT,
) => (date ? parseStringToDate(date, format) : null);

const parseStringToDate = (date: string, format = CALENDAR_DATE_FORMAT) => {
  if (format === CALENDAR_DATE_FORMAT) return parseISO(date);
  return parse(date, format, new Date());
};

export function parseDateRange(
  start: string | null,
  end: string | null,
  format?: string,
) {
  if (!start && !end) return '';
  if (!end)
    return formatCalendarDate(parseNullishStringToDate(start), format) || '';
  return `${
    formatCalendarDate(parseNullishStringToDate(start), format) || ''
  } - ${formatCalendarDate(parseNullishStringToDate(end), format) || ''}`;
}

export const formatDate = (
  date: Date | string | number,
  options?: {
    format?: string;
    /**
     * Some BE APIs return timestamp in seconds, some in milliseconds.
     */
    shouldBeConvertedToMilliseconds?: boolean;
  },
) => {
  const {
    shouldBeConvertedToMilliseconds: convertTimeStampToMilliseconds = false,
    format: dateFormat = GENERAL_DATE_FORMAT,
  } = options || {};
  if (typeof date === 'string') {
    return format(new Date(date), dateFormat);
  }
  if (typeof date === 'number' && convertTimeStampToMilliseconds) {
    return format(date * 1000, dateFormat);
  }
  return format(date, dateFormat);
};

export const getRelativeDate = (date: string, format = GENERAL_DATE_FORMAT) => {
  const newDate = new Date(date);
  const seconds = parseInt(
    formatDistanceToNowStrict(newDate, {
      addSuffix: false,
      unit: 'second',
    }),
    10,
  );

  if (seconds <= 60) return t`default:actionPanel.dateNow`;
  if (seconds > 60 && seconds < 3600)
    return t('default:actionPanel.dateAgo', {
      date: `${secondsToMinutes(seconds)}m`,
    });
  if (seconds >= 3600 && seconds < 86400)
    return t('default:actionPanel.dateAgo', {
      date: `${secondsToHours(seconds)}h`,
    });

  return formatCalendarDate(newDate, format);
};

export const isValidDate = (dateFormat: string) => {
  const parsedDate = parse(dateFormat, 'yyyy-MM-dd', new Date(), {
    locale: enGB,
  });
  return isValid(parsedDate);
};

export const isValidYear = (value: string) => {
  const year = parseStringToNumber(value);
  if (!year) return false;

  const currentYear = getYear(new Date());
  const MIN_YEAR = 1000;

  return year >= MIN_YEAR && year <= currentYear;
};
