import type { ReactNode } from 'react';
import { createPortal } from 'react-dom';

export const Portal = ({
  children,
  id,
}: {
  children: ReactNode;
  id?: string;
}) => {
  const wrapper = id ? document.getElementById(id) : null;
  const container = wrapper ?? document.body;

  return createPortal(children, container);
};
