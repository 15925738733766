import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchTeamFiles } from '@/api/v4/team-files.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { teamIdState } from '../../../team.state';

export const useTeamFiles = () => {
  const { t } = useTranslation('default');
  const teamId = useRecoilValue(teamIdState);

  const { hasRequiredPermission } = useUserPermissions();

  const fetchFiles = async () => {
    if (!teamId) return [];

    return fetchTeamFiles(teamId);
  };

  return useQuery([QueryKey.TeamFiles, teamId], fetchFiles, {
    onError: () =>
      notify({
        message: t`default:unknownError`,
      }),
    enabled: !!teamId && hasRequiredPermission(PERMISSION.VIEW_FILES),
    staleTime: 1000 * 60 * 5,
  });
};
