import { createContext, createElement, useContext } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import type { DefaultTheme } from 'goober';
import { setup } from 'goober';
import { prefix } from 'goober/prefixer';
import { shouldForwardProp } from 'goober/should-forward-prop';

import { animations } from '@/theme/animations';
import {
  brandPalette,
  colors,
  componentsPalette,
  productPalette,
} from '@/theme/colors';

import { mixins } from './mixins';
import { shadow } from './shadow';
import { fonts, typography } from './typography';

export const theme: DefaultTheme = {
  animations,
  colors,
  palettes: {
    brand: brandPalette,
    product: productPalette,
    component: componentsPalette,
  },
  typography,
  mixins,
  shadow,
  fonts,
};

const ThemeContext = createContext(theme);
const useTheme = () => useContext(ThemeContext);

setup(createElement, prefix, useTheme, shouldForwardProp(isPropValid));
