import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { GENERAL_DATE_FORMAT } from '@/constants';
import {
  formatCalendarDate,
  formatDate,
  getRelativeDate,
} from '@/helpers/format-date';
import { Tooltip } from '@/ui/tooltip/tooltip';

interface ActionPanelDateProps {
  date: string;
  format?: string;
  relative?: boolean;
}

export function ActionPanelDate({
  date,
  format = GENERAL_DATE_FORMAT,
  relative = false,
}: ActionPanelDateProps) {
  const { t } = useTranslation('default');

  return (
    <Container>
      <Tooltip
        placement="left"
        content={t('actionPanel.createdAt', {
          date: formatDate(date, { format: `MMM dd, yyyy HH:mm` }),
        })}
        visible
      >
        <div>
          {relative
            ? getRelativeDate(date, format)
            : formatCalendarDate(date, format)}
        </div>
      </Tooltip>
    </Container>
  );
}

const Container = styled('div')`
  ${({ theme }) => theme.typography.actionsPanel.updates.personOrCompanyAction}
  color: ${({ theme }) => theme.colors.gray.c11};
  white-space: nowrap;
  display: inline-flex;
`;
