import { useRef } from 'react';
import type { SingleValueProps } from 'react-select';
import { components } from 'react-select';

import { isEllipsis, isOption } from '@/helpers/other';
import { Inline } from '@/ui/line/line';
import type { LogoProps } from '@/ui/logo/logo';
import { Logo } from '@/ui/logo/logo';
import { Tooltip } from '@/ui/tooltip/tooltip';

import { SingleValueContainer } from '../../async-select.styles';

interface SingleValueWithLogoProps extends SingleValueProps<unknown, boolean> {
  logoProps?: Partial<LogoProps>;
}

export const SingleValueWithLogo = ({
  children,
  logoProps,
  ...props
}: SingleValueWithLogoProps) => {
  const ref = useRef<HTMLDivElement>(null);

  if (!(isOption(props.data) && 'logo' in props.data)) {
    return <></>;
  }

  return (
    <components.SingleValue {...props}>
      <Tooltip
        placement="bottom"
        content={children}
        visible={isEllipsis(ref.current)}
      >
        <SingleValueContainer ref={ref}>
          <Inline alignItems="center" gap="8px" justifyContent="flex-start">
            <Logo
              size="14px"
              name={props.data.label ?? ''}
              logoUrl={
                typeof props.data.logo === 'string'
                  ? props.data.logo
                  : undefined
              }
              {...logoProps}
            />
            {children}
          </Inline>
        </SingleValueContainer>
      </Tooltip>
    </components.SingleValue>
  );
};
