import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilValue } from 'recoil';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';

import { modalState } from '@/state/modal.state';
import { notify } from '@/ui/snackbar/notify';

import { ConfirmDeleteModal } from './confirm-delete-modal';
import { useDeleteCompanies } from './delete-companies-modal/use-delete-company';

export const DeleteCompanyModal = () => {
  const modal = useRecoilValue(modalState);
  const { t } = useTranslation('companies');
  const navigateTo = useNavigateTo();
  const { deleteCompanies } = useDeleteCompanies();

  if (!modal || modal.state !== 'deleteCompany') return null;

  const handleDeleteCompanies = async () => {
    const result = await deleteCompanies({
      organizationIds: [modal?.company.id],
    });

    if (result.unableToDelete.length > 0) {
      notify({
        message: t`deleteCompanyModal.error`,
      });
    }

    if (result.deleted > 0) {
      navigateTo.companies();
    }
  };

  return (
    <Modal
      title={t`deleteCompanyModal.title`}
      description={t`deleteCompanyModal.description`}
      confirmLabel={t`deleteCompanyModal.confirm`}
      onConfirm={handleDeleteCompanies}
    />
  );
};

const Modal = styled(ConfirmDeleteModal)`
  max-width: 1024px;
`;
