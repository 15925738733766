import {
  get,
  type APIRequestQueryParams,
  type APIResponse,
} from './base-fetch';

export type Attendee = APIResponse<'/api/v4/attendees'>[number];

export type AttendeesQueryParams = APIRequestQueryParams<'/api/v4/attendees'>;

export const fetchAttendees = (params: AttendeesQueryParams) =>
  get<Attendee[]>('/v4/attendees', { params });
