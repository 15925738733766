import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';

import { getMessageFromErrorResponse, isBaseError } from '@/api/v4/base-fetch';
import type { AddOrInviteTeamMemberPayload } from '@/api/v4/team.api';
import { addOrInviteTeamMember as addOrInviteTeamMemberAPI } from '@/api/v4/team.api';
import { modalState } from '@/state/modal.state';
import { notify } from '@/ui/snackbar/notify';

import { useTeam } from '../../teams/overview/use-team.query';

export const useAddOrInviteTeamMember = () => {
  const { t } = useTranslation('default');

  const { refetch } = useTeam();

  const setModalState = useSetRecoilState(modalState);

  const addOrInviteTeamMemberMutation = useMutation(addOrInviteTeamMemberAPI, {
    onError: (err: Error) => {
      if (isBaseError(err)) {
        if (err.response.status === 400) {
        }
        if (err.response.status === 422) {
          const message = getMessageFromErrorResponse(err);
          notify({ message: message ?? t`default:unknownError` });
          return;
        }
      }

      notify({ message: t`default:unknownError` });
      if (err instanceof Error) console.error(err.message);
    },
    onSuccess: async () => {
      await refetch();
      setModalState(null);
    },
  });

  const addOrInviteTeamMember = async (payload: AddOrInviteTeamMemberPayload) =>
    await addOrInviteTeamMemberMutation.mutateAsync(payload);

  return {
    addOrInviteTeamMember,
    isLoading: addOrInviteTeamMemberMutation.isLoading,
    isError: addOrInviteTeamMemberMutation.isError,
  };
};
