import React, { useMemo } from 'react';
import { EmptyState } from './components/empty-state';
import { InitialState } from './components/initial-state';
import { SearchLoadingSkeleton } from './components/search-loading-skeleton';
import { RecentSearchRecords } from './search-suggestions/user-recent-search/recent-search-records';
import { useRecentSearchRecords } from './search-suggestions/user-recent-search/use-recent-search-records.query';
import { useSearchResults } from './use-search-results.query';

import 'react-loading-skeleton/dist/skeleton.css';
import { SearchResultsContent } from './search-results-content';

interface SearchContentProps {
  immediateSearchPhrase: string | null;
  debouncedSearchPhrase: string | null;
}

export const SearchContent: React.FC<SearchContentProps> = ({
  immediateSearchPhrase,
  debouncedSearchPhrase: searchPhrase,
}) => {
  const { data: searchResults, isFetching: isFetchingSearchResults } =
    useSearchResults(searchPhrase);

  const {
    hasUserSomeRecentSearchRecords,
    data: recentSearchRecords,
    isLoading: isLoadingRecentSearchRecords,
  } = useRecentSearchRecords();

  const isLoading = isFetchingSearchResults || isLoadingRecentSearchRecords;

  const results = searchResults ?? [];
  const hasResults = results.length > 0;

  const recentRecords = useMemo(
    () => recentSearchRecords ?? [],
    [recentSearchRecords],
  );

  if (hasResults) {
    return <SearchResultsContent results={results} />;
  }

  if (isLoading) {
    return <SearchLoadingSkeleton columns={6} />;
  }

  if (searchPhrase && !hasResults) {
    return <EmptyState searchPhrase={searchPhrase} />;
  }

  if (hasUserSomeRecentSearchRecords && !immediateSearchPhrase) {
    return <RecentSearchRecords records={recentRecords} />;
  }

  if (!immediateSearchPhrase) {
    return <InitialState />;
  }

  return null;
};
