import { Trans } from 'react-i18next';

import { colors } from '@/theme/colors';
import { Logo } from '@/ui/logo/logo';

import type { ProjectListingUpdate } from './helpers';

export function AddToProject({ update }: { update: ProjectListingUpdate }) {
  const organizationName = update.data.organization.name;
  const userDisplayName = `${update.user.firstName} ${update.user.lastName}`;
  const organizationLogoUrl = update.data.organization.logoUrl;
  return (
    <>
      <Logo
        singleLetter
        name={organizationName}
        logoUrl={organizationLogoUrl}
        bgColor={
          organizationLogoUrl ? colors.basics.white : colors.accent.green.c3
        }
      />
      <div>
        <Trans
          ns="default"
          i18nKey="actionPanel.updates.addToProject"
          values={{
            user: userDisplayName,
            company: organizationName,
          }}
          components={{ strong: <strong /> }}
        />
      </div>
    </>
  );
}
