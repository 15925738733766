import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { createOrganizationDescription } from '@/api/v4/organization-suggestions.api';
import { notify } from '@/ui/snackbar/notify';

export const useCreateCompanyDescription = () => {
  const { t } = useTranslation('default');

  return useMutation(createOrganizationDescription, {
    onError: (error: Error) => {
      notify({
        message: t`unknownError`,
      });
      console.error(error.message);
    },
  });
};
