import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { type ShareProjectBody } from '@/api/v4/projects.api';
import { useProject } from '@/features/projects/overview/use-project.query';
import { useUserAccount } from '@/user/use-user-account';

import {
  mapNewUsersToPayload,
  mapTeamIdsToPayload,
  mapUserIdsToPayload,
} from '../helpers/map-share-payload';
import { ShareModal } from '../share-modal';
import type { ShareWithData } from '../share-with-select/share-with';

import { useShareProject } from './use-share-project';
import { useUsersAndTeamsWithAccessToProject } from './use-users-and-teams-with-access-to-project';

export const ShareProjectModal = () => {
  const { t } = useTranslation('default');
  const { data: project } = useProject();
  const userAccount = useUserAccount();

  const { shareProject } = useShareProject();
  const { isLoading, usersAndTeamsWithAccessToProject } =
    useUsersAndTeamsWithAccessToProject();

  const [shareWithData, setShareWithData] = useState<ShareWithData>({
    draftOptions: [],
    removedOptions: [],
    isPublic: project ? project.public : null,
    ownerId: project?.creator?.id,
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setShareWithData(prevState => ({
      ...prevState,
      draftOptions: usersAndTeamsWithAccessToProject,
      isPublic: project ? project.public : null,
    }));
  }, [isLoading]);

  const handleSubmit = async () => {
    const { draftOptions, isPublic } = shareWithData;

    const payload: ShareProjectBody = {
      public: Boolean(isPublic),
      newUsers: mapNewUsersToPayload(draftOptions),
      teamIds: mapTeamIdsToPayload(draftOptions),
      userIds: mapUserIdsToPayload(draftOptions),
    };

    await shareProject(payload);
  };

  return (
    <ShareModal
      entitiesWithAccess={usersAndTeamsWithAccessToProject}
      isLoading={isLoading}
      title={t`share.shareProject`}
      privacyDefaultValue={Boolean(project?.public)}
      privacyPublicLabel={
        <Trans
          ns="default"
          i18nKey="share.projectPublicLabel"
          values={{ accountName: userAccount?.name }}
          components={{ u: <u /> }}
        />
      }
      privacyPrivateLabel={t`share.projectPrivateLabel`}
      onChange={setShareWithData}
      shareWithData={shareWithData}
      onSubmit={handleSubmit}
    />
  );
};
