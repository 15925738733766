import type { ReactElement } from 'react';
import { styled } from 'goober';

import { colors } from '@/theme/colors';

import { Flexbox } from '../flexbox/flexbox';
import type { IconVariant } from '../icons/icon';
import { Icon } from '../icons/icon';

export const Snackbar = ({
  message,
  withIcon = false,
  icon = 'Check',
}: {
  message: string | ReactElement;
  withIcon?: boolean;
  icon?: IconVariant;
}) => {
  return (
    <Container
      name="snackbar-container"
      alignItems="center"
      justify="center"
      gap="8px"
    >
      {message}
      {withIcon && <Icon icon={icon} color={colors.accent.pink.c3} />}
    </Container>
  );
};

const Container = styled(Flexbox)`
  border-radius: 4px;
  height: 100%;
  padding: 12px 60px;
  box-shadow: ${({ theme }) => theme.shadow.actionsPanel};
  background-color: ${({ theme }) => theme.colors.accent.pink.c1};
  color: ${({ theme }) => theme.colors.accent.pink.c3};
  white-space: pre-line;

  ${({ theme }) => theme.typography.atoms.snackbar};
`;
