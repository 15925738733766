import type { CurrentUser } from '@/api/v4/auth.api';
import type { QueryClient } from '@tanstack/react-query';
import { currentUserQuery } from './use-current-user.query';
import { paths } from '@/routes/helpers/paths';

export const userLoader = (queryClient: QueryClient) => async () => {
  const query = currentUserQuery();
  try {
    return (
      queryClient.getQueryData<CurrentUser>(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  } catch (error) {
    window.open(paths.signIn(), '_self');
    return null;
  }
};
