import { styled } from 'goober';

import { OrganizationSearchLoadingSkeleton } from './organization-search-loading-skeleton';
import type {
  AddCompanyEvent,
  OrganizationSearchResultsRowData,
} from './organization-search-results-table-row';
import { SearchResultTableRow } from './organization-search-results-table-row';

type OrganizationSearchResultsTableProps = {
  buttonAddedText: string;
  buttonAddText: string;
  fromCB: boolean;
  headerText: string;
  hideIfEmpty?: boolean;
  id: string;
  isLoading: boolean;
  noResultsText: string;
  data: OrganizationSearchResultsRowData[];
  onAddCompany?: AddCompanyEvent;
  rowClickRedirectsToWebsite?: boolean;
};

export const OrganizationSearchResultsTable = ({
  buttonAddText,
  buttonAddedText,
  data,
  fromCB,
  headerText,
  hideIfEmpty,
  id,
  isLoading,
  noResultsText,
  onAddCompany,
  rowClickRedirectsToWebsite,
}: OrganizationSearchResultsTableProps) => {
  if (isLoading)
    return <OrganizationSearchLoadingSkeleton fromCb={fromCB} rows={5} />;

  if (hideIfEmpty && data.length === 0) return null;

  return (
    <Table data-testid={id}>
      <TableCaption>{headerText}</TableCaption>
      <tbody>
        {data.length > 0 ? (
          data.map(row => {
            return (
              <SearchResultTableRow
                buttonAddedText={buttonAddedText}
                buttonAddText={buttonAddText}
                fromCB={fromCB}
                crunchbaseId={row.crunchbaseId}
                organizationId={row.organizationId}
                key={row.crunchbaseId || row.organizationId}
                name={row.name}
                shortDescription={row.shortDescription}
                websiteUrl={row.websiteUrl}
                imageUrl={row.imageUrl}
                isBusy={row.isBusy}
                isButtonHidden={row.isButtonHidden}
                listingCount={row.listingCount}
                permalink={row.permalink}
                onAddCompany={onAddCompany}
                isAdded={row.isAdded}
                rowClickRedirectsToCompanyPage={rowClickRedirectsToWebsite}
                isDomainUse={row.isDomainUse}
              />
            );
          })
        ) : (
          <NoResultTR>
            <td>{noResultsText}</td>
          </NoResultTR>
        )}
      </tbody>
    </Table>
  );
};

const Table = styled('table')`
  background-color: ${({ theme }) => theme.colors.basics.white};
  height: fit-content;
  table-layout: fixed;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;

  td {
    background-color: transparent;
  }
`;

const TableCaption = styled('caption')`
  ${({ theme }) => theme.typography.acrossPlatform.buttonText}
  color: ${({ theme }) => theme.colors.gray.c12};
  text-align: start;
  padding-bottom: 2px;
`;

const NoResultTR = styled('tr')`
  ${({ theme }) => theme.typography.widget.paragraphSmall}
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray.c3}`};
  height: 60px;
  color: ${({ theme }) => theme.colors.gray.c10};
`;
