import type { ComponentType } from 'react';
import { lazy } from 'react';

const RELOAD_FLAG_KEY = 'lazyLoadReloaded';

export const lazyLoad = (
  importModule: () => Promise<{ default: ComponentType<any> }>,
) => {
  return lazy(() =>
    importModule()
      .then(module => ({ default: module.default }))
      .catch(error => {
        const hasReloaded = sessionStorage.getItem(RELOAD_FLAG_KEY);

        if (!hasReloaded) {
          sessionStorage.setItem(RELOAD_FLAG_KEY, 'true');
          window.location.reload();
        } else {
          sessionStorage.removeItem(RELOAD_FLAG_KEY);
        }

        return Promise.reject(error);
      }),
  );
};
