import { useTranslation } from 'react-i18next';

import { useSidebars } from '@/hooks/use-sidebars';
import { Button } from '@/ui/button/button';

export const ActionButton = ({
  totalValue,
  onClick,
}: {
  totalValue: number;
  onClick: () => void;
}) => {
  const { t } = useTranslation('default');
  const { isActionPanelOpen } = useSidebars();

  return (
    <Button
      onClick={onClick}
      startIcon="ChartVectorUp"
      variant={isActionPanelOpen ? 'secondary' : 'ghost'}
    >
      {t(`actions`, { total: totalValue > 99 ? '99+' : totalValue })}
    </Button>
  );
};
