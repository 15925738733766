import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { deleteTeamFile as deleteTeamFileAPI } from '@/api/v4/team-files.api';
import { QueryKey } from '@/config/query-client';

import { teamIdState } from '../../teams/team.state';

export const useDeleteTeamFile = () => {
  const queryClient = useQueryClient();
  const teamId = useRecoilValue(teamIdState);

  const mutation = useMutation(deleteTeamFileAPI, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.TeamFiles]);
      await queryClient.invalidateQueries([QueryKey.TeamActionsInfo]);
    },
  });

  const deleteTeamFile = (fileId: string) => {
    if (!teamId) return;

    return mutation.mutateAsync({ teamId, fileId });
  };

  return {
    deleteTeamFile,
    isError: mutation.isError,
  };
};
