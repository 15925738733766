import type { ContainerProps } from 'react-select';
import { components } from 'react-select';

import { SelectContainer } from '../async-select.styles';

export function AsyncSelectContainer({
  children,
  ...props
}: ContainerProps<unknown, boolean>) {
  return (
    <components.SelectContainer {...props}>
      <SelectContainer isFocused={props.isFocused}>{children}</SelectContainer>
    </components.SelectContainer>
  );
}
