import type { ReactElement } from 'react';
import { toast } from 'react-toastify';

import type { IconVariant } from '../icons/icon';

import { Snackbar } from './snackbar';

import './main.css';

export const notify = ({
  message,
  withIcon = false,
  icon,
}: {
  message: string | ReactElement;
  withIcon?: boolean;
  icon?: IconVariant;
}) => {
  const autoClose = 5000;

  return toast(<Snackbar message={message} withIcon={withIcon} icon={icon} />, {
    autoClose,
    draggable: false,
    position: 'bottom-center',
    hideProgressBar: true,
    closeOnClick: true,
    closeButton: false,
  });
};
