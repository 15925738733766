import { orderBy } from 'lodash-es';

import { useProjectTags } from '@/features/projects/overview/project-tags/use-project-tags';
import type { ITagsModalType } from '@/state/modal.state';

import { useCompanyTags } from '../../companies/overview/tags/use-company-tags.query';
import { useTeamTags } from '../../teams/overview/team-tags/use-team-tags.query';

export const useEntityTags = (type: ITagsModalType | null) => {
  const projectTags = useProjectTags();

  const { data } = useCompanyTags();

  const { data: teamTagsData } = useTeamTags();

  switch (type) {
    case 'company':
      return data?.tags ?? [];
    case 'team':
      return teamTagsData?.tags ?? [];
    case 'project':
      return orderBy(projectTags, tag => tag.name, 'asc') ?? [];
  }
  return [];
};
