import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios';
import axios from 'axios';

import { jwtTokenState } from '@/auth/auth.state';
import { isOfType } from '@/helpers/other';
import i18n from '@/lib/i18n/i18n';
import { getRecoil, setRecoil } from '@/shared/recoil-nexus';
import { notify } from '@/ui/snackbar/notify';

import type { paths } from './types';

const JWT_TOKEN_HEADER = 'Authorization';

const client = axios.create({
  withCredentials: true,
  baseURL:
    window.runTimeEnvs.VITE_API_V4URL ?? window.runTimeEnvs.VITE_API_BASEURL,
});

client.interceptors.request.use(config => {
  const requestConfig = { ...config };

  const token = getRecoil(jwtTokenState);

  if (!requestConfig.headers) requestConfig.headers = {} as AxiosRequestHeaders;

  if (token) {
    requestConfig.headers[JWT_TOKEN_HEADER] = token;
  }

  return requestConfig;
});

client.interceptors.response.use(
  res => {
    const token = res.config.headers?.[JWT_TOKEN_HEADER];
    setRecoil(jwtTokenState, token ? String(token) : null);

    return res;
  },
  (err: AxiosError) => {
    console.error(err.message);
    if (err?.response?.status === 500) {
      notify({
        message: i18n.t('default:unknownError'),
      });
    }

    return Promise.reject(err);
  },
);

export const getMessageFromErrorResponse = (error: any): string | null => {
  if (!isBaseError(error)) return null;

  if (Array.isArray(error.response.data.message)) {
    return error.response.data.message.join('\n');
  }

  return error.response.data.message;
};

export const isBaseError = (error: unknown): error is BaseError => {
  return (
    isOfType(error, 'response') &&
    isOfType(error.response, 'status') &&
    isOfType(error.response, 'data') &&
    isOfType(error.response.data, 'message')
  );
};

export const get = async <T>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<T> => {
  const response = await client.get<T>(url, config);
  return response.data;
};

export const post = async <T, Payload = unknown>(
  url: string,
  data?: Payload,
  config?: AxiosRequestConfig,
): Promise<T> => {
  const response = await client.post<T>(url, data, config);
  return response.data;
};

export const put = async <T, Payload>(
  url: string,
  data?: Payload,
  config?: AxiosRequestConfig,
): Promise<T> => {
  const response = await client.put<T>(url, data, config);
  return response.data;
};

export const patch = async <T, Payload = unknown>(
  url: string,
  data?: Payload,
  config?: AxiosRequestConfig,
): Promise<T> => {
  const response = await client.patch<T>(url, data, config);
  return response.data;
};

export const remove = async <T>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<T> => {
  const response = await client.delete<T>(url, config);
  return response.data;
};

export type APIRequestQueryParams<Path extends keyof paths> =
  paths[Path] extends {
    get: {
      parameters: { query?: infer QueryParams };
    };
  }
    ? QueryParams
    : paths[Path] extends {
        post: {
          parameters: { query: infer QueryParams };
        };
      }
    ? QueryParams
    : paths[Path] extends {
        put: {
          parameters: { query: infer QueryParams };
        };
      }
    ? QueryParams
    : paths[Path] extends {
        patch: {
          parameters: { query: infer QueryParams };
        };
      }
    ? QueryParams
    : paths[Path] extends {
        delete: {
          parameters: { query: infer QueryParams };
        };
      }
    ? QueryParams
    : never;

export type APIRequestPathParams<Path extends keyof paths> =
  paths[Path] extends {
    get: {
      parameters: { param?: infer Params };
    };
  }
    ? Params
    : paths[Path] extends {
        post: {
          parameters: { path: infer Params };
        };
      }
    ? Params
    : paths[Path] extends {
        put: {
          parameters: { path: infer Params };
        };
      }
    ? Params
    : paths[Path] extends {
        patch: {
          parameters: { path: infer Params };
        };
      }
    ? Params
    : paths[Path] extends {
        delete: {
          parameters: { path: infer Params };
        };
      }
    ? Params
    : paths[Path] extends {
        get: {
          parameters: { path: infer Params };
        };
      }
    ? Params
    : never;

export type APIRequestBody<Path extends keyof paths> = paths[Path] extends {
  post: {
    requestBody: { content: { 'application/json': infer RequestContent } };
  };
}
  ? RequestContent
  : paths[Path] extends {
      put: {
        requestBody: { content: { 'application/json': infer RequestContent } };
      };
    }
  ? RequestContent
  : paths[Path] extends {
      patch: {
        requestBody: { content: { 'application/json': infer RequestContent } };
      };
    }
  ? RequestContent
  : never;

export type APIResponse<Path extends keyof paths> = paths[Path] extends {
  post: {
    responses: { 201: { content: { 'application/json': infer ResponseBody } } };
  };
}
  ? ResponseBody
  : paths[Path] extends {
      get: {
        responses: {
          200: { content: { 'application/json': infer ResponseBody } };
        };
      };
    }
  ? ResponseBody
  : paths[Path] extends {
      put: {
        responses: {
          200: { content: { 'application/json': infer ResponseBody } };
        };
      };
    }
  ? ResponseBody
  : paths[Path] extends {
      patch: {
        responses: {
          200: { content: { 'application/json': infer ResponseBody } };
        };
      };
    }
  ? ResponseBody
  : paths[Path] extends {
      delete: {
        responses: {
          200: { content: { 'application/json': infer ResponseBody } };
        };
      };
    }
  ? ResponseBody
  : never;

interface BaseError {
  response: { status: number; data: { message: string } };
}

export enum BaseExceptions {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
  INTERNAL_SERVER_ERROR = 500,
}
