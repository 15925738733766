import { styled } from 'goober';
import { sortBy } from 'lodash-es';

import type { ListingStage } from '@/api/v4/project-listings.api';
import { Flexbox } from '@/ui/flexbox/flexbox';
import type { IconVariant } from '@/ui/icons/icon';
import { Icon } from '@/ui/icons/icon';
import type { RadioOptions } from '@/ui/radio/radio-group';
import { RadioGroup } from '@/ui/radio/radio-group';
import { SubHeader } from '@/ui/typography/widgets';

import { useDealStatuses } from '../use-deal-statuses';

const orderDealOptions = (statuses: ListingStage[]) =>
  sortBy(statuses, status => (status.id === 'Investment' ? 0 : 1));

export const DealRadio = ({
  value,
  onSelect,
}: {
  value: string | null;
  onSelect: (value: string) => void;
}) => {
  const dealStatuses = useDealStatuses();

  const options: RadioOptions[] = orderDealOptions(dealStatuses).map(status => {
    return {
      label: (
        <DealLabel
          status={status}
          onClick={() => onSelect(status.id)}
          selected={status.id === value}
        />
      ),
      value: status.id,
    };
  });

  return <RadioGroup options={options} value={value} onSelect={onSelect} />;
};

const DealLabel = ({
  status,
  onClick,
  selected,
}: {
  status: ListingStage;
  onClick: () => void;
  selected: boolean;
}) => {
  const getDealIcon = (): IconVariant => {
    switch (status.name) {
      case 'Implementation':
      case 'Investment':
        return status.name;

      default:
        return 'OtherTransaction';
    }
  };
  return (
    <Container
      name="deal-label-container"
      direction="column"
      alignItems="center"
      onClick={onClick}
      selected={selected}
    >
      <Icon icon={getDealIcon()} />
      <SubHeader>{status.name}</SubHeader>
    </Container>
  );
};

const Container = styled(Flexbox)<{ selected: boolean }>`
  padding: 43px 20px;
  gap: 36px;

  width: 206px;
  height: 286px;

  background-color: ${({ theme }) => theme.colors.basics.canvas};
  box-shadow: ${({ theme }) => theme.shadow.widgets};
  border-radius: 20px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue.c8};
    cursor: pointer;
  }

  ${({ theme, selected }) =>
    selected &&
    `
    background-color: ${theme.colors.blue.c5};

    &:hover {
      background-color: ${theme.colors.blue.c5};
    }
  `}
`;
