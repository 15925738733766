import { colors } from '@/theme/colors';
import { Logo } from '@/ui/logo/logo';

export const SavvyLogoCompany = ({
  name,
  logoUrl,
  size,
  borderSize = 1,
}: {
  name: string;
  logoUrl?: string;
  size?: string;
  borderSize?: number;
}) => {
  return (
    <Logo
      name={name}
      size={size}
      logoUrl={logoUrl}
      singleLetter={true}
      bgColor={colors.accent.green.c3}
      border={`${borderSize}px solid ${colors.gray.c3}`}
    />
  );
};
