import type { CompanyActivityStatus } from '../overview/company.state';
import { useCompany } from '../overview/use-company.query';

export const useMetadata = () => {
  const { data: company } = useCompany();

  const activityStatus = company?.activityStatus.value?.name
    ? (company?.activityStatus.value?.name as CompanyActivityStatus)
    : null;

  const socialUrlValues = {
    twitterUrl: company?.twitterUrl.value ?? '',
    linkedinUrl: company?.linkedInUrl.value ?? '',
    websiteUrl: company?.website.value ?? '',
  };

  return {
    activityStatus,
    hqLocation: company?.hqLocation,
    socialUrlValues,
  };
};
