import { styled } from 'goober';

import type { ButtonProps } from '@/ui/button/icon-button';
import { IconButton } from '@/ui/button/icon-button';

import { Tooltip } from '../tooltip/tooltip';
import type { MouseEventHandler } from 'react';

interface SavvyIconButtonWebURLProps extends Partial<ButtonProps> {
  hideTooltip?: boolean;
  url: string;
  stopPropagation?: boolean;
}

export const SavvyIconButtonWebURL = ({
  hideTooltip,
  url,
  stopPropagation,
  ...props
}: SavvyIconButtonWebURLProps) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    if (stopPropagation) {
      event.stopPropagation();
    }
    return window.open(url, '_blank');
  };
  return (
    <Tooltip content={url} disabled={hideTooltip}>
      <StyledIconButton
        icon="Globe"
        variant="ghost"
        onClick={handleClick}
        {...props}
      />
    </Tooltip>
  );
};

const StyledIconButton = styled(IconButton)`
  padding: 0px;
  &:hover {
    background-color: transparent;
  }
`;
