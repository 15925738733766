import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import type { Tag } from '@/api/v4/tags.api';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { GeneralTag } from '@/ui/tag/general-tag';
import { ParagraphMedium } from '@/ui/typography/widgets';

export const TagCategoryTab = ({
  tags,
  onTagClick,
}: {
  tags: Tag[];
  onTagClick: (tagId: string) => void;
}) => {
  const { t } = useTranslation('tags');

  if (tags.length === 0) {
    return (
      <Flexbox
        name="no-tags-results-container"
        alignItems="center"
        justify="center"
        direction="column"
        grow="1"
      >
        <ParagraphMedium>{t`didntFind`}</ParagraphMedium>
        <Flexbox name="no-results-description" gap="9px">
          <ParagraphMedium>{t`suggestTag`}</ParagraphMedium>
          <Icon icon="Intercom" />
        </Flexbox>
      </Flexbox>
    );
  }
  return (
    <OverflowContainer
      name="add-tags-content"
      wrap="wrap"
      gap="8px"
      data-testid="tags-results"
    >
      {tags.map(tag => (
        <GeneralTag
          key={tag.id}
          variant="gray"
          onClick={onTagClick.bind(null, tag.id)}
        >
          {tag.name.toLowerCase()}
        </GeneralTag>
      ))}
    </OverflowContainer>
  );
};

const OverflowContainer = styled(Flexbox)`
  overflow-y: auto;
  margin-top: 30px;
  padding-right: 10px;
  ${({ theme }) => theme.mixins.scrollbar}
`;
