import { Flexbox } from '@/ui/flexbox/flexbox';
import type { CompanySearchSuggestion } from './company-suggestions-table';
import { Inline, Stack } from '@/ui/line/line';
import { Logo } from '@/ui/logo/logo';
import { ParagraphSmall, SmallText } from '@/ui/typography/widgets';
import { SavvyIconButtonWebURL } from '@/ui/button/savvy-icon-button-weburl';
import { LinkIcon } from '@/ui/link/link-icon';
import { styled } from 'goober';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { useTranslation } from 'react-i18next';
import { fonts } from '@/theme/typography';
import { colors } from '@/theme/colors';
import { SkeletonText } from '@/ui/table/infinite-table/skeleton-row';
import { formatLocation } from '@/helpers/format-location';

type CompanyRowMainCellProps = Pick<
  CompanySearchSuggestion['enrichedData'],
  'keyOffering' | 'location' | 'website' | 'linkedInUrl'
> &
  Pick<CompanySearchSuggestion['basicData'], 'name' | 'logoUrl'> & {
    isEnrichDataLoading: boolean;
  };

export const CompanyRowMainCell = ({
  name,
  logoUrl,
  keyOffering,
  location,
  website,
  linkedInUrl,
  isEnrichDataLoading,
}: CompanyRowMainCellProps) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'globalSearch.suggestionsTable',
  });

  return (
    <SuggestionWrapper
      name="company-suggestion-main-cell"
      justify="space-between"
      alignContent="center"
      alignItems="center"
    >
      <Flexbox name="company-suggestion-data">
        <Logo
          border="none"
          name={name}
          logoUrl={logoUrl}
          size="32px"
          singleLetter
          fontSize="14px"
        />
        <Stack gap="0" margin="0 0 0 12px">
          <LightTooltip
            content={
              <Stack gap="3px">
                <TooltipTitle>{t`keyOffering`}</TooltipTitle>
                {keyOffering}
              </Stack>
            }
            withArrow
            padding="14px 20px"
          >
            <ParagraphSmall>{name}</ParagraphSmall>
          </LightTooltip>
          {isEnrichDataLoading ? (
            <SkeletonText width="80%" />
          ) : (
            <TextValue>
              {formatLocation(
                {
                  cityName: location.city?.name,
                  countryName: location.country?.name,
                  regionName: location.region?.name,
                },
                {
                  compact: true,
                },
              )}
            </TextValue>
          )}
        </Stack>
      </Flexbox>

      {isEnrichDataLoading ? (
        <SkeletonText width="20px" />
      ) : (
        <Inline gap="12px">
          {website && (
            <SavvyIconButtonWebURL url={website} size="16px" stopPropagation />
          )}
          {linkedInUrl && (
            <LinkIcon
              icon="LinkedIn"
              url={linkedInUrl}
              size="16px"
              color={colors.blue.primaryA}
              stopPropagation
            />
          )}
        </Inline>
      )}
    </SuggestionWrapper>
  );
};

const SuggestionWrapper = styled(Flexbox)`
  position: relative;
  min-width: 320px;
  padding: 12px 20px;
`;

const LightTooltip = styled(Tooltip)`
  font-family: ${fonts.wallop};
  background-color: ${({ theme }) => theme.colors.basics.white};
  color: ${({ theme }) => theme.colors.basics.black};
  text-align: left;

  .tippy-arrow {
    color: ${({ theme }) => theme.colors.basics.white};
  }
`;

const TooltipTitle = styled('h6')`
  font-weight: 700;
  letter-spacing: 0.03em;
`;

const TextValue = styled(SmallText)`
  ${({ theme }) => theme.typography.enhance.tableSmallText};
  font-family: ${fonts.wallop};
`;
