import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import ErrorSVG from '@/assets/svg/explore-no-results.svg';
import { Stack } from '@/ui/line/line';
import { Header, ParagraphMedium } from '@/ui/typography/widgets';

import { useProjectListingSuggestionState } from '../use-project-listing-suggestion.state';

export const EnhanceProjectNoResultsView = () => {
  const { t } = useTranslation('projects');
  const state = useProjectListingSuggestionState();

  useEffect(() => {
    if (state.currentPageStep !== 'error') state.setCurrentPageStep('error');
  }, []);

  return (
    <Stack gap="36px" centered margin="0px auto 39px auto">
      <Image src={ErrorSVG} />
      <Stack gap="0px" centered>
        <Header>{t`enhanceNoResultsHeader`}</Header>
        <ParagraphMedium>{t`enhanceNoResults1`}</ParagraphMedium>
        <ParagraphMedium>{t`enhanceNoResults2`}</ParagraphMedium>
      </Stack>
    </Stack>
  );
};

const Image = styled('img')`
  z-index: 0;
  margin-left: 0px;
  margin-bottom: -200px;
`;
