import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { modalState } from '@/state/modal.state';

import { ConfirmDeleteModal } from '../confirm-delete-modal';

import { useDeleteProjectListing } from './use-delete-project-listing.mutation';

export const DeleteProjectListingModal = () => {
  const modal = useRecoilValue(modalState);
  const { t } = useTranslation('projects', {
    keyPrefix: 'listings',
  });
  const { deleteProjectListing } = useDeleteProjectListing();

  if (!modal || modal.state !== 'deleteProjectListing') return null;

  const handleDelete = async () => {
    await deleteProjectListing(modal.listingId);
  };

  return (
    <ConfirmDeleteModal
      title={t`removeCompany`}
      description={t`areYouSureToRemove`}
      onConfirm={handleDelete}
      confirmLabel={t`remove`}
    />
  );
};
