import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { MeetingCreatePayload } from '@/api/v4/meetings.api';
import { createMeeting as createMeetingAPI } from '@/api/v4/meetings.api';
import { notify } from '@/ui/snackbar/notify';
import { QueryKey } from '@/config/query-client';

export const useCreateMeeting = () => {
  const { t } = useTranslation('default');
  const queryClient = useQueryClient();

  const createMeetingMutation = useMutation(createMeetingAPI, {
    onSuccess: async meeting => {
      if (meeting.projectId) {
        await queryClient.invalidateQueries([
          QueryKey.Project,
          meeting.projectId,
        ]);
      }
      if (meeting.organizationId) {
        await queryClient.invalidateQueries([
          QueryKey.Company,
          meeting.organizationId,
        ]);
      }
      if (meeting.teamId) {
        await queryClient.invalidateQueries([QueryKey.Team, meeting.teamId]);
      }

      await Promise.all([
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);
    },
    onError: () => {
      notify({
        message: t`unknownError`,
      });
    },
  });

  const createMeeting = (payload: MeetingCreatePayload) =>
    createMeetingMutation.mutateAsync(payload);

  return {
    createMeeting,
    isError: createMeetingMutation.isError,
  };
};
