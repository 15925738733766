import { useSetRecoilState } from 'recoil';

import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { modalState } from '@/state/modal.state';

type searchItemType = 'company' | 'project' | 'team';

export const useNavigateToSearchItemPage = () => {
  const navigateTo = useNavigateTo();
  const setModal = useSetRecoilState(modalState);

  const navigateToSearchItemPage = ({
    type,
    id,
  }: {
    type: searchItemType;
    id: string;
  }) => {
    switch (type) {
      case 'company':
        return navigateTo.company({ companyId: id });
      case 'project':
        return navigateTo.project({ projectId: id });
      case 'team':
        return navigateTo.team({ teamId: id });
    }
  };

  return ({ type, id }: { type: searchItemType; id: string }) => {
    setModal(null);
    navigateToSearchItemPage({ type, id });
  };
};
