import { useRef } from 'react';
import { components } from 'react-select';
import { styled } from 'goober';

import { isEllipsis } from '@/helpers/other';
import { colors } from '@/theme/colors';
import type { UserOption } from '@/types';
import { Icon } from '@/ui/icons/icon';
import { Logo } from '@/ui/logo/logo';
import {
  OptionContainer,
  OptionLabel,
} from '@/ui/select/async/async-select.styles';
import type { AsyncSelectOptionProps } from '@/ui/select/async/components/option/option';
import { SLIDING_SIDEBAR_WIDTH } from '@/ui/sliding-sidebar/sliding-sidebar';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { SmallText } from '@/ui/typography/widgets';

interface UserOptionProps extends AsyncSelectOptionProps {
  data: UserOption;
  disabledOptionMessage?: string;
}

export function UserOption({
  data,
  disabledOptionMessage,
  ...props
}: UserOptionProps) {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <components.Option data={data} {...props} isDisabled={props.isDisabled}>
      <Tooltip
        delay={0}
        placement="top"
        maxWidth={`calc(100vw - ${SLIDING_SIDEBAR_WIDTH})`}
        content={props.isDisabled ? disabledOptionMessage : data.label}
        visible={isEllipsis(ref.current) || props.isDisabled}
      >
        <OptionContainer
          isSelected={props.isSelected}
          style={{ height: '60px' }}
          isDisabled={props.isDisabled}
        >
          {
            <LogoUser
              name={data.label}
              singleLetter={!Boolean(data.firstName && data.lastName)}
              bgColor={
                data.status === 'invited'
                  ? colors.blue.primaryB
                  : colors.blue.primaryA
              }
            />
          }
          <OptionLabel
            ref={ref}
            {...(data.team && { 'data-team': data.team.name })}
          >
            {data.label}
            {data.team && <TeamName>{data.team.name}</TeamName>}
          </OptionLabel>
          {data.role && <SmallText>{data.role.name}</SmallText>}
          {props.withCheckIconOnSelect && props.isSelected && (
            <Icon icon="Check" width="18px" />
          )}
        </OptionContainer>
      </Tooltip>
    </components.Option>
  );
}

const LogoUser = styled(Logo)`
  font-size: 12px;
`;

const TeamName = styled(SmallText)`
  height: 0;
  opacity: 0;
`;
