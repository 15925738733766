import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import type { CompanyOption } from '@/types';

import type { MeetingFormFields } from './use-meeting-form';
import { fetchOrganizationOptionById } from '@/api/v4/organization.api';

export const useMeetingDefaultCompanyOption = () => {
  const { watch } = useFormContext<MeetingFormFields>();
  const [defaultCompanyOption, setDefaultCompanyOption] =
    useState<CompanyOption | null>(null);

  const relatedCompanyId = watch('relatedCompanyId');

  useEffect(() => {
    const setDefaultCompany = async () => {
      if (relatedCompanyId) {
        const organizationOption = await fetchOrganizationOptionById(
          relatedCompanyId,
        );
        setDefaultCompanyOption({
          label: organizationOption.name,
          value: organizationOption.id,
          logo: organizationOption.logoUrl,
          domain: organizationOption.domain,
        });
        return;
      }

      setDefaultCompanyOption(null);
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setDefaultCompany();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedCompanyId]);

  return { defaultCompanyOption };
};
