import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { isEmpty } from 'lodash-es';

import { mapTeamsToBasicTeamOptions } from '@/api/v4/mappers/team-basic-option';
import { mapUsersToOptions } from '@/api/v4/mappers/user-option.mapper';
import { fetchTeams } from '@/api/v4/team.api';
import { fetchUsers } from '@/api/v4/user.api';
import { Loader } from '@/components/loader/loader';
import {
  isInvitedUserOption,
  isTeamBasicOption,
  isUserOption,
} from '@/helpers/other';
import { prepareSafeInput } from '@/helpers/prepare-safe-input';
import { useRoles } from '@/hooks/queries/use-roles.query';
import { EMAIL_REGEX } from '@/regexes';
import type { InvitedUserOption, TeamBasicOption, UserOption } from '@/types';
import { APP_USER_ROLES } from '@/types';
import type { AsyncSelectProps } from '@/ui/select/async/async-select-with-list';
import { AsyncSelectWithList } from '@/ui/select/async/async-select-with-list';
import type { CustomAsyncSelectProps } from '@/ui/select/async/use-select-type';
import { useUserRole } from '@/user/use-user-role';

import { PrivacyStatus as PrivacyStatusComponent } from '../privacy-status';

import { SelectedUserOrTeamOption } from './selected-user-or-team-option';
import { ShareWithUserOrTeamOption } from './share-with-user-or-team-option';

type DraftOptions = (UserOption | TeamBasicOption | InvitedUserOption)[];

export interface ShareWithData {
  draftOptions: DraftOptions;
  removedOptions: (UserOption | TeamBasicOption)[];
  isPublic: boolean | null;
  ownerId?: number;
}

export interface CompanyProjectShareWithProps
  extends Pick<AsyncSelectProps, 'searchInputSize'> {
  separateDefaultValue?: boolean;
  privacyDefaultValue?: boolean;
  privacyPublicLabel?: string | ReactNode;
  privacyPrivateLabel?: string | ReactNode;
  onChange: (options: ShareWithData) => void;
  shareWithData: ShareWithData;
  entitiesWithAccess: (UserOption | TeamBasicOption)[];
  isLoading: boolean;
}

export function ShareWith({
  privacyDefaultValue,
  privacyPublicLabel,
  privacyPrivateLabel,
  searchInputSize,
  separateDefaultValue = true,
  entitiesWithAccess,
  isLoading,
  onChange,
  shareWithData,
}: CompanyProjectShareWithProps) {
  const { t } = useTranslation('default');

  const { data: appRoles } = useRoles();
  const managerRole = appRoles?.find(r => r.name === APP_USER_ROLES.MANAGER);
  const userRole = useUserRole();
  const isAppUserGuest = userRole?.includes(APP_USER_ROLES.GUEST);

  const setOptions: AsyncSelectProps['setOptions'] = async (
    originalSearchPhrase,
    page,
  ) => {
    const safeSearchPhrase = prepareSafeInput(originalSearchPhrase);

    if (!managerRole) return [];

    const [teams, users] = await Promise.all([
      fetchTeams({ page, search: safeSearchPhrase }),
      fetchUsers({ page, search: safeSearchPhrase }),
    ]);

    const options: Array<UserOption | TeamBasicOption | InvitedUserOption> = [
      ...mapUsersToOptions(users),
      ...mapTeamsToBasicTeamOptions(teams),
    ];

    const emailOnDraftList = shareWithData.draftOptions.find(
      option =>
        isInvitedUserOption(option) &&
        option.email?.toLowerCase() === safeSearchPhrase.toLowerCase(),
    ) as InvitedUserOption | undefined;

    if (emailOnDraftList) {
      const falseUserOption: UserOption = {
        firstName: null,
        lastName: null,
        team: null,
        email: safeSearchPhrase,
        role: emailOnDraftList.role ?? managerRole,
        status: 'invited',
        value: Math.random(),
        label: safeSearchPhrase,
      };
      options.push(falseUserOption);
    }

    if (
      safeSearchPhrase.match(EMAIL_REGEX) &&
      !options.some(
        item => isUserOption(item) && item.email === safeSearchPhrase,
      ) &&
      !shareWithData.draftOptions.some(
        item => isUserOption(item) && item.email === safeSearchPhrase,
      )
    ) {
      options.push({
        value: safeSearchPhrase,
        label: originalSearchPhrase,
        email: safeSearchPhrase,
        type: 'invited',
        role: managerRole
          ? { id: managerRole.id, name: managerRole.name }
          : null,
      });
    }

    if (isEmpty(options) && safeSearchPhrase.includes('@')) {
      options.push({
        label: t`invite.keepTyping`,
        value: '',
        email: null,
        role: null,
        type: 'invited',
      });
    }

    return options;
  };

  const handleOnChange: CustomAsyncSelectProps['onChange'] = (
    newValue,
    actionMeta,
  ) => {
    if (actionMeta.action === 'select-option') {
      onChange({ ...shareWithData, draftOptions: newValue as DraftOptions });
    }

    if (actionMeta.action === 'remove-value') {
      if (
        isUserOption(actionMeta.removedValue) ||
        isTeamBasicOption(actionMeta.removedValue)
      ) {
        const removedOption = actionMeta.removedValue;

        onChange({
          ...shareWithData,
          draftOptions: shareWithData.draftOptions.filter(
            o => o.value !== removedOption.value,
          ),
          removedOptions: [...shareWithData.removedOptions, removedOption],
        });
      }
    }
  };

  if (isLoading) return <Loader size="small" />;

  const invitedUserEmails = shareWithData.draftOptions
    .filter(isInvitedUserOption)
    .map(i => i.email);

  return (
    <>
      <ShareContainerList>
        <AsyncSelectWithList
          defaultValue={entitiesWithAccess}
          setOptions={setOptions}
          cacheUniqs={[invitedUserEmails]}
          placeholder={t`share.placeholder`}
          shouldLoadMore={() => false}
          components={{ Option: ShareWithUserOrTeamOption }}
          SelectedOptionsListComponent={
            <SelectedUserOrTeamOption isDisabled={isAppUserGuest} />
          }
          onChange={handleOnChange}
          searchInputSize={searchInputSize}
          separateDefaultValue={separateDefaultValue}
          isDisabled={isAppUserGuest}
          isOptionDisabled={option =>
            (isUserOption(option) &&
              !!invitedUserEmails.find(email => email === option.email)) ||
            shareWithData.draftOptions.some(
              draftOption =>
                (isShareWithOption(option) &&
                  draftOption.value === option.value) ||
                (isTeamBasicOption(draftOption) &&
                  isUserOption(option) &&
                  draftOption.value === option.team?.id),
            )
          }
        />
      </ShareContainerList>
      <PrivacyStatus
        defaultValue={privacyDefaultValue}
        publicLabel={privacyPublicLabel}
        privateLabel={privacyPrivateLabel}
        onChange={isPublic => onChange({ ...shareWithData, isPublic })}
      />
    </>
  );
}

const ShareContainerList = styled('div')`
  padding-bottom: 12px;
`;

const PrivacyStatus = styled(PrivacyStatusComponent)`
  margin-bottom: -58px;
  z-index: 1;
  width: 600px;
`;

function isShareWithOption(
  option: unknown,
): option is UserOption | TeamBasicOption | InvitedUserOption {
  return (
    isUserOption(option) ||
    isTeamBasicOption(option) ||
    isInvitedUserOption(option)
  );
}
