import Skeleton from 'react-loading-skeleton';
import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';

export const OrganizationSearchLoadingSkeleton = ({
  fromCb,
  rows,
}: {
  fromCb: boolean;
  rows: number;
}) => (
  <Container>
    {Array.from({ length: rows }).map((_, cellIndex) => (
      <SkeletonItem key={cellIndex} name="organization-search-table-skeleton">
        <CellLogo>
          <Skeleton circle width="20px" height="20px" />
        </CellLogo>
        <CellInfo>
          <Skeleton height="15px" width="70%" />
          <Skeleton height="11px" width="40%" />
        </CellInfo>
        <CellOtherIcon>
          {fromCb ? (
            <Skeleton width="20px" height="20px" />
          ) : (
            <Skeleton circle width="20px" height="20px" />
          )}
        </CellOtherIcon>
      </SkeletonItem>
    ))}
  </Container>
);

const Container = styled('div')`
  padding: 0px 15px;
`;

const CellLogo = styled('div')`
  width: 40px;
`;
const CellInfo = styled('div')`
  width: 748px;
`;

const CellOtherIcon = styled('div')`
  width: 40px;
`;

const SkeletonItem = styled(Flexbox)`
  padding: 14px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.c3};
`;
