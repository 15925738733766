import { z } from 'zod';

import i18n from '@/lib/i18n/i18n';

const { t } = i18n;

export const duplicateProjectValidationSchema = z.object({
  name: z.string().nonempty(t`default:fieldIsRequired`),
  withDetailsCopied: z.boolean(),
});
