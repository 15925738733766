const analystsPaths = {
  baseUrl: window.runTimeEnvs.VITE_API_BASEURL,
  search: {
    autocomplete: '/v2/search/suggest',
  },
  clients: {
    index: '/v2/clients',
  },
  organizations: {
    grantAccess: '/v2/organizations/link_for_user',
  },
  streams: {
    exportXLS: (id: string) => `/v2/streams/${id}/xls`,
    copy: (id: string) => `/v2/streams/${id}/duplicate`,
    grantAccess: '/v2/streams/link_for_user',
  },
} as const;

export default analystsPaths;
