import { selectedCompaniesState } from '@/features/companies/companies.state';
import { useCompany } from '@/features/companies/overview/use-company.query';
import { useFocusOnMount } from '@/hooks/use-focus-on-mount';
import { modalState } from '@/state/modal.state';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Input } from '@/ui/input/input';
import { Stack } from '@/ui/line/line';
import { Modal } from '@/ui/modal/modal';
import { SubHeaderBold } from '@/ui/typography/widgets';
import { styled } from 'goober';
import type { ChangeEvent } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useDebounce } from 'use-debounce';
import { ProjectSearchContent } from './project-search-content';

export function AddCompanyToProjectsModal({
  companyName,
}: {
  companyName?: string;
}) {
  const { t } = useTranslation('companies');
  const [searchPhrase, setSearchPhrase] = useState('');
  const searchRef = useRef<HTMLInputElement>(null);
  useFocusOnMount(searchRef);
  const { data: company } = useCompany();

  const setModalState = useSetRecoilState(modalState);
  const selectedCompanies = useRecoilValue(selectedCompaniesState);

  const isEmpty = !Boolean(searchRef);

  const handlePhraseChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setSearchPhrase('');
    }

    setSearchPhrase(e.target.value);
  };

  const [debouncedPhrase] = useDebounce(searchPhrase, 300, {
    maxWait: 1000,
    leading: true,
  });

  const handleResetSearch = () => {
    setSearchPhrase('');
    searchRef.current?.focus();
  };

  return (
    <Modal
      onClose={() => setModalState(null)}
      CustomHeaderComponent={SubHeaderBold}
      header={
        companyName || company?.name.value
          ? t('addToProject.headerNamedCompany', {
              companyName: companyName ?? company?.name.value,
            })
          : t('addToProject.header', { count: selectedCompanies.length })
      }
    >
      <ContentContainer gap="30px">
        <Input
          name="projects-search"
          placeholder={t`addToProject.searchbarPlaceholder`}
          onChange={handlePhraseChange}
          ref={searchRef}
        />

        <ResultsContainer
          name="projects-search-results"
          direction="column"
          alignItems="center"
          grow="1"
        >
          <ProjectSearchContent
            isEmpty={isEmpty}
            onReset={handleResetSearch}
            searchPhrase={debouncedPhrase}
          />
        </ResultsContainer>
      </ContentContainer>
    </Modal>
  );
}

const ContentContainer = styled(Stack)`
  width: 900px;
  grid-template-rows: 40px auto;
  margin-top: 16px;
  min-height: 400px;
  height: 100%;
  position: relative;
`;

const ResultsContainer = styled(Flexbox)`
  ${({ theme }) => theme.mixins.scrollbar};
  overflow-y: auto;
  max-height: 475px;
`;
