/**
 * Implementation copied from npm package recoil-nexus
 * (https://github.com/luisanton-io/recoil-nexus/blob/master/src/RecoilNexus.tsx)
 *
 * Package doesn't provide properly built version of package
 * and suggested workaround ({"transformIgnorePatterns": []} in jest configuration) is terrible for building performance
 */

import type { RecoilState, RecoilValue } from 'recoil';
import { useGetRecoilValueInfo_UNSTABLE } from 'recoil';
import { useRecoilCallback, useRecoilTransaction_UNSTABLE } from 'recoil';

interface Nexus {
  get?: <T>(atom: RecoilValue<T>) => T;
  getPromise?: <T>(atom: RecoilValue<T>) => Promise<T>;
  set?: <T>(
    atom: RecoilState<T>,
    valOrUpdater: T | ((currVal: T) => T),
  ) => void;
  reset?: (atom: RecoilState<any>) => void;
}

const nexus: Nexus = {};

export default function RecoilNexus() {
  nexus.get = useRecoilCallback<[atom: RecoilValue<any>], any>(
    ({ snapshot }) =>
      function <T>(atom: RecoilValue<T>) {
        return snapshot.getLoadable(atom).contents;
      },
    [],
  );

  nexus.getPromise = useRecoilCallback<[atom: RecoilValue<any>], Promise<any>>(
    ({ snapshot }) =>
      function <T>(atom: RecoilValue<T>) {
        return snapshot.getPromise(atom);
      },
    [],
  );

  const getInfo = useGetRecoilValueInfo_UNSTABLE();
  const transact = useRecoilTransaction_UNSTABLE(({ set }) => set);

  // @ts-expect-error
  nexus.set = useRecoilCallback(({ set }) => {
    return function <T>(
      recoilState: RecoilState<T>,
      valOrUpdater: T | ((currVal: T) => T),
    ) {
      const update = {
        atom: transact,
        selector: set,
      }[getInfo(recoilState).type];

      update(recoilState, valOrUpdater);
    };
  }, []);

  nexus.reset = useRecoilCallback(({ reset }) => reset, []);

  return null;
}

export function getRecoil<T>(atom: RecoilValue<T>): T {
  return nexus.get!(atom);
}

export function getRecoilPromise<T>(atom: RecoilValue<T>): Promise<T> {
  return nexus.getPromise!(atom);
}

export function setRecoil<T>(
  atom: RecoilState<T>,
  valOrUpdater: T | ((currVal: T) => T),
) {
  nexus.set!(atom, valOrUpdater);
}

export function resetRecoil(atom: RecoilState<any>) {
  nexus.reset!(atom);
}
