import { forwardRef, useEffect, useRef, useState } from 'react';
import { styled } from 'goober';

import { isEllipsis } from '@/helpers/other';
import { useRoles } from '@/hooks/queries/use-roles.query';
import { colors } from '@/theme/colors';
import type {
  InvitedUserOption,
  IOption,
  TeamBasicOption,
  UserOption,
} from '@/types';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Logo } from '@/ui/logo/logo';
import { Select } from '@/ui/select/select';
import { Tooltip } from '@/ui/tooltip/tooltip';

import {
  OptionsListItem,
  OptionsListItemLabel,
} from '../selected-options-list';

import { OPTION_ITEM_HEIGHT } from './selected-basic-team-option';

interface SelectedInvitedUserOptionProps {
  onRemove?: (item: UserOption | TeamBasicOption | InvitedUserOption) => void;
  isDisabled?: boolean;
  option: InvitedUserOption;
  index: number;
  onChangeRole: (role: unknown) => void;
}

export function SelectedInvitedUserOption({
  onRemove,
  isDisabled,
  option,
  index,
  onChangeRole,
}: SelectedInvitedUserOptionProps) {
  const [isReady, setIsReady] = useState(false);
  const refItemLabel = useRef<(HTMLDivElement | null)[]>([]);

  const { data: appRoles } = useRoles();

  useEffect(() => {
    refItemLabel.current && setIsReady(true);
  }, [refItemLabel]);

  const rolesOptions: IOption[] = appRoles
    ? appRoles.map(role => ({
        value: role.id,
        label: role.name,
      }))
    : [];

  return (
    <ListOption
      key={option.value}
      name="options-list-item"
      justify="space-between"
      alignItems="center"
    >
      <Tooltip
        placement="left"
        content={option.label}
        visible={isReady && isEllipsis(refItemLabel.current[index])}
      >
        <Flexbox name="option-content" gap="8px" alignItems="center">
          <LogoUser
            name={option.label}
            singleLetter
            bgColor={colors.blue.primaryA}
          />

          <Label
            ref={element => {
              refItemLabel.current[index] = element;
            }}
          >
            {option.label}
          </Label>
        </Flexbox>
      </Tooltip>
      <Actions name="actions" gap="20px" alignItems="center">
        <RoleDropdown
          options={rolesOptions}
          menuPosition="fixed"
          defaultValue={
            rolesOptions.find(role => role.value === option.role?.id) ??
            rolesOptions[0]
          }
          onChange={onChangeRole}
        />

        {!isDisabled && onRemove && (
          <IconWrapper>
            <Icon
              icon="Close"
              width="10px"
              height="10px"
              color={colors.gray.c13}
              onClick={() => onRemove?.(option)}
            />
          </IconWrapper>
        )}
      </Actions>
    </ListOption>
  );
}

const Actions = styled(Flexbox)`
  font-size: 14px;
`;

const IconWrapper = styled('div')`
  visibility: hidden;
`;

const ListOption = styled(OptionsListItem)`
  min-height: ${OPTION_ITEM_HEIGHT}px;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.c2};

  [data-name='share-modal-container'] &:hover {
    background-color: ${({ theme }) => theme.colors.blue.c8};
    ${IconWrapper} {
      visibility: visible;
    }
  }
`;

const Label = styled(OptionsListItemLabel, forwardRef)`
  ${({ theme }) => theme.typography.acrossPlatform.userName}

  [data-name='share-modal-container'] & {
    max-width: 560px;
  }

  &::after {
    content: attr(data-team);
    display: block;
    clear: left;
    margin-top: 2px;
    ${({ theme }) => theme.typography.widget.smallText}
    ${({ theme }) => theme.mixins.ellipsis}
  }
`;

const LogoUser = styled(Logo)`
  font-size: 12px;
`;

const RoleDropdown = styled(Select)`
  width: 120px;
`;
