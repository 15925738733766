import { fetchEnrichedCompanies } from '@/api/v4/organization-enrich.api';
import { QueryKey } from '@/config/query-client';
import { getTimeInMs } from '@/helpers/get-time-in-ms';
import { useQuery } from '@tanstack/react-query';

export const useEnrichedCompanySuggestions = (companyIds: string[]) => {
  return useQuery(
    [QueryKey.CompaniesSearchSuggestions, companyIds],
    async () =>
      await fetchEnrichedCompanies({
        organizationIds: companyIds,
      }),
    {
      staleTime: getTimeInMs(5, 'minute'),
      cacheTime: getTimeInMs(5, 'minute'),
    },
  );
};
