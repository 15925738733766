import { useCallback, useState } from 'react';

export const useDescopeFlow = () => {
  const [isFlowLoading, setIsFlowLoading] = useState(true);

  const onReady = useCallback(() => {
    setIsFlowLoading(false);
  }, [setIsFlowLoading]);

  return {
    isFlowLoading,
    onReady,
  };
};
