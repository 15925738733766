import type { CSSProperties } from 'react';
import { forwardRef } from 'react';
import { styled } from 'goober';

const LineBase = styled('div', forwardRef)`
  display: grid;
  height: fit-content;
`;

interface LineProps {
  gap?: string;
  margin?: string;
  centered?: boolean;
  $end?: boolean;
}

interface StackProps extends LineProps {
  fullWidth?: boolean;
  fullHeight?: boolean;
  maxWidth?: string;
  minWidth?: string;
  width?: string;
  minHeight?: string;
  colSpan?: number;
}

export const Stack = styled(LineBase, forwardRef)<StackProps>`
  min-height: ${({ minHeight = '0' }) => minHeight};
  row-gap: ${({ gap = '10px' }) => gap};
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
  ${({ fullHeight }) => fullHeight && 'height: 100%;'}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
    ${({ width }) => width && `width: ${width};`}
    ${({ colSpan }) => colSpan && `grid-column: 1 / span ${colSpan};`}
    ${({ margin }) => margin && `margin: ${margin};`}
    ${({ centered }) =>
    centered &&
    `
    text-align: center;
    & > * {
      margin: 0 auto;
    }
  `}
    ${({ $end }) => $end && 'justify-content: end;'}
`;

interface InlineProps extends LineProps {
  fullHeight?: boolean;
  hasOverflow?: boolean;
  templateColumns?: CSSProperties['gridTemplateColumns'];
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  width?: CSSProperties['width'];
}
export const Inline = styled(LineBase)<InlineProps>`
  align-items: ${({ alignItems = 'self-start' }) => alignItems};
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}
  grid-auto-flow: column;
  column-gap: ${({ gap = '10px' }) => gap};
  ${({ fullHeight }) => fullHeight && 'height: 100%;'}
  ${({ hasOverflow }) => hasOverflow && 'overflow: scroll;'}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ templateColumns }) =>
    templateColumns && `grid-template-columns: ${templateColumns};`}
    ${({ width }) => width && `width: ${width};`}
`;
