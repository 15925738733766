import type { Location } from '@/api/v4/locations.api';
import type { CompanyLocationOption } from '@/types';

export const mapCompanyLocationOption = (
  location: Location,
): CompanyLocationOption => ({
  label: {
    cityName: location.name,
    regionName: location.regionName ?? '',
    countryName: location.countryName ?? '',
  },
  value: {
    cityId: location.cityId,
    regionId: location.regionId,
    countryId: location.countryId,
  },
});
