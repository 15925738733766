import type { Meeting } from '@/api/v4/meetings.api';

export const reorderMeetings = (
  meetings: Meeting[],
  meetingToPutAsFirstId: string,
) => {
  const foundedMeetingIndex = meetings.findIndex(
    meeting => meeting.id === meetingToPutAsFirstId,
  );

  if (foundedMeetingIndex !== -1) {
    const editedMeeting = meetings.splice(foundedMeetingIndex, 1)[0];
    meetings.unshift(editedMeeting);
  }

  return meetings;
};
