import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchUsers } from '@/api/v4/user.api';
import { QueryKey } from '@/config/query-client';

import { projectIdState } from '../../../projects/project.state';
import { mapUserToOption } from '../helpers/map-users-and-teams-to-options';
import { useTeamOptions } from '@/features/teams/use-team-options.query';

export const useUsersAndTeamsWithAccessToProject = () => {
  const projectId = useRecoilValue(projectIdState);

  const fetchUsersWithAccessToProject = async () => {
    if (!projectId) return [];

    return fetchUsers({ withAccessToProject: projectId });
  };

  const { data: usersWithAccessToProject = [], isLoading: loadingUsers } =
    useQuery([QueryKey.Users, projectId], fetchUsersWithAccessToProject, {
      enabled: !!projectId,
      select: users => users.map(mapUserToOption),
      staleTime: 1000 * 10,
    });

  const { data: teamsWithAccessToProject = [], isLoading: loadingTeams } =
    useTeamOptions({
      withAccessToProject: projectId ?? undefined,
    });

  return {
    usersAndTeamsWithAccessToProject: [
      ...usersWithAccessToProject,
      ...teamsWithAccessToProject,
    ],
    isLoading: loadingTeams || loadingUsers,
  };
};
