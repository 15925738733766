import { useEffect, useMemo } from 'react';
import { debounce } from 'lodash-es';

export const useDebounce = <A extends any[]>(
  callback: (...args: A) => void,
  delay = 300,
) => {
  const debouncedResults = useMemo(() => {
    return debounce(callback, delay);
  }, [callback, delay]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, [debouncedResults]);

  return debouncedResults;
};
