import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

export const MinimizedHeader = styled('h2')`
  ${({ theme }) => theme.typography.acrossPlatform.minimizedHeader}
`;

export const FullSizedHeader = styled('h1')`
  ${({ theme }) => theme.typography.acrossPlatform.fullSizedHeader}
`;

export const Small = styled('p')<{ color?: string }>`
  ${({ theme }) => theme.typography.acrossPlatform.small}
  ${({ color }) => color && `color: ${color};`}
`;

export const UserName = styled('p', forwardRef)<{ color?: string }>`
  ${({ theme }) => theme.typography.acrossPlatform.userName}
  ${({ color }) => color && `color: ${color};`}
`;

interface IReadMoreProps {
  label?: string;
  onClick?: () => void;
  showMore?: boolean;
  color?: string;
}

// eslint-disable-next-line react/display-name
export const ReadMore = forwardRef<HTMLSpanElement, IReadMoreProps>(
  ({ label, onClick, showMore = false, color }, ref) => {
    const { t } = useTranslation('default');

    return (
      <ReadMoreTypography
        onClick={onClick}
        showMore={showMore}
        ref={ref}
        color={color}
      >
        {label ?? t`readMore`}
      </ReadMoreTypography>
    );
  },
);

const ReadMoreTypography = styled('span', forwardRef)<{
  showMore: boolean;
  color?: string;
}>`
  ${({ theme }) => theme.typography.acrossPlatform.readMore}
  ${({ color }) => color && `color: ${color}`};
  ${({ showMore, theme }) =>
    showMore && `color: ${theme.colors.blue.primaryA}`};
  cursor: pointer;
`;

export const ParagraphSmall = styled('p')<{ color?: string }>`
  ${({ theme }) => theme.typography.acrossPlatform.small}
  ${({ color }) => color && `color: ${color};`}
`;
