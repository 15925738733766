import { useEffect } from 'react';
import { styled } from 'goober';

import type { CreateProjectListingSuggestionResponse } from '@/api/v4/project-enhance-listing-suggestions.api';
import { Stack } from '@/ui/line/line';
import { TableRow } from '@/ui/table/infinite-table/table-row';

import { OverflowContainer } from '../project-listing-suggestion-form-modal';
import { useProjectListingSuggestionState } from '../use-project-listing-suggestion.state';

import { useProjectResultsTableConfig } from './enhance-project-results-table-configuration';

export const EnhanceProjectCompleteView = ({
  data,
}: {
  data: CreateProjectListingSuggestionResponse;
}) => {
  const { rows } = useProjectResultsTableConfig(data);
  const state = useProjectListingSuggestionState();

  useEffect(() => {
    if (state.currentPageStep !== 'results')
      state.setCurrentPageStep('results');
  }, []);

  return (
    <OverflowContainer
      dataTestId="project-listing-suggestion-results-body"
      name="project-listing-suggestion-results-body"
      height="455px"
      data-testid="tags-results"
      direction="column"
    >
      <Container gap="0px">
        <Table data-testid="project-listing-suggestion-results">
          <tbody>
            {rows.map((row, index) => (
              <TableRow
                isLast={index >= rows.length - 1}
                key={row.id}
                row={row}
              />
            ))}
          </tbody>
        </Table>
      </Container>
    </OverflowContainer>
  );
};

const Container = styled(Stack)`
  width: 100%;
  max-width: 890px;
`;

const Table = styled('table')`
  background-color: ${({ theme }) => theme.colors.basics.white};
  height: fit-content;
  table-layout: fixed;
  margin-left: 15px;

  td {
    background-color: transparent;
  }
`;
