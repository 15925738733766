import type { MouseEventHandler } from 'react';
import { forwardRef } from 'react';
import type {
  MentionData,
  MentionPluginTheme,
} from '@draft-js-plugins/mention';
import { styled } from 'goober';

import { colors } from '@/theme/colors';
import { theme } from '@/theme/setupTheme';
import { fonts } from '@/theme/typography';
import { Z_INDEX_MENTIONS_DROPDOWN_MENU } from '@/theme/z-index';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { Logo } from '@/ui/logo/logo';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';

interface MentionSuggestionItemProps {
  className?: string;
  onMouseDown: MouseEventHandler<HTMLDivElement>;
  onMouseUp: MouseEventHandler<HTMLDivElement>;
  onMouseEnter: MouseEventHandler<HTMLDivElement>;
  role: string;
  id: string;
  selected?: boolean;
  theme?: MentionPluginTheme;
  mention: MentionData;
  isFocused: boolean;
}

const MENTION_POPOVER_ANCHOR_MARGIN = 8;
const MENTION_POPOVER_MAX_HEIGHT = 300;
const MENTION_POPOVER_MAX_WIDTH = 300;

type MentionPopoverContainerProps = {
  position?: 'top' | 'bottom';
  height?: number;
};

export const MentionPopoverContainer = styled(
  Stack,
  forwardRef,
)<MentionPopoverContainerProps>`
  box-shadow: ${theme.shadow.actionsPanel};
  background-color: ${theme.colors.basics.white};
  position: absolute;
  top: ${({ position, height }) =>
    position === 'top'
      ? `-${
          (height ?? MENTION_POPOVER_MAX_HEIGHT) + MENTION_POPOVER_ANCHOR_MARGIN
        }px`
      : '38px'};
  z-index: ${Z_INDEX_MENTIONS_DROPDOWN_MENU};
  overflow: auto;
  row-gap: 0px;
  max-width: ${MENTION_POPOVER_MAX_WIDTH}px;
  max-height: ${MENTION_POPOVER_MAX_HEIGHT}px;
`;

export const MentionSuggestionItem = (props: MentionSuggestionItemProps) => {
  const { mention, ...parentProps } = props;

  return (
    <ItemContainer
      name="mention-suggestion-item"
      gap="12px"
      margin="0px"
      alignItems="center"
      aria-selected={props.selected}
      {...parentProps}
    >
      <Logo name={mention.name} bgColor={colors.blue.primaryA} />
      <Stack gap="2px" width="200px">
        <EllipsisTextTooltip text={mention.name} Component={<EllipsisText />} />
        <EllipsisTextTooltip text={mention.teamName} Component={<TeamName />} />
      </Stack>
    </ItemContainer>
  );
};

const EllipsisText = styled('p', forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis}
  max-width: 180px;
`;

const TeamName = styled(EllipsisText, forwardRef)`
  color: ${({ theme }) => theme.colors.gray.c11};
  font-size: 12px;
  margin: 0;
`;

const ItemContainer = styled(Flexbox, forwardRef)`
  padding: 12px 18px;
  cursor: pointer;
  font-family: ${fonts.wallop};
  color: ${theme.colors.basics.black};
  font-size: 14px;
  line-height: 16.07px;
  border-top: 1px solid ${theme.colors.gray.c3};
`;

export const MentionText = styled('span')`
  color: ${theme.colors.blue.primaryA};
  background-color: transparent;
  cursor: text;
`;
