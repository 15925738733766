import { forwardRef } from 'react';
import { styled } from 'goober';

export const WidgetBox = styled('div', forwardRef)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.basics.white};
  border-radius: 20px;
  box-shadow: ${({ theme }) => theme.shadow.widgets};
  position: relative;
  padding: 32px;
`;
