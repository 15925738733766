import { useProject } from '@/features/projects/overview/use-project.query';

import { TopbarWrapper } from '../topbar';

import { ProjectHeaderActions } from './shared/project-header-actions';

export const ProjectOverviewHeader = () => {
  const { data: project } = useProject();

  if (!project) return null;

  return (
    <TopbarWrapper
      name="project-overview"
      justify="flex-end"
      bgColor="transparent"
    >
      <ProjectHeaderActions />
    </TopbarWrapper>
  );
};
