import { useLayoutEffect, useRef } from 'react';
import { useState } from 'react';

export const useOutsideWindowMeasure = (
  element?: HTMLElement | null,
  triggerMeasure?: boolean,
) => {
  const [isElementOutsideWindow, setIsElementOutsideWindow] = useState(false);

  const elementRef = useRef<HTMLUListElement | null>(null);

  useLayoutEffect(() => {
    const elementReference = element ?? elementRef.current;

    if (!elementReference) {
      return;
    }

    const rect = elementReference.getBoundingClientRect();

    setIsElementOutsideWindow(
      rect.x + rect.width > window.innerWidth ||
        rect.y + rect.height > window.innerHeight,
    );
  }, [element, triggerMeasure]);

  return { elementRef, isElementOutsideWindow };
};
