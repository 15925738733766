import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { MentionData } from '@draft-js-plugins/mention';

import type { UserToMention } from '@/api/v4/user.api';

import { useUsersToMention } from '../app-users/hooks/use-users-to-mention.query';

import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import { useDebounce } from '@/hooks/use-debounce';
import type { RichTextFieldWithMentionProps } from '@/components/rich-text-field/rich-text-field-with-mention';
import { RichTextFieldWithMention } from '@/components/rich-text-field/rich-text-field-with-mention';

type UsersMentionTextFieldProps = Omit<
  RichTextFieldWithMentionProps,
  'suggestions' | 'onSearchChange'
> & {
  projectId?: string;
  teamId?: string;
  organizationId?: string;
};

const mapUsersToSuggestions = (users?: UserToMention[]) => {
  if (!users) return [];

  return users.map<MentionData>(user => ({
    name: `${user.firstName} ${user.lastName}`,
    id: user.id,
    teamName: user.team?.name ?? '',
  }));
};

const MAX_MENTIONED_USERS = 50;

export const UsersMentionTextField = ({
  onChange,
  defaultValue,
  readOnly = false,
  teamId,
  projectId,
  organizationId,
  placeholder,
  onSubmit,
  height,
  onFocus,
  onBlur,
  richTextEditorRef,
  dataTestId,
}: UsersMentionTextFieldProps) => {
  const [searchPhrase, setSearchPhrase] = useState<string | null>(null);
  const [focused, setFocused] = useState(false);

  const debounceSetSearchPhrase = useDebounce(setSearchPhrase, 300);

  const { t } = useTranslation('default');

  const { data: users } = useUsersToMention({
    search: searchPhrase,
    organizationId,
    projectId,
    teamId,
    items: MAX_MENTIONED_USERS,
  });

  const handleOnFocus = (e: SyntheticEvent<Element, Event>) => {
    setFocused(true);
    onFocus?.(e);
  };

  const handleOnBlur = (e: SyntheticEvent<Element, Event>) => {
    setFocused(false);
    onBlur?.(e);
  };

  return (
    <RichTextFieldWithMention
      key="user-mentions-rich-text-field"
      defaultValue={defaultValue}
      onChange={onChange}
      readOnly={readOnly}
      suggestions={mapUsersToSuggestions(users)}
      onSearchChange={debounceSetSearchPhrase}
      placeholder={!focused ? placeholder : t(`userMentions.inputPlaceholder`)}
      onSubmit={onSubmit}
      height={height}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      richTextEditorRef={richTextEditorRef}
      dataTestId={dataTestId}
      allowFormating
    />
  );
};
