import { useTranslation } from 'react-i18next';

import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { SmallText } from '@/ui/typography/widgets';

export const OtherProjects = ({
  count,
  emptyList = false,
}: {
  count: number;
  emptyList?: boolean;
}) => {
  const { t } = useTranslation('projects');
  return (
    <Flexbox
      name="other-projects-container"
      justify="space-between"
      padding="0 4px"
      gap="8px"
      alignItems="center"
    >
      <SmallText color={colors.gray.c11}>
        {t(emptyList ? 'projects.countProject' : 'projects.otherProject', {
          count,
        })}
      </SmallText>
      <Tooltip
        content={t`projects.otherProjectsTooltipInfo`}
        placement="bottom"
        withArrow={false}
      >
        <Icon icon="Info" color={colors.gray.c11} />
      </Tooltip>
    </Flexbox>
  );
};
