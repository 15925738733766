import { useCallback, useEffect, useState } from 'react';
import { uniqBy } from 'lodash-es';
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import type { ContactFormItem } from '@/features/contacts/contacts';
import { useLoadableResults } from '@/hooks/use-loadable-results';

import {
  companyContactDeletedIdState,
  companyContactsAtomState,
  companyContactsEditModeState,
  companyContactsPageState,
  companyContactsState,
  companyIdState,
  firstSixCompanyContactsAtomState,
  isMoreContactsThanMaxVisibleState,
} from '../company.state';
import { useDeleteCompanyContact } from './use-delete-company-contact.mutation';

export const MAX_VISIBLE_CONTACTS_NUMBER = 6;

export const useCompanyContacts = () => {
  const data = useLoadableResults(companyContactsState);
  const [allContactsVisible, setAllContactsVisible] = useState(false);

  const { deleteContact } = useDeleteCompanyContact();

  const resetCompanyContactsState =
    useRecoilRefresher_UNSTABLE(companyContactsState);
  const companyId = useRecoilValue(companyIdState);
  const isMoreContactsThanMaxVisible = useRecoilValue(
    isMoreContactsThanMaxVisibleState,
  );
  const [contacts, setContacts] = useRecoilState(companyContactsAtomState);
  const firstSixContacts = useRecoilValue(firstSixCompanyContactsAtomState);

  const [visibleContacts, setVisibleContacts] =
    useState<ContactFormItem[]>(firstSixContacts);
  const { pageSize } = useRecoilValue(companyContactsPageState);
  const setDeletedContact = useSetRecoilState(companyContactDeletedIdState);
  const resetPagination = useResetRecoilState(companyContactsPageState);
  const resetEditContactState = useResetRecoilState(
    companyContactsEditModeState,
  );
  const [resetContacts, setResetContacts] = useState(false);

  useEffect(() => {
    resetPagination();
    setContacts([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (resetContacts) return;

    if (!data || data.contacts.length === 0) return;
    setContacts(old =>
      uniqBy([...old, ...data.contacts], contact => contact.id),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.contacts, setContacts, resetContacts]);

  useEffect(() => {
    if (!firstSixContacts.length) return;
    setVisibleContacts(firstSixContacts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  useEffect(() => {
    if (!visibleContacts.length) return;

    setVisibleContacts(allContactsVisible ? contacts : firstSixContacts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleContacts, allContactsVisible]);

  useEffect(() => {
    if (!resetContacts) return;

    setContacts(uniqBy(data?.contacts || [], contact => contact.id));
    resetPagination();

    setResetContacts(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data?.contacts, setContacts]);

  const reset = useCallback(() => {
    resetEditContactState();
    resetCompanyContactsState();
    setResetContacts(true);
  }, [resetCompanyContactsState, resetEditContactState]);

  const addContactList = useCallback(
    (contacts: ContactFormItem[]) => {
      setContacts(currentContacts => [...currentContacts, ...contacts]);
    },
    [setContacts],
  );

  const editContactList = useCallback(
    (editedContacts: ContactFormItem[]) => {
      setContacts(contacts =>
        contacts.map(oldContact => {
          const editedContact = editedContacts.find(
            editedContact => editedContact.id === oldContact.id,
          );

          if (editedContact) return editedContact;

          return oldContact;
        }),
      );
    },
    [setContacts],
  );

  const handleDeleteContact = useCallback(
    async (contactId: string, synced: boolean) => {
      if (synced && companyId) {
        await deleteContact({
          companyId,
          companyContactId: contactId,
        });
      }

      setDeletedContact(contactId);
      setContacts(contacts =>
        contacts.filter(oldContact => {
          return oldContact.id !== contactId;
        }),
      );
    },
    [companyId, setContacts, setDeletedContact],
  );

  const hasNextPage = data?.contacts && data?.contacts.length >= pageSize;
  const poweredByCrunchbase = data?.poweredBy === 'crunchbase';

  const toggleAllContacts = () => setAllContactsVisible(val => !val);

  return {
    allContacts: contacts,
    visibleContacts,
    isLoading: !data,
    hasNextPage,
    addContactList,
    editContactList,
    deleteContact: handleDeleteContact,
    reset,
    isMoreContactsThanMaxVisible,
    toggleAllContacts,
    allContactsVisible,
    poweredByCrunchbase,
  };
};
