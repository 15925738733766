import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import type { UpdateTeamTagsAPIPayload } from '@/api/v4/team-tags.api';
import { updateTeamTags } from '@/api/v4/team-tags.api';
import { queryClient, QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

type EditTeamTagsMutationParams = {
  teamId: string;
  payload: UpdateTeamTagsAPIPayload;
};

export const useUpdateTeamTags = () => {
  const { t } = useTranslation('default');

  return useMutation(
    async ({ teamId, payload }: EditTeamTagsMutationParams) => {
      return updateTeamTags(teamId, payload);
    },
    {
      onError: (error: Error) => {
        notify({
          message: t`unknownError`,
        });
        captureException(error);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKey.TeamTags]);
      },
    },
  );
};
