import { useState } from 'react';
import { useDrag } from 'react-dnd-cjs';
import { NativeTypes } from 'react-dnd-html5-backend-cjs';
import type { UseQueryResult } from '@tanstack/react-query';
import { styled } from 'goober';
import { isEmpty } from 'lodash-es';

import SvgFiles from '@/assets/widgets/empty-state-files.svg';
import { ActionPanelEmpty } from '@/features/action-panel/action-panel-empty';
import { withDraggableProvider } from '@/hoc/with-draggable-provider';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { FilesDragDrop } from '../../files-drag-drop/files-drag-drop';
import type {
  File,
  FileLinkDownloadHandler,
  FileScanning,
} from './file.interface';
import { UploadFileInput } from './upload-file-input';
import type { ShareFilePayload } from './edit-file';
import { withPermission } from '@/hoc/with-permission';
import { AVAILABLE_EXTENSIONS } from './constants';
import { useSetRecoilState } from 'recoil';
import { useActionPanelSearchParams } from '../use-action-panel-search-params';
import { actionPanelScrollToParamState } from '../action-panel.state';
import type { IGetRefs } from '@/hooks/use-multi-refs';
import { FilesList } from './files-list';

interface FilesTabContentProps {
  files: File[];
  allowToChangeFilePrivacy?: boolean;
  emptyListLabel: string;
  onSubmit: (files: globalThis.File[]) => void;
  onClickDelete: (fileId: string, fileName: string) => void;
  onFileLinkDownload: FileLinkDownloadHandler;
  onShareFile: (payload: ShareFilePayload) => Promise<void>;
  useFileScanningStatus: (
    file: File,
  ) => UseQueryResult<FileScanning | undefined>;
}

const FilesDragDropWithPermission = withPermission(
  FilesDragDrop,
  PERMISSION.ADD_FILE,
);

const UploadFileInputWithPermission = withPermission(
  UploadFileInput,
  PERMISSION.ADD_FILE,
);

export const FilesTabContent = withDraggableProvider(
  ({
    files,
    emptyListLabel,
    onClickDelete,
    onSubmit,
    onFileLinkDownload,
    onShareFile,
    useFileScanningStatus,
    allowToChangeFilePrivacy = true,
  }: FilesTabContentProps) => {
    const [editedFileId, setEditedFileId] = useState<string | null>(null);
    const { hasRequiredPermission } = useUserPermissions();
    const setIsScrolled = useSetRecoilState(actionPanelScrollToParamState);
    const { fileParam } = useActionPanelSearchParams();

    const canDrag = hasRequiredPermission(PERMISSION.ADD_FILE);

    const [{ draggingItems }] = useDrag({
      canDrag,
      item: {
        type: NativeTypes.FILE,
      },
      collect: monitor => ({
        draggingItems: monitor.getItem(),
      }),
    });

    const scrollToFile: IGetRefs = refs => {
      if (!fileParam) return;

      const fileNode = refs[fileParam];

      if (!fileNode) return;

      fileNode.focus();
      fileNode.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
        inline: 'nearest',
      });

      setIsScrolled(true);
    };

    return (
      <TabContainer name="action-panel-wrapper" direction="column" grow={1}>
        {Boolean(draggingItems) && (
          <FilesDragDropWithPermission onDrop={onSubmit} />
        )}
        <Flexbox
          name="action-panel-button-container"
          justify="right"
          alignItems="center"
          height="56px"
          padding="0 30px"
        >
          <UploadFileInputWithPermission
            onSubmit={onSubmit}
            acceptedFiles={AVAILABLE_EXTENSIONS}
          />
        </Flexbox>
        {isEmpty(files) ? (
          <ActionPanelEmpty image={SvgFiles} label={emptyListLabel}>
            <UploadFileInputWithPermission
              variant="primary"
              onSubmit={onSubmit}
              acceptedFiles={AVAILABLE_EXTENSIONS}
            />
          </ActionPanelEmpty>
        ) : (
          <FilesList
            files={files}
            getRefs={scrollToFile}
            editedFileId={editedFileId}
            editedFileIdSetter={setEditedFileId}
            onClickDelete={onClickDelete}
            onFileLinkDownload={onFileLinkDownload}
            onShareFile={onShareFile}
            useFileScanningStatus={useFileScanningStatus}
            allowToChangeFilePrivacy={allowToChangeFilePrivacy}
          />
        )}
      </TabContainer>
    );
  },
);

const TabContainer = styled(Flexbox)`
  position: relative;
`;
