import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { styled } from 'goober';

import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Logo } from '@/ui/logo/logo';

import { isDealStage, ListingStageName } from '../../../listing-stage/types';
import { type ProjectListingStageUpdate } from './helpers';

export function ChangeStatus({
  update,
}: {
  update: ProjectListingStageUpdate;
}) {
  const resolveIsDeal = useMemo(
    () => (update.data ? isDealStage(update.data.status) : false),
    [update.data],
  );

  const notRelevant = update.data.status === ListingStageName.notRelevant;
  const components = { strong: <strong /> };

  const stageName = update.data.status;
  return (
    <Wrapper
      name="update-item"
      alignItems="center"
      gap="16px"
      isDeal={resolveIsDeal}
      isBlurred={notRelevant}
    >
      <Logo
        singleLetter
        name={update.data.organization.name}
        logoUrl={update.data.organization.logoUrl}
        bgColor={
          update.data.organization.logoUrl
            ? colors.basics.white
            : colors.accent.green.c3
        }
      />
      <TextContainer>
        {resolveIsDeal ? (
          <Trans
            ns="default"
            i18nKey="actionPanel.updates.changeStatus"
            values={{
              user: `${update.user.firstName} ${update.user.lastName}`,
              company: update.data.organization.name,
              status: 'Deal',
            }}
            components={components}
          />
        ) : notRelevant ? (
          <Trans
            ns="default"
            i18nKey="actionPanel.updates.movedTo"
            values={{
              user: `${update.user.firstName} ${update.user.lastName}`,
              company: update.data.organization.name,
              status: stageName,
            }}
            components={components}
          />
        ) : (
          <Trans
            ns="default"
            i18nKey="actionPanel.updates.movedTo"
            values={{
              user: `${update.user.firstName} ${update.user.lastName}`,
              company: update.data.organization.name,
              status: stageName,
            }}
            components={components}
          />
        )}
      </TextContainer>
    </Wrapper>
  );
}

const Wrapper = styled(Flexbox)<{ isDeal: boolean; isBlurred: boolean }>`
  ${({ isDeal, theme }) =>
    isDeal && `background-color: ${theme.colors.blue.c2}`}

  ${({ isBlurred }) => isBlurred && 'opacity: 0.3;'}
`;

const TextContainer = styled('div')`
  padding-top: 5px;
`;
