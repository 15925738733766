import { atom } from 'recoil';

import type { UsersListSort } from '@/api/v4/user.api';
import type { APP_USER_ROLES } from '@/types';
import type { ISort } from '@/ui/table/table.types';

import type { SettingsTabs } from './use-settings-tabs-config';

export const settingsActiveTabState = atom<SettingsTabs>({
  key: 'settingsActiveTabState',
  default: 'profileSettings',
});

export const settingsUsersLoadingState = atom<boolean>({
  key: 'settingsUsersLoadingState',
  default: false,
});

export const settingsUsersSearchPhraseState = atom<string>({
  key: 'settingsUsersSearchPhraseState',
  default: '',
});

export const settingsUsersSortState = atom<ISort<UsersListSort>>({
  key: 'settingsUsersSortState',
  default: {
    sort: 'status',
    direction: 'asc',
  },
});

export const settingsInvitedUsersSearchPhraseState = atom<string>({
  key: 'settingsInvitedUsersSearchPhraseState',
  default: '',
});

export const settingsInvitedUsersSortState = atom<ISort<UsersListSort>>({
  key: 'settingsInvitedUsersSortState',
  default: {
    sort: 'createdDate',
    direction: 'desc',
  },
});

export type UserRoleOption = {
  label: APP_USER_ROLES;
  id: string;
};

type ChangeUserRolePayload = {
  userId: number;
  newRoleId: string;
  entities: Entity[];
};

export type Entity = {
  value: string;
  label: string;
  type: 'company' | 'project';
};

export const settingsChangeUserRoleFormState =
  atom<ChangeUserRolePayload | null>({
    key: 'settingsChangeUserRoleFormState',
    default: null,
  });
