import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import { useSidebars } from '@/hooks/use-sidebars';

import type { ActionPanel } from './action-panel.state';
import {
  actionPanelActiveTabState,
  actionPanelOpenOnLoadState,
  actionPanelState,
} from './action-panel.state';
import type { ActionPanelSearchParamData } from './use-action-panel-search-params';

export const useActionPanel = () => {
  const actionPanel = useRecoilValue(actionPanelState);
  const resetActionPanel = useResetRecoilState(actionPanelState);
  const setActionPanel = useSetRecoilState(actionPanelState);
  const setActionPanelActiveTab = useSetRecoilState(actionPanelActiveTabState);
  const [isAlreadyOpenedOnLoad, setIsOpenOnLoad] = useRecoilState(
    actionPanelOpenOnLoadState,
  );

  const { resetSidebar, isActionPanelOpen, setCurrentSidebar } = useSidebars();

  const toggleActionPanel = (data?: ActionPanel) => {
    if (isActionPanelOpen) {
      closeActionPanel();
      return;
    }
    if (!data) {
      throw new Error('Action panel data is required to open the action panel');
    }
    openActionPanel(data);
  };

  const closeActionPanel = () => {
    resetActionPanel();
    resetSidebar();
  };

  const openActionPanel = (data: ActionPanel) => {
    setCurrentSidebar('ActionsPanel');
    setActionPanel(data);
  };

  const openActionPanelOnSearchParamTab = (
    actionPanelSearchParam: ActionPanelSearchParamData,
    actionPanelContext: ActionPanel,
  ) => {
    if (actionPanelSearchParam.type === 'meeting') {
      openActionPanel(actionPanelContext);
      setActionPanelActiveTab('meetings');
    }

    if (actionPanelSearchParam.type === 'comment') {
      openActionPanel(actionPanelContext);
      setActionPanelActiveTab('comments');
    }

    if (actionPanelSearchParam.type === 'file') {
      openActionPanel(actionPanelContext);
      setActionPanelActiveTab('files');
    }
  };

  return {
    actionPanel,
    toggleActionPanel,
    closeActionPanel,
    openActionPanel,
    isActionPanelOpen,
    openActionPanelOnSearchParamTab,
    isAlreadyOpenedOnLoad,
    setIsOpenOnLoad,
  };
};
