import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import type { EditOrganizationCommentPayload } from '@/api/v4/organization-comments.api';
import { editOrganizationComment } from '@/api/v4/organization-comments.api';
import { queryClient, QueryKey } from '@/config/query-client';
import { projectIdState } from '@/features/projects/project.state';
import { teamIdState } from '@/features/teams/team.state';
import { notify } from '@/ui/snackbar/notify';

import { companyIdState } from '../../company.state';

export const useEditCompanyComment = () => {
  const { t } = useTranslation('default');
  const companyId = useRecoilValue(companyIdState);
  const projectId = useRecoilValue(projectIdState);
  const teamId = useRecoilValue(teamIdState);

  const editCompanyCommentMutation = useMutation(editOrganizationComment, {
    onError: (error: Error) => {
      notify({
        message: t`unknownError`,
      });
      console.error(error.message);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([
          QueryKey.CompanyComments,
          companyId,
          projectId,
          teamId,
        ]),
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);
    },
  });

  const editComment = (payload: EditOrganizationCommentPayload) =>
    editCompanyCommentMutation.mutateAsync(payload);

  return {
    editComment,
    isError: editCompanyCommentMutation.isError,
  };
};
