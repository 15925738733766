import { styled } from 'goober';

import { TOPBAR_HEIGHT } from '@/features/topbar/topbar';
import { Button } from '@/ui/button/button';
import type { IconVariant } from '@/ui/icons/icon';
import { MinimizedHeader } from '@/ui/typography/across-platform';

import { Flexbox } from '../flexbox/flexbox';

interface Props {
  header: string;
  text: string;
  buttonText: string;
  buttonIcon: IconVariant;
  onButtonClick: () => void;
  marginTop?: string;
  showButton?: boolean;
}

export const NoResults = ({
  header,
  text,
  buttonText,
  buttonIcon,
  onButtonClick,
  marginTop,
  showButton = true,
}: Props) => {
  return (
    <Container
      name="no-results-container"
      direction="column"
      gap="16px"
      alignItems="center"
      marginTop={marginTop}
    >
      <MinimizedHeader>{header}</MinimizedHeader>
      <Text>{text}</Text>
      {showButton && (
        <Button
          startIcon={buttonIcon}
          variant="secondary"
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      )}
    </Container>
  );
};

const Container = styled(Flexbox)<{ marginTop?: string }>`
  margin-top: ${({ marginTop = `-${TOPBAR_HEIGHT}` }) => marginTop};
`;

const Text = styled('p')`
  ${({ theme }) => theme.typography.widget.paragraphSmall}
  color: ${({ theme }) => theme.colors.gray.c12};
  max-width: 280px;
  text-align: center;
  margin-bottom: 10px;
`;
