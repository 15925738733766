import { useQuery } from '@tanstack/react-query';
import { HttpStatusCode } from 'axios';
import { useRecoilValue } from 'recoil';

import { QueryKey } from '@/config/query-client';
import { isOfType } from '@/helpers/other';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { companyIdState } from './company.state';
import { useMemo } from 'react';
import { fetchCompany } from '@/api/v4/organization.api';

export const useCompany = () => {
  const { hasAtLeastOnePermission } = useUserPermissions();
  const companyId = useRecoilValue(companyIdState);
  const navigateTo = useNavigateTo();

  const getCompany = () => {
    if (!companyId) return;
    return fetchCompany(companyId);
  };

  const query = useQuery([QueryKey.Company, companyId], getCompany, {
    onError: (error: unknown) => {
      if (isIdNotRecognizedError(error)) {
        navigateTo.notFound();
      }
    },
    retry: false,
    enabled:
      !!companyId &&
      hasAtLeastOnePermission([PERMISSION.VIEW_ORGANIZATION_HOME_SCREEN]),
    staleTime: 1000 * 60 * 5,
  });

  const isCrunchbaseCompany = useMemo(
    () => query.isFetched && !!query.data?.crunchbasePermalink,
    [query.data?.crunchbasePermalink, query.isFetched],
  );

  return { ...query, isCrunchbaseCompany };
};

const isIdNotRecognizedError = (error: unknown) => {
  return (
    isOfType(error, 'response') &&
    isOfType(error.response, 'status') &&
    (error.response.status === HttpStatusCode.NotFound ||
      error.response.status === HttpStatusCode.BadRequest)
  );
};
