import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import CrunchbaseSvg from '@/assets/widgets/edit-stamp-crunchbase.svg';
import { getCompanyCrunchbaseUrl } from '@/features/companies/helpers/get-company-crunchbase-url';
import { formatDate } from '@/helpers/format-date';
import { componentsPalette } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Tooltip } from '@/ui/tooltip/tooltip';

export interface EditStampProps {
  margin?: string;
  padding?: string;
  align?: 'left' | 'right';
  crunchbasePermalink: string | null;
  lastEdited?: string;
}

const CrunchbaseLink = ({
  crunchbasePermalink,
}: {
  crunchbasePermalink: string;
}) => {
  const urlValue = getCompanyCrunchbaseUrl(crunchbasePermalink);
  return (
    <Link href={urlValue} target="_blank" rel="noreferrer">
      <img src={CrunchbaseSvg} alt="crunchbase-link" />
    </Link>
  );
};

export const EditStamp = ({
  align = 'right',
  margin,
  crunchbasePermalink,
  lastEdited,
  ...props
}: EditStampProps) => {
  const { t } = useTranslation('companies', { keyPrefix: 'company' });
  const tooltipContent = `${t('editStamp.updatedOn')} ${
    lastEdited && formatDate(lastEdited)
  }`;

  if (!crunchbasePermalink) {
    return null;
  }

  return (
    <Container name="edit-stamp" align={align} margin={margin} {...props}>
      <Tooltip visible={!!lastEdited} content={tooltipContent}>
        <Flexbox name="cb-stamp" alignItems="center">
          {t`editStamp.poweredBy`}{' '}
          <CrunchbaseLink crunchbasePermalink={crunchbasePermalink} />
        </Flexbox>
      </Tooltip>
    </Container>
  );
};

const Container = styled(Flexbox)<
  Pick<EditStampProps, 'align' | 'margin' | 'padding'>
>`
  ${({ theme }) => theme.typography.acrossPlatform.editStamp}
  color: ${componentsPalette.company.editStamp.color};
  margin: ${({ margin }) => margin ?? '0'};
  ${({ align }) => align === 'left' && `margin-right: auto;`}
  ${({ align }) => align === 'right' && `margin-left: auto;`}
  padding: ${({ padding }) => padding ?? '0 20px 0 0'};
`;

const Link = styled('a')`
  margin-left: 4px;
`;
