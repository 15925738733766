import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { ReactComponent as NoSearchResultsIllustration } from '@/assets/svg/no-assets-illustration.svg';
import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Small } from '@/ui/typography/across-platform';

import { SearchContentContainer } from './content-container';

export const EmptyState = ({ searchPhrase }: { searchPhrase: string }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'globalSearch',
  });

  return (
    <SearchContentContainer fullWidth>
      <Flexbox
        name="search-empty-state"
        direction="column"
        justify="center"
        alignItems="center"
      >
        <NoSearchResultsIllustration />
        <Small color={colors.gray.c12}>
          {t('noSearchResults')} <SearchPhrase>{searchPhrase}</SearchPhrase>
        </Small>
      </Flexbox>
    </SearchContentContainer>
  );
};

const SearchPhrase = styled('span')`
  font-weight: bold;
`;
