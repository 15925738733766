import { styled } from 'goober';

import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import createLinkPlugin from '@draft-js-plugins/anchor';
import {
  BoldButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton,
} from '@draft-js-plugins/buttons';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';

import '@draft-js-plugins/mention/lib/plugin.css';
import 'draft-js/dist/Draft.css';

export const useRichTextToolbar = () => {
  const { t } = useTranslation('default');

  const linkPlugin = createLinkPlugin({
    placeholder: t`formatingToolbar.link.placeholder`,
    linkTarget: '_blank',
  });

  const [toolbarPlugin, InlineToolbar] = useMemo(() => {
    const plugin = createInlineToolbarPlugin();
    return [plugin, plugin.InlineToolbar];
  }, []);

  const plugins = [toolbarPlugin, linkPlugin];

  const Toolbar = useMemo(
    () => (
      <ToolBarContainer>
        <InlineToolbar>
          {(externalProps: PropsWithChildren<any>) => (
            <div>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
              <linkPlugin.LinkButton {...externalProps} />
            </div>
          )}
        </InlineToolbar>
      </ToolBarContainer>
    ),
    [InlineToolbar, linkPlugin],
  );

  return {
    plugins,
    Toolbar,
  };
};

const ToolBarContainer = styled('div')`
  .tukdd6b {
    border-radius: 10px;
    border: 0px;
    background-color: ${({ theme }) => theme.colors.basics.black};
    display: flex;
    justify-content: center;
    min-width: 162px; // need this to prevent the toolbar from expanding vertically on edges

    &::before {
      border-top-color: ${({ theme }) => theme.colors.basics.black};
    }

    &::after {
      border-top-color: ${({ theme }) => theme.colors.basics.black};
    }
  }

  .i1qh9dya {
    ${({ theme }) => theme.typography.actionsPanel.acrossPanel.smallText}
    background-color: ${({ theme }) => theme.colors.basics.black};
    color: ${({ theme }) => theme.colors.basics.white};
    padding: 3px;
    border: 0px;
  }

  .i119ugvj {
    ${({ theme }) => theme.typography.actionsPanel.acrossPanel.smallText}
    background-color: ${({ theme }) => theme.colors.basics.black};
    color: ${({ theme }) => theme.colors.accent.pink};
    padding: 3px;
    margin: 0px;
    opacity: 1;
  }

  input {
    width: 160px;
  }

  button {
    background-color: ${({ theme }) => theme.colors.basics.black};
    color: ${({ theme }) => theme.colors.basics.white};
    border-radius: 25%;
    padding: 4px;
    margin: 3px 0px;
    height: 24px;
    width: 24px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.blue.primaryA};
    }

    &.a9immln {
      background-color: ${({ theme }) => theme.colors.blue.primaryA};
    }

    svg {
      fill: ${({ theme }) => theme.colors.basics.white};
      height: 14px;
      width: 14px;
    }
  }
`;
