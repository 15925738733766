import { forwardRef } from 'react';
import { styled } from 'goober';

import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import { StatusTag } from '@/ui/tag/status-tag';

type ListStatusTagProps = { text: string; maxWidth?: string };

export const ListStatusTag = ({ text }: ListStatusTagProps) => {
  return (
    <StatusTag variant="purple">
      <EllipsisTextTooltip Component={<EllipsisText />} text={text} />
    </StatusTag>
  );
};

const EllipsisText = styled('p', forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis}
  max-width: 180px;
`;
