import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

interface RouteLinkProps extends LinkProps {
  className?: string;
}

export const RouteLink = (props: RouteLinkProps) => {
  return <Link {...props}>{props.children}</Link>;
};
