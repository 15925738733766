import { Alert } from '@/components/alert/alert';
import { Stack } from '@/ui/line/line';
import { styled } from 'goober';
import { DraggableProvider } from '../../files-drag-drop/use-drag-and-drop';
import { LoadCompaniesToProjectDragDrop } from './load-companies-drag-and-drop';
import { useLoadCompaniesToProject } from './use-load-companies-to-project.mutation';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const COMPANIES_BULK_IMPORT_TEMPLATE_URL =
  'https://docs.google.com/spreadsheets/d/1Tq1rWFf4Ag88mxmAi3WtkiZaUJPcGgYc/edit?usp=sharing&ouid=107451855958666558457&rtpof=true&sd=true';

export const LoadCompaniesToProject = ({
  projectId,
}: {
  projectId: string;
}) => {
  const { t } = useTranslation('projects');
  const [file, setFile] = useState<File | null>(null);
  const { mutateAsync, isLoading, data } = useLoadCompaniesToProject(projectId);

  const handleDroppedFiles = async (files: globalThis.File[]) => {
    const file = files[0];
    setFile(file);

    await mutateAsync({
      file,
      projectId,
    });
  };

  return (
    <Stack gap="12px">
      {COMPANIES_BULK_IMPORT_TEMPLATE_URL && (
        <a
          href={COMPANIES_BULK_IMPORT_TEMPLATE_URL}
          download
          target="_blank"
          rel="noreferrer"
        >
          Companies Excel Load Template
        </a>
      )}
      {data === null ? (
        <Alert type="success" messages={[t`loadCompaniesFromExcelSuccess`]} />
      ) : data ? (
        <>
          <Alert type="error" messages={[t`loadCompaniesFromExcelError`]} />
          {file && (
            <a
              href={data}
              download={`${file.name.split('.')[0]}.Savvy-Load-Report.xlsx`}
            >
              {t`downloadLoadCompaniesFromExcelGeneratedReport`}
            </a>
          )}
        </>
      ) : undefined}
      <Container>
        <DraggableProvider>
          <LoadCompaniesToProjectDragDrop
            onDrop={handleDroppedFiles}
            isLoading={isLoading}
          />
        </DraggableProvider>
      </Container>
    </Stack>
  );
};

const Container = styled('div')`
  position: relative;
  height: 300px;
  margin: 14px 0;
  align-content: center;
`;
