import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { queryClient, QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

import type { DeleteCompanyContactPayload } from '@/api/v4/organization-contacts.api';
import { deleteCompanyContact } from '@/api/v4/organization-contacts.api';
import { companyIdState } from '../company.state';

export const useDeleteCompanyContact = () => {
  const { t } = useTranslation('default');
  const companyId = useRecoilValue(companyIdState);

  const mutation = useMutation(deleteCompanyContact, {
    onError: () => {
      notify({
        message: t`unknownError`,
      });
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([QueryKey.Company, companyId]),
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);
    },
  });

  const deleteContact = (payload: DeleteCompanyContactPayload) =>
    mutation.mutateAsync(payload);

  return {
    deleteContact,
    isLoading: mutation.isLoading,
  };
};
