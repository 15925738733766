import { paths } from '@/routes/helpers/paths';
import { LogoContainer, LogoIcon, LogoTextIcon } from './navbar.styles';

export function Logo() {
  return (
    <LogoContainer to={paths.home()}>
      <LogoTextIcon />
      <LogoIcon />
    </LogoContainer>
  );
}
