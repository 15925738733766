import { colors } from '@/theme/colors';
import type { HeaderCellConfig } from '@/ui/table/table.types';

export const companySuggestionsTableHeaders: HeaderCellConfig[] = [
  {
    columnKey: 'companies',
    bgColor: colors.basics.white,
    withSort: false,
    padding: '20px 12px',
  },
  {
    columnKey: 'tags',
    bgColor: colors.basics.white,
    withSort: false,
    padding: '20px 12px',
  },
  {
    columnKey: 'projects',
    bgColor: colors.basics.white,
    withSort: false,
    padding: '20px 12px',
  },
  {
    columnKey: 'founded',
    bgColor: colors.basics.white,
    withSort: false,
    padding: '20px 12px',
  },
  {
    columnKey: 'fundingStage',
    bgColor: colors.basics.white,
    withSort: false,
    padding: '20px 12px',
  },
  {
    columnKey: 'capitalRaised',
    bgColor: colors.basics.white,
    withSort: false,
    padding: '20px 12px',
  },
  {
    columnKey: 'lastModified',
    bgColor: colors.basics.white,
    withSort: false,
    padding: '20px 12px',
  },
];
