import type { TeamBasicOption } from '@/types';

import type { ProjectFiltersTeamOptionAPI } from '../project-filters.api';

export const mapTeamsToBasicTeamOptions = (
  teams: ProjectFiltersTeamOptionAPI[],
): TeamBasicOption[] =>
  teams.map(team => ({
    value: team.id,
    label: team.name,
    type: 'team',
  }));
