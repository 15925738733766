import type { QueryFunctionContext } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { fetchSharedCompanies } from '@/api/v4/shared-organizations';
import { QueryKey } from '@/config/query-client';
import { getTimeInMs } from '@/helpers/get-time-in-ms';

export const useSharedCompanies = (email: string | undefined) =>
  useInfiniteQuery(
    [QueryKey.SharedCompanies, email],
    ({ pageParam = 1 }: QueryFunctionContext) => {
      if (!email) return [];
      return fetchSharedCompanies({
        page: pageParam,
        items: 10,
        uid: email,
      });
    },
    {
      staleTime: getTimeInMs(2, 'minute'),
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = lastPage.length ? allPages.length + 1 : undefined;
        return nextPage;
      },
    },
  );
