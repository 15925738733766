import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import {
  BaseExceptions,
  getMessageFromErrorResponse,
  isBaseError,
} from '@/api/v4/base-fetch';
import {
  shareProject as shareProjectAPI,
  type ShareProjectBody,
} from '@/api/v4/projects.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

import { projectIdState } from '../../../projects/project.state';

export const useShareProject = () => {
  const { t } = useTranslation('default');
  const queryClient = useQueryClient();

  const projectId = useRecoilValue(projectIdState);

  const shareProjectMutation = useMutation(
    async (payload: ShareProjectBody) => {
      if (!projectId) {
        return;
      }
      return shareProjectAPI(projectId, payload);
    },
    {
      onSuccess: async () => {
        notify({ message: t`share.shareProjectSuccess`, withIcon: true });

        await Promise.all([
          queryClient.invalidateQueries([QueryKey.Project, projectId]),
          queryClient.invalidateQueries([QueryKey.TeamOptions]),
          queryClient.invalidateQueries([QueryKey.Users, projectId]),
        ]);

        queryClient.removeQueries([QueryKey.TeamOptions]);
        queryClient.removeQueries([QueryKey.Users, projectId]);
      },

      onError: (error: unknown) => {
        if (isBaseError(error)) {
          if (error.response.status === BaseExceptions.BAD_REQUEST) {
            const message = getMessageFromErrorResponse(error);
            notify({ message: message ?? t`unknownError` });
            return;
          }
        }
        notify({ message: t`unknownError` });
      },
    },
  );

  const shareProject = (payload: ShareProjectBody) =>
    shareProjectMutation.mutateAsync(payload);

  return {
    shareProject,
    isError: shareProjectMutation.isError,
  };
};
