import type { ReactNode } from 'react';
import { useContext, createContext } from 'react';
import { DndProvider } from 'react-dnd-cjs';
import HTML5Backend from 'react-dnd-html5-backend-cjs';
import TouchBackend from 'react-dnd-touch-backend-cjs';

const draggableBackend = {
  html5: HTML5Backend,
  touch: TouchBackend,
};

const DraggableContext = createContext<
  { backendType: keyof typeof draggableBackend } | undefined
>(undefined);

export const DraggableProvider = ({
  children,
  type = 'html5',
}: {
  children: ReactNode;
  type?: keyof typeof draggableBackend;
}) => {
  return (
    // @ts-ignore: Property 'children' does not exist on type 'IntrinsicAttributes & DndProviderProps<any, any>'.
    // DndProvider is built on React 17, where FC type includes "children" property by default, while in React 18 it is not included
    // Therefore we can ignore this error as "children" is handled by DndProvider anyway.
    <DndProvider
      context={window}
      backend={draggableBackend[type]}
      options={{ enableMouseEvents: true }}
    >
      <DraggableContext.Provider value={{ backendType: type }}>
        {children}
      </DraggableContext.Provider>
    </DndProvider>
  );
};

export const useDraggableContext = () => {
  const context = useContext(DraggableContext);
  if (!context) {
    throw new Error(
      'useDraggableContext must be used within a DraggableProvider',
    );
  }
  return context;
};
