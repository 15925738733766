import { useMutation, useQueryClient } from '@tanstack/react-query';

import { MutationKey, QueryKey } from '@/config/query-client';
import { shareOrganizationFile } from '@/api/v4/organization-files.api';
import { companyIdState } from '../../company.state';
import { useRecoilValue } from 'recoil';
import type { ShareFilePayload } from '@/features/action-panel/files/edit-file';

export const useShareCompanyFile = () => {
  const queryClient = useQueryClient();
  const companyId = useRecoilValue(companyIdState);

  const mutation = useMutation(
    async ({ fileId, ...payload }: ShareFilePayload) => {
      if (!companyId) {
        return;
      }
      return shareOrganizationFile(companyId, fileId, payload);
    },
    {
      mutationKey: [MutationKey.CompanyFileShare],
    },
  );

  return {
    shareCompanyFile: (payload: ShareFilePayload) =>
      mutation.mutateAsync(payload, {
        onSuccess: async (_data, variables) => {
          const params = {
            withAccessToOrganizationFile: variables.fileId,
          };

          await queryClient.invalidateQueries([QueryKey.CompanyFiles]);
          await queryClient.invalidateQueries([QueryKey.Users, params]);
          await queryClient.invalidateQueries([QueryKey.TeamOptions, params]);
        },
      }),
  };
};
