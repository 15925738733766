import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { getMessageFromErrorResponse, isBaseError } from '@/api/v4/base-fetch';
import type { InviteUsersPayload } from '@/api/v4/user.api';
import { inviteUsers } from '@/api/v4/user.api';
import { notify } from '@/ui/snackbar/notify';

import { useInvitedUsers } from './use-invited-users';

export const useInviteUser = () => {
  const { t } = useTranslation('default');

  const { refreshUsers } = useInvitedUsers();

  const inviteUserMutation = useMutation(inviteUsers, {
    onError: (error: Error) => {
      if (isBaseError(error)) {
        if (error.response.status === 400) {
          const message = getMessageFromErrorResponse(error);
          notify({ message: message ?? t`default:unknownError` });
          return;
        }
      }

      notify({ message: t`default:unknownError` });
    },
    onSuccess: async () => {
      await refreshUsers();
      notify({ message: t`invite.inviteSuccess`, withIcon: true });
    },
  });

  const inviteUser = async (payload: InviteUsersPayload) =>
    await inviteUserMutation.mutateAsync(payload);

  return {
    inviteUser,
    isLoading: inviteUserMutation.isLoading,
    isError: inviteUserMutation.isError,
  };
};
