import type { ComponentType } from 'react';
import { styled } from 'goober';

import { Flexbox } from '../flexbox/flexbox';
import { Icon } from '../icons/icon';
import { Header as TypographyHeader } from '../typography/widgets';

interface HeaderProps {
  header?: string;
  closeIcon: boolean;
  onClose: () => void;
  CustomHeaderComponent?: ComponentType;
}

export const Header = ({
  header,
  onClose,
  closeIcon = true,
  CustomHeaderComponent,
}: HeaderProps) => {
  const CloseIcon = <Icon icon="Close" onClick={onClose} />;

  const HeaderComponent = CustomHeaderComponent ?? TypographyHeader;

  if (!header && !closeIcon) {
    return null;
  }

  if (!header) {
    return <AbsoluteIcon>{CloseIcon}</AbsoluteIcon>;
  }

  return (
    <Flexbox
      data-testid="page-modal-header-container"
      name="modal-header-container"
      justify="space-between"
      alignItems="flex-start"
      fullWidth
      padding={header ? `0 0 ${MODAL_HEADER_PADDING_BOTTOM}px 0` : '0'}
    >
      <HeaderComponent className="header">{header}</HeaderComponent>
      {closeIcon && <IconWrapper>{CloseIcon}</IconWrapper>}
    </Flexbox>
  );
};

const MODAL_HEADER_PADDING_BOTTOM = 12;

const AbsoluteIcon = styled('div')`
  position: absolute;
  top: 37px;
  right: 37px;
`;

const IconWrapper = styled('div')`
  margin-left: auto;
  padding: 5px;
`;
