import { atom, selector } from 'recoil';

import type { CompanyFreeTextField } from '@/api/v4/organization.api';
import { fetchCompanyFreeTextFields } from '@/api/v4/organization.api';

import { companyIdState } from '../company.state';

type CompanyFreeTextFieldForm = Nullable<CompanyFreeTextField> & {
  id: string;
  synced: boolean;
};

export const companyFreeTextFields = atom<CompanyFreeTextFieldForm[]>({
  key: 'companyFreeTextFields',
  default: [],
});

export const companyFreeTextFieldsEditModeState = atom<string | null>({
  key: 'companyFreeTextFieldsEditModeState',
  default: null,
});

export const companyFreeTextFieldsState = selector<CompanyFreeTextField[]>({
  key: 'companyFreeTextFieldsState',
  get: ({ get }) => {
    const companyId = get(companyIdState);
    if (!companyId) return [];

    return fetchCompanyFreeTextFields(companyId);
  },
});
