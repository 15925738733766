import { useRef } from 'react';
import { components } from 'react-select';

import { isEllipsis } from '@/helpers/other';
import type { TeamBasicOption } from '@/types';
import { Icon } from '@/ui/icons/icon';
import { SquareLogo } from '@/ui/logo/logo';
import {
  OptionContainer,
  OptionLabel,
} from '@/ui/select/async/async-select.styles';
import type { AsyncSelectOptionProps } from '@/ui/select/async/components/option/option';
import { SLIDING_SIDEBAR_WIDTH } from '@/ui/sliding-sidebar/sliding-sidebar';
import { Tooltip } from '@/ui/tooltip/tooltip';

interface TeamBasicOptionProps extends AsyncSelectOptionProps {
  data: TeamBasicOption;
}

export function TeamBasicOption({ data, ...props }: TeamBasicOptionProps) {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <components.Option {...props} data={data}>
      <Tooltip
        placement="left"
        maxWidth={`calc(100vw - ${SLIDING_SIDEBAR_WIDTH})`}
        content={data.label}
        visible={isEllipsis(ref.current)}
      >
        <OptionContainer
          isSelected={props.isSelected}
          style={{ height: '60px' }}
        >
          <SquareLogo name={data.label ?? ''} />
          <OptionLabel ref={ref}>{data.label}</OptionLabel>
          {props.withCheckIconOnSelect && props.isSelected && (
            <Icon icon="Check" width="18px" />
          )}
        </OptionContainer>
      </Tooltip>
    </components.Option>
  );
}
