import { Outlet, RouterProvider } from 'react-router-dom';
import { useRouter } from './use-router';
import { Loader } from '@/components/loader/loader';
import { Suspense } from 'react';

export const AppRouter = () => {
  const router = useRouter();

  return (
    <RouterProvider
      router={router}
      fallbackElement={
        <Suspense fallback={<Loader isFullPage />}>
          <Outlet />
        </Suspense>
      }
    />
  );
};
