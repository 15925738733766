import { v3api, v3paths } from './v3';

export type UseCaseFitPayload = {
  use_case_fit: string;
  project_listing_id: string;
};

export const createUseCaseFit = (data: UseCaseFitPayload) =>
  v3api.post(v3paths.useCaseFits(), data);

export const updateUseCaseFit = ({
  projectListingId,
  payload,
}: {
  projectListingId: string;
  payload: UseCaseFitPayload;
}) => v3api.put(v3paths.useCaseFit({ projectListingId }), payload);

export const deleteUseCaseFit = (projectListingId: string) =>
  v3api.remove(v3paths.useCaseFit({ projectListingId }));
