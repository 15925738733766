import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import type {
  ProjectCompaniesSort,
  ProjectListing,
} from '@/api/v4/projects.api';
import { fetchProjectListings } from '@/api/v4/projects.api';
import { QueryKey } from '@/config/query-client';
import type { ISort } from '@/ui/table/table.types';

import { projectListingSortState } from '../project-listing.state';
import { getTimeInMs } from '@/helpers/get-time-in-ms';

export const useProjectListings = (
  projectId?: string | null,
  sort?: ISort<ProjectCompaniesSort>,
  enabled = true,
) => {
  const projectListingsSort = useRecoilValue(projectListingSortState);
  const sortParams = sort ?? projectListingsSort;

  const queryKey = useMemo(
    () => [
      QueryKey.ProjectListings,
      projectId,
      ...(sortParams ? [sortParams] : []),
    ],
    [projectId, sortParams],
  );

  const fetchListings = async () => {
    if (!projectId) return [];

    return fetchProjectListings(projectId, sortParams);
  };

  return useQuery<ProjectListing[]>({
    queryKey,
    queryFn: fetchListings,
    enabled: Boolean(projectId) && enabled,
    staleTime: getTimeInMs(5, 'minute'),
  });
};
