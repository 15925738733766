import { selectedCompaniesState } from '@/features/companies/companies.state';
import { modalState } from '@/state/modal.state';
import { IconButton } from '@/ui/button/icon-button';
import { Inline } from '@/ui/line/line';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import { useSetRecoilState } from 'recoil';

export const CompanyActions = ({
  companyId,
  companyName,
}: {
  companyId: string;
  companyName: string;
}) => {
  const setModalState = useSetRecoilState(modalState);
  const { hasRequiredPermission } = useUserPermissions();
  const setSelectedCompanies = useSetRecoilState(selectedCompaniesState);

  return (
    <Inline alignItems="center" justifyContent="center">
      {hasRequiredPermission(PERMISSION.ADD_COMPANIES_TO_PROJECTS) && (
        <IconButton
          variant="primary"
          icon="Plus"
          onClick={e => {
            e.stopPropagation();
            setSelectedCompanies([{ organizationId: companyId }]);
            setModalState({ state: 'addCompanyToProjects', companyName });
          }}
        />
      )}

      {hasRequiredPermission(PERMISSION.DELETE_ORGANIZATION) && (
        <IconButton
          variant="dangerText"
          icon="Bin"
          size="14px"
          onClick={e => {
            e.stopPropagation();
            setModalState({
              state: 'deleteCompany',
              company: {
                id: companyId,
                name: companyName,
              },
            });
          }}
        />
      )}
    </Inline>
  );
};
