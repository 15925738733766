import type { ListingStage } from '@/api/v4/project-listings.api';

type ListingStageBase = Omit<ListingStage, 'displayName' | 'sortOrder'>;

export const getListingStageName = (
  listingStage: ListingStageBase,
  listingStages: ListingStage[],
) => {
  const stage = listingStages.find(({ id }) => id === listingStage.id);

  if (!stage) {
    throw new Error(
      `Unknown stage: ${listingStage.id}, listing stages data: ${listingStages}`,
    );
  }

  return stage.displayName ?? stage.name ?? listingStage.name;
};
