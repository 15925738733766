import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';
import type { IconVariant } from '@/ui/icons/icon';
import { Icon } from '@/ui/icons/icon';

interface LocationBoxProps {
  name: string;
  address: string;
  icon: IconVariant;
  margin?: string;
}

export const LocationBox = ({
  name,
  address,
  icon,
  margin,
}: LocationBoxProps) => {
  return (
    <Wrapper
      name="location-box-wrapper"
      gap="18px"
      padding="26px 30px 36px 30px"
      margin={margin}
    >
      <StyledIcon icon={icon} centered={false} />
      <div>
        <Name>{name}</Name>
        <Address>{address}</Address>
      </div>
      <DirectionArrowWrapper>
        <Icon icon="DirectionArrow" centered={false} />
      </DirectionArrowWrapper>
    </Wrapper>
  );
};

const StyledIcon = styled(Icon)`
  margin-top: 3px;
`;

const Wrapper = styled(Flexbox)<{ margin?: string }>`
  position: relative;
  width: 290px;
  ${({ margin }) => margin && `margin: ${margin};`}
  border-radius: 34.6988px;
  background-color: ${({ theme }) => theme.colors.basics.white};
  box-shadow: ${({ theme }) => theme.shadow.authPage.locationBox};
`;

const Name = styled('h6')`
  margin-bottom: 4px;
  ${({ theme }) => theme.typography.authPage.location.name}
  color: ${({ theme }) => theme.colors.basics.black};
`;

const Address = styled('p')`
  ${({ theme }) => theme.typography.authPage.location.address}
`;

const DirectionArrowWrapper = styled('div')`
  position: absolute;
  bottom: -36px;
  left: 16px;
`;
