import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import type { OrganizationActionResponse } from '@/api/v4/organization-actions-info';
import { fetchOrganizationActionsInfo } from '@/api/v4/organization-actions-info';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

import { companyIdState } from '../company.state';

export const useCompanyActionsInfo = () => {
  const { t } = useTranslation('default');
  const organizationId = useRecoilValue(companyIdState);

  const fetchCompanyActionsInfo =
    async (): Promise<OrganizationActionResponse> => {
      if (!organizationId)
        return { comments: 0, meetings: 0, files: 0, total: 0 };

      return fetchOrganizationActionsInfo(organizationId);
    };

  return useQuery(
    [QueryKey.CompanyActionsInfo, organizationId],
    fetchCompanyActionsInfo,
    {
      onError: () =>
        notify({
          message: t`default:unknownError`,
        }),
      enabled: !!organizationId,
      staleTime: 1000 * 60 * 5,
    },
  );
};
