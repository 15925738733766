import type { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import { range } from 'lodash-es';

import i18n from '@/lib/i18n/i18n';
import { theme } from '@/theme/setupTheme';

import { Flexbox } from '../flexbox/flexbox';
import type { SelectProps } from '../select/select';
import { Select } from '../select/select';
import { SelectOption } from '../select/select-option';

import {
  ButtonDecreaseMonth,
  ButtonIncreaseMonth,
  DatePickerHeader,
} from './date-picker.styles';

type TDatePickerCustomHeader = ReactDatePickerCustomHeaderProps & {
  isRange?: boolean;
};

export function DatePickerCustomHeader({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  isRange = false,
}: TDatePickerCustomHeader) {
  const offset = isRange ? 2 : 0;
  const years = range(1980, getYear(new Date()) + 1 + offset, 1).reverse();
  const months = [...new Array(12)].map((_, index) =>
    i18n.t(`dates:months.month${index}`),
  );

  const defaultYear = {
    label: getYear(date).toString(),
    value: getYear(date).toString(),
  };
  const defaultMonth = {
    label: months[getMonth(date)],
    value: months[getMonth(date)],
  };

  const handleChangeMonth: SelectProps<string>['onChange'] = newValue =>
    newValue && changeMonth(months.indexOf(newValue.value));

  const handleChangeYear: SelectProps<string>['onChange'] = newValue =>
    !isNaN(Number(newValue?.value)) && changeYear(Number(newValue?.value));

  const dropdownStyles = (menuIsOpen: boolean) => ({
    borderRadius: '15px',
    padding: '4px 12px',
    ...(menuIsOpen
      ? { backgroundColor: theme.colors.blue.c4 }
      : { ':hover': { backgroundColor: theme.colors.blue.c8 } }),
  });

  return (
    <DatePickerHeader
      name="async-select-calendar-header"
      justify="space-between"
      alignItems="baseline"
    >
      <ButtonDecreaseMonth
        color={theme.colors.basics.black}
        icon="ArrowLeft"
        variant="text"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      />

      <Flexbox name="date-selector">
        <Select
          value={defaultMonth}
          options={months.map(month => ({ label: month, value: month }))}
          onChange={handleChangeMonth}
          containerStyles={{ borderBottom: 'none', width: '120px' }}
          components={{
            IndicatorsContainer: () => null,
            Option: SelectOption,
          }}
          styles={{
            singleValue: (base, props) => ({
              ...base,
              ...dropdownStyles(props.selectProps.menuIsOpen),
            }),
            menu: base => ({
              ...base,
              width: '150px',
              textAlign: 'left',
              marginLeft: '12px',
            }),
          }}
        />
        <Select
          value={defaultYear}
          options={years.map(year => ({ label: year, value: year.toString() }))}
          onChange={handleChangeYear}
          containerStyles={{ borderBottom: 'none', width: '80px' }}
          components={{
            IndicatorsContainer: () => null,
            Option: SelectOption,
          }}
          styles={{
            singleValue: (base, props) => ({
              ...base,
              ...dropdownStyles(props.selectProps.menuIsOpen),
            }),
            menu: base => ({
              ...base,
              width: '110px',
              textAlign: 'left',
              marginLeft: '12px',
            }),
          }}
        />
      </Flexbox>

      <ButtonIncreaseMonth
        color={theme.colors.basics.black}
        icon="ArrowLeft"
        variant="text"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      />
    </DatePickerHeader>
  );
}
