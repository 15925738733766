export enum APP_USER_ROLES {
  ADMIN = 'Admin',
  MANAGER = 'Manager',
  GUEST = 'Guest',
}

export interface SortParams {
  sort?: string;
  direction?: string;
}

export interface FilterParams {
  id?: string | string[];
  filter?: string[];
  hq_location?: string[];
  not_project?: string[];
  without_stream?: string;
  without_organization?: string;
  without_file?: string;
  without_team_file?: string;
  related_project?: string[];
  hq_location_city?: string[];
  exclude_by_id?: string[];
  created_by?: string[];
  hq_location_country?: string[];
  hq_location_region?: string[];
  status_id?: string;
  source?: string | string[];
  verified?: string;
  crf?: string;
  rating?: number;
  project_name?: string[];
  project_owner?: string[];
  project_id?: string | string[];
  account?: string[];
  account_owner?: string[];
  related_client?: string[];
  exclude_organization?: string;
  project?: string[];
  category?: string[];
  types?: string[];
  tags?: string[];
  record_source_id?: string[];
  record_medium_id?: string[];
  record_campaign?: string[];
  investors?: string[];
  location?: string[];
  locations?: string[];
  industry?: string[];
  funding_stage?: string[];
  product_stage?: string[];
  organization?: string[];
  meeting_organization?: string[];
  organization_id?: string;
  first_name?: string[];
  last_name?: string[];
  listing_status?: string[];
  project_label?: string[];
  title?: string[];
  status?: 'disabled' | 'active' | 'invited' | string[];
  activity_status?: string[];
  project_listing_id?: string;
  project_ids?: string[];
  role?: string[];
  role_id?: string[];
  meeting?: string[];
  'year_founded[min]'?: string;
  'year_founded[max]'?: string;
  'event_date[start_date]'?: string;
  'event_date[end_date]'?: string;
  'created_date[start_date]'?: string;
  'created_date[end_date]'?: string;
  stage?: string[];
  impacts?: string[];
  team?: string[];
  linked_only?: boolean;
}

export interface PageParams {
  page?: number;
  items?: number;
}

export interface SearchParams {
  search?: any;
  size?: number;
  'filters[search]'?: string;
}

export interface ContextParams {
  context?: 'project' | 'team' | 'organization' | 'meeting';
}

export interface ExtraParams {
  timestamp?: number; //this is mostly for cache busting
}

export interface IndexParams
  extends PageParams,
    SortParams,
    FilterParams,
    ContextParams,
    SearchParams,
    ExtraParams {}

export interface SignUpFetchInvitedUserParams {
  id: string;
  token: string;
}

export interface IPaginationHeaders {
  current: number;
  itemsPerPage: number;
  itemsTotal?: number;
  pagesTotal?: number;
}
