import { colors } from '@/theme/colors';
import { Icon } from '@/ui/icons/icon';

import { SuggestionContainer } from './suggestion-container';
import { SuggestionContent } from './suggestion-content';

export const ProjectSuggestion = ({
  onClick,
  name,
  type,
  highlights,
  listingsCount,
  isSelected,
  id,
}: {
  onClick?: () => void;
  name: string;
  type: string;
  highlights?: string[];
  listingsCount?: number;
  isSelected?: boolean;
  id: string;
}) => {
  return (
    <SuggestionContainer
      id={id}
      onClick={onClick}
      label={name}
      isSelected={isSelected}
    >
      <Icon
        icon="ProjectLogoDefault"
        color={colors.gray.c5}
        height="24px"
        width="24px"
        centered
      />
      <SuggestionContent
        name={name}
        type={type}
        highlights={highlights}
        listingsCount={listingsCount}
      />
    </SuggestionContainer>
  );
};
