import { useCurrentUser } from '../use-current-user.query';

import type { PRODUCT } from './product.type';

export const useUserProducts = () => {
  const { data: user } = useCurrentUser();
  const userProducts = user?.products ?? [];

  const hasRequiredProduct = (productName: PRODUCT): boolean =>
    userProducts.includes(productName);

  const hasRequiredProducts = (productNames: PRODUCT[]): boolean =>
    productNames.every(requiredProduct => hasRequiredProduct(requiredProduct));

  return {
    userProducts,
    hasRequiredProduct,
    hasRequiredProducts,
  };
};
