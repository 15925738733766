import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteProject } from '@/api/v4/projects.api';
import { QueryKey } from '@/config/query-client';

export const useDeleteProject = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteProject, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([QueryKey.ProjectsKpi]),
        queryClient.invalidateQueries([QueryKey.Projects]),
        queryClient.invalidateQueries([QueryKey.ProjectOptions]),
        queryClient.invalidateQueries([QueryKey.CompaniesStatistics]),
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);
    },
  });
};
