import { useQuery } from '@tanstack/react-query';

import type { AllUsersQueryParams } from '@/api/v4/user.api';
import { fetchAllUsers } from '@/api/v4/user.api';
import { QueryKey } from '@/config/query-client';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

export const useAllUsers = (params: AllUsersQueryParams) => {
  const { hasRequiredPermission } = useUserPermissions();

  return useQuery([QueryKey.UsersAll, params], () => fetchAllUsers(params), {
    staleTime: 1000 * 10 * 5,
    enabled: hasRequiredPermission(PERMISSION.VIEW_USER_MANAGEMENT),
  });
};
