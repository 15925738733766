import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  isAttendeeOption,
  isAttendeeOptionArray,
  isPlaceholderOption,
} from '@/helpers/other';
import { AttendeeOption } from '@/ui/select/async/components/option/attendee-option';
import type { AsyncSelectOptionProps } from '@/ui/select/async/components/option/option';
import { PlaceholderOption } from '@/ui/select/async/components/option/placeholder-option';

import { useAttendeesOptions } from '../hooks/use-attendees-options';
import type { MeetingFormFields } from '../hooks/use-meeting-form';
import { FormSelect } from '../ui/form-select';
import { WithLabel } from '../ui/with-label';
import type { MeetingTabContext } from '../meetings-tab';

export const AttendeesSelect = ({
  context,
}: {
  context: MeetingTabContext;
}) => {
  const { t } = useTranslation('default');
  const {
    setValue,
    watch,
    formState: { defaultValues },
  } = useFormContext<MeetingFormFields>();

  const { setOptions } = useAttendeesOptions({
    context,
  });

  const relatedCompanyId = watch('relatedCompanyId');
  const relatedTeamId = watch('relatedTeamId');

  const defaultAttendees = useMemo(() => {
    if (!defaultValues) {
      return [];
    }

    return defaultValues['attendees'];
  }, [defaultValues]);

  return (
    <WithLabel
      name="meeting-attendees"
      labelText={t`actionPanel.meetings.attendees`}
    >
      <FormSelect
        // https://stackoverflow.com/questions/54107238/clear-cached-options-on-async-select
        key={JSON.stringify({ relatedCompanyId, relatedTeamId })}
        isMulti
        defaultValue={defaultAttendees}
        setOptions={setOptions}
        components={{
          Option: AttendeeSelectOption,
        }}
        onChange={newAttendees => {
          isAttendeeOptionArray(newAttendees) &&
            setValue('attendees', newAttendees, {
              shouldDirty: true,
            });
        }}
      />
    </WithLabel>
  );
};

function AttendeeSelectOption({ data, ...props }: AsyncSelectOptionProps) {
  if (isAttendeeOption(data)) {
    return <AttendeeOption {...props} data={data} />;
  }

  if (isPlaceholderOption(data)) {
    return <PlaceholderOption {...props} data={data} />;
  }

  return null;
}
