/**
 * Custom hook that contains collection of functions that redirect to a specific route.
 *
 * Usage:
 *   `const navigateTo = useNavigateTo()`
 *   `navigateTo.projects({ projectId: 'abc-def });`
 */

import { mapValues } from 'lodash-es';
import type { NavigateOptions } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import type { PathParams } from '../helpers/paths';
import { paths } from '../helpers/paths';

export const useNavigateTo = () => {
  const navigate = useNavigate();

  return mapValues(
    paths,
    path => (args?: PathParams, options?: NavigateOptions) => {
      navigate(path(args), options && { ...options });
    },
  );
};
