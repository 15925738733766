import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import type { ProjectActionResponse } from '@/api/v4/project-actions-info';
import { fetchProjectActionsInfo } from '@/api/v4/project-actions-info';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

import { projectIdState } from '../project.state';

export const useProjectActionsInfo = () => {
  const { t } = useTranslation('default');
  const projectId = useRecoilValue(projectIdState);

  const fetchCompanyActionsInfo = async (): Promise<ProjectActionResponse> => {
    if (!projectId)
      return { updates: 0, comments: 0, meetings: 0, files: 0, total: 0 };

    return fetchProjectActionsInfo(projectId);
  };

  return useQuery(
    [QueryKey.ProjectActionsInfo, projectId],
    fetchCompanyActionsInfo,
    {
      onError: () =>
        notify({
          message: t`default:unknownError`,
        }),
      enabled: !!projectId,
      staleTime: 1000 * 60 * 5,
    },
  );
};
