import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';
import { colorIcon } from '@/ui/icons/functions';
import { Icon } from '@/ui/icons/icon';
import type { RadioProps } from '@/ui/radio/radio';
import { Radio } from '@/ui/radio/radio';

interface PrivacyStatusRadioProps extends RadioProps {
  status: 'public' | 'private';
}

export function PrivacyStatusRadio({
  status,
  label,
  ...props
}: PrivacyStatusRadioProps) {
  const { t } = useTranslation('default');
  const isPublic = status === 'public';

  return (
    <Radio
      {...props}
      inputHidden
      label={
        <Label name={`privacy-status-${status}`} gap="10px">
          <Flexbox name="privacy-status" gap="4px">
            <Icon icon={isPublic ? 'StatusPublic' : 'StatusPrivate'} />
            {t(isPublic ? 'share.public' : 'share.private')}
          </Flexbox>
          <div>{label}</div>
        </Label>
      }
    />
  );
}

const Label = styled(Flexbox)`
  :checked + & [data-name='privacy-status'] {
    color: ${({ theme }) => theme.colors.blue.primaryA};
  }

  :not(:checked) + & {
    color: ${({ theme }) => theme.colors.gray.c9};
    ${({ theme }) => colorIcon(theme.colors.gray.c9)}
  }
`;
