import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { ROUTES } from '@/routes/helpers/paths';

import { HomeHeader } from '../home/home-header';

import { CompaniesHeader } from './content/companies-header';
import { CompanyHeader } from './content/company-header/company-header';
import { ExploreResultsHeader } from './content/explore-results-header';
import { ProjectOverviewHeader } from './content/project-overview-header';
import { ProjectsHeader } from './content/projects-header';
import { SettingsHeader } from './content/settings-header';
import { TeamOverviewHeader } from './content/team-overview-header';
import { TeamsHeader } from './content/teams-header';

export const useTopbarContent = () => {
  const { pathname } = useLocation();

  const content = useMemo(
    () => [
      {
        routes: [ROUTES.home],
        component: HomeHeader,
      },
      {
        routes: [ROUTES.projects],
        component: ProjectsHeader,
      },
      {
        routes: [ROUTES.project],
        component: ProjectOverviewHeader,
      },

      {
        routes: [ROUTES.team],
        component: TeamOverviewHeader,
      },
      {
        routes: [ROUTES.company],
        component: CompanyHeader,
      },
      {
        routes: [ROUTES.companies],
        component: CompaniesHeader,
      },
      {
        routes: [ROUTES.teams],
        component: TeamsHeader,
      },
      {
        routes: [ROUTES.settings],
        component: SettingsHeader,
      },
      { routes: [ROUTES.exploreResults], component: ExploreResultsHeader },
    ],
    [],
  );

  return content.find(item =>
    item.routes.some(route => matchPath(route, pathname)),
  )?.component;
};
