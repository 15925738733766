import { useSetRecoilState } from 'recoil';

import { modalState } from '@/state/modal.state';

import { useDeleteMeeting } from './use-delete-meeting.mutation';

export const useHandleDeleteMeeting = ({
  onRefreshData,
}: {
  onRefreshData: () => void;
}) => {
  const setModal = useSetRecoilState(modalState);

  const { deleteMeeting, isDeleting } = useDeleteMeeting({ onRefreshData });

  const onDeleteHandler = (meetingId: string) => {
    setModal({
      state: 'confirmDelete',
      onConfirm: () => deleteMeeting(meetingId),
    });
  };

  return {
    onDeleteHandler,
    isDeleting,
  };
};
