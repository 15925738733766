import type { Ref, SyntheticEvent } from 'react';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Editor from '@draft-js-plugins/editor';
import type { MentionData } from '@draft-js-plugins/mention';
import createMentionPlugin from '@draft-js-plugins/mention';
import type { RawDraftContentState, EditorState } from 'draft-js';
import { convertToRaw } from 'draft-js';
import { styled } from 'goober';
import { theme } from '@/theme/setupTheme';

import {
  MentionPopoverContainer,
  MentionSuggestionItem,
  MentionText,
} from './mention-suggestion-item';

import '@draft-js-plugins/mention/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import { useEditor } from './use-editor';
import { useRichTextToolbar } from './use-rich-text-toolbar';

export type RichTextFieldRef = {
  reset: () => void;
};

export type RichTextFieldWithMentionProps = {
  onChange?: (value: string | null, mentionedUsers: number[]) => void;
  defaultValue: string | null;
  readOnly?: boolean;
  suggestions: MentionData[];
  onSearchChange: (searchPhrase: string) => void;
  placeholder?: string;
  onSubmit?: () => void;
  height?: string;
  onFocus?: (e: SyntheticEvent) => void;
  onBlur?: (e: SyntheticEvent) => void;
  richTextEditorRef?: Ref<RichTextFieldRef>;
  dataTestId?: string;
  allowFormating?: boolean;
};

const mapUserIdsFromContentState = (entity: RawDraftContentState) => {
  const mentionedEntries = Object.values(entity.entityMap);

  return mentionedEntries
    ?.filter(value => value?.type === 'mention')
    .map(value => value.data.mention.id);
};

export const RichTextFieldWithMention = ({
  onChange,
  defaultValue,
  suggestions,
  readOnly,
  onSearchChange,
  placeholder,
  onSubmit,
  height,
  onFocus,
  onBlur,
  richTextEditorRef,
  dataTestId,
  allowFormating = false,
}: RichTextFieldWithMentionProps) => {
  const ref = useRef<Editor>(null);

  const popupRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    editor,
    setEditor,
    resetEditor,
    myKeyBindingFn,
    handleKeyCommand,
    handleOnTab,
  } = useEditor({ onSubmit, defaultValue, readOnly });

  const { t } = useTranslation('default');

  const [isMentionBoxOpen, setIsMentionBoxOpen] = useState(false);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [popupHeight, setPopupHeight] = useState<number | undefined>(undefined);
  const [popupVisible, setPopupVisible] = useState<boolean>();

  const { plugins: toolbarPlugins, Toolbar } = useRichTextToolbar();

  const mentionPlugin = useMemo(
    () =>
      createMentionPlugin({
        mentionTrigger: ['@'],
        entityMutability: 'IMMUTABLE',
        supportWhitespace: true,
        mentionComponent: MentionText,
      }),
    [],
  );

  const { MentionSuggestions } = mentionPlugin;

  const plugins = useMemo(
    () => [...toolbarPlugins, mentionPlugin],
    [mentionPlugin, toolbarPlugins],
  );

  useEffect(() => {
    setPopupHeight(popupRef?.current?.clientHeight);
  }, [popupRef?.current?.clientHeight, suggestions.length, isMentionBoxOpen]);

  useEffect(() => {
    if (popupHeight) return;

    setPopupVisible(true);
  }, [popupHeight]);

  useImperativeHandle(richTextEditorRef, () => ({
    reset: () => {
      resetEditor();
    },
  }));

  const isPopoverOverflowing = useMemo(() => {
    if (!containerRef.current || !popupHeight) return false;
    const containerRect = containerRef.current.getBoundingClientRect();
    return containerRect.y + popupHeight + 32 > windowSize[1];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef?.current, windowSize, popupHeight]);

  const onOpenChange = (open: boolean) => setIsMentionBoxOpen(open);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const onStateChange = useCallback(
    (state: EditorState) => {
      setEditor(state);
      const raw: RawDraftContentState = convertToRaw(state.getCurrentContent());
      const mentionedUsers = mapUserIdsFromContentState(raw);
      if (!editor) return onChange?.(null, []);
      const currentContentState = editor.getCurrentContent();
      const newContentState = state.getCurrentContent();

      const hasSingleEmptyBlock =
        raw.blocks.length === 1 && raw.blocks[0].text === '';

      if (!currentContentState.equals(newContentState)) {
        if (hasSingleEmptyBlock) {
          onChange?.(null, []);
          resetEditor();
          return;
        }
        onChange?.(JSON.stringify(raw), mentionedUsers);
      }
    },
    [editor, onChange, resetEditor],
  );

  return (
    <RichTextWrapper
      ref={containerRef}
      onClick={() => {
        if (!ref.current) return;
        ref.current.focus();
      }}
      readOnly={readOnly}
      data-testid={dataTestId}
      height={height}
    >
      {editor ? (
        <>
          <Editor
            ref={ref}
            editorKey=""
            editorState={editor}
            onChange={onStateChange}
            onTab={handleOnTab}
            placeholder={
              placeholder ?? t`actionPanel.meetings.meetingSummaryPlaceholder`
            }
            readOnly={readOnly}
            keyBindingFn={!isMentionBoxOpen ? myKeyBindingFn : undefined}
            handleKeyCommand={handleKeyCommand}
            plugins={plugins}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {popupVisible ? (
            <MentionSuggestions
              open={isMentionBoxOpen}
              onOpenChange={onOpenChange}
              suggestions={suggestions}
              onSearchChange={e => onSearchChange(e.value)}
              entryComponent={MentionSuggestionItem}
              popoverContainer={({ children }) => (
                <MentionPopoverContainer
                  ref={popupRef}
                  position={isPopoverOverflowing ? 'top' : 'bottom'}
                  height={popupHeight}
                >
                  {children}
                </MentionPopoverContainer>
              )}
            />
          ) : undefined}
          {allowFormating ? Toolbar : undefined}
        </>
      ) : null}
    </RichTextWrapper>
  );
};

const RichTextWrapper = styled('div', forwardRef)<{
  readOnly?: boolean;
  height?: string;
}>`
  background-color: ${({ theme }) => theme.colors.basics.white};
  transition: all 0.1s linear;
  resize: ${({ readOnly }) => (!readOnly ? 'vertical' : 'none')};
  height: ${({ readOnly, height }) =>
    height ? height : !readOnly ? '64px' : '100%'};
  min-height: 45px;
  width: 100%;
  overflow-y: auto;

  border: ${({ readOnly, theme }) =>
    !readOnly ? `2px solid ${theme.colors.gray.c2}` : 'none'};
  border-radius: 10px;
  display: flex;

  &:focus-within {
    border-color: ${theme.colors.blue.primaryA};
  }

  box-sizing: border-box;

  .DraftEditor-root {
    ${({ theme }) => theme.typography.inputs.textarea}
    padding: ${({ readOnly }) => (!readOnly ? '12px 16px' : undefined)};
    width: 100%;
    height: 100%;
    overflow-y: auto;
    color: ${({ theme }) => theme.colors.basics.black};
  }

  .DraftEditor-editorContainer {
    cursor: text;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .public-DraftEditor-content {
    height: 100%;
  }

  .public-DraftEditorPlaceholder-root {
    width: 360px;
    color: ${({ theme }) => theme.colors.gray.c11};
    font-family: ${({ theme }) => theme.fonts.neue};
    font-size: 12px;
    z-index: 0;
  }
`;
