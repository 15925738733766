import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { getUserInitials } from '@/helpers/get-user-initials';
import { openNewTab } from '@/helpers/open-new-tab';
import { paths } from '@/routes/helpers/paths';
import { colors } from '@/theme/colors';
import type { DropdownOption } from '@/ui/dropdown/dropdown';
import { WithDropdown } from '@/ui/dropdown/dropdown';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Inline, Stack } from '@/ui/line/line';
import { Logo } from '@/ui/logo/logo';
import { MenuButtonWithVisibilitySwitch } from '@/ui/menu-button/menu-button-with-visibility-switch';
import type { AsyncSelectProps } from '@/ui/select/async/async-select';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { UserName as TypographyUserName } from '@/ui/typography/across-platform';
import { SmallText } from '@/ui/typography/widgets';
import { WidgetBox } from '@/ui/widget-box/widget-box';

import { UsersMentionTextField } from '../../inputs/users-mention-text-field';
import { ActionPanelDate } from '../action-panel-date';

import type { EditCommentCallback } from './comment.type';
import type { Comment as CommentType } from './comment.type';
import { EditComment } from './edit-comment';
import { CopyActionItemUrlButton } from '../copy-action-item-url-button';

interface CommentProps {
  comment: CommentType;
  onClickDelete: () => void;
  onEditSubmit: EditCommentCallback<void>;
  dropdown?: AsyncSelectProps;
}
export const Comment = forwardRef<HTMLDivElement, CommentProps>(
  function Comment({ comment, onClickDelete, onEditSubmit, dropdown }, ref) {
    const { t } = useTranslation('default');
    const [isEditMode, setEditMode] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const menu: DropdownOption[] = [
      {
        label: t`actionPanel.comments.edit`,
        onClick: () => setEditMode(true),
      },
      {
        label: t`delete`,
        onClick: onClickDelete,
      },
    ];

    const submitEdit: EditCommentCallback<void> = async (commentId, data) => {
      await onEditSubmit(commentId, data);
      setEditMode(false);
    };

    return (
      <Widget
        onMouseEnter={() => !isEditMode && setIsHovered(true)}
        onMouseLeave={() => !isEditMode && setIsHovered(false)}
        ref={ref}
      >
        <Stack gap="20px">
          <Flexbox name="comment-header" justify="space-between">
            <Flexbox name="comment-author" gap="8px" alignItems="center">
              <CommentAuthorLogo
                bgColor={colors.blue.primaryA}
                name={getUserInitials(comment.author)}
              />
              <Stack gap="0px" maxWidth="300px">
                <EllipsisTextTooltip
                  text={`${comment.author.firstName} ${comment.author.lastName}`}
                  Component={<UserName />}
                />
                {comment.author.team ? (
                  <EllipsisTextTooltip
                    text={comment.author.team.name}
                    onClick={() =>
                      comment.author.team &&
                      openNewTab(paths.team({ teamId: comment.author.team.id }))
                    }
                    Component={<TeamLink />}
                  />
                ) : null}
              </Stack>
            </Flexbox>

            <Inline>
              {'projectListing' in comment && comment.projectListing ? (
                <Tooltip content={comment.projectListing.organization.name}>
                  <CommentOrganizationLogo
                    onClick={() => {
                      if (comment.projectListing)
                        openNewTab(
                          paths.company({
                            companyId: comment.projectListing.organization.id,
                          }),
                        );
                    }}
                    size="20px"
                    fontSize="9px"
                    singleLetter
                    name={comment.projectListing.organization.name}
                    logoUrl={
                      comment.projectListing.organization.logoUrl !== null
                        ? comment.projectListing.organization.logoUrl
                        : undefined
                    }
                  />
                </Tooltip>
              ) : null}
              <CopyActionItemUrlButton
                actionItemId={comment.id}
                actionItemType="comment"
              />
            </Inline>
          </Flexbox>

          <AbsoluteBox>
            <WithDropdown items={menu} options={{ placement: 'bottom-end' }}>
              <MenuButtonWithVisibilitySwitch isVisible={isHovered} />
            </WithDropdown>
          </AbsoluteBox>
          {isEditMode ? (
            <EditComment
              comment={comment}
              onSubmit={submitEdit}
              onCancel={() => setEditMode(false)}
              dropdown={dropdown}
            />
          ) : (
            <>
              <UsersMentionTextField
                defaultValue={comment.noteContent}
                readOnly
              />
              <ActionPanelDate date={comment.createdDate} relative />
            </>
          )}
        </Stack>
      </Widget>
    );
  },
);

const UserName = styled(TypographyUserName, forwardRef)`
  max-width: 300px;
  ${({ theme }) => theme.mixins.ellipsis};
  cursor: default;
`;

const Widget = styled(WidgetBox, forwardRef)`
  position: relative;
`;

const TeamLink = styled(SmallText, forwardRef)`
  max-width: 300px;
  ${({ theme }) => theme.mixins.ellipsis};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const AbsoluteBox = styled('div')`
  position: absolute;
  top: 38px;
  right: 10px;
`;

const CommentAuthorLogo = styled(Logo)`
  font-size: 12px;
`;

const CommentOrganizationLogo = styled(Logo)`
  background-color: ${({ theme }) => theme.colors.accent.green.c3};
  border: none;
  &:hover {
    cursor: pointer;
  }
`;
