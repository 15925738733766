import { useEffect, useRef } from 'react';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import { Icon } from '@/ui/icons/icon';

import { OptionContainer, OptionLabel } from './async/async-select.styles';
import type { SelectOptionValue } from './select';

export const SelectOption = (
  props: OptionProps<SelectOptionValue<string>, false>,
) => {
  const { checkOnSelect = true } = props.selectProps;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    props.isSelected &&
      ref.current.scrollIntoView({
        block: 'nearest',
        inline: 'start',
      });
  }, [ref, props.isSelected]);

  return (
    <components.Option {...props}>
      <OptionContainer ref={ref} isSelected={props.isSelected}>
        <OptionLabel>{props.data.label}</OptionLabel>
        {checkOnSelect && props.isSelected && (
          <Icon icon="Check" width="18px" />
        )}
      </OptionContainer>
    </components.Option>
  );
};
