import type { ProjectOption } from '@/features/action-panel/meetings/meetings.state';
import type { Entity } from '@/features/settings/settings.state';
import type { IOption, Option } from '@/types';
import type {
  ClientOption,
  CompanyOption,
  IAttendeeOption,
  CapitalRaisedOption,
  CompanyLocationOption,
  DateRangeOption,
  IntegrationOption,
  INumberOption,
  InvestorOption,
  InvitedUserOption,
  LocationOption,
  OrganizationOption,
  PlaceholderOption,
  TeamBasicOption,
  UserOption,
} from '@/types/controls';

const isError = (err: unknown): err is Error => err instanceof Error;

const isOfType = <T>(
  varToBeChecked: unknown,
  propertyToCheckFor: keyof T,
): varToBeChecked is T =>
  (varToBeChecked as T)?.[propertyToCheckFor] !== undefined;

const isDefaultOption = (option: unknown): option is IOption =>
  isOfType<IOption>(option, 'label') &&
  isOfType<IOption>(option, 'value') &&
  typeof option.value === 'string';
const isEntityOption = (option: unknown): option is IOption =>
  isOfType<Entity>(option, 'label') &&
  isOfType<Entity>(option, 'value') &&
  isOfType<Entity>(option, 'type');
const isDefaultOptionArray = (option: unknown): option is IOption[] =>
  Array.isArray(option) && option.every(isDefaultOption);
const isDefaultEntityArray = (option: unknown): option is Entity[] =>
  Array.isArray(option) && option.every(isEntityOption);

const isUserOption = (option: unknown): option is UserOption =>
  isOfType<UserOption>(option, 'email') &&
  isOfType<UserOption>(option, 'role') &&
  isOfType<UserOption>(option, 'status') &&
  isOfType<UserOption>(option, 'firstName') &&
  isOfType<UserOption>(option, 'lastName');

const isInvitedUserOption = (option: unknown): option is InvitedUserOption =>
  isOfType<InvitedUserOption>(option, 'email') &&
  isOfType<InvitedUserOption>(option, 'role') &&
  option.type === 'invited';

const isTeamBasicOption = (option: unknown): option is TeamBasicOption =>
  isOfType<IOption>(option, 'value') &&
  typeof option.value === 'string' &&
  isOfType<TeamBasicOption>(option, 'type') &&
  option.type === 'team';

/** @deprecated use isCompanyOption instead for v4 API */
const isOrganizationOption = (option: unknown): option is OrganizationOption =>
  isOfType<OrganizationOption>(option, 'logo') &&
  isOfType<OrganizationOption>(option, 'domain');

const isCompanyOption = (option: unknown): option is CompanyOption =>
  isOfType<CompanyOption>(option, 'logo') &&
  isOfType<CompanyOption>(option, 'domain');

const isInvestorOption = (option: unknown): option is InvestorOption =>
  isOfType<InvestorOption>(option, 'investorId') &&
  isOfType<InvestorOption>(option, 'crunchbaseId') &&
  isOfType<InvestorOption>(option, 'source');
const isInvestorOptionArray = (option: unknown): option is InvestorOption[] =>
  Array.isArray(option) && option.every(isInvestorOption);

const isClientOption = (option: unknown): option is ClientOption =>
  isOfType<ClientOption>(option, 'clientId') &&
  isOfType<ClientOption>(option, 'source');

const isClientOptionArray = (option: unknown): option is ClientOption[] =>
  Array.isArray(option) && option.every(isClientOption);

const isIntegrationOption = (option: unknown): option is IntegrationOption =>
  isOfType<IntegrationOption>(option, 'integrationId') &&
  isOfType<IntegrationOption>(option, 'source');
const isIntegrationOptionArray = (
  option: unknown,
): option is IntegrationOption[] =>
  Array.isArray(option) && option.every(isIntegrationOption);
const isLocationOption = (option: unknown): option is LocationOption =>
  isOfType<LocationOption>(option, 'type') &&
  Boolean(
    option.type.match('country|state|city|region|continent|organization'),
  ) &&
  Array.isArray(option.hierarchy);
const isDateRangeOption = (option: unknown): option is DateRangeOption =>
  isOfType<DateRangeOption>(option, 'start') &&
  isOfType<DateRangeOption>(option, 'end');
const isCompanyLocationOption = (
  option: unknown,
): option is CompanyLocationOption =>
  isOfType<CompanyLocationOption>(option, 'value') &&
  isOfType<CompanyLocationOption['value']>(option['value'], 'cityId');

const isAttendeeOption = (option: unknown): option is IAttendeeOption =>
  isOfType<IAttendeeOption>(option, 'entity') &&
  isOfType<IAttendeeOption>(option, 'type');
const isAttendeeOptionArray = (option: unknown): option is IAttendeeOption[] =>
  Array.isArray(option) && option.every(isAttendeeOption);

const isProjectOption = (option: unknown): option is ProjectOption =>
  isOfType<ProjectOption>(option, 'value') &&
  isOfType<ProjectOption>(option, 'createdFor');

const isCapitalRaisedOption = (
  option: unknown,
): option is CapitalRaisedOption =>
  isOfType<CapitalRaisedOption>(option, 'label') &&
  isOfType<CapitalRaisedOption['value']>(option['value'], 'min') &&
  isOfType<CapitalRaisedOption['value']>(option['value'], 'max');

const isCapitalRaisedOptionArray = (
  option: unknown,
): option is CapitalRaisedOption[] =>
  Array.isArray(option) && option.every(isCapitalRaisedOption);

const isNumberOption = (option: unknown): option is INumberOption =>
  isOfType<INumberOption>(option, 'label') &&
  isOfType<INumberOption>(option, 'value') &&
  typeof option.value === 'number';
const isNumberOptionArray = (option: unknown): option is INumberOption[] =>
  Array.isArray(option) && option.every(isNumberOption);

const isPlaceholderOption = (option: unknown): option is PlaceholderOption =>
  isOfType<PlaceholderOption>(option, 'label') &&
  isOfType<PlaceholderOption>(option, 'value') &&
  isOfType<PlaceholderOption>(option, 'type') &&
  option.type === 'placeholder';

const isOption = (
  option:
    | UserOption
    | OrganizationOption
    | CompanyOption
    | UserOption
    | InvitedUserOption
    | TeamBasicOption
    | LocationOption
    | ClientOption
    | InvestorOption
    | DateRangeOption
    | IAttendeeOption
    | INumberOption
    | unknown,
): option is Option => {
  return (
    (option as Option)?.value !== undefined &&
    (isDefaultOption(option) ||
      isDefaultOptionArray(option) ||
      isNumberOption(option) ||
      isNumberOptionArray(option) ||
      isUserOption(option) ||
      isInvitedUserOption(option) ||
      isTeamBasicOption(option) ||
      isOrganizationOption(option) ||
      isLocationOption(option) ||
      isClientOption(option) ||
      isIntegrationOption(option) ||
      isIntegrationOptionArray(option) ||
      isInvestorOption(option) ||
      isCapitalRaisedOption(option) ||
      isCapitalRaisedOptionArray(option) ||
      isDateRangeOption(option))
  );
};

const isEllipsis = (el: HTMLElement | null) =>
  (el?.offsetWidth || 0) < (el?.scrollWidth || 0);

const isInViewportTop = (el: HTMLElement | null) => {
  if (!el) return false;
  const rect = el.getBoundingClientRect();
  return rect.top >= 0;
};

const isInViewportBottom = (el: HTMLElement | null) => {
  if (!el) return false;
  const rect = el.getBoundingClientRect();
  return (
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
};

const isInViewportRight = (el: HTMLElement | null) => {
  if (!el) return false;
  const rect = el.getBoundingClientRect();
  return (
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const isInViewport = (el: HTMLElement | null) => {
  if (!el) return false;
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

// create a new type with renamed keys of another type
// https://stackoverflow.com/a/71912306
export type RenameByT<T, U> = {
  [K in keyof U as K extends keyof T
    ? T[K] extends string
      ? T[K]
      : never
    : K]: K extends keyof U ? U[K] : never;
};

// type Object.entries(), so [key, value] has typings
// https://stackoverflow.com/a/60142095
export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type NonUndefined<T> = T extends undefined ? never : T;

export {
  isAttendeeOption,
  isAttendeeOptionArray,
  isCapitalRaisedOption,
  isCapitalRaisedOptionArray,
  isClientOption,
  isCompanyLocationOption,
  isCompanyOption,
  isDateRangeOption,
  isDefaultEntityArray,
  isDefaultOption,
  isDefaultOptionArray,
  isEllipsis,
  isError,
  isIntegrationOption,
  isIntegrationOptionArray,
  isInvestorOption,
  isInvestorOptionArray,
  isInViewport,
  isInViewportBottom,
  isInViewportRight,
  isInViewportTop,
  isInvitedUserOption,
  isLocationOption,
  isNumberOption,
  isNumberOptionArray,
  isOfType,
  isOption,
  isOrganizationOption,
  isPlaceholderOption,
  isProjectOption,
  isTeamBasicOption,
  isUserOption,
  isClientOptionArray,
};
