import type { RecoilValue } from 'recoil';
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil';

import { usePreviousWhileLoading } from './use-previous-while-loading';

export function useLoadableResults<T>(state: RecoilValue<T>): T | null {
  const result = usePreviousWhileLoading(useRecoilValueLoadable(state));

  if (result.state === 'hasValue' && result.contents) {
    return result.contents;
  }

  return null;
}

export function useLoadableData<T>(state: RecoilValue<T[]>): {
  data: T[];
  isLoading: boolean;
  refreshData: () => void;
} {
  const result = useRecoilValueLoadable(state);
  const refreshData = useRecoilRefresher_UNSTABLE(state);

  const current = usePreviousWhileLoading(result);

  return {
    data:
      result.state === 'hasValue' && result.contents
        ? result.contents
        : current.state === 'hasValue' && current.contents
        ? current.contents
        : [],
    isLoading: result.state === 'loading',
    refreshData,
  };
}
