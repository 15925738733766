import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';
import { colorIcon } from '@/ui/icons/functions';
import { Icon } from '@/ui/icons/icon';

import { PrivacyStatusRadio } from './privacy-status-radio';

interface PrivacyStatusProps {
  defaultValue?: boolean;
  publicLabel: string | ReactNode;
  privateLabel: string | ReactNode;
  onChange?: (isPublic: boolean) => void;
  className?: string;
}

export function PrivacyStatus({
  defaultValue,
  publicLabel,
  privateLabel,
  onChange,
  className,
}: PrivacyStatusProps) {
  const [isPublic, setIsPublic] = useState(false);

  useEffect(() => {
    if (defaultValue === undefined) return;

    setIsPublic(defaultValue);
  }, [defaultValue, setIsPublic]);

  const onStatusChange = (isPublic: boolean) => {
    setIsPublic(isPublic);
    onChange && onChange(isPublic);
  };
  if (defaultValue === undefined) {
    return (
      <PrivacyInfo label={publicLabel || privateLabel} className={className} />
    );
  }

  return (
    <Flexbox
      name="privacy-status"
      direction="column"
      gap="12px"
      className={className}
    >
      <PrivacyStatusRadio
        name="privacy-status-radio-public"
        status="public"
        onChange={() => onStatusChange(true)}
        checked={Boolean(isPublic)}
        label={publicLabel}
      />
      <PrivacyStatusRadio
        name="privacy-status-radio-private"
        status="private"
        onChange={() => onStatusChange(false)}
        checked={!Boolean(isPublic)}
        label={privateLabel}
      />
    </Flexbox>
  );
}

const PrivacyInfo = ({
  label,
  className,
}: {
  label: React.ReactNode;
  className?: string;
}) => {
  return (
    <PrivacyInfoContainer
      name="privacy-info"
      gap="10px"
      alignItems="center"
      className={className}
    >
      <Icon icon="Info" width="14px" height="14px" />
      <div>{label}</div>
    </PrivacyInfoContainer>
  );
};

const PrivacyInfoContainer = styled(Flexbox)`
  ${({ theme }) => theme.typography.acrossPlatform.small}
  ${({ theme }) => colorIcon(theme.colors.gray.c13)}
  color: ${({ theme }) => theme.colors.gray.c13};
`;
