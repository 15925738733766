import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Loader } from '@/components/loader/loader';
import { Stack } from '@/ui/line/line';

import { InfiniteAssetsList } from '../../../search/infinite-assets-list';
import { useNavigateToSearchItemPage } from '../../../search/use-navigate-to-search-item-page';

import { EmptyState } from './empty-state';
import { useSharedCompanies } from './use-shared-companies.query';
import { useSharedProjects } from './use-shared-projects.query';

export const SharedAssets = ({ userEmail }: { userEmail: string }) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'sharedAssets',
  });

  const {
    data,
    hasNextPage: hasCompaniesNextPage,
    isLoading: isLoadingCompanies,
    fetchNextPage: fetchCompaniesNextPage,
  } = useSharedCompanies(userEmail);

  const companies = data ? data.pages.flat() : [];

  const {
    data: projectsData,
    hasNextPage: hasProjectsNextPage,
    isLoading: isLoadingProjects,
    fetchNextPage: fetchProjectsNextPage,
  } = useSharedProjects(userEmail);

  const projects = projectsData ? projectsData.pages.flat() : [];

  const navigateToSearchItemPage = useNavigateToSearchItemPage();

  const hasNoSharedAssets =
    !isLoadingCompanies && companies.length < 1 && projects.length < 1;

  if (hasNoSharedAssets) {
    return <EmptyState />;
  }

  return (
    <Container gap="28px" isLoading={isLoadingCompanies}>
      {isLoadingCompanies ? (
        <Loader />
      ) : (
        <>
          {Boolean(projects.length) && (
            <>
              <ListTitle>{t`projects`}</ListTitle>
              <InfiniteAssetsList
                items={projects}
                hasMore={Boolean(hasProjectsNextPage)}
                next={fetchProjectsNextPage}
                defaultIcon="ProjectLogoDefault"
                onClickHandler={id =>
                  navigateToSearchItemPage({ type: 'project', id })
                }
                isLoading={isLoadingProjects}
              />
            </>
          )}
          {Boolean(companies.length) && (
            <>
              <ListTitle>{t`companies`}</ListTitle>
              <InfiniteAssetsList
                items={companies}
                hasMore={Boolean(hasCompaniesNextPage)}
                next={fetchCompaniesNextPage}
                onClickHandler={id =>
                  navigateToSearchItemPage({ type: 'company', id })
                }
                isLoading={isLoadingCompanies}
              />
            </>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled(Stack)<{ isLoading?: boolean }>`
  margin-top: 12px;
  ${({ isLoading }) => isLoading && `height: 300px;`}
`;

const ListTitle = styled('h5')`
  ${({ theme }) => theme.typography.companyInfo.tableHeader}
`;
