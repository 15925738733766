import type { Tag } from '@/api/v4/tags.api';

import type { TagCategory } from '../tag-tabs.config';

export const filterTagsByCategory = (tags: Tag[], category?: TagCategory) => {
  if (!category) return tags;

  const mainCategories = ['Sector', 'Industry', 'Sub Industry', 'Technology'];

  if (category === 'Other')
    return tags.filter(tag => !mainCategories.includes(tag.categoryName));

  return tags.filter(tag => tag.categoryName === category);
};
