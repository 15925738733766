import type {
  APIRequestBody,
  APIRequestQueryParams,
  APIResponse,
} from './base-fetch';
import { get, post, remove } from './base-fetch';

export type CompanyContactList =
  APIResponse<'/api/v4/organizations/{organizationId}/contacts'>;

export type CompanyContact =
  | APIResponse<'/api/v4/organizations/{organizationId}/contacts/create'>[number]
  | APIResponse<'/api/v4/organizations/{organizationId}/contacts/edit'>[number];

export type CompanyContactQueryParams =
  APIRequestQueryParams<'/api/v4/organizations/{organizationId}/contacts'>;

export const fetchCompanyContacts = async (
  organizationId: string,
  params: CompanyContactQueryParams,
) =>
  get<CompanyContactList>(`/v4/organizations/${organizationId}/contacts`, {
    params,
  });

export type CreateCompanyContactBodyPayload =
  APIRequestBody<'/api/v4/organizations/{organizationId}/contacts/create'>;

export interface CreateCompanyContactPayload {
  companyId: string;
  data: CreateCompanyContactBodyPayload;
}

export const createCompanyContacts = async ({
  companyId,
  data,
}: CreateCompanyContactPayload) =>
  post<CompanyContact[]>(
    `/v4/organizations/${companyId}/contacts/create`,
    data,
  );

export type EditCompanyContactBodyPayload =
  APIRequestBody<'/api/v4/organizations/{organizationId}/contacts/edit'>;

export type EditCompanyContactPayload = {
  companyId: string;
  data: EditCompanyContactBodyPayload;
};

export const editCompanyContacts = async ({
  companyId,
  data,
}: EditCompanyContactPayload) =>
  post<CompanyContact[]>(`/v4/organizations/${companyId}/contacts/edit`, data);

export type DeleteCompanyContactPayload = {
  companyId: string;
  companyContactId: string;
};

export const deleteCompanyContact = async ({
  companyContactId,
  companyId,
}: DeleteCompanyContactPayload) =>
  remove(`/v4/organizations/${companyId}/contacts/${companyContactId}/delete`);
