import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { modalState } from '@/state/modal.state';
import { Modal } from '@/ui/modal/modal';

import { useDeleteProject } from './use-delete-project';
import { notify } from '@/ui/snackbar/notify';

export const DeleteProjectModal = () => {
  const [modal, setModal] = useRecoilState(modalState);
  const navigateTo = useNavigateTo();

  const { t } = useTranslation(['default', 'projects']);
  const { mutateAsync: deleteProject, isLoading } = useDeleteProject();

  if (!modal || modal.state !== 'deleteProject') return null;

  const close = () => {
    setModal(null);
  };

  const handleDeleteProject = async (projectId: string) => {
    const result = await deleteProject(projectId);

    close();

    if (result.errors.length > 0) {
      notify({
        message: t`projects:deleteError`,
      });
      return;
    }

    navigateTo.projects();
  };

  return (
    <Modal
      header={t`default:areYouSure`}
      onClose={close}
      mainAction={{
        label: t`default:delete`,
        onClick: handleDeleteProject.bind(null, modal.projectId),
      }}
      secondaryAction={{
        label: t`default:cancel`,
        onClick: close,
      }}
      variant="danger"
      loading={isLoading}
    >
      <Container>
        <Description>{t`default:cannotUndo`}</Description>
      </Container>
    </Modal>
  );
};

const Container = styled('div')`
  height: 100px;
`;

const Description = styled('p')`
  ${({ theme }) => theme.typography.acrossPlatform.small};
  color: ${({ theme }) => theme.colors.gray.c12};
`;
