import type {
  CapitalRaisedOption,
  DateRangeOption,
  INumberOption,
  IOption,
} from 'src/types/index';

import {
  isDateRangeOption,
  isDefaultOption,
  isDefaultOptionArray,
  isNumberOption,
  isNumberOptionArray,
} from '@/helpers/other';

import type { IRangeNumberFilterOption } from '../features/projects/project.state';

const getSingleFilterValue = (
  filter: IOption[] | INumberOption[],
  name: string,
): string[] => filter.map(item => `${name}=${item.value}`);

export const filterOutEmptyFilterValues = (value: unknown) => {
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  if (isDateRangeOption(value)) {
    return value.start || value.end;
  }

  return Boolean(value);
};

export type DateRange = {
  start: string | null;
  end: string | null;
};

type FilterValue = boolean | string | string[] | DateRange;

export type ExtractedFilters = Record<string, FilterValue>;

export const extractArrayFilters = (filter: FilterValue, value: string) => {
  if (Array.isArray(filter)) {
    return [...filter, value];
  }
  return [value];
};

export const stringifyFilter = <
  T extends Record<
    string,
    | string
    | boolean
    | IOption
    | INumberOption
    | INumberOption[]
    | IOption[]
    | DateRangeOption
    | CapitalRaisedOption[]
    | IRangeNumberFilterOption
    | number[]
    | null
  >,
>(
  obj: T,
  key: keyof T,
) => {
  const value = obj[key];

  if (!value) {
    return '';
  }
  if (typeof value === 'boolean' || typeof value === 'string') {
    return `${String(key)}=${value}`;
  }
  if (isDefaultOption(value) || isNumberOption(value)) {
    return `${String(key)}=${value.value}`;
  }
  if (isDefaultOptionArray(value) || isNumberOptionArray(value)) {
    return getSingleFilterValue(value, key.toString());
  }

  return '';
};
