import type { GenAiRequestHistoryResult } from '@/api/v4/genai-request-history.api';
import type { ExploreOrganizationSuggestionResult } from '@/api/v4/organization-suggestions.api';

type ExploreResult =
  | ExploreOrganizationSuggestionResult
  | GenAiRequestHistoryResult;

export const isDescriptionNotFounded = (description: string) =>
  description.includes('not available');

const sortByRelevancy = (results: ExploreResult[]) => {
  return results.sort((a, b) => a.resultNumber - b.resultNumber);
};

export const sortByShortDescriptionAndRelevancy = (
  results: ExploreResult[],
) => {
  const withNoDescription = results.filter(
    result =>
      !result.shortDescription ||
      isDescriptionNotFounded(result.shortDescription),
  );

  const withDescription = results.filter(
    result =>
      result.shortDescription &&
      !isDescriptionNotFounded(result?.shortDescription),
  );

  return [
    ...sortByRelevancy([...withDescription]),
    ...sortByRelevancy([...withNoDescription]),
  ];
};
