import { Trans } from 'react-i18next';

import { colors } from '@/theme/colors';
import { Logo } from '@/ui/logo/logo';
import type { ProjectMeetingUpdate } from './helpers';

export function Meeting({ update }: { update: ProjectMeetingUpdate }) {
  const userDisplayName = `${update.user.firstName} ${update.user.lastName}`;

  return (
    <>
      {update.data.organization && (
        <Logo
          singleLetter
          name={update.data.organization.name}
          logoUrl={update.data.organization?.logoUrl}
          bgColor={
            update.data.organization?.logoUrl
              ? colors.basics.white
              : colors.accent.green.c3
          }
        />
      )}
      <div>
        <Trans
          ns="default"
          i18nKey={
            update.data.organization
              ? 'actionPanel.updates.meeting'
              : 'actionPanel.updates.meetingWithoutCompany'
          }
          values={{
            user: userDisplayName,
            company: update.data.organization?.name,
          }}
          components={{ strong: <strong /> }}
        />
      </div>
    </>
  );
}
