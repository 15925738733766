import { forwardRef } from 'react';
import { styled } from 'goober';

import { isOption } from '@/helpers/other';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Logo } from '@/ui/logo/logo';
import type { AsyncSelectOptionProps } from '@/ui/select/async/components/option/option';

type SearchCustomOptionProps = Pick<AsyncSelectOptionProps, 'data'>;

export const SearchOptionItem = forwardRef<
  HTMLDivElement,
  SearchCustomOptionProps
>((props, ref) => {
  if (!isOption(props.data)) {
    return <></>;
  }
  return (
    <Flexbox name="search-option-container" gap="14px" alignItems="center">
      {'logo' in props.data ? (
        <Logo
          border="none"
          name={props.data.label ?? ''}
          logoUrl={
            typeof props.data.logo === 'string' ? props.data.logo : undefined
          }
          size="24px"
          singleLetter
          fontSize="12px"
        />
      ) : (
        <Icon
          icon="ProjectLogoDefault"
          color="gray.c5"
          height="24px"
          width="24px"
          centered
        />
      )}
      <OptionLabel ref={ref}>{props.data.label}</OptionLabel>
    </Flexbox>
  );
});

SearchOptionItem.displayName = 'SearchOptionItem';

const OptionLabel = styled('div', forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis}
  flex-grow: 1;

  display: block;
  clear: left;
  margin-top: 2px;
  width: 100%;
  ${({ theme }) => theme.typography.widget.smallText}
  ${({ theme }) => theme.mixins.ellipsis}
  color: ${({ theme }) => theme.colors.basics.black};
  font-size: 16px;
`;
