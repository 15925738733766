import { useTranslation } from 'react-i18next';

import { TopbarWrapper } from '@/features/topbar/topbar';
import { useCreateActions } from '@/hooks/use-create-actions';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { MinimizedHeader } from '@/ui/typography/across-platform';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { AskXButton } from '../../ask-x/ask-x-button';

export const CompaniesHeader = () => {
  const { t } = useTranslation('companies');
  const { onAddOrganization } = useCreateActions();
  const { hasRequiredPermission } = useUserPermissions();
  const canAddCompany = hasRequiredPermission(
    PERMISSION.ADD_COMPANIES_TO_PROJECTS,
  );

  return (
    <TopbarWrapper
      name="companies-header"
      justify="space-between"
      alignItems="center"
      gap="16px"
      bgColor="white"
      shadow
    >
      <MinimizedHeader>{t`companies.title`}</MinimizedHeader>

      <Flexbox name="companies-header-actions" gap="28px">
        <AskXButton />

        {canAddCompany && (
          <Button
            variant="secondary"
            startIcon="Plus"
            onClick={onAddOrganization}
          >{t`companies.addCompanyButton`}</Button>
        )}
      </Flexbox>
    </TopbarWrapper>
  );
};
