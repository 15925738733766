import type { EnrichedCompanySearchSuggestion } from '@/api/v4/organization-enrich.api';
import type { CompanySearchSuggestion } from './company-suggestions-table';
import type { SearchSuggestion } from '@/api/v4/search.api';

const DEFAULT_ENRICHED_COMPANY_SUGGESTION: EnrichedCompanySearchSuggestion = {
  id: '',
  tags: [],
  location: {
    city: null,
    country: null,
    region: null,
  },
  website: '',
  keyOffering: '',
  linkedInUrl: '',
  projectListingsCount: 0,
  yearEstablished: 0,
  fundingStage: '',
  capitalRaised: null,
  lastEditedDate: null,
};

export const combineCompanyData = (
  data: SearchSuggestion[],
  enrichedCompanySuggestions: EnrichedCompanySearchSuggestion[] | undefined,
): CompanySearchSuggestion[] => {
  return data.map(suggestion => {
    const enrichedCompanySuggestion =
      enrichedCompanySuggestions?.find(
        enriched => enriched.id === suggestion.id,
      ) ?? DEFAULT_ENRICHED_COMPANY_SUGGESTION;

    return {
      basicData: { ...suggestion },
      enrichedData: { ...enrichedCompanySuggestion },
    };
  });
};
