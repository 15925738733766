import type { APIRequestQueryParams, APIResponse } from './base-fetch';
import { get } from './base-fetch';

export type OrganizationsSavvySearchParams =
  APIRequestQueryParams<'/api/v4/organization/list'>;

type OrganizationsSavvySearchResults =
  APIResponse<'/api/v4/organization/list'>[number];

export const fetchOrganizationsSavvySearchResults = async (
  params: OrganizationsSavvySearchParams,
) => {
  let query = `?search=${params.search}`;
  if (params.sort) query += `&sort=${params.sort}`;
  if (params.page) query += `&page=${params.page}`;
  if (params.items) query += `&items=${params.items}`;
  if (params.capitalRaised) query += `&capitalRaised=${params.capitalRaised}`;
  if (params.createdBy) query += `&createdBy=${params.createdBy}`;
  if (params.employeesRange)
    query += `&employeesRange=${params.employeesRange}`;
  if (params.fundingStages) query += `&fundingStages=${params.fundingStages}`;
  if (params.hqCities) query += `&hqCities=${params.hqCities}`;
  if (params.listingStatuses)
    query += `&listingStatuses=${params.listingStatuses}`;
  if (params.max) query += `&max=${params.max}`;
  if (params.min) query += `&min=${params.min}`;
  if (params.ranking) query += `&ranking=${params.ranking}`;

  return await get<OrganizationsSavvySearchResults[]>(
    `/v4/organization/list${query}`,
  );
};
