import { useRecoilValue } from 'recoil';

import { modalState } from '@/state/modal.state';

import { ConfirmDeleteModal } from '../confirm-delete-modal';

import { useDeleteProjectFile } from './use-delete-project-file.mutation';

export function DeleteProjectFileModal() {
  const modal = useRecoilValue(modalState);
  const { deleteProjectFile } = useDeleteProjectFile();

  if (!modal || modal.state !== 'deleteProjectFile') return null;

  return (
    <ConfirmDeleteModal
      onConfirm={deleteProjectFile.bind(null, modal.file.id)}
    />
  );
}
