import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchMeetings } from '@/api/v4/meetings.api';
import { queryClient, QueryKey } from '@/config/query-client';
import { reorderMeetings } from '@/features/action-panel/meetings/helpers/reorder-meetings';
import { editedMeetingIdState } from '@/features/action-panel/meetings/meetings.state';
import { notify } from '@/ui/snackbar/notify';

import { projectIdState } from '../../project.state';

export const useProjectMeetings = () => {
  const { t } = useTranslation('default');
  const projectId = useRecoilValue(projectIdState);
  const editedMeetingId = useRecoilValue(editedMeetingIdState);

  const fetchProjectMeetings = async () => {
    if (!projectId) return [];

    return fetchMeetings({ projectId });
  };

  return useQuery([QueryKey.ProjectMeetings, projectId], fetchProjectMeetings, {
    select: meetings =>
      editedMeetingId ? reorderMeetings(meetings, editedMeetingId) : meetings,

    onError: () =>
      notify({
        message: t`unknownError`,
      }),
    enabled: !!projectId,
    staleTime: 1000 * 60 * 5,

    onSuccess: async () => {
      await queryClient.invalidateQueries([
        QueryKey.ProjectActionsInfo,
        projectId,
      ]);
    },
  });
};
