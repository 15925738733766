import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import type { EditProjectPayload } from '@/api/v4/projects.api';
import { editProject } from '@/api/v4/projects.api';
import { QueryKey } from '@/config/query-client';

import { projectIdState } from '../project.state';

export const useUpdateProject = () => {
  const queryClient = useQueryClient();
  const projectId = useRecoilValue(projectIdState);

  const mutation = useMutation(
    (data: EditProjectData) => {
      const { projectId, ...payload } = data;
      return editProject(projectId, payload);
    },
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries([QueryKey.ProjectsKpi]),
          queryClient.invalidateQueries([QueryKey.Project, projectId]),
          queryClient.invalidateQueries([QueryKey.ProjectOptions]),
          queryClient.invalidateQueries([QueryKey.LastActivities]),
          queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
        ]);
      },
    },
  );

  return {
    editProject: (data: EditProjectData) => mutation.mutateAsync(data),
    isError: mutation.isError,
  };
};

interface EditProjectData extends EditProjectPayload {
  projectId: string;
}
