import { useCallback } from 'react';
import type { SetterOrUpdater } from 'recoil';
import { useRecoilState } from 'recoil';

import { organizationSearchResultsAddedCompaniesState } from '../../companies/search-results/organization-search-results.state';
import type { ExampleCompany } from '../explore.state';

export const useAddCompanyToExploreForm = (
  setExampleCompanies?: SetterOrUpdater<ExampleCompany[]>,
) => {
  const [addedCompanies, setAddedCompanies] = useRecoilState(
    organizationSearchResultsAddedCompaniesState,
  );

  const addCompany = ({ id, name, website, logoUrl }: ExampleCompany) => {
    if (!setExampleCompanies) {
      return;
    }

    setExampleCompanies(companies => {
      return [
        ...companies,
        {
          id,
          name,
          website,
          logoUrl,
        },
      ];
    });

    setAddedCompanies(companyIds => [...companyIds, id]);
  };

  return useCallback(
    ({
      crunchbaseId,
      organizationId,
      logoUrl,
      website,
      name,
    }: {
      crunchbaseId?: string;
      organizationId?: string;
      name?: string;
      website?: string;
      logoUrl?: string;
    }) => {
      if (
        (!crunchbaseId && !organizationId) ||
        (crunchbaseId && addedCompanies.includes(crunchbaseId)) ||
        (organizationId && addedCompanies.includes(organizationId))
      )
        return;

      addCompany({
        id: organizationId ?? crunchbaseId ?? '',
        name: name ?? '',
        website: website ?? '',
        logoUrl: logoUrl ?? '',
      });
    },
    [addedCompanies],
  );
};
