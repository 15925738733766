import { useTranslation } from 'react-i18next';

import { isInvitedUserOption, isUserOption } from '@/helpers/other';
import { InvitedUserOption } from '@/ui/select/async/components/option/invited-user-option';
import type { AsyncSelectOptionProps } from '@/ui/select/async/components/option/option';
import { UserOption } from '@/ui/select/async/components/option/user-option';

export function InviteNewUserOption({
  withCheckIconOnSelect = true,
  data,
  ...props
}: AsyncSelectOptionProps) {
  const { t } = useTranslation(['default']);

  if (isInvitedUserOption(data)) {
    return (
      <InvitedUserOption
        {...props}
        data={data}
        withCheckIconOnSelect={withCheckIconOnSelect}
      />
    );
  }

  if (isUserOption(data)) {
    return (
      <UserOption
        {...props}
        data={data}
        isDisabled={props.isDisabled}
        withCheckIconOnSelect={withCheckIconOnSelect}
        disabledOptionMessage={t`default:invite:optionDisabled`}
      />
    );
  }

  return null;
}
