import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import type { DeleteCompaniesResponse } from '@/api/v4/organization.api';
import { modalState } from '@/state/modal.state';
import { colors } from '@/theme/colors';
import { Modal as ModalComponent } from '@/ui/modal/modal';
import { SmallText } from '@/ui/typography/widgets';

import { selectedCompaniesState } from '../../companies/companies.state';

import { DeleteCompaniesNotification } from './delete-companies-notification';
import { useDeleteCompanies } from './use-delete-company';

const NOTIFICATION_TIMEOUT = 20000;

export const DeleteCompaniesModal = () => {
  const [result, setResult] = useState<DeleteCompaniesResponse | null>();
  const [modal, setModalState] = useRecoilState(modalState);
  const { t } = useTranslation('companies');
  const [selectedCompanies, setSelectedCompanies] = useRecoilState(
    selectedCompaniesState,
  );

  const { deleteCompanies, isLoading } = useDeleteCompanies();

  const closeModal = () => {
    setModalState(null);
    setResult(null);
  };

  useEffect(() => {
    if (!result) return;
    setTimeout(() => {
      closeModal();
    }, NOTIFICATION_TIMEOUT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!modal || modal.state !== 'deleteCompanies') return null;

  const handleDeleteCompanies = async () => {
    const result = await deleteCompanies({
      organizationIds: selectedCompanies
        .filter(item => item.organizationId)
        .map(item => item.organizationId as string),
    });

    if (result.unableToDelete.length > 0) {
      setResult(result);
    }

    const selectedCompaniesBeforeDelete = [...selectedCompanies];

    setSelectedCompanies(
      selectedCompanies.filter(
        id =>
          id.organizationId &&
          result.unableToDelete.includes(id.organizationId),
      ),
    );

    if (result.deleted === selectedCompaniesBeforeDelete.length) {
      closeModal();
    }
  };

  return (
    <Modal
      header={
        result
          ? t(
              result.deleted === 0
                ? 'deleteCompanyModal.allCompaniesCannotBeDeleted'
                : 'deleteCompanyModal.partiallyDeleted',
            )
          : t`deleteCompanyModal.title`
      }
      onClose={closeModal}
      variant={result ? 'info' : 'danger'}
      loading={false}
      closeIcon={!result}
      mainAction={
        !result
          ? {
              label: t`deleteCompanyModal.confirm`,
              onClick: handleDeleteCompanies,
              isDisabled: isLoading,
            }
          : {
              label: t`deleteCompanyModal.done`,
              onClick: closeModal,
            }
      }
    >
      {result ? (
        <DeleteCompaniesNotification
          deleted={result?.deleted}
          undeletableCompanies={result?.unableToDelete}
        />
      ) : (
        <Container>
          <SmallText
            color={colors.gray.c12}
          >{t`deleteCompanyModal.multipleDescription`}</SmallText>
        </Container>
      )}
    </Modal>
  );
};

const Modal = styled(ModalComponent)`
  max-width: 1024px;
`;

const Container = styled('div')`
  height: 100px;
`;
