import { useTranslateHeaders } from '@/ui/table/helpers/use-translate-headers';
import { companySuggestionsTableHeaders } from './headers-config';
import type { CompanySearchSuggestion } from './company-suggestions-table';
import { useMemo } from 'react';
import { CompanyRowMainCell } from './company-row-main-cell';
import { TagsCell } from '../enriched-table/tags-cell';
import { RelatedProjectsCount } from '@/features/companies/related-projects/related-projects-count';
import { getAbbreviateNumber } from '@/helpers/format-number';
import { formatDate } from '@/helpers/format-date';
import { useNavigateToSearchItemPage } from '@/features/search/use-navigate-to-search-item-page';
import { colors } from '@/theme/colors';
import { TextCell } from '../enriched-table/text-cell';
import { SkeletonText } from '@/ui/table/infinite-table/skeleton-row';
import { CompanyActions } from './company-actions';

export const useCompanySuggestionsTableConfig = (
  data: CompanySearchSuggestion[],
  isEnrichDataLoading: boolean,
) => {
  const navigateToSearchItemPage = useNavigateToSearchItemPage();
  const headers = useTranslateHeaders(
    companySuggestionsTableHeaders,
    'default',
    'globalSearch.suggestionsTable',
  );

  const rows = useMemo(() => {
    return data.map(({ basicData, enrichedData }) => {
      return {
        id: basicData.id,
        rowHover: true,
        rowBackground: colors.basics.white,
        rowBackgroundHover: colors.gray.c1,
        onClick: () =>
          navigateToSearchItemPage({
            type: 'company',
            id: basicData.id,
          }),
        cells: [
          {
            value: (
              <CompanyRowMainCell
                name={basicData.name}
                location={enrichedData.location}
                logoUrl={basicData.logoUrl}
                keyOffering={enrichedData.keyOffering}
                website={enrichedData.website}
                linkedInUrl={enrichedData.linkedInUrl}
                isEnrichDataLoading={isEnrichDataLoading}
              />
            ),
          },
          {
            value: (
              <TagsCell
                tags={enrichedData.tags}
                isLoading={isEnrichDataLoading}
                stopPropagation
              />
            ),
          },
          {
            value: isEnrichDataLoading ? (
              <SkeletonText />
            ) : (
              <RelatedProjectsCount
                value={enrichedData.projectListingsCount ?? 0}
                companyId={basicData.id}
              />
            ),
          },
          {
            value: (
              <TextCell
                value={enrichedData.yearEstablished}
                isLoading={isEnrichDataLoading}
              />
            ),
          },
          {
            value: (
              <TextCell
                value={enrichedData.fundingStage}
                isLoading={isEnrichDataLoading}
              />
            ),
          },
          {
            value: (
              <TextCell
                value={
                  enrichedData.capitalRaised
                    ? `$${getAbbreviateNumber(enrichedData.capitalRaised)}`
                    : ''
                }
                isLoading={isEnrichDataLoading}
              />
            ),
          },
          {
            value: (
              <TextCell
                value={
                  enrichedData.lastEditedDate
                    ? formatDate(enrichedData.lastEditedDate, {
                        format: 'dd MMM, yyyy',
                      })
                    : ''
                }
                isLoading={isEnrichDataLoading}
              />
            ),
          },
          {
            value: (
              <CompanyActions
                companyId={basicData.id}
                companyName={basicData.name}
              />
            ),
            hoverOnly: true,
          },
        ],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isEnrichDataLoading]);

  return {
    headers,
    rows,
  };
};
