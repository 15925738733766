import { useQuery } from '@tanstack/react-query';

import { fetchUsers } from '@/api/v4/user.api';
import { QueryKey } from '@/config/query-client';

import { mapUserToOption } from '../helpers/map-users-and-teams-to-options';
import { useTeamOptions } from '@/features/teams/use-team-options.query';

export const useUsersAndTeamsWithAccessToOrganization = (
  companyId: string | null,
) => {
  const fetchUsersWithAccessToOrganization = async () => {
    if (!companyId) return [];

    return fetchUsers({ withAccessToOrganization: companyId });
  };

  const { data: usersWithAccessToOrganization = [], isLoading: loadingUsers } =
    useQuery([QueryKey.Users, companyId], fetchUsersWithAccessToOrganization, {
      enabled: !!companyId,
      select: users => users.map(mapUserToOption),
      staleTime: 1000 * 10,
    });

  const { data: teamsWithAccessToOrganization = [], isLoading: loadingTeams } =
    useTeamOptions({
      withAccessToOrganization: companyId ?? undefined,
    });

  return {
    usersAndTeamsWithAccessToOrganization: [
      ...usersWithAccessToOrganization,
      ...teamsWithAccessToOrganization,
    ],
    isLoading: loadingTeams || loadingUsers,
  };
};
