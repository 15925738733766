import { useContext, useEffect, useRef, useState } from 'react';
import type { Props } from 'react-select';
import type Select from 'react-select/dist/declarations/src/Select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { styled } from 'goober';

import { formatNotApplicable } from '@/helpers/format-empty';
import { Flexbox } from '@/ui/flexbox/flexbox';

import { AsyncSelectWithListContext } from './async-select-with-list-context';
import type { CustomAsyncSelectProps, OptionType } from './use-select-type';
import { useSelectType } from './use-select-type';

export type AsyncSelectProps = Props & CustomAsyncSelectProps;

export function AsyncSelect({
  id,
  label,
  onChange,
  labelPlacement = 'top',
  isMulti,
  readOnly = false,
  readOnlyValue,
  className,
  onClick,
  dataTestId,
  ...props
}: AsyncSelectProps) {
  const reactSelectProps = useSelectType(props);
  const ref = useRef<Select<unknown, boolean>>(null);
  const [clear, setClear] = useState(false);
  const { setSelectRef } = useContext(AsyncSelectWithListContext);

  const handleOnChange: Props['onChange'] = (newOption, actionMeta) => {
    if (isMulti) return onChange?.(newOption, actionMeta);
    const [oldOption] = ref.current?.getValue() as (OptionType | undefined)[];
    const oldValue = oldOption?.value ?? null;
    const newValue = (newOption as OptionType)?.value ?? null;

    if (oldValue === newValue) {
      setClear(true);
      onChange?.(null, actionMeta);
    } else {
      onChange?.(newOption, actionMeta);
    }
  };

  useEffect(() => {
    if (!clear) return;
    ref.current?.clearValue();
    setClear(false);
  }, [clear]);

  useEffect(() => {
    if (!ref.current || !setSelectRef) {
      return;
    }
    setSelectRef(ref.current);
  }, [ref, setSelectRef]);

  return (
    <Flexbox
      data-testid={dataTestId ?? 'select'}
      name="async-select-container"
      direction="column"
      gap={readOnly ? '10px' : '7px'}
      className={className}
      onClick={onClick}
    >
      {label && (
        <Label htmlFor={id} order={labelPlacement === 'top' ? 0 : 2}>
          {label}
        </Label>
      )}
      {readOnly ? (
        <SingleValue id={id}>{formatNotApplicable(readOnlyValue)}</SingleValue>
      ) : (
        <AsyncPaginate
          id={id}
          key={JSON.stringify(props.defaultValue)}
          {...reactSelectProps}
          isMulti={isMulti}
          selectRef={ref}
          onChange={handleOnChange}
        />
      )}
    </Flexbox>
  );
}

const Label = styled('label')<{ order: number }>`
  ${({ theme }) => theme.typography.inputs.select.label}
  display: inline-block;
  margin-left: 10px;
  order: ${({ order }) => order};
`;

const SingleValue = styled('span')`
  ${({ theme }) => theme.typography.inputs.select.singleValue}
  font-family: ${({ theme }) => theme.fonts.wallop};
  display: inline-block;
  margin-left: 10px;
`;
