import type { FC, ReactNode } from 'react';
import { components } from 'react-select';

import { Icon } from '@/ui/icons/icon';
import type { ITooltipProps } from '@/ui/tooltip/tooltip';
import { Tooltip } from '@/ui/tooltip/tooltip';

import { OptionContainer } from '../../async-select.styles';

import type { AsyncSelectOptionProps } from './option';

interface CustomOptionWrapperProps extends AsyncSelectOptionProps {
  tooltipContent: ITooltipProps['content'];
  isTooltipVisible?: ITooltipProps['visible'];
  optionHeight?: string;
  dataTestId?: string;
  children: ReactNode;
}

export const CustomOptionWrapper: FC<CustomOptionWrapperProps> = ({
  tooltipContent,
  isTooltipVisible,
  optionHeight,
  children,
  dataTestId,
  ...props
}) => {
  const { withCheckIconOnSelect = true } = props;

  return (
    <components.Option {...props}>
      <Tooltip
        placement="left"
        content={tooltipContent}
        visible={isTooltipVisible}
      >
        <OptionContainer
          isSelected={props.isSelected}
          height={optionHeight}
          data-testid={dataTestId}
        >
          {children}
          {withCheckIconOnSelect && props.isSelected && (
            <Icon icon="Check" width="18px" />
          )}
        </OptionContainer>
      </Tooltip>
    </components.Option>
  );
};
