import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useSetRecoilState } from 'recoil';

import { modalState } from '@/state/modal.state';
import type { InvitedUserOption, UserOption } from '@/types';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { TextField } from '@/ui/input/text-field';
import { Modal } from '@/ui/modal/modal';
import { SubHeaderBold } from '@/ui/typography/widgets';

import { useInviteUser } from '../../settings/invited-users/use-invite-user.mutation';

import { InviteForm } from './invite-form';

export function InviteUserModal() {
  const { t } = useTranslation('default');
  const { inviteUser, isLoading } = useInviteUser();
  const [invitedList, setInvitedList] = useState<
    (UserOption | InvitedUserOption)[]
  >([]);
  const [note, setNote] = useState('');
  const setModalState = useSetRecoilState(modalState);

  const close = useCallback(() => {
    setModalState(null);
  }, [setModalState]);

  const handleSubmit = async () => {
    const payload = {
      ...(Boolean(note) && { note }),
      users: invitedList.flatMap(item =>
        item.role && item.email
          ? {
              email: item.email,
              roleId: item.role.id,
            }
          : [],
      ),
    };

    await inviteUser(payload);
    close();
  };

  return (
    <Modal onClose={close} variant="info" closeIcon={false}>
      <ContentContainer
        name="invite-modal-container"
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        gap="28px"
        width="824px"
      >
        <Flexbox name="modal-header" justify="space-between">
          <SubHeaderBold>{t`invite.inviteUser`}</SubHeaderBold>
          <Icon icon="Close" onClick={close} />
        </Flexbox>

        <InviteForm
          onChange={setInvitedList}
          placeholder={t`invite.inviteUser`}
          invitedUsersList={invitedList}
        />

        <Note
          value={note}
          onChange={event => setNote(event.target.value)}
          placeholder={t`invite.addNote`}
        />

        <Flexbox
          name="modal-footer"
          justify="flex-end"
          alignItems="flex-end"
          gap="20px"
        >
          <Button variant="underlined" onClick={close}>{t`Cancel`}</Button>
          <Button
            onClick={handleSubmit}
            disabled={!Boolean(invitedList.length) || isLoading}
          >{t`invite.sendInvite`}</Button>
        </Flexbox>
      </ContentContainer>
    </Modal>
  );
}

const ContentContainer = styled(Flexbox)<{ width: string }>`
  width: ${({ width }) => width};
`;

const Note = styled(TextField)`
  height: 100px;
`;
