import { useEffect, useRef } from 'react';

export type IGetRefs = (refs: { [key: string]: HTMLDivElement | null }) => void;

export function useMultiRefs({ getRefs }: { getRefs?: IGetRefs }) {
  const multiRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  useEffect(() => {
    if (!getRefs) {
      return;
    }

    getRefs(multiRefs.current);
  }, [getRefs]);

  return multiRefs;
}
