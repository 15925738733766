import { forwardRef } from 'react';

import { ReadMore } from '@/ui/typography/across-platform';

export interface ReadMoreWidgetButtonProps {
  showMore: boolean;
  label: string;
  onClick: () => void;
  padding?: string;
  color?: string;
}

export const ReadMoreWidgetButton = forwardRef<
  HTMLDivElement,
  ReadMoreWidgetButtonProps
>(function ReadMoreWidgetButton({ showMore, label, onClick, color }, ref) {
  return (
    <ReadMore
      onClick={onClick}
      showMore={showMore}
      label={label}
      color={color}
      ref={ref}
    />
  );
});
