import type { APIRequestBody, APIResponse } from './base-fetch';
import { get, put } from './base-fetch';

type TeamTagAPIResponse = APIResponse<'/api/v4/teams/{teamId}/tags'>;
export type TeamTag = TeamTagAPIResponse['tags'][0];
export const fetchTeamTags = (teamId: string) =>
  get<TeamTagAPIResponse>(`/v4/teams/${teamId}/tags`);

type UpdateTeamTagsAPIResponse =
  APIResponse<'/api/v4/teams/{teamId}/tags/update'>;

export type UpdateTeamTagsAPIPayload =
  APIRequestBody<'/api/v4/teams/{teamId}/tags/update'>;
export const updateTeamTags = (
  teamId: string,
  payload: UpdateTeamTagsAPIPayload,
) =>
  put<UpdateTeamTagsAPIResponse, UpdateTeamTagsAPIPayload>(
    `/v4/teams/${teamId}/tags/update`,
    payload,
  );
