import type { ReactNode } from 'react';
import { useState } from 'react';
import type { ControlProps, Props, SingleValue } from 'react-select';
import ReactSelect, { components } from 'react-select';
import { styled } from 'goober';

import { theme } from '@/theme/setupTheme';

export interface SelectOptionValue<T = string> {
  label: ReactNode;
  value: T;
}

interface ContainerStyles {
  border?: string;
  borderBottom?: string;
  borderRadius?: string;
  height?: string;
  width?: string;
}

export interface SelectProps<T = string> {
  options: SelectOptionValue<T>[];
  onChange?: (newValue: SingleValue<SelectOptionValue<T>>) => void;
  value?: SelectOptionValue<T> | null;
  placeholder?: string;
  optionPadding?: string;
  isSearchable?: boolean;
  hideMenu?: boolean;
  containerStyles?: ContainerStyles;
}

export const Select = <T extends string>({
  options,
  onChange,
  value,
  placeholder,
  optionPadding,
  styles,
  components: propsComponents,
  isSearchable = false,
  hideMenu = false,
  containerStyles,
  ...props
}: SelectProps<T> & Props<SelectOptionValue<T>, false>) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  return (
    <ReactSelect<SelectOptionValue<T>>
      value={value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      isSearchable={isSearchable}
      menuIsOpen={menuIsOpen && !hideMenu}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
      styles={{
        option: base => ({
          ...base,
          padding: '0px',
        }),
        container: base => ({
          ...base,
          cursor: 'pointer',
          border: 'none',
          borderBottom: `1px solid ${theme.colors.gray.c7}`,
          ...containerStyles,
        }),
        menuList: base => ({
          ...base,
          borderRadius: '4px',
          paddingBottom: '0px',
          paddingTop: '0px',
        }),
        valueContainer: styles => ({ ...styles, paddingRight: 0 }),
        // empty to cut off default styles
        control: () => ({}),
        indicatorSeparator: () => ({}),
        ...styles,
      }}
      components={{
        Option: props => (
          <components.Option {...props}>
            <OptionWrapper
              isSelected={props.isSelected}
              padding={optionPadding}
            >
              {props.label}
            </OptionWrapper>
          </components.Option>
        ),
        Control,
        ...propsComponents,
      }}
      {...props}
    />
  );
};
const Control = <T extends string>({
  children,
  ...props
}: ControlProps<SelectOptionValue<T>, false>) => {
  return (
    <components.Control {...props}>
      <ControlWrapper>{children}</ControlWrapper>
    </components.Control>
  );
};

const ControlWrapper = styled('div')`
  display: flex;
  border-radius: 0px;
`;

const OptionWrapper = styled('div')<{ isSelected: boolean; padding?: string }>`
  ${({ theme }) => theme.typography.widget.smallText}
  color: ${({ theme }) => theme.colors.basics.black};

  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.blue.c8 : theme.colors.basics.white};
  padding: ${({ padding = '12px ' }) => padding};
  cursor: pointer;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.c2};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.blue.c8};
  }
`;
