import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import type {
  GenAIRequestResultOrganizationBody,
  GenAIRequestResultParams,
} from '@/api/v4/genai-request';
import { updateGenAIRequestResultOrganization } from '@/api/v4/genai-request';
import { notify } from '@/ui/snackbar/notify';

export const useGenAIRequestResultOrganizationMutation = () => {
  const { t } = useTranslation('default');

  return useMutation({
    mutationFn: async (config: {
      params: GenAIRequestResultParams;
      body: GenAIRequestResultOrganizationBody;
    }) => {
      return await updateGenAIRequestResultOrganization(
        config.params,
        config.body,
      );
    },
    onError: (error: Error) => {
      notify({
        message: t`unknownError`,
      });
      console.error(error.message);
    },
  });
};
