import type { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import type { PRODUCT } from '@/user/products/product.type';
import { useUserProducts } from '@/user/products/use-user-products';
import { useCurrentUser } from '@/user/use-current-user.query';
import { useMemo } from 'react';
import { PageNotFound } from '../page-not-found';

interface ProtectedRouteProps {
  children: JSX.Element;
  required: Required;
  withFallback?: boolean;
}

interface Required {
  permissions?: PERMISSION[];
  products?: PRODUCT[];
}

export const ProtectedRoute = ({
  children,
  required,
  withFallback,
}: ProtectedRouteProps) => {
  const { isFetched } = useCurrentUser();
  const { hasRequiredPermissions } = useUserPermissions();
  const { hasRequiredProducts } = useUserProducts();

  const hasAccess = useMemo(() => {
    const hasRequiredPermissionsProperty = required?.permissions
      ? hasRequiredPermissions(required.permissions)
      : true;
    const hasRequiredProductsProperty = required?.products
      ? hasRequiredProducts(required.products)
      : true;

    return hasRequiredPermissionsProperty && hasRequiredProductsProperty;
  }, [
    hasRequiredPermissions,
    hasRequiredProducts,
    required.permissions,
    required.products,
  ]);

  if (!hasAccess && withFallback && isFetched) {
    return <PageNotFound />;
  }

  if (!hasAccess && isFetched) {
    return null;
  }

  return children;
};
