import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useSetRecoilState } from 'recoil';

import { useBoolean } from '@/hooks/use-boolean';
import { modalState } from '@/state/modal.state';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Modal } from '@/ui/modal/modal';
import { SubHeaderBold } from '@/ui/typography/widgets';

import type { CompanyProjectShareWithProps } from './share-with-select/share-with';
import { ShareWith } from './share-with-select/share-with';

interface ShareModalProps extends CompanyProjectShareWithProps {
  title: string;
  onSubmit: () => void;
}

export function ShareModal({
  privacyDefaultValue,
  title,
  onSubmit,
  shareWithData,
  entitiesWithAccess,
  ...props
}: ShareModalProps) {
  const { t } = useTranslation('default');
  const [submitButtonActive, setSubmitButtonActive] = useBoolean(false);

  const setModalState = useSetRecoilState(modalState);

  useEffect(() => {
    if (
      shareWithData.draftOptions ||
      shareWithData.removedOptions ||
      (privacyDefaultValue !== undefined &&
        shareWithData.isPublic !== privacyDefaultValue)
    ) {
      setSubmitButtonActive.on();
    } else {
      setSubmitButtonActive.off();
    }
  }, [shareWithData, privacyDefaultValue, setSubmitButtonActive]);

  const close = useCallback(() => {
    setModalState(null);
  }, [setModalState]);

  const handleSubmit = async () => {
    try {
      setSubmitButtonActive.off();
      onSubmit();
    } finally {
      setSubmitButtonActive.on();
      close();
    }
  };

  const mappedOwnershipToEntities = useMemo(() => {
    if (shareWithData.ownerId) {
      return entitiesWithAccess.map(entity => ({
        ...entity,
        isOwner: shareWithData.ownerId === entity.value,
      }));
    }

    return entitiesWithAccess;
  }, [entitiesWithAccess, shareWithData.ownerId]);

  return (
    <Modal onClose={close} CustomHeaderComponent={SubHeaderBold} header={title}>
      <ContentContainer
        name="share-modal-container"
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        gap="28px"
        width="824px"
      >
        <ShareWith
          {...props}
          entitiesWithAccess={mappedOwnershipToEntities}
          shareWithData={shareWithData}
          privacyDefaultValue={privacyDefaultValue}
        />
        <Flexbox
          name="share-with-modal-footer"
          justify="flex-end"
          alignItems="flex-end"
          gap="20px"
        >
          <Button variant="underlined" onClick={close}>{t`Cancel`}</Button>
          <Button onClick={handleSubmit} disabled={!submitButtonActive}>
            {t`share.share`}
          </Button>
        </Flexbox>
      </ContentContainer>
    </Modal>
  );
}

const ContentContainer = styled(Flexbox)<{ width: string }>`
  width: ${({ width }) => width};
  margin-top: 16px;
`;
