import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteMeeting as deleteMeetingAPI } from '@/api/v4/meetings.api';
import { notify } from '@/ui/snackbar/notify';
import { projectIdState } from '@/features/projects/project.state';
import { companyIdState } from '@/features/companies/overview/company.state';
import { teamIdState } from '@/features/teams/team.state';
import { useRecoilValue } from 'recoil';
import { QueryKey } from '@/config/query-client';

export const useDeleteMeeting = ({
  onRefreshData,
}: {
  onRefreshData: () => void;
}) => {
  const { t } = useTranslation('default');
  const [isDeleting, setIsDeleting] = useState(false);

  const queryClient = useQueryClient();
  const projectId = useRecoilValue(projectIdState);
  const companyId = useRecoilValue(companyIdState);
  const teamId = useRecoilValue(teamIdState);

  const deleteMeetingMutation = useMutation(
    async (meetingId: string) => {
      setIsDeleting(true);
      await deleteMeetingAPI(meetingId);
    },
    {
      onError: (error: Error) => {
        setIsDeleting(false);
        notify({ message: t`default:unknownError` });
        console.error(error.message);
      },
      onSuccess: async () => {
        onRefreshData();
        if (projectId) {
          await queryClient.invalidateQueries([QueryKey.Project, projectId]);
        }
        if (companyId) {
          await queryClient.invalidateQueries([QueryKey.Company, companyId]);
        }
        if (teamId) {
          await queryClient.invalidateQueries([QueryKey.Team, teamId]);
        }

        await Promise.all([
          queryClient.invalidateQueries([QueryKey.LastActivities]),
          queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
        ]);

        setIsDeleting(false);
      },
    },
  );

  const deleteMeeting = async (meetingId: string) =>
    await deleteMeetingMutation.mutateAsync(meetingId);

  return {
    deleteMeeting,
    isDeleting,
    isError: deleteMeetingMutation.isError,
  };
};
