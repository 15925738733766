import type { AxiosRequestConfig } from 'axios';

import type { APIResponse } from './base-fetch';
import { get } from './base-fetch';

export type CompanyProjectsListings =
  APIResponse<'/api/v4/organization/{organizationId}/project-listings'>;

export const fetchCompanyProjectListings = async ({
  organizationId,
  config,
}: {
  organizationId: string;
  config?: AxiosRequestConfig;
}) =>
  get<CompanyProjectsListings>(
    `/v4/organization/${organizationId}/project-listings`,
    config,
  );
