import { fetchProjectCompanies } from '@/api/v4/projects.api';
import { QueryKey } from '@/config/query-client';
import { useQuery } from '@tanstack/react-query';

export const useProjectCompanies = (projectId: string | null) => {
  const getCompanies = async () => {
    if (!projectId) {
      return [];
    }

    return fetchProjectCompanies(projectId);
  };
  return useQuery([QueryKey.ProjectCompanies, projectId], getCompanies, {
    enabled: !!projectId,
  });
};
