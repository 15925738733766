import { useTranslation } from 'react-i18next';
import type { SingleValue } from 'react-select';

import { isDealStage } from '@/features/listing-stage/types';
import { withPermission } from '@/hoc/with-permission';
import type { SelectOptionValue } from '@/ui/select/select';
import { Select } from '@/ui/select/select';
import { PERMISSION } from '@/user/permissions/permission.type';

import { useListingStages } from '../../../../listing-stage/use-listing-stages.query';
import { useUpdateProjectListingStage } from '../../use-update-project-listing-stage.mutation';
import type { IProjectListingStatus } from '../types';

export const DealTypeSelect = withPermission(
  ({
    companyStage: listingStage,
    listingId,
  }: {
    companyStage: IProjectListingStatus;
    listingId: string;
  }) => {
    const { t } = useTranslation('projects', {
      keyPrefix: 'projectOverview',
    });
    const changeProjectListingStage = useUpdateProjectListingStage();

    const { listingStages } = useListingStages();

    const handleStageChange = async (
      selectedStageOption: SingleValue<SelectOptionValue>,
    ) => {
      if (!selectedStageOption) {
        return;
      }

      await changeProjectListingStage({
        projectListingId: listingId,
        newProjectListingStageId: selectedStageOption.value,
      });
    };

    const options = listingStages
      .filter(stage => {
        if (!stage.name) return false;
        return isDealStage(stage.name);
      })
      .map(stage => ({
        value: stage.id,
        label: stage.name ? t(stage.name) : '',
      }));

    return (
      <Select
        options={options}
        onChange={handleStageChange}
        value={{
          label: listingStage.name,
          value: listingStage.id,
        }}
      />
    );
  },
  PERMISSION.UPDATE_COMPANY_LISTING_STATUS,
);
