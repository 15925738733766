import { useQuery } from '@tanstack/react-query';
import { HttpStatusCode } from 'axios';
import { useRecoilValue } from 'recoil';

import { isBaseError } from '@/api/v4/base-fetch';
import { fetchProject } from '@/api/v4/projects.api';
import { QueryKey } from '@/config/query-client';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';

import { projectIdState } from '../project.state';

export const useProject = () => {
  const projectId = useRecoilValue(projectIdState);
  const navigateTo = useNavigateTo();

  const getProject = () => {
    if (!projectId) return;

    return fetchProject(projectId);
  };

  return useQuery([QueryKey.Project, projectId], getProject, {
    enabled: !!projectId,
    retry: false,
    staleTime: 1000 * 60 * 5,
    onError: (error: unknown) => {
      if (isIdNotRecognizedError(error)) {
        navigateTo.notFound();
      }
    },
  });
};

const isIdNotRecognizedError = (error: unknown) => {
  return (
    isBaseError(error) &&
    (error.response.status === HttpStatusCode.NotFound ||
      error.response.status === HttpStatusCode.BadRequest)
  );
};
