import { styled } from 'goober';

import { Flexbox } from '../flexbox/flexbox';

export const LoadingDots = () => {
  return (
    <Flexbox name="loading-dots">
      <Dot />
      <Dot />
      <Dot />
      <Dot />
    </Flexbox>
  );
};

const Dot = styled('span')`
  display: inline-block;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 6.5px;

  ${({ theme }) =>
    theme.animations.blinkAnimation({
      numberOfElements: 4,
      darkColor: theme.colors.gray.c12,
      lightColor: theme.colors.blue.c6,
    })}
`;
