import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { snakeCase } from 'lodash-es';

import { Flexbox } from '@/ui/flexbox/flexbox';
import type { IconVariant } from '@/ui/icons/icon';
import { Icon } from '@/ui/icons/icon';
import { Tab } from '@/ui/typography/action-panel';

import { UseCaseFitVotesDropdown } from './use-case-fit-votes-dropdown';

export const UseCaseOption = ({
  icon,
  label,
  voted,
}: {
  icon: IconVariant;
  label: string;
  voted: { name: string; description: string }[];
}) => {
  const { t } = useTranslation('projects');
  const nodeId = snakeCase(label);
  return (
    <UseCaseFitVotesDropdown items={voted} id={nodeId}>
      <OptionContainer
        name="use-case-fit-option-wrapper"
        id={nodeId}
        justify="space-between"
        alignItems="center"
        padding="12px"
        gap="12px"
      >
        <Flexbox
          name="use-case-fit-label-wrapper"
          justify="space-between"
          gap="8px"
        >
          <Icon icon={icon} />
          <NoWrapSpan>{label}</NoWrapSpan>
        </Flexbox>
        {
          <Tab className="hidden-inside-single-value">
            <NoWrapSpan>
              {voted.length} {t`voted`}
            </NoWrapSpan>
          </Tab>
        }
      </OptionContainer>
    </UseCaseFitVotesDropdown>
  );
};

const OptionContainer = styled(Flexbox)`
  position: relative;
`;

const NoWrapSpan = styled('span')`
  white-space: nowrap;
`;
