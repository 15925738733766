import type { MouseEvent } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import { styled } from 'goober';

import { isEllipsis } from '@/helpers/other';
import { colors } from '@/theme/colors';
import type { InvitedUserOption } from '@/types';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Logo } from '@/ui/logo/logo';
import {
  OptionContainer,
  OptionLabel,
} from '@/ui/select/async/async-select.styles';
import type { AsyncSelectOptionProps } from '@/ui/select/async/components/option/option';
import { SLIDING_SIDEBAR_WIDTH } from '@/ui/sliding-sidebar/sliding-sidebar';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { SmallText } from '@/ui/typography/widgets';

interface InvitedUserOptionProps extends AsyncSelectOptionProps {
  data: InvitedUserOption;
}

export function InvitedUserOption({ data, ...props }: InvitedUserOptionProps) {
  const { t } = useTranslation(['default', 'teams']);
  const ref = useRef<HTMLDivElement>(null);

  const handleInviteActions = (event: MouseEvent<HTMLElement>) => {
    if ((event.target as HTMLElement).tagName.toLowerCase() === 'div') {
      event.stopPropagation();
    }
  };

  return (
    <components.Option data={data} {...props}>
      <Tooltip
        placement="left"
        maxWidth={`calc(100vw - ${SLIDING_SIDEBAR_WIDTH})`}
        content={data.label}
        visible={isEllipsis(ref.current)}
      >
        <OptionContainer
          isSelected={props.isSelected}
          style={{ height: '60px' }}
        >
          {data.email && (
            <LogoUser
              name={data.label}
              bgColor={colors.blue.primaryB}
              singleLetter
            />
          )}

          <OptionLabel
            ref={ref}
            {...(!data.email && { color: colors.system.disable })}
          >
            {data.label}
          </OptionLabel>
          {props.withCheckIconOnSelect && props.isSelected && (
            <Icon icon="Check" width="18px" />
          )}

          {data.email && (
            <Flexbox
              name="invite-actions"
              alignItems="center"
              gap="10px"
              onClick={handleInviteActions}
            >
              <SmallText
                color={colors.blue.primaryA}
              >{t`invite.invite`}</SmallText>
            </Flexbox>
          )}
        </OptionContainer>
      </Tooltip>
    </components.Option>
  );
}

const LogoUser = styled(Logo)`
  font-size: 12px;
`;
