import { useRef } from 'react';
import { styled } from 'goober';

import { isEllipsis } from '@/helpers/other';
import { colors } from '@/theme/colors';
import type { IAttendeeOption } from '@/types';
import { Inline, Stack } from '@/ui/line/line';
import { Logo } from '@/ui/logo/logo';
import { SmallText } from '@/ui/typography/widgets';

import { OptionLabel } from '../../async-select.styles';

import { CustomOptionWrapper } from './custom-option-wrapper';
import type { AsyncSelectOptionProps } from './option';

interface AttendeeOptionProps extends AsyncSelectOptionProps {
  data: IAttendeeOption;
}

export const AttendeeOption = ({ ...props }: AttendeeOptionProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <CustomOptionWrapper
      tooltipContent={props.data.label}
      isTooltipVisible={isEllipsis(ref.current)}
      optionHeight="68px"
      {...props}
    >
      <Content gap="8px">
        {props.data.label && (
          <Initials
            name={props.data.label}
            bgColor={colors.blue.primaryA}
            border="none"
          />
        )}
        <Stack gap="0px">
          <OptionLabel ref={ref}>{props.data.label}</OptionLabel>
          {props.data.entity && (
            <SmallText color={colors.gray.c11}>
              {props.data.entity.name}
            </SmallText>
          )}
        </Stack>
      </Content>
    </CustomOptionWrapper>
  );
};

const Content = styled(Inline)`
  padding: 8px 2px;
`;

const Initials = styled(Logo)`
  font-size: 12px;
`;
