import { useMemo } from 'react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { ListStatusTag } from '@/components/list-status-tag/list-status-tag';
import { Loader } from '@/components/loader/loader';
import { OtherProjects } from '@/components/other-projects/other-projects';
import { useCompanyProjectsListings } from '@/hooks/queries/use-company-projects-listings.query';
import { Z_INDEX_POPPER_CONTENT } from '@/theme/z-index';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import { TableRow } from '@/ui/table/infinite-table/table-row';
import type { Row } from '@/ui/table/table.types';
import { SmallText } from '@/ui/typography/widgets';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';

const RELATED_PROJECTS_POPUP_WIDTH = 467;
const RELATED_PROJECTS_POPUP_HEIGHT = 417;

interface RelatedProjectsProps {
  companyId: string;
  isVisible: boolean;
}

export const RelatedProjects = ({
  companyId,
  isVisible,
}: RelatedProjectsProps) => {
  const { t } = useTranslation('projects');
  const { data } = useCompanyProjectsListings(companyId, {
    staleTime: 1000 * 60 * 5,
    enabled: isVisible,
  });
  const navigateTo = useNavigateTo();

  const rows: Row[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.listings.map(project => ({
      id: project.id,
      rowPadding: {
        left: '8px',
        right: '8px',
      },
      cells: [
        {
          width: '100%',
          padding: '0 8px',
          value: (
            <>
              <EllipsisTextTooltip
                text={project.project.name}
                Component={<CompanyName />}
                onClick={() =>
                  navigateTo.project({ projectId: project.project.id })
                }
              />
              <SmallText>
                {t('projects.company', { count: project.listingCount })}
              </SmallText>
            </>
          ),
        },
        {
          padding: '0 8px',
          align: 'right',
          value: <ListStatusTag text={project.listingStatus.name} />,
        },
      ],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <RelatedProjectsContainer>
      <ContentContainer
        name="related-projects-container"
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        gap="28px"
        width={`${RELATED_PROJECTS_POPUP_WIDTH}px`}
      >
        <Content name="projects-list" fullWidth justify="flex-start" grow={1}>
          {!data?.listings ? (
            <Flexbox name="related-projects-loader" justify="center" grow={1}>
              <Loader />
            </Flexbox>
          ) : (
            <Stack fullWidth>
              <Table>
                <tbody>
                  {rows.map(row => (
                    <TableRow key={row.id} row={row} />
                  ))}
                </tbody>
              </Table>
              {data.otherProjectsCount > 0 && (
                <OtherProjects count={data.otherProjectsCount} />
              )}
            </Stack>
          )}
        </Content>
      </ContentContainer>
    </RelatedProjectsContainer>
  );
};

const ContentContainer = styled(Flexbox)<{ width: string }>`
  background-color: ${({ theme }) => theme.colors.basics.white};
  width: ${({ width }) => width};
  max-height: ${RELATED_PROJECTS_POPUP_HEIGHT}px;
  border-radius: 20px;
  box-shadow: ${({ theme }) => theme.shadow.widgets};
  padding: 16px 20px;
`;
const Content = styled(Flexbox)`
  ${({ theme }) => theme.mixins.scrollbar}
  overflow-y: auto;
`;

const Table = styled('table')`
  background-color: ${({ theme }) => theme.colors.basics.white};
  width: calc(100% - 15px);
  height: fit-content;

  td {
    background-color: transparent;

    & > div {
      display: inline-flex;
    }
  }
`;

const CompanyName = styled('p', forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis};
  ${({ theme }) => theme.typography.actionsPanel.meetings.header}
  color: ${({ theme }) => theme.colors.basics.black};
  max-width: 230px;
  margin-bottom: 6px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const RelatedProjectsContainer = styled('div', forwardRef)`
  z-index: ${Z_INDEX_POPPER_CONTENT};
`;
