import { forwardRef } from 'react';
import type { DefaultTheme } from 'goober';
import { styled } from 'goober';

import { Z_INDEX_SIDEBAR_DROPDOWN } from '@/theme/z-index';

import { colorIcon } from '../icons/functions';

const styleDropdownList = (theme: DefaultTheme) => `
  position: absolute;
  z-index: ${Z_INDEX_SIDEBAR_DROPDOWN};
  background-color: ${theme.colors.basics.white};
  border-radius: 4px;
  box-shadow: ${theme.shadow.actionsPanel};

  &.hidden {
    display: none;
  }
  `;

export const DropdownList = styled('ul', forwardRef)`
  ${({ theme }) => styleDropdownList(theme)}
`;

export const DropdownItem = styled('li', forwardRef)`
  ${props => props.theme.typography.widget.smallText}
  color: ${props => props.theme.colors.basics.black};
  border-bottom: 1px solid ${props => props.theme.colors.gray.c2};
  min-height: 42px;
  padding: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  white-space: nowrap;

  ${({ theme }) => colorIcon(theme.colors.basics.black)}
  &:hover {
    background-color: ${props => props.theme.colors.blue.c7};
    & > ul {
      display: block;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  padding-left: 14px;
  padding-right: 14px;
  flex-grow: 1;

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: ${props => props.theme.colors.gray.c1};
    color: ${props => props.theme.colors.gray.c10};
  }
`;

export const DropdownSubList = styled('ul', forwardRef)<{
  float: 'right' | 'left';
}>`
  ${({ theme }) => styleDropdownList(theme)}
  top: 0;
  ${({ float = 'left' }) => `${float}: 100%`};
  padding: 0;
  display: none;
`;
