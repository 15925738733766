import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { modalState } from '../../state/modal.state';
import { Modal } from '../../ui/modal/modal';
import { activeMeetingFormState } from '../action-panel/meetings/meetings.state';

export const ConfirmDiscardMeetingFormChangesModal = () => {
  const { t } = useTranslation('default');
  const [modal, setModalState] = useRecoilState(modalState);
  const [activeMeetingForm, setActiveMeetingForm] = useRecoilState(
    activeMeetingFormState,
  );

  if (!modal || modal.state !== 'discardMeetingFormChanges') return null;

  const handleDiscardChanges = () =>
    setActiveMeetingForm({ ...activeMeetingForm, shouldDiscard: true });

  const resetShouldDiscardFlag = () =>
    setActiveMeetingForm({ ...activeMeetingForm, shouldDiscard: false });

  const close = () => {
    setModalState(null);
  };

  return (
    <Modal
      header={t`areYouSure`}
      onClose={() => {
        resetShouldDiscardFlag();
        close();
      }}
      mainAction={{
        label: t`discard`,
        onClick: () => {
          handleDiscardChanges();
          modal.mainAction();
          resetShouldDiscardFlag();
          close();
        },
      }}
      secondaryAction={{
        label: t`cancel`,
        onClick: () => {
          resetShouldDiscardFlag();
          close();
        },
      }}
      variant="info"
    >
      <Container>
        <Description>{t`unsavedChangesWarning`}</Description>
      </Container>
    </Modal>
  );
};

const Container = styled('div')`
  height: 100px;
`;

const Description = styled('p')`
  ${({ theme }) => theme.typography.acrossPlatform.small};
  color: ${({ theme }) => theme.colors.gray.c12};
`;
