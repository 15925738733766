import type { ProjectRequestParams } from '@/api/v4/projects.api';
import { fetchProjects } from '@/api/v4/projects.api';
import { QueryKey } from '@/config/query-client';
import { selectedCompaniesState } from '@/features/companies/companies.state';
import { notify } from '@/ui/snackbar/notify';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

const resolveParamAvailableProjects = (
  entityToShareId: string,
): ProjectRequestParams => ({
  excludeOrganizations: [entityToShareId],
});

export const useProjectSearchResults = (searchPhrase?: string) => {
  const { t } = useTranslation('default');

  const selectedCompanies = useRecoilValue(selectedCompaniesState);

  const fetchProjectSearchResults = () => {
    if (!selectedCompanies.length || searchPhrase === '') return [];

    const params =
      selectedCompanies.length === 1 && selectedCompanies[0].organizationId
        ? resolveParamAvailableProjects(selectedCompanies[0].organizationId)
        : {};

    return fetchProjects({ ...params, search: searchPhrase });
  };

  const queryKey = Boolean(searchPhrase)
    ? [QueryKey.ProjectSearchResults, searchPhrase]
    : [QueryKey.ProjectSearchResults];

  return useQuery(queryKey, fetchProjectSearchResults, {
    onError: () =>
      notify({
        message: t`unknownError`,
      }),
    staleTime: 1000 * 10,
    enabled: !!searchPhrase,
  });
};
