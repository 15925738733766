import type {
  ActionPanelContext,
  ActionPanelTab,
} from '@/features/action-panel/action-panel.state';
import { CompanyCommentsTab } from '@/features/companies/overview/action-panel/comments/company-comments-tab';
import { CompanyMeetingsTab } from '@/features/companies/overview/action-panel/company-meetings-tab';
import { CompanyFilesTab } from '@/features/companies/overview/action-panel/files/company-files-tab';
import { ProjectMeetingsTab } from '@/features/projects/action-panel/project-meetings-tab';
import { ProjectUpdates } from '@/features/projects/action-panel/updates/updates-tab';
import { TeamCommentsTab } from '@/features/teams/overview/action-panel/comments/team-comments-tab';
import { TeamFilesTab } from '@/features/teams/overview/action-panel/files/team-files-tab';
import { TeamMeetingsTab } from '@/features/teams/overview/action-panel/meetings/team-meetings-tab';

import { ProjectCommentsTab } from '../projects/action-panel/comments/project-comments-tab';
import { ProjectFilesTab } from '../projects/action-panel/files/project-files-tab';

export const actionPanelTabConfig: ActionPanelTab[] = [
  'updates',
  'comments',
  'meetings',
  'files',
];

export const defaultActiveTab: Record<ActionPanelContext, ActionPanelTab> = {
  company: 'comments',
  team: 'comments',
  project: 'updates',
};

export const actionPanelConfig: Record<
  Partial<ActionPanelContext>,
  Partial<Record<ActionPanelTab, React.ReactNode>>
> = {
  project: {
    updates: <ProjectUpdates />,
    meetings: <ProjectMeetingsTab />,
    comments: <ProjectCommentsTab />,
    files: <ProjectFilesTab />,
  },
  company: {
    meetings: <CompanyMeetingsTab />,
    comments: <CompanyCommentsTab />,
    files: <CompanyFilesTab />,
  },
  team: {
    meetings: <TeamMeetingsTab />,
    comments: <TeamCommentsTab />,
    files: <TeamFilesTab />,
  },
};
