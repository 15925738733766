import { useTranslation } from 'react-i18next';

import { modalState } from '@/state/modal.state';
import { notify } from '@/ui/snackbar/notify';

import { useDeleteTeam } from './use-delete-team';
import { Modal } from '@/ui/modal/modal';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';

export const DeleteTeamModal = () => {
  const [modal, setModal] = useRecoilState(modalState);
  const { t } = useTranslation(['teams, default']);
  const navigateTo = useNavigateTo();

  const { mutateAsync: deleteTeam, isLoading } = useDeleteTeam();

  if (!modal || modal.state !== 'deleteTeam') return null;

  const close = () => {
    setModal(null);
  };

  const handleDeleteTeam = async (teamId: string) => {
    const response = await deleteTeam(teamId);
    close();

    if (response.errors.length > 0) {
      notify({
        message: t`teams:deleteError`,
      });
      return;
    }

    notify({
      message: t('teams:deleteSuccessMessage', {
        name: modal.teamName,
      }),
    });

    navigateTo.teams();
  };

  return (
    <Modal
      header={t`teams:deleteTeamTitle`}
      onClose={close}
      mainAction={{
        label: t`default:delete`,
        onClick: handleDeleteTeam.bind(null, modal.teamId),
      }}
      secondaryAction={{
        label: t`default:cancel`,
        onClick: close,
      }}
      variant="danger"
      loading={isLoading}
    >
      <Container>
        <Description>{t`default:cannotUndo`}</Description>
      </Container>
    </Modal>
  );
};

const Container = styled('div')`
  height: 100px;
`;

const Description = styled('p')`
  ${({ theme }) => theme.typography.acrossPlatform.small};
  color: ${({ theme }) => theme.colors.gray.c12};
`;
