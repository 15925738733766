import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';

export const ActionPanelTabControl = ({ name }: { name: string }) => (
  <Tab name="action-panel-tab-control" alignItems="center">
    {name}
  </Tab>
);

const Tab = styled(Flexbox)`
  position: relative;
  text-align: center;
  padding: 0 12px;
  border-bottom: 2px solid transparent;
`;
