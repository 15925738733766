import type { EntityTag } from '../../tags/tags';
import { useTags } from '@/features/tags/use-tags.query';

export const useNotSelectedTags = ({
  selectedTags,
}: {
  selectedTags: EntityTag[];
}) => {
  const { tags } = useTags();

  const selectedTagIds = selectedTags.map(tag => tag.id);
  return tags.filter(tag => !selectedTagIds.includes(tag.id));
};
