import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '@/config/query-client';
import { fetchCrunchbaseSearchResults } from '@/api/v4/crunchbase.api';

export const useCrunchbaseOrganizationsSearch = (searchPhrase: string) => {
  return useQuery([QueryKey.CompaniesSearchCrunchbase, searchPhrase], () => {
    if (searchPhrase.trim() === '') return [];
    return fetchCrunchbaseSearchResults(searchPhrase);
  });
};
