import type { Tag } from '@/api/v4/tags.api';

import { prepareSafeInput } from './prepare-safe-input';

export const filterSearchTags = ({
  searchPhrase,
}: {
  searchPhrase: string;
}) => {
  const phrasesParts = searchPhrase.split(' ').filter(phrase => phrase !== '');

  const doesTagMatchSearchPhrase = (tag: Pick<Tag, 'name'>) => {
    const conditions = [prepareSafeInput(tag.name)];

    return conditions.some(condition =>
      phrasesParts.some(phrase => condition.includes(prepareSafeInput(phrase))),
    );
  };

  return { phrasesParts, doesTagMatchSearchPhrase };
};
