import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import './lib/i18n/i18n';
import './theme/setupTheme';

import App from './App';

import './index.css';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import { swapDynamicLoadErrorPage } from './dynamic-load-error-page/swap-dynamic-load-error-page';

/* catch preload event */
window.addEventListener('vite:preloadError', async event => {
  event.preventDefault();
  await swapDynamicLoadErrorPage();
  window.location.reload();
});

const container = document.getElementById('root');

const root = createRoot(container!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
