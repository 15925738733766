import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { api } from '@/api';
import { Modal } from '@/ui/modal/modal';
import { notify } from '@/ui/snackbar/notify';

import { modalState } from '../../state/modal.state';
import { settingsChangeUserRoleFormState } from '../settings/settings.state';
import { ChangeUserRole } from '../settings/user-management/change-user-role';
import { useActiveAndDisabledUsers } from '../settings/user-management/use-active-and-disabled-users';
import { editUserRole } from '@/api/v4/user.api';

export const ChangeUserRoleModal = () => {
  const { t } = useTranslation(['settings', 'default']);
  const [isDisabled, setIsDisabled] = useState(false);

  const [modal, setModal] = useRecoilState(modalState);
  const [changeUserRoleForm] = useRecoilState(settingsChangeUserRoleFormState);

  const { refreshUsers } = useActiveAndDisabledUsers();

  const closeModal = () => setModal(null);

  const handleOnChange = useCallback(async () => {
    if (!changeUserRoleForm) return;

    if (changeUserRoleForm.entities.length > 0) {
      const projectIds = changeUserRoleForm.entities
        .filter(entity => entity.type === 'project')
        .map(entity => entity.value);

      const companyIds = changeUserRoleForm.entities
        .filter(entity => entity.type === 'company')
        .map(entity => entity.value);

      if (projectIds.length > 0) {
        await api.grantAccessToStreams({
          user_id: `${changeUserRoleForm.userId}`,
          ids: projectIds,
        });
      }

      if (companyIds.length > 0) {
        await api.grantAccessToOrganizations({
          user_id: `${changeUserRoleForm.userId}`,
          ids: companyIds,
        });
      }
    }

    await editUserRole({
      userInAccountId: changeUserRoleForm.userId,
      roleId: changeUserRoleForm.newRoleId,
    });

    await refreshUsers();

    notify({ message: t`modal.changeRole.successPrompt` });
    closeModal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeUserRoleForm]);

  if (!modal || modal.state !== 'changeUserRole') return null;

  return (
    <Modal
      header={t`modal.changeRole.title`}
      onClose={closeModal}
      mainAction={{
        label: t`modal.changeRole.change`,
        onClick: handleOnChange,
      }}
      secondaryAction={{
        label: t`modal.changeRole.cancel`,
        onClick: closeModal,
      }}
      variant="info"
      disabled={isDisabled}
    >
      <ChangeUserRole
        userId={modal.userId}
        userDisplayName={modal.userDisplayName}
        currentRole={modal.currentRole}
        newRole={modal.newRole}
        hasLinkedEntities={modal.hasLinkedEntities}
        setActionDisable={setIsDisabled}
      />
    </Modal>
  );
};
