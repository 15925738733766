import { atom } from 'recoil';

export enum CompanySection {
  ABOUT = 'about',
  EDITOR_NOTE = 'editor-note',
  FUNDING = 'funding',
  INVESTORS = 'investors',
  CLIENTS = 'clients',
  LISTED_IN = 'listed-in',
  EXTERNAL_LINKS = 'external-links',
  TAGS = 'tags',
  RANKING = 'ranking',
  PRODUCT = 'product',
  NEWS_FEED = 'news-feed',
  CONTACTS = 'contacts',
  FREE_TEXT = 'free-text',
  OFFICE_LOCATIONS = 'office-locations',
}

export const selectedSectionState = atom<CompanySection | null>({
  key: 'selectedSectionState',
  default: null,
});
