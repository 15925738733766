import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteProjectListing as deleteProjectListingAPI } from '@/api/v4/project-listings.api';
import { QueryKey } from '@/config/query-client';
import { useInvalidateProjectListings } from '@/features/projects/project-listings/use-invalidate-project-listings';
import { notify } from '@/ui/snackbar/notify';

export const useDeleteProjectListing = () => {
  const { t } = useTranslation('projects', {
    keyPrefix: 'listings',
  });
  const queryClient = useQueryClient();
  const invalidateProjectListings = useInvalidateProjectListings();

  const deleteProjectListingMutation = useMutation(deleteProjectListingAPI, {
    onSuccess: async () => {
      notify({ message: t`companyWasRemoved` });
      await invalidateProjectListings();
      await queryClient.invalidateQueries([QueryKey.Snapshot]);
      await queryClient.invalidateQueries([QueryKey.Funnel]);
      await queryClient.invalidateQueries([QueryKey.LastActivities]);
      await queryClient.invalidateQueries([QueryKey.LastActivitiesCount]);
      await queryClient.invalidateQueries([QueryKey.CompanyOptionsCount]);
    },
  });

  const deleteProjectListing = (projectListingId: string) =>
    deleteProjectListingMutation.mutateAsync(projectListingId);

  return {
    deleteProjectListing,
    isError: deleteProjectListingMutation.isError,
  };
};
