import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { isEmpty } from 'lodash-es';

import SvgFiles from '@/assets/widgets/empty-state-comments.svg';
import { Loader } from '@/components/loader/loader';
import { type IGetRefs, useMultiRefs } from '@/hooks/use-multi-refs';
import { Flexbox } from '@/ui/flexbox/flexbox';
import type { AsyncSelectProps } from '@/ui/select/async/async-select';

import { ActionPanelEmpty } from '../action-panel-empty';

import { Comment as CommentComponent } from './comment';
import type { Comment, EditCommentCallback } from './comment.type';
import { actionPanelScrollToParamState } from '../action-panel.state';
import { useRecoilValue } from 'recoil';

export const CommentsList = ({
  comments,
  onClickDelete,
  onEditSubmit,
  getRefs,
  loading,
  dropdown,
}: {
  comments: Comment[];
  onClickDelete: (commentId: string) => void;
  onEditSubmit: EditCommentCallback<void>;
  getRefs?: IGetRefs;
  loading: boolean;
  dropdown?: AsyncSelectProps;
}) => {
  const { t } = useTranslation('default');
  const commentRefs = useMultiRefs({ getRefs });
  const isAlreadyScrolled = useRecoilValue(actionPanelScrollToParamState);

  useEffect(() => {
    if (isAlreadyScrolled) return;
    getRefs?.(commentRefs.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentRefs]);

  if (loading)
    return (
      <ListContainer name="comments-list-container" direction="column" grow={1}>
        <Loader />
      </ListContainer>
    );

  if (isEmpty(comments))
    return (
      <ActionPanelEmpty
        image={SvgFiles}
        label={t`actionPanel.comments.empty`}
      />
    );

  return (
    <ListContainer
      name="comments-list-container"
      direction="column"
      gap="12px"
      height="0"
    >
      {comments.map(comment => (
        <CommentComponent
          comment={comment}
          key={comment.id}
          onClickDelete={onClickDelete.bind(null, comment.id)}
          onEditSubmit={onEditSubmit}
          ref={el => (commentRefs.current[comment.id] = el)}
          dropdown={dropdown}
        />
      ))}
    </ListContainer>
  );
};

const ListContainer = styled(Flexbox)`
  background-color: ${({ theme }) => theme.colors.basics.canvas};
  overflow: auto;
  ${({ theme }) => theme.mixins.scrollbar}
  padding: 20px 32px;
  flex: 1 1 auto;
`;
