import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { ReactComponent as NoMeetingsIllustration } from '@/assets/svg/no-meetings-illustration.svg';
import { Button } from '@/ui/button/button';
import { Stack } from '@/ui/line/line';
import { Small } from '@/ui/typography/across-platform';

import type { MeetingsTabProps } from './meetings-tab';

export const EmptyState = ({
  context,
  onButtonClick,
  isAddMeetingButtonShown = true,
}: {
  context: MeetingsTabProps['context'];
  onButtonClick: () => void;
  isAddMeetingButtonShown?: boolean;
}) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'actionPanel',
  });

  return (
    <Container fullWidth centered>
      <Content gap="24px" centered>
        <NoMeetingsIllustration />
        <Small>
          {t('meetings.noMeetingsText', {
            context,
          })}
        </Small>
        {isAddMeetingButtonShown && (
          <Button startIcon="Plus" variant="primary" onClick={onButtonClick}>
            {t`meetings.addMeeting`}
          </Button>
        )}
      </Content>
    </Container>
  );
};

const Container = styled(Stack)`
  position: relative;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.basics.white};
`;

const Content = styled(Stack)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
