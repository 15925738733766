import type { ProjectSortOptions } from '@/api/v4/projects.api';
import type { HeaderCellConfig } from '@/ui/table/table.types';

export const projectsHeaders: HeaderCellConfig<ProjectSortOptions>[] = [
  {
    columnKey: 'project',
    width: '30%',
    sortKey: 'projectName',
    padding: '30px 20px 20px 60px',
    defaultSortDirection: 'asc',
  },
  {
    columnKey: 'stage',
    width: '14%',
    sortKey: 'stage',
  },
  {
    columnKey: 'createdFor',
    width: '14%',
    sortKey: 'createdFor',
    defaultSortDirection: 'asc',
  },
  {
    columnKey: 'createdBy',
    width: '14%',
    sortKey: 'createdBy',
    defaultSortDirection: 'asc',
  },
  {
    columnKey: 'created',
    width: '14%',
    sortKey: 'createdDate',
  },
  {
    columnKey: 'edited',
    width: '14%',
    sortKey: 'lastEditedDate',
  },
];
