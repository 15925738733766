import { useRecoilValue } from 'recoil';

import { queryClient, QueryKey } from '@/config/query-client';

import { projectIdState } from '../project.state';

export const useInvalidateProjectListings = () => {
  const projectOverviewPageId = useRecoilValue(projectIdState);

  return async (projectId?: string) => {
    const projectToInvalidateListings = projectId ?? projectOverviewPageId;

    await queryClient.invalidateQueries({
      predicate: query => {
        const queryKey = query.queryKey;
        const hasProjectListings = queryKey[0] === QueryKey.ProjectListings;
        const hasProjectId = queryKey[1] === projectToInvalidateListings;
        return hasProjectListings && hasProjectId;
      },
    });
  };
};
