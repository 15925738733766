import type { ProjectUpdate } from '@/api/v4/project-updates.api';
import type { components } from '@/api/v4/types';
import { hasProperty } from '@/helpers/has-property';

type BaseUpdate = Pick<ProjectUpdate, 'action' | 'createdAt' | 'user'>;

export type ProjectMeetingUpdate = BaseUpdate & {
  data: components['schemas']['ProjectMeetingUpdatesResponse'];
};

export type ProjectListingStageUpdate = BaseUpdate & {
  data: {
    status: string;
    organization: components['schemas']['ProjectUpdatesOrganization'];
  };
};

export type ProjectListingUpdate = BaseUpdate & {
  data: {
    organization: components['schemas']['ProjectUpdatesOrganization'];
  };
};

export const isProjectMeetingUpdate = (
  update: ProjectUpdate,
): update is ProjectMeetingUpdate => {
  return (
    update.action === 'addMeeting' &&
    hasProperty('meeting', update.data) &&
    hasProperty('organization', update.data)
  );
};

export const isProjectListingStageUpdate = (
  update: ProjectUpdate,
): update is ProjectListingStageUpdate => {
  return (
    update.action === 'projectListingStageChange' &&
    hasProperty('data', update) &&
    hasProperty('organization', update.data)
  );
};

export const isProjectListingUpdate = (
  update: ProjectUpdate,
): update is ProjectListingUpdate => {
  return (
    update.action === 'addProjectListing' &&
    hasProperty('data', update) &&
    hasProperty('organization', update.data)
  );
};
