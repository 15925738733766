import type { HighlightKeys, SearchSuggestion } from '@/api/v4/search.api';
import { assertNever } from '@/helpers/assert-never';
import i18n from '@/lib/i18n/i18n';

const { t } = i18n;
const tPrefix = 'default:globalSearch.';

export const mapHighlights = (
  highlights: SearchSuggestion['highlights'],
  type: string,
) => {
  if (!highlights) return [];

  const entries = Object.entries(highlights);

  return entries
    .map(([key, highlights]) => {
      const highlightKey = key as HighlightKeys;

      switch (highlightKey) {
        case 'partialText':
          return highlights.map(h => `${h}...`);

        case 'fullText':
          return highlights;

        case 'contacts':
        case 'members':
        case 'formallyKnownAs':
        case 'createdFor':
        case 'product': {
          if (highlights.length === 0) {
            return [];
          }

          return [
            t(tPrefix + 'highlights.' + highlightKey) + highlights.join(', '),
          ];
        }

        case 'projectListings':
          return highlights.map(
            h =>
              t(
                type === 'project'
                  ? tPrefix + 'highlights.inThisProject'
                  : tPrefix + 'highlights.listedIn',
              ) + h,
          );

        case 'tags': {
          if (highlights.length === 0) {
            return [];
          }

          return [
            t(tPrefix + 'highlights.tags') +
              highlights
                .map(h => `<em>#${clearHighlightFromEmphasizedTag(h)}</em>`)
                .join(', '),
          ];
        }

        default:
          assertNever(highlightKey);
      }
    })
    .flat();
};

const clearHighlightFromEmphasizedTag = (tag: string) => {
  return tag.replace(/<\/?em>/g, '');
};
