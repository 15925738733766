import type { ReactNode } from 'react';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { captureException } from '@sentry/react';

import { ErrorScreen, type OnExit } from '../error-screen/error-screen';
import { Loader } from '../loader/loader';

export const SuspenseFallback = ({
  children,
  onErrorExit,
}: {
  children: ReactNode;
  onErrorExit?: OnExit;
}) => {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => {
        captureException(error);
        return <ErrorScreen onExit={onErrorExit} />;
      }}
    >
      <React.Suspense fallback={<Loader />}>{children}</React.Suspense>
    </ErrorBoundary>
  );
};
