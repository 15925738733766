import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { modalState } from '@/state/modal.state';
import { colors } from '@/theme/colors';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { Header } from '@/ui/modal/header';
import { Modal } from '@/ui/modal/modal';
import { Small } from '@/ui/typography/across-platform';

export const VerifyCompanyModal = () => {
  const { t } = useTranslation('companies', { keyPrefix: 'verify' });
  const [modal, setModal] = useRecoilState(modalState);
  const errorFields = modal && 'errorFields' in modal ? modal.errorFields : [];

  const closeModal = () => {
    setModal(null);
  };

  return (
    <Modal closeIcon={false}>
      <Stack gap="40px">
        <Header header={t`title`} onClose={closeModal} closeIcon />
        <Content color={colors.gray.c12}>
          {!!errorFields.length &&
            errorFields.map(error => t(error)).join(', ') + '.'}
        </Content>
        <Flexbox
          name="verify-modal-close-button"
          justify="flex-end"
          padding="40px 0 0 0"
        >
          <Button variant="primary" onClick={closeModal}>
            {t`gotIt`}
          </Button>
        </Flexbox>
      </Stack>
    </Modal>
  );
};

const Content = styled(Small)`
  padding-right: 24px;
`;
