import { isUserOption } from '@/helpers/other';
import type { InvitedUserOption } from '@/types';
import { type UserOption } from '@/types';
import type { AsyncSelectProps } from '@/ui/select/async/async-select-with-list';
import { AsyncSelectWithList } from '@/ui/select/async/async-select-with-list';
import type { CustomAsyncSelectProps } from '@/ui/select/async/use-select-type';

import { SelectedUserOption } from '../../app-users/invite-users/selected-user-option';
import { useInvitedUsersOptions } from '../../app-users/invite-users/use-invited-users-options';
import { useTeam } from '../../teams/overview/use-team.query';

import { AddTeamMemberOption } from './team-member-option';

interface IInviteProps extends Pick<AsyncSelectProps, 'searchInputSize'> {
  onChange: (options: UserOption[]) => void;
  placeholder: string;
  invitedList: Array<UserOption | InvitedUserOption>;
}

export function InvitedUsersSelect({
  onChange,
  placeholder,
  invitedList,
}: IInviteProps) {
  const { data: team } = useTeam();

  const { setOptions } = useInvitedUsersOptions({
    invitedUsersList: invitedList,
    team,
  });

  const handleOnChange: CustomAsyncSelectProps['onChange'] = newValue => {
    if (Array.isArray(newValue)) onChange(newValue);
  };

  return (
    <AsyncSelectWithList
      setOptions={setOptions}
      cacheUniqs={[invitedList]}
      placeholder={placeholder}
      components={{ Option: AddTeamMemberOption }}
      SelectedOptionsListComponent={<SelectedUserOption />}
      isOptionDisabled={option => isUserOption(option) && !!option.team}
      onChange={handleOnChange}
    />
  );
}
