import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import { fetchCountEntitiesByTag } from '@/api/v4/tags.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

export const useCountEntitiesByTag = (tagId: string) => {
  const { t } = useTranslation('default');

  return useQuery(
    [QueryKey.CountEntitiesByTag, tagId],
    fetchCountEntitiesByTag.bind(null, tagId),
    {
      onError: () =>
        notify({
          message: t`unknownError`,
        }),
      staleTime: 1000 * 10,
    },
  );
};
