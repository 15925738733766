import { Descope } from '@descope/react-sdk';
import { captureException } from '@sentry/react';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { Loader } from '@/components/loader/loader';
import { useDescopeFlow } from '@/hooks/use-descope-flow';
import { modalState } from '@/state/modal.state';
import { Modal as ModalComponent } from '@/ui/modal/modal';

export const ChangePasswordModal = () => {
  const [modal, setModal] = useRecoilState(modalState);
  const { isFlowLoading, onReady } = useDescopeFlow();

  if (!modal || modal.state !== 'changePassword') return null;

  return (
    <Modal>
      {isFlowLoading && <Loader />}
      <Descope
        flowId={window.runTimeEnvs.VITE_DESCOPE_UPDATE_PASSWORD_FLOW}
        onSuccess={() => {
          setModal(null);
        }}
        onError={event => {
          captureException(event);
        }}
        onReady={onReady}
      />
    </Modal>
  );
};

const Modal = styled(ModalComponent)`
  max-width: 700px;
`;
