import { useRef } from 'react';
import type { Loadable } from 'recoil';

export const usePreviousWhileLoading = <T,>(
  value: Loadable<T>,
): Loadable<T> => {
  const ref = useRef<Loadable<T>>();

  if (value.state !== 'loading' || ref.current === undefined) {
    ref.current = value;
  }

  return ref.current;
};
