import { useTranslation } from 'react-i18next';

import { Stack } from '@/ui/line/line';

import { OrganizationSearchResultsTable } from './organization-search-results-table';
import type {
  AddCompanyEvent,
  OrganizationSearchResultsRowData,
} from './organization-search-results-table-row';

type OrganizationSearchResultsTableProps = {
  buttonAddText: string;
  buttonAddedText: string;
  savvyResultsHeader: string;
  crunchbaseResultsHeader: string;
  onAddSavvyCompanyClick?: AddCompanyEvent;
  onAddCrunchbaseCompanyClick: AddCompanyEvent;
  savvyCompaniesData: OrganizationSearchResultsRowData[];
  isSavvyCompaniesLoading: boolean;
  crunchbaseCompaniesData: OrganizationSearchResultsRowData[];
  isCrunchbaseCompaniesLoading: boolean;
  rowClicksRedirectsToWebsite?: boolean;
};

export const OrganizationSearchResults = ({
  buttonAddText,
  buttonAddedText,
  crunchbaseResultsHeader,
  savvyResultsHeader,
  onAddCrunchbaseCompanyClick,
  onAddSavvyCompanyClick,
  savvyCompaniesData,
  crunchbaseCompaniesData,
  rowClicksRedirectsToWebsite,
  isSavvyCompaniesLoading,
  isCrunchbaseCompaniesLoading,
}: OrganizationSearchResultsTableProps) => {
  const { t } = useTranslation('projects');

  return (
    <Stack>
      <OrganizationSearchResultsTable
        id="savvy-search-results"
        buttonAddText={buttonAddText}
        buttonAddedText={buttonAddedText}
        data={savvyCompaniesData}
        fromCB={false}
        headerText={savvyResultsHeader}
        hideIfEmpty
        isLoading={isSavvyCompaniesLoading}
        noResultsText={t`addCompany.noResults`}
        onAddCompany={onAddSavvyCompanyClick}
        rowClickRedirectsToWebsite={rowClicksRedirectsToWebsite}
      />

      <OrganizationSearchResultsTable
        id="crunchbase-search-results"
        buttonAddText={buttonAddText}
        buttonAddedText={buttonAddedText}
        data={crunchbaseCompaniesData}
        fromCB
        headerText={crunchbaseResultsHeader}
        isLoading={isCrunchbaseCompaniesLoading}
        noResultsText={t`addCompany.noResults`}
        onAddCompany={onAddCrunchbaseCompanyClick}
      />
    </Stack>
  );
};
