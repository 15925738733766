import type { RefObject } from 'react';
import { styled } from 'goober';

import { isPresent } from '@/helpers/is-present';
import {
  isInvitedUserOption,
  isOfType,
  isTeamBasicOption,
  isUserOption,
} from '@/helpers/other';
import type {
  InvitedUserOption,
  IOption,
  TeamBasicOption,
  UserOption,
} from '@/types';
import { SelectedBasicTeamOption } from '@/ui/select/async/components/selected-options-list/options/selected-basic-team-option';
import { SelectedInvitedUserOption } from '@/ui/select/async/components/selected-options-list/options/selected-invited-user-option';
import { SelectedUserOption } from '@/ui/select/async/components/selected-options-list/options/selected-user-option';
import { OptionsList } from '@/ui/select/async/components/selected-options-list/selected-options-list';

interface ISelectedOptionsList {
  height?: string;
  list?: (UserOption | TeamBasicOption | InvitedUserOption)[];
  listRef?: RefObject<HTMLDivElement>;
  isListExpanded?: boolean;
  onRemove?: (item: UserOption | TeamBasicOption | InvitedUserOption) => void;
  isDisabled?: boolean;
}

const OPTION_ITEM_HEIGHT = 60;

export function SelectedUserOrTeamOption({
  list,
  listRef,
  height,
  onRemove,
  isListExpanded = false,
  isDisabled,
}: ISelectedOptionsList) {
  const changeRole = (role: unknown, option: InvitedUserOption) => {
    if (!isOfType<IOption>(role, 'value')) return;

    option.role = { id: role.value, name: role.label };
  };

  return (
    <OptionsListUsersTeams
      height={height}
      name="users-and-teams-options-list"
      direction="column"
      defaultRef={listRef}
      isScrollable={Boolean(isListExpanded || onRemove)}
      maxHeight="360px"
    >
      {list
        ?.map((item, index) => {
          if (isUserOption(item)) {
            return (
              <SelectedUserOption
                key={item.value}
                option={item}
                index={index}
                isDisabled={isDisabled}
                onRemove={onRemove}
              />
            );
          }

          if (isInvitedUserOption(item)) {
            return (
              <SelectedInvitedUserOption
                key={item.value}
                option={item}
                index={index}
                isDisabled={isDisabled}
                onChangeRole={role => changeRole(role, item)}
                onRemove={onRemove}
              />
            );
          }

          if (isTeamBasicOption(item)) {
            return (
              <SelectedBasicTeamOption
                key={item.value}
                option={item}
                index={index}
                isDisabled={isDisabled}
                onRemove={onRemove}
              />
            );
          }
          return null;
        })
        .filter(isPresent)}
    </OptionsListUsersTeams>
  );
}

const OptionsListUsersTeams = styled(OptionsList)`
  max-height: calc(${OPTION_ITEM_HEIGHT}px * 3);

  &:not(:empty) {
    margin-top: 12px;
  }
`;
