import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const recentItemsTypes = ['all', 'project', 'company', 'team'] as const;

export type RecentItemType = (typeof recentItemsTypes)[number];

export const recentItemsTypeState = atom<RecentItemType>({
  key: 'selectedRecentItemsTypeState',
  default: 'all',
  effects: [persistAtom],
});
