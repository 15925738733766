import type { APIRequestBody } from './base-fetch';
import { post, type APIResponse } from './base-fetch';

type LocationPropertyObject = {
  id: string;
  name: string;
};

export type EnrichedCompanyLocation = {
  city: LocationPropertyObject | null;
  country: LocationPropertyObject | null;
  region: LocationPropertyObject | null;
};

export type Tag = {
  id: string;
  name: string;
  category: {
    id: string;
    name: string;
  } | null;
};

export type EnrichedCompanySearchSuggestion = Omit<
  APIResponse<'/api/v4/organization/enrich'>[number],
  'location' | 'tags'
> & {
  location: EnrichedCompanyLocation;
  tags: Tag[];
};

export type GetEnrichedCompaniesPayload =
  APIRequestBody<'/api/v4/organization/enrich'>;

export const fetchEnrichedCompanies = async (
  payload: GetEnrichedCompaniesPayload,
) => post<EnrichedCompanySearchSuggestion[]>('v4/organization/enrich', payload);
