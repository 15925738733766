export const getUserInitials = (user: {
  firstName: string | null;
  lastName: string | null;
  email?: string;
}) =>
  user.firstName && user.lastName
    ? [user.firstName.charAt(0), user.lastName.charAt(0)].join('')
    : user.email
    ? user.email.substring(0, 2).toLocaleUpperCase()
    : '';
