import { useRecoilValue } from 'recoil';

import { DOMAIN_EXTRACTOR_REGEX } from '@/regexes';
import { paths } from '@/routes/helpers/paths';

import type { OrganizationSearchResultsRowData } from '../../../components/organization-search-results/organization-search-results-table-row';
import { companiesSearchPhraseState } from '../overview/company.state';

import { organizationSearchResultsCompaniesBusyState } from './organization-search-results.state';
import { useCrunchbaseOrganizationsSearch } from './use-crunchbase-organizations-search.query';
import { useOrganizationsSavvySearchQuery } from './use-organizations-savvy-search-query';

export const useOrganizationsResults = ({
  checkIsAdded,
  checkIsActionButtonHidden,
}: {
  checkIsAdded: (id: string, fromCb: boolean) => boolean;
  checkIsActionButtonHidden?: (fromCb: boolean) => boolean;
}) => {
  const companiesSearchPhrase = useRecoilValue(companiesSearchPhraseState);
  const busyCompanies = useRecoilValue(
    organizationSearchResultsCompaniesBusyState,
  );

  const { data: savvyResults, isLoading: isSavvyCompaniesResultsLoading } =
    useOrganizationsSavvySearchQuery({
      search: companiesSearchPhrase,
      sort: 'rating',
      min: 0,
      max: 20,
    });
  const {
    data: crunchbaseResults,
    isLoading: isCrunchbaseCompaniesResultsLoading,
  } = useCrunchbaseOrganizationsSearch(companiesSearchPhrase);

  const permalinkFilter = savvyResults
    ? savvyResults.map(row => row.cbPermalink)
    : [];

  const extractDomain = (url?: string) => {
    if (!url) return undefined;
    const matches = url.match(DOMAIN_EXTRACTOR_REGEX);
    return matches ? matches[1] : null;
  };

  const alreadyInSavvy = (name: string, website?: string) => {
    if (!savvyResults) return false;
    return savvyResults.some(
      row =>
        row.name === name &&
        extractDomain(row.website || undefined) === extractDomain(website),
    );
  };

  const savvyResultsFiltered: OrganizationSearchResultsRowData[] =
    savvyResults?.map(row => ({
      imageUrl: row.logoUrl || undefined,
      name: row.name,
      isBusy: busyCompanies.includes(row.id),
      organizationId: row.id,
      permalink: row.cbPermalink || undefined,
      isAdded: checkIsAdded(row.id, false),
      shortDescription: row.keyOffering || '',
      isButtonHidden: checkIsActionButtonHidden
        ? checkIsActionButtonHidden(false)
        : false,
      listingCount: row.listingCount,
      companyPageUrl: paths.company({ companyId: row.id }),
      websiteUrl: row.website ?? '',
    })) || [];

  const crunchbaseResultsFiltered: OrganizationSearchResultsRowData[] =
    crunchbaseResults
      ?.filter(
        row =>
          !permalinkFilter.includes(row.permalink) &&
          !alreadyInSavvy(row.name, row.websiteUrl),
      )
      .map(row => ({
        crunchbaseId: row.uuid,
        imageUrl: row.imageUrl,
        name: row.name,
        isBusy: busyCompanies.includes(row.uuid),
        organizationId: row.organizationId,
        permalink: row.permalink,
        isAdded: checkIsAdded(row.organizationId ?? '', true),
        isButtonHidden: checkIsActionButtonHidden
          ? checkIsActionButtonHidden(true)
          : false,
        shortDescription: row.shortDescription,
        websiteUrl: row.websiteUrl,
        uuid: row.uuid,
        isDomainUse: row.isDomainUse,
      })) || [];

  return {
    savvyCompaniesResults: savvyResultsFiltered,
    isSavvyCompaniesResultsLoading,
    crunchbaseCompaniesResults: crunchbaseResultsFiltered,
    isCrunchbaseCompaniesResultsLoading,
  };
};
