import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from 'axios';
import axios from 'axios';

import { jwtTokenState } from '@/auth/auth.state';
import { getRecoil, setRecoil } from '@/shared/recoil-nexus';
import { notify } from '@/ui/snackbar/notify';

import i18n from '../lib/i18n/i18n';
import type { APP_USER_ROLES, IndexParams } from '../types';

import type { GrantAccessPayload } from '.';
import analystsPaths from './analysts-paths';
import {
  type ClientCreatePayload,
  type SearchResultsResponse,
  type StreamCopyPayload,
} from './types';

export enum KnownErrors {
  ItemNotFound = 'Item not found',
}

type ApiResponse<T = any> = Promise<AxiosResponse<T>>;

export const axiosInstance = axios.create({
  baseURL: analystsPaths.baseUrl,
});

export const api = (v3Ins => {
  let refreshUserRole: (role: APP_USER_ROLES) => void;
  let handleAuthenticationError: () => void;
  let handle404Error: () => void;

  const axiosInstance = v3Ins;

  const isAuthenticationError = (statusCode: number) => statusCode === 401;

  const isPermissionError = (statusCode: number): boolean => statusCode === 403;

  const isUnknownError = (statusCode: number): boolean => statusCode === 500;

  const isPageNotFoundError = (statusCode: number) => statusCode === 404;

  const applyAxiosInterceptors = (instance: AxiosInstance) => {
    instance.interceptors.response.use(
      response => {
        const authorizationToken = response.headers['authorization'];

        if (!authorizationToken) {
          handleAuthenticationError();
        } else if (authorizationToken) {
          setRecoil(jwtTokenState, authorizationToken);
        }

        return response;
      },
      (errorReturned: AxiosError) => {
        if (axios.isCancel(errorReturned)) {
          throw errorReturned;
        }
        const error: AxiosError = errorReturned;
        if ((error as AxiosError)?.response) {
          switch (true) {
            case error.response?.status &&
              isPageNotFoundError(error.response?.status) &&
              !error?.response.data:
              handle404Error();
              break;
            case error.response?.status &&
              isAuthenticationError(error.response?.status):
              handleAuthenticationError();
              break;
            case error.response?.status &&
              isPermissionError(error.response?.status):
              refreshUserRole(
                error.response?.headers['app-user-role'] as APP_USER_ROLES,
              );
              break;
            case error.response?.status &&
              isUnknownError(error.response?.status):
              notify(i18n.t('default:apiUnknownError'));
              break;
            default:
              notify(i18n.t('default:apiUnknownError'));
          }
        }

        throw error;
      },
    );

    instance.interceptors.request.use(config => {
      const customConfig = { ...config };
      const jwtToken = getRecoil(jwtTokenState);

      if (!customConfig.headers)
        customConfig.headers = {} as AxiosRequestHeaders; // Fix: Initialize as AxiosRequestHeaders
      if (jwtToken && !customConfig.headers['Authorization']) {
        customConfig.headers['Authorization'] = jwtToken;
      }

      return customConfig;
    });
  };

  applyAxiosInterceptors(axiosInstance);

  const init = ({
    onAuthenticationError,
    on404Error,
  }: {
    onAuthenticationError: () => void;
    on404Error: () => void;
  }) => {
    handleAuthenticationError = onAuthenticationError;
    handle404Error = on404Error;
  };

  const get = <T>(url: string, config?: AxiosRequestConfig): ApiResponse<T> =>
    axiosInstance.get<T>(url, config);

  const remove = (url: string, config?: AxiosRequestConfig): ApiResponse =>
    axiosInstance.delete(url, config);

  const post = <T, Data = unknown>(
    url: string,
    data?: Data,
    config?: AxiosRequestConfig,
  ): ApiResponse<T> => axiosInstance.post<T>(url, data, config);

  const put = <T>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): ApiResponse<T> => axiosInstance.put<T>(url, data, config);

  const patch = <T>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): ApiResponse<T> => axiosInstance.patch<T>(url, data, config);

  return {
    init,
    get,
    remove,
    post,
    put,
    patch,
    async searchForAutocomplete(params?: IndexParams) {
      return axiosInstance.get<SearchResultsResponse>(
        analystsPaths.search.autocomplete,
        { params },
      );
    },
    async copyStream(payload: StreamCopyPayload) {
      return axiosInstance.post(
        analystsPaths.streams.copy(payload.id),
        payload,
      );
    },
    async fetchStreamXLS(id: string) {
      return axiosInstance.get(analystsPaths.streams.exportXLS(id), {
        responseType: 'arraybuffer',
      });
    },
    async grantAccessToStreams(payload: GrantAccessPayload) {
      return axiosInstance.post(analystsPaths.streams.grantAccess, payload);
    },
    async grantAccessToOrganizations(payload: GrantAccessPayload) {
      return axiosInstance.post(
        analystsPaths.organizations.grantAccess,
        payload,
      );
    },
    async createOrganizationClient(payload: ClientCreatePayload) {
      return axiosInstance.post(analystsPaths.clients.index, payload);
    },
  };
})(axiosInstance);
