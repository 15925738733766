import type { ComponentType } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { styled } from 'goober';
import { useSetRecoilState } from 'recoil';

import { useKeyPressListener } from '@/hooks/use-key-press-listener';
import { modalState } from '@/state/modal.state';
import { Z_INDEX_MODAL_OVERLAY } from '@/theme/z-index';

import { Flexbox } from '../flexbox/flexbox';
import { Portal } from '../portal/portal';

import type { FooterProps } from './footer';
import { Footer } from './footer';
import { Header } from './header';

export type ModalVariant = 'danger' | 'info';

export interface Action {
  label: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
}

type IModalProps = {
  children: React.ReactNode;
  CustomHeaderComponent?: ComponentType;
  onClose?: () => void;
  outsideClose?: boolean;
  header?: string;
  closeIcon?: boolean;
  variant?: FooterProps['variant'];
  mainAction?: FooterProps['mainAction'];
  secondaryAction?: FooterProps['secondaryAction'];
  disabled?: FooterProps['disabled'];
  loading?: FooterProps['loading'];
  className?: string;
};

export const Modal = ({
  children,
  CustomHeaderComponent,
  onClose,
  outsideClose = true,
  header,
  variant = 'info',
  mainAction,
  secondaryAction,
  disabled,
  loading,
  closeIcon = true,
  className,
}: IModalProps) => {
  const setModalState = useSetRecoilState(modalState);
  const handleClose = onClose || (() => setModalState(null));

  useKeyPressListener({ onKeyPress: handleClose, key: 'Escape' });

  return (
    <Portal>
      <Backdrop>
        <OutsideClickHandler
          onOutsideClick={handleClose}
          disabled={!outsideClose}
        >
          <ModalContainer
            data-testid="page-modal-dialog-container"
            name="modal-container"
            direction="column"
            justify="end"
            className={className}
          >
            <Header
              CustomHeaderComponent={CustomHeaderComponent}
              onClose={handleClose}
              header={header}
              closeIcon={closeIcon}
            />

            {children}

            <Footer
              variant={variant}
              mainAction={mainAction}
              secondaryAction={secondaryAction}
              loading={loading}
              disabled={disabled}
            />
          </ModalContainer>
        </OutsideClickHandler>
      </Backdrop>
    </Portal>
  );
};

const Backdrop = styled('div')`
  background-color: rgba(2, 6, 44, 0.4);
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 50vw;
  transform: translate3d(-50%, 0, 0);
  position: fixed;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${Z_INDEX_MODAL_OVERLAY};
`;

const ModalContainer = styled(Flexbox)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.basics.white};
  border-radius: 30px;
  box-shadow: ${({ theme }) => theme.shadow.modal};
  padding: 38px 44px;
  min-width: 808px;
`;
