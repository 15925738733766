import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { fetchSearchSuggestions } from '@/api/v4/search.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';
import { useCallback } from 'react';

export const useSearchResults = (searchPhrase: string | null) => {
  const { t } = useTranslation('default');

  const fetch = useCallback(() => {
    if (!searchPhrase) {
      return [];
    }
    return fetchSearchSuggestions(searchPhrase);
  }, [searchPhrase]);

  return useQuery([QueryKey.Search, searchPhrase], fetch, {
    onError: () =>
      notify({
        message: t`unknownError`,
      }),
    staleTime: 1000 * 10,
  });
};
