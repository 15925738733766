import { scrollMainLayoutTo } from '@/page-layout';

import type { CompanySection } from '../overview/sections-pane/sections-pane.state';
import { GRID_GAP } from '../overview/utils/constants';

export const scrollToSection = (section: CompanySection) => {
  const sectionElement = document.querySelector(
    `.section-${section}`,
  ) as HTMLDivElement | null;

  if (!sectionElement) return;

  scrollMainLayoutTo({
    top: sectionElement.offsetTop - GRID_GAP,
    behavior: 'smooth',
  });
};
