import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { modalState } from '@/state/modal.state';
import { Modal } from '@/ui/modal/modal';
import { SubHeaderBold } from '@/ui/typography/widgets';

import { SharedAssets } from '../settings/user-management/shared-assets/shared-assets';

export const SharedAssetsModal = () => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'sharedAssets',
  });
  const modal = useRecoilValue(modalState);

  if (modal?.state !== 'showSharedAssets') {
    return null;
  }

  return (
    <Modal
      CustomHeaderComponent={SubHeaderBold}
      header={t('assetsSharedWith', {
        user: modal.userDisplayName,
      })}
    >
      <SharedAssets userEmail={modal.email} />
    </Modal>
  );
};
