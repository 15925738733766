import { useState } from 'react';

import { getInitials } from '@/helpers/get-initials';
import { WithDropdownContent } from '@/ui/dropdown/with-dropdown-content';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { SmallText } from '@/ui/typography/widgets';
import { useCurrentUser } from '@/user/use-current-user.query';
import { styled } from 'goober';
import { AccountSettingsIcon, NavButtonDefault } from '../../navbar.styles';
import { DropdownAccountsContent } from './dropdown-accounts-content';

interface DropdownAccountsProps {
  onAccountSwitch: (userInAccountId: number) => void;
}

export function DropdownAccounts({ onAccountSwitch }: DropdownAccountsProps) {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const { data } = useCurrentUser();
  const userAccounts = data?.userAccounts || [];

  const activeUserAccount = userAccounts.find(
    userAccount => userAccount.userInAccountId === data?.id,
  );

  if (!activeUserAccount) return null;

  const userAccountOptions = userAccounts.filter(
    userAccount =>
      userAccount.userInAccountId !== activeUserAccount.userInAccountId,
  );

  if (userAccountOptions.length === 0) return null;

  return (
    <Tooltip
      visible={!isDropdownOpened}
      content={activeUserAccount.account.name}
      placement="right"
    >
      <Container data-testid="user-accounts">
        <NavWrapper>
          <WithDropdownContent
            content={
              <DropdownAccountsContent
                items={userAccountOptions}
                onClick={onAccountSwitch}
              />
            }
            fullWidth
            options={{
              placement: 'top-start',
              modifiers: [{ name: 'offset', options: { offset: [0, -50] } }],
            }}
            setIsDropdownOpened={setIsDropdownOpened}
          >
            <Flexbox
              name="settings-user-account"
              alignItems="center"
              justify="space-around"
            >
              <Flexbox
                name="settings-active-account"
                gap="4px"
                alignItems="baseline"
              >
                <AccountSettingsIcon
                  label={getInitials(activeUserAccount.account.name)}
                  selected={isDropdownOpened}
                  isButtonHovered={false}
                />
                <AccountName>{activeUserAccount.account.name}</AccountName>
              </Flexbox>
              <Icon icon="CaretDown" />
            </Flexbox>
          </WithDropdownContent>
        </NavWrapper>
      </Container>
    </Tooltip>
  );
}

const AccountName = styled(SmallText)`
  color: ${({ theme }) => theme.colors.basics.black};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Container = styled('div')`
  border: 1px solid #c6c8d8;
  padding: 4px;
  gap: 0px;
  border-radius: 10px;
  justify: space-between;
  opacity: 0px;
`;

const NavWrapper = styled('div')`
  ${({ theme }) => NavButtonDefault(theme)}

  p {
    opacity: 0;
  }
  .is-open & {
    p {
      opacity: 1;
    }
  }
`;
