import { useRef } from 'react';
import { components } from 'react-select';

import { colors } from '@/theme/colors';
import type { PlaceholderOption } from '@/types';
import {
  OptionContainer,
  OptionLabel,
} from '@/ui/select/async/async-select.styles';
import type { AsyncSelectOptionProps } from '@/ui/select/async/components/option/option';

interface PlaceholderOptionProps extends AsyncSelectOptionProps {
  data: PlaceholderOption;
}

export function PlaceholderOption({ data, ...props }: PlaceholderOptionProps) {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <components.Option data={data} {...props} isDisabled>
      <OptionContainer isSelected={props.isSelected} style={{ height: '60px' }}>
        <OptionLabel ref={ref} {...{ color: colors.system.disable }}>
          {data.label}
        </OptionLabel>
      </OptionContainer>
    </components.Option>
  );
}
