import type { ReactNode } from 'react';
import React from 'react';
import type { DefaultTheme } from 'goober';
import { styled } from 'goober';

import { Spinner } from '@/components/loader/spinner';
import { componentsPalette } from '@/theme/colors';
import type { IconVariant } from '@/ui/icons/icon';
import { Icon } from '@/ui/icons/icon';

import { colorIcon } from '../icons/functions';

export type Variant =
  | 'primary'
  | 'secondary'
  | 'navy'
  | 'ghost'
  | 'white'
  | 'danger'
  | 'dangerText'
  | 'underlined'
  | 'text'
  | 'purple'
  | 'gray'
  | 'black';

type ContentPadding = 'lg' | 'md' | 'sm';

type Size = 'normal' | 'big';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant;
  fullWidth?: boolean;
  isLoading?: boolean;
  hidden?: boolean;
  startIcon?: IconVariant | 'Loader';
  endIcon?: IconVariant | 'Loader';
  className?: string;
  iconColor?: string;
  isActive?: boolean;
  height?: number;
  width?: number;
  size?: Size;
  textCenter?: boolean;
  contentPadding?: ContentPadding;
  children?: ReactNode;
}

export const Button = ({
  startIcon,
  endIcon,
  children,
  variant = 'primary',
  onClick,
  type,
  hidden,
  isLoading,
  fullWidth,
  disabled,
  className,
  iconColor,
  isActive,
  color,
  height,
  width,
  size = 'normal',
  textCenter = false,
  contentPadding = 'md',
  ...rest
}: ButtonProps) => {
  const props = {
    variant,
    onClick,
    fullWidth,
    className,
    height,
    width,
    size,
    textCenter,
    type: type || (onClick ? 'button' : 'submit'),
    contentPadding,
    ...rest,
  };

  return (
    <ButtonBase
      {...props}
      iconColor={iconColor}
      disabled={disabled || isLoading}
      isLoading={isLoading}
      isActive={isActive}
      color={color}
      hidden={hidden}
    >
      {startIcon && startIcon !== 'Loader' && <Icon icon={startIcon} />}
      {startIcon === 'Loader' && (
        <Spinner color={componentsPalette.button[variant].text} />
      )}
      {children}
      {endIcon === 'Loader' && (
        <Spinner color={componentsPalette.button[variant].text} />
      )}
      {endIcon && endIcon !== 'Loader' && <Icon icon={endIcon} />}
    </ButtonBase>
  );
};

type ButtonBaseProps = Pick<
  ButtonProps,
  | 'fullWidth'
  | 'isLoading'
  | 'iconColor'
  | 'isActive'
  | 'hidden'
  | 'color'
  | 'height'
  | 'width'
  | 'size'
  | 'textCenter'
> & {
  variant: Variant;
  contentPadding: ContentPadding;
};

const ButtonBase = styled('button')<ButtonBaseProps>`
  display: flex;
  pointer-events: auto;
  justify-content: ${({ textCenter }) =>
    textCenter ? 'center' : 'space-between'};
  align-items: center;
  gap: 0.3rem;
  border: 1px solid transparent;
  border-radius: 100px;
  outline: none;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  white-space: nowrap;
  padding: 5px
    ${({ contentPadding }) =>
      contentPadding === 'sm' ? 8 : contentPadding === 'md' ? 12 : 20}px;
  cursor: pointer;
  ${({ hidden }) => hidden && 'visibility: hidden;'}

  ${({ theme }) => theme.typography.acrossPlatform.buttonText}

  background-color: ${({ theme, variant, isActive }) =>
    isActive
      ? theme.palettes.component.button[variant].hover
      : theme.palettes.component.button[variant].main};
  color: ${({ theme, variant }) =>
    theme.palettes.component.button[variant].text};
  ${({ theme, variant }) =>
    colorIcon(theme.palettes.component.button[variant].text)}

  ${({ theme, variant }) => buttonMixins(variant, theme)}

  ${({ height }) =>
    height &&
    `
    height: ${height}px;
    max-height: ${height}px;
  `};

  ${({ width }) =>
    width &&
    `
    width: ${width}px;
    max-width: ${width}px;
  `};

  ${({ size }) =>
    size === 'big' &&
    `
    padding-top: 8px;
    padding-bottom: 8px;
  `};

  &:hover {
    ${({ theme, variant }) => ({
      backgroundColor: theme.palettes.component.button[variant].hover,
      color: theme.palettes.component.button[variant].hoverText,
    })}
  }
  &:disabled {
    background-color: ${({ theme, variant }) =>
      theme.palettes.component.button[variant].disabled};
    color: ${({ theme, variant }) =>
      theme.palettes.component.button[variant].disabledText};
    cursor: not-allowed;
  }

  ${({ iconColor }) => !!iconColor && colorIcon(iconColor)}

  ${({ color }) =>
    !!color &&
    `
    ${colorIcon(color)}
    color: ${color};
    `}
`;

const buttonMixins = (variant: Variant, theme: DefaultTheme) => {
  switch (variant) {
    case 'underlined':
      return `
        border-bottom: 1px solid ${theme.colors.gray.c12};
        border-radius: 0;
        padding: 4px 0;
      `;

    default:
      return;
  }
};
