import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { modalState } from '@/state/modal.state';

import { useUpdateTeam } from '../teams/use-update-team';

import { ConfirmDeleteModal } from './confirm-delete-modal';

export const DeleteTeamMemberModal = () => {
  const modal = useRecoilValue(modalState);
  const { t } = useTranslation('teams');
  const { removeTeamMember } = useUpdateTeam();

  if (!modal || modal.state !== 'deleteTeamMember') return null;

  const { user } = modal;
  return (
    <ConfirmDeleteModal
      title={t`areYouSure`}
      description={t('deleteTeamMemberConfirm', {
        name: `${user.firstName} ${user.lastName}`,
      })}
      onConfirm={async () => {
        if (!user.team) return;
        await removeTeamMember(user.team.id, user.id);
        modal.onConfirm();
      }}
      confirmLabel={t`Remove`}
    />
  );
};
