import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import {
  EditStamp,
  type EditStampProps,
} from '@/features/companies/overview/edit-stamp';
import { Z_INDEX_FILTERS_DROPDOWN_MENU } from '@/theme/z-index';
import { Icon } from '@/ui/icons/icon';
import type { ReadMoreWidgetButtonProps } from '@/ui/widget/read-more-widget-button';
import { ReadMoreWidgetButton } from '@/ui/widget/read-more-widget-button';

import { Flexbox } from '../flexbox/flexbox';

interface BottomContent {
  padding?: string;
}
interface EditIconProps {
  color?: string;
  padding?: string;
  background?: string;
  borderRadius?: string;
}

export interface WidgetProps {
  showMore?: boolean;
  children?: React.ReactNode;
  header?: React.ReactNode;
  height?: number;
  width?: number;
  maxWidth?: number;
  className?: string;
  id?: string;
  isEditMode?: boolean;
  showExpandButton?: boolean;
  showEditButton?: boolean;
  showEditStamp?: boolean;
  labelExpand?: string;
  labelCollapse?: string;
  onClick?: () => void;
  onEditClick?: () => void;
  onReadMoreClick?: () => void;
  disabled?: boolean;
  padding?: string;
  shadowOnHover?: boolean;
  backgroundColor?: string;
  direction?: string;
  bottomContentProps?: BottomContent;
  readMoreProps?: Pick<ReadMoreWidgetButtonProps, 'color'>;
  editStampProps?: EditStampProps;
  editIconProps?: EditIconProps;
  dataTestId?: string;
}

export const WIDGET_CONTAINER_PADDING_VERTICAL = 36;
export const WIDGET_CONTAINER_PADDING_HORIZONTAL = 40;
export const WIDGET_CONTAINER_MAX_HEIGHT = 700;
const WIDGET_CONTAINER_DEFAULT_HEIGHT = 237;

export const Widget = forwardRef<HTMLDivElement, WidgetProps>(function Widget(
  {
    children,
    header,
    onClick,
    onEditClick,
    onReadMoreClick,
    height = WIDGET_CONTAINER_DEFAULT_HEIGHT,
    width,
    maxWidth,
    className,
    id,
    showExpandButton = false,
    showEditStamp = false,
    showEditButton = true,
    isEditMode = false,
    showMore = false,
    disabled = false,
    padding,
    shadowOnHover = false,
    backgroundColor,
    readMoreProps,
    bottomContentProps,
    editStampProps,
    editIconProps,
    labelExpand,
    labelCollapse,
    dataTestId,
  },
  ref,
) {
  const { t } = useTranslation('projects');
  const isBottomContentVisible = showExpandButton || showEditStamp;

  return (
    <Container
      className={className}
      id={id}
      ref={ref}
      tabIndex={0}
      onClick={() => {
        if (disabled) return;
        onClick?.();
      }}
      minHeight={height}
      minWidth={width}
      maxWidth={maxWidth}
      isEditMode={isEditMode}
      padding={padding}
      shadowOnHover={shadowOnHover}
      backgroundColor={backgroundColor}
      data-testid={dataTestId}
    >
      {showEditButton && !disabled && !isEditMode && (
        <EditIcon
          icon="Pen"
          role="button"
          onClick={onEditClick}
          color={editIconProps?.color}
          {...editIconProps}
        />
      )}
      {header}
      {children}
      {isBottomContentVisible && (
        <BottomContentContainer
          name="widget-bottom-content"
          alignContent="center"
          alignItems="flex-end"
          grow={1}
          fullWidth
          {...bottomContentProps}
        >
          {showExpandButton && (
            <ReadMoreWidgetButton
              onClick={() => onReadMoreClick?.()}
              showMore={showMore}
              label={
                showMore
                  ? labelCollapse ?? t`projectOverview.readLess`
                  : labelExpand ?? t`projectOverview.readMore`
              }
              {...readMoreProps}
            />
          )}

          {showEditStamp && (
            <EditStamp
              {...editStampProps}
              crunchbasePermalink={editStampProps?.crunchbasePermalink ?? null}
            />
          )}
        </BottomContentContainer>
      )}
    </Container>
  );
});

const Container = styled('div', forwardRef)<{
  minHeight: number;
  minWidth?: number;
  maxWidth?: number;
  isEditMode: boolean;
  padding?: string;
  shadowOnHover?: boolean;
  backgroundColor?: string;
  direction?: string;
}>`
  position: relative;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ?? theme.colors.basics.white};
  padding: ${({ padding }) =>
    padding ??
    `${WIDGET_CONTAINER_PADDING_VERTICAL}px ${WIDGET_CONTAINER_PADDING_HORIZONTAL}px`};
  border-radius: 20px;
  box-shadow: ${({ theme, shadowOnHover }) =>
    shadowOnHover ? 'none' : theme.shadow.widgets};
  position: relative;
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px`};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px`};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};

  ${({ direction }) => direction && `flex-direction: ${direction};`}

  ${({ theme, isEditMode }) =>
    isEditMode &&
    `
      outline: 2px solid ${theme.colors.blue.primaryA};
    `}

  ${({ theme, shadowOnHover }) =>
    shadowOnHover &&
    `
    &:hover {
      box-shadow: ${theme.shadow.widgets};
    }
    `}
`;

const EditIcon = styled(Icon)<EditIconProps>`
  cursor: pointer;
  pointer-events: none;
  position: absolute;
  right: 36px;
  top: 36px;
  transform: scale3d(0, 0, 0);
  ${({ theme }) => theme.mixins.transition('transform')}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ background }) => background && `background: ${background};`}
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`}
  z-index: ${Z_INDEX_FILTERS_DROPDOWN_MENU};

  :not(.edit-mode):hover > & {
    pointer-events: auto;
    transform: scale3d(1, 1, 1);
  }
`;

const BottomContentContainer = styled(Flexbox)<BottomContent>`
  padding: ${({ padding = '24px 0 0 0' }) => padding};
`;
