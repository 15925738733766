import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { QueryKey } from '@/config/query-client';
import { MeetingsTab } from '@/features/action-panel/meetings/meetings-tab';

import { useHandleDeleteMeeting } from '../../action-panel/meetings/hooks/use-handle-delete-meeting';
import { useProject } from '../overview/use-project.query';
import { projectIdState } from '../project.state';

import { useProjectMeetings } from './meetings/use-project-meetings.query';

export const ProjectMeetingsTab = () => {
  const queryClient = useQueryClient();

  const { data: meetings, isFetching } = useProjectMeetings();
  const projectId = useRecoilValue(projectIdState);
  const { data: project } = useProject();

  const refreshData = async () => {
    await queryClient.invalidateQueries([QueryKey.ProjectMeetings, projectId]);
  };

  const { onDeleteHandler, isDeleting } = useHandleDeleteMeeting({
    onRefreshData: refreshData,
  });

  if (!project) return null;

  return (
    <MeetingsTab
      meetingsData={meetings ?? []}
      refreshHandler={refreshData}
      isLoading={isFetching || isDeleting}
      deleteMeetingHandler={onDeleteHandler}
      newMeetingFormDefaultValues={{
        relatedProjectId: project.id,
        relatedTeamId: project.team ? project.team.id : undefined,
      }}
      withCompanyField
      context="project"
      isCompanyRequired
    />
  );
};
