import { Flexbox } from '@/ui/flexbox/flexbox';
import { styled } from 'goober';
import type {
  File,
  FileLinkDownloadHandler,
  FileScanning,
} from './file.interface';
import { ActionPanelItem } from '../action-panel';
import { FileCard } from './file-card';
import type { ShareFilePayload } from './edit-file';
import type { UseQueryResult } from '@tanstack/react-query';
import { useMultiRefs, type IGetRefs } from '@/hooks/use-multi-refs';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { actionPanelScrollToParamState } from '../action-panel.state';

export const FilesList = ({
  files,
  getRefs,
  editedFileId,
  editedFileIdSetter,
  onClickDelete,
  onFileLinkDownload,
  onShareFile,
  allowToChangeFilePrivacy = true,
  useFileScanningStatus,
}: {
  files: File[];
  getRefs?: IGetRefs;
  editedFileId: string | null;
  editedFileIdSetter: (fileId: string | null) => void;
  onClickDelete: (fileId: string, fileName: string) => void;
  onFileLinkDownload: FileLinkDownloadHandler;
  onShareFile: (payload: ShareFilePayload) => Promise<void>;
  allowToChangeFilePrivacy?: boolean;
  useFileScanningStatus: (
    file: File,
  ) => UseQueryResult<FileScanning | undefined>;
}) => {
  const isAlreadyScrolled = useRecoilValue(actionPanelScrollToParamState);
  const filesRefs = useMultiRefs({
    getRefs: isAlreadyScrolled ? undefined : getRefs,
  });

  useEffect(() => {
    if (isAlreadyScrolled) return;
    getRefs?.(filesRefs.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesRefs, isAlreadyScrolled]);

  return (
    <ListContainer name="file-list-container" direction="column" grow={1}>
      {files.map(file => (
        <ActionPanelItem
          key={file.id}
          name="action-panel-item"
          alignItems="center"
          gap="16px"
          margin="0 12px"
          borderWidth="2px"
        >
          <Flexbox name="action-panel-text" gap="16px" grow={1}>
            <FileCard
              file={file}
              editedFileId={editedFileId}
              setEditedFileId={fileId => editedFileIdSetter(fileId)}
              onClickDelete={onClickDelete.bind(null, file.id, file.name)}
              onFileLinkDownload={onFileLinkDownload}
              onShareFile={onShareFile}
              allowToChangeFilePrivacy={allowToChangeFilePrivacy}
              useFileScanningStatus={useFileScanningStatus}
              ref={el => (filesRefs.current[file.id] = el)}
            />
          </Flexbox>
        </ActionPanelItem>
      ))}
    </ListContainer>
  );
};

const ListContainer = styled(Flexbox)`
  background-color: ${({ theme }) => theme.colors.basics.white};
  overflow: auto;
  ${({ theme }) => theme.mixins.scrollbar}
`;
