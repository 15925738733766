import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteTeam } from '@/api/v4/team.api';
import { QueryKey } from '@/config/query-client';

export const useDeleteTeam = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteTeam, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([QueryKey.Teams]),
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);
    },
  });
};
