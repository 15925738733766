import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorSVG from '@/assets/svg/ai-enhancement-error.svg';
import { Stack } from '@/ui/line/line';
import { Header, ParagraphMedium } from '@/ui/typography/widgets';

import { useProjectListingSuggestionState } from '../use-project-listing-suggestion.state';

export const EnhanceProjectErrorView = () => {
  const { t } = useTranslation('projects');
  const state = useProjectListingSuggestionState();

  useEffect(() => {
    if (state.currentPageStep !== 'error') state.setCurrentPageStep('error');
  }, []);

  return (
    <Stack gap="36px" centered margin="0px auto 39px auto">
      <img src={ErrorSVG} />
      <Stack gap="0px" centered>
        <Header>{t`enhanceErrorHeader`}</Header>
        <ParagraphMedium>{t`enhanceError1`}</ParagraphMedium>
        <ParagraphMedium>{t`enhanceError2`}</ParagraphMedium>
      </Stack>
    </Stack>
  );
};
