import { useTranslation } from 'react-i18next';

import { useLogout } from '@/auth/hooks/use-logout.mutation';
import { TopbarWrapper } from '@/features/topbar/topbar';
import { Button } from '@/ui/button/button';

export const SettingsHeader = () => {
  const logout = useLogout();
  const { t } = useTranslation('settings');

  return (
    <TopbarWrapper
      name="settings-header"
      bgColor="transparent"
      justify="flex-end"
    >
      <Button
        variant="secondary"
        startIcon="ArrowLeftFromSquare"
        onClick={logout}
      >{t`logout`}</Button>
    </TopbarWrapper>
  );
};
