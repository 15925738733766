import type { ReactNode } from 'react';
import { styled } from 'goober';

import { Flexbox } from '../flexbox/flexbox';

import type { TagVariant } from './variants';

type IWeight = 'normal' | 'semibold';

interface StatusTagProps {
  variant: TagVariant;
  children?: ReactNode;
  weight?: IWeight;
  onClick?: () => void;
}

const weights = {
  normal: 400,
  semibold: 500,
};

export const StatusTag = ({
  variant,
  children,
  onClick,
  weight = 'normal',
}: StatusTagProps) => (
  <TagWrapper
    name="status-tag"
    alignItems="center"
    justify="space-between"
    gap="8px"
    variant={variant}
    weight={weight}
    onClick={onClick}
  >
    {children}
  </TagWrapper>
);

const TagWrapper = styled(Flexbox)<{
  variant: TagVariant;
  weight: IWeight;
}>`
  max-width: fit-content;
  border-radius: 4px;
  padding: 4px 12px;
  white-space: nowrap;

  background-color: ${({ theme, variant }) =>
    theme.palettes.component.tag[variant].main};
  color: ${({ theme, variant }) => theme.palettes.component.tag[variant].text};

  ${({ theme }) => theme.typography.atoms.tags.status}
  font-weight: ${({ weight }) => weights[weight]};
`;
