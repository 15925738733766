import { styled } from 'goober';

import { TeamLogo } from '@/ui/logo/logo';

import { SuggestionContainer } from './suggestion-container';
import { SuggestionContent } from './suggestion-content';

export const TeamSuggestion = ({
  onClick,
  name,
  type,
  highlights,
  isSelected,
  id,
}: {
  onClick?: () => void;
  name: string;
  type: string;
  highlights?: string[];
  isSelected?: boolean;
  id: string;
}) => {
  return (
    <SuggestionContainer
      id={id}
      onClick={onClick}
      label={name}
      isSelected={isSelected}
    >
      <SmallTeamLogo name={name} size="20px" />
      <SuggestionContent name={name} type={type} highlights={highlights} />
    </SuggestionContainer>
  );
};

const SmallTeamLogo = styled(TeamLogo)`
  font-size: 9px;
`;
