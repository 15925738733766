import { type ReactNode } from 'react';
import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';

export const SuggestionContainer = ({
  onClick,
  children,
  label,
  isSelected,
  id,
}: {
  onClick?: () => void;
  children: ReactNode;
  label: string;
  isSelected?: boolean;
  id?: string;
}) => {
  return (
    <Container
      id={id}
      dataTestId={label}
      name="search-item-container"
      gap="16px"
      alignItems="center"
      isClickable={Boolean(onClick)}
      isSelected={Boolean(isSelected)}
      onClick={onClick}
    >
      {children}
    </Container>
  );
};

const Container = styled(Flexbox)<{
  isClickable?: boolean;
  isSelected?: boolean;
}>`
  padding: 15px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.c3};
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.blue.c9 : theme.colors.basics.white};
  overflow: hidden;
  ${({ isClickable, theme }) =>
    isClickable &&
    `
      cursor: pointer;
      &:hover {
        background-color: ${theme.colors.blue.c9};
      }
  `}
`;
