import type { ControlProps } from 'react-select';
import { components } from 'react-select';

import { ControlContainer } from '../async-select.styles';

export function AsyncSelectControl({
  children,
  ...props
}: ControlProps<unknown, boolean>) {
  return (
    <components.Control {...props}>
      <ControlContainer isFocused={props.isFocused}>
        {children}
      </ControlContainer>
    </components.Control>
  );
}
