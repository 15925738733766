import { useEffect, useState } from 'react';

import { useKeyPressListener } from '@/hooks/use-key-press-listener';

import type { SearchedListItem } from './use-searched-item-click';
import { useSearchedItemClick } from './use-searched-item-click';

export const useKeyboardNavigationOnSearchedListItems = (
  searchedListItems: SearchedListItem[],
) => {
  const [selectedListItem, setSelectedListItem] =
    useState<SearchedListItem | null>(null);
  const handleSearchedItemClick = useSearchedItemClick();

  const navigateList = (direction: 'up' | 'down') => {
    const firstSuggestion = searchedListItems[0];
    const lastSuggestion = searchedListItems[searchedListItems?.length - 1];

    setSelectedListItem(currentlySelected => {
      if (!currentlySelected) {
        return direction === 'down' ? firstSuggestion : lastSuggestion;
      }

      if (direction === 'down' && currentlySelected.id === lastSuggestion.id) {
        return firstSuggestion;
      }

      if (direction === 'up' && currentlySelected.id === firstSuggestion.id) {
        return lastSuggestion;
      }

      const currentIndex = searchedListItems.findIndex(
        suggestion => suggestion.id === currentlySelected.id,
      );
      const newIndex =
        direction === 'down' ? currentIndex + 1 : currentIndex - 1;
      const newSelected = searchedListItems[newIndex];

      if (!newSelected) {
        return currentlySelected;
      }

      return newSelected;
    });
  };

  useEffect(() => {
    if (!selectedListItem) {
      return;
    }

    document
      .getElementById(selectedListItem.id)
      ?.scrollIntoView({ behavior: 'smooth' });
  }, [selectedListItem]);

  useKeyPressListener({
    onKeyPress: () => navigateList('down'),
    key: 'ArrowDown',
  });

  useKeyPressListener({
    onKeyPress: () => navigateList('up'),
    key: 'ArrowUp',
  });

  useKeyPressListener({
    onKeyPress: async () => {
      if (!selectedListItem) {
        return;
      }

      await handleSearchedItemClick({
        type: selectedListItem.type,
        id: selectedListItem.id,
      });
    },
    key: 'Enter',
  });

  return {
    selectedListItem,
  };
};
