import type { APIRequestBody, APIRequestQueryParams } from './base-fetch';
import { type APIResponse, get, post, put, remove } from './base-fetch';

export type TeamContact =
  APIResponse<'/api/v4/teams/{teamId}/contacts'>[number];

export type TeamContactPayload =
  APIRequestBody<'/api/v4/teams/{teamId}/contacts/create'>;

export type TeamContactParams =
  APIRequestQueryParams<'/api/v4/teams/{teamId}/contacts'>;

export const fetchTeamContacts = async (
  teamId: string,
  params: TeamContactParams,
) => get<TeamContact[]>(`/v4/teams/${teamId}/contacts`, { params });

export const createTeamContact = async (
  teamId: string,
  payload: TeamContactPayload,
) => post<TeamContact>(`/v4/teams/${teamId}/contacts/create`, payload);

export const updateTeamContact = async (
  teamId: string,
  teamContactId: string,
  payload: TeamContactPayload,
) =>
  put<TeamContact, TeamContactPayload>(
    `/v4/teams/${teamId}/contacts/${teamContactId}/update`,
    payload,
  );

export const deleteTeamContact = async (
  teamId: string,
  teamContactId: string,
) => remove<void>(`/v4/teams/${teamId}/contacts/${teamContactId}/delete`);
