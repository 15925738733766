import type { ButtonHTMLAttributes } from 'react';
import { styled } from 'goober';

import { colorIcon } from '../icons/functions';
import type { IconVariant } from '../icons/icon';
import { Icon } from '../icons/icon';

import type { Variant } from './button';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant;
  icon: IconVariant;
  className?: string;
  isLoading?: boolean;
  color?: string;
  size?: string;
  dataTestId?: string;
}
export const IconButton = ({
  icon,
  variant = 'primary',
  onClick,
  disabled,
  className,
  isLoading = false,
  type,
  color,
  size = '12px',
  title,
  dataTestId,
}: ButtonProps) => {
  return (
    <ButtonBase
      className={className}
      variant={variant}
      onClick={onClick}
      disabled={disabled ?? isLoading}
      type={type}
      title={title}
      data-testid={dataTestId}
    >
      <Icon icon={icon} width={size} height={size} color={color} />
    </ButtonBase>
  );
};

interface ButtonBaseProps {
  variant: Variant;
}

const ButtonBase = styled('button')<ButtonBaseProps>`
  display: flex;
  pointer-events: auto;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  padding: 8px;

  background-color: ${({ theme, variant }) =>
    theme.palettes.component.button[variant].main};
  color: ${({ theme, variant }) =>
    theme.palettes.component.button[variant].text};
  ${({ theme, variant }) =>
    colorIcon(theme.palettes.component.button[variant].text)}

  &:hover {
    background-color: ${({ theme, variant }) =>
      theme.palettes.component.button[variant].hover};
  }
  &:disabled {
    background-color: ${({ theme, variant }) =>
      theme.palettes.component.button[variant].disabled};
    color: ${({ theme, variant }) =>
      theme.palettes.component.button[variant].disabledText};
    ${({ theme, variant }) =>
      colorIcon(theme.palettes.component.button[variant].disabledText)}
  }
`;
