import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import type { MeetingFormFields } from './use-meeting-form';
import { fetchProjectOptionById } from '@/api/v4/projects.api';
import type { ProjectOption } from '../meetings.state';

export const useMeetingDefaultProjectOption = () => {
  const { watch } = useFormContext<MeetingFormFields>();

  const [defaultProjectOption, setDefaultProjectOption] =
    useState<ProjectOption | null>(null);

  const relatedProjectId = watch('relatedProjectId');

  useEffect(() => {
    const setDefaultProject = async () => {
      if (relatedProjectId) {
        const projectOption = await fetchProjectOptionById(relatedProjectId);
        setDefaultProjectOption({
          label: projectOption.name,
          value: projectOption.id,
          createdFor: projectOption.team ? projectOption.team.id : null,
        });
        return;
      }

      setDefaultProjectOption(null);
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setDefaultProject();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedProjectId]);

  return {
    defaultProjectOption,
  };
};
