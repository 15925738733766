import type { APIResponse } from './base-fetch';
import { get } from './base-fetch';

export type ProjectUpdates =
  APIResponse<'/api/v4/projects/{projectId}/updates'>;

export type ProjectUpdate = ProjectUpdates[number];

export const fetchProjectUpdates = async (projectId: string) =>
  get<ProjectUpdates>(`/v4/projects/${projectId}/updates`);
