import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { TeamCommentPayload } from '@/api/v4/team-comments.api';
import { updateTeamComment } from '@/api/v4/team-comments.api';
import { notify } from '@/ui/snackbar/notify';
import { QueryKey } from '@/config/query-client';

type UpdateTeamCommentMutationParams = {
  teamId: string;
  commentId: string;
  payload: TeamCommentPayload;
};

export const useUpdateTeamComment = () => {
  const { t } = useTranslation('default');
  const queryClient = useQueryClient();

  return useMutation(
    async ({ teamId, commentId, payload }: UpdateTeamCommentMutationParams) => {
      return updateTeamComment(teamId, commentId, payload);
    },
    {
      onSuccess: async (_data, variables) => {
        await queryClient.invalidateQueries([
          QueryKey.TeamComments,
          variables.teamId,
        ]);
      },
      onError: (error: Error) => {
        notify({
          message: t`unknownError`,
        });
        captureException(error);
      },
    },
  );
};
