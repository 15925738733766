import type { ProjectCompaniesSort } from '@/api/v4/projects.api';
import { ListingStageName } from '@/features/listing-stage/types';
import type { HeaderCellConfig } from '@/ui/table/table.types';

const useCaseFitStageTabNames = [
  ListingStageName.discovery,
  ListingStageName.potentialFit,
  ListingStageName.contacted,
  ListingStageName.dueDiligence,
  ListingStageName.pilot,
];

export const getProjectListingsHeaders = (
  tabStageName: string,
  hasPermission: boolean,
): HeaderCellConfig<ProjectCompaniesSort>[] => [
  {
    columnKey: 'company',
    isVisible: true,
    sortKey: 'companyName',
  },
  {
    columnKey: 'founded',
    isVisible: true,
    sortKey: 'yearEstablished',
  },
  {
    columnKey: 'lastRound',
    isVisible: true,
    sortKey: 'fundingStage',
  },
  {
    columnKey: 'capitalRaised',
    isVisible: true,
    sortKey: 'capitalRaised',
  },
  {
    columnKey: 'ranking',
    isVisible: true,
    sortKey: 'averageRating',
  },
  {
    columnKey: 'useCaseFit',
    isVisible:
      hasPermission &&
      !!useCaseFitStageTabNames.some(name => name === tabStageName),
    width: '242px',
    sortKey: 'useCaseFit',
  },
  {
    columnKey: 'dealType',
    isVisible: tabStageName === ListingStageName.deal,
    sortKey: 'status',
    width: '240px',
  },
  {
    columnKey: 'movedFrom',
    isVisible: tabStageName === ListingStageName.notRelevant,
    sortKey: 'status',
  },
  {
    columnKey: 'action',
    isVisible: true,
    hideLabel: true,
    width: '22px',
    padding: '0',
  },
];
