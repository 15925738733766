import { useQuery } from '@tanstack/react-query';

import { fetchUsersToMentionList } from '@/api/v4/user.api';
import { QueryKey } from '@/config/query-client';

export const useUsersToMention = ({
  search,
  organizationId,
  projectId,
  teamId,
  items,
}: {
  search: string | null;
  organizationId?: string;
  projectId?: string;
  teamId?: string;
  items?: number;
}) =>
  useQuery(
    [
      QueryKey.UsersToMentionList,
      search,
      organizationId,
      projectId,
      teamId,
      items,
    ],
    () => {
      return fetchUsersToMentionList({
        ...(search && { search }),
        ...(organizationId && { organizationId }),
        ...(projectId && { projectId }),
        ...(teamId && { teamId }),
        ...(items && { items }),
      });
    },
    {
      enabled: Boolean(organizationId || projectId || teamId),
    },
  );
