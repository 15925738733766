import type { RefObject } from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { styled } from 'goober';

import { isEllipsis } from '@/helpers/other';
import { colors } from '@/theme/colors';
import type { IOption } from '@/types';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Tooltip } from '@/ui/tooltip/tooltip';
import {
  WIDGET_CONTAINER_MAX_HEIGHT,
  WIDGET_CONTAINER_PADDING_VERTICAL,
} from '@/ui/widget/widget';

export interface SelectedOptionsListProps {
  height?: string;
  list?: IOption[];
  listRef?: RefObject<HTMLDivElement>;
  isListExpanded?: boolean;
  onRemove?: (item: IOption) => void;
}
export function SelectedOptionsList({
  list,
  listRef,
  height,
  onRemove,
  isListExpanded = false,
}: SelectedOptionsListProps) {
  const [isReady, setIsReady] = useState(false);
  const refItemLabel = useRef<(HTMLDivElement | null)[]>([]);
  const editMode = Boolean(onRemove);
  const [maxHeight, setMaxHeight] = useState<number | null>();

  useEffect(() => {
    refItemLabel.current && setIsReady(true);
  }, [refItemLabel]);

  useEffect(() => {
    if (!listRef?.current) return;

    listRef.current.scrollTop = 0;
    const widgetBottomHeight = (
      listRef.current.offsetParent?.querySelector(
        '[data-name="widget-bottom-content"]',
      ) as HTMLDivElement | null
    )?.offsetHeight;

    setMaxHeight(
      WIDGET_CONTAINER_MAX_HEIGHT -
        WIDGET_CONTAINER_PADDING_VERTICAL -
        (listRef?.current?.offsetTop ?? 0) -
        (widgetBottomHeight ? widgetBottomHeight + 12 : 0),
    );
  }, [isListExpanded, listRef, onRemove]);

  if (!list) return null;

  return (
    <OptionsList
      height={height}
      name="options-list"
      direction="column"
      gap="16px"
      defaultRef={listRef}
      editMode={editMode}
      isScrollable={Boolean(isListExpanded || onRemove)}
      {...(maxHeight && { maxHeight: `${maxHeight}px` })}
    >
      {list.map((item, index) => (
        <OptionsListItem
          key={item.value}
          name="options-list-item"
          justify="space-between"
        >
          <Tooltip
            placement="left"
            content={item.label}
            visible={isReady && isEllipsis(refItemLabel.current[index])}
          >
            <OptionsListItemLabel
              ref={element => {
                refItemLabel.current[index] = element;
              }}
            >
              {item.label}
            </OptionsListItemLabel>
          </Tooltip>
          {onRemove && (
            <Icon
              icon="Close"
              width="10px"
              height="10px"
              color={colors.gray.c13}
              onClick={() => onRemove?.(item)}
            />
          )}
        </OptionsListItem>
      ))}
    </OptionsList>
  );
}

export const OptionsList = styled(Flexbox)<{
  height?: string;
  editMode?: boolean;
  isScrollable: boolean;
  maxHeight?: string;
}>`
  ${({ theme }) => theme.mixins.scrollbar}
  max-height: ${({ height = `${WIDGET_CONTAINER_MAX_HEIGHT}px` }) => height};
  user-select: none;
  overflow: hidden;

  ${({ editMode }) => editMode && 'margin-top: 29px;'}

  ${({ isScrollable, maxHeight }) =>
    isScrollable &&
    maxHeight &&
    `
    overflow: auto;
    max-height: ${maxHeight};
  `}
`;

export const OptionsListItem = styled(Flexbox)`
  ${({ theme }) => theme.typography.widget.paragraphMedium}
`;

export const OptionsListItemLabel = styled('div', forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis}
  max-width: 280px;
  cursor: default;
`;
