import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Loader } from '@/components/loader/loader';
import { isTeamBasicOption, isUserOption } from '@/helpers/other';
import type { TeamBasicOption, UserOption } from '@/types';
import type { AsyncSelectProps } from '@/ui/select/async/async-select-with-list';
import { AsyncSelectWithList } from '@/ui/select/async/async-select-with-list';
import type { CustomAsyncSelectProps } from '@/ui/select/async/use-select-type';

import { PrivacyStatus as PrivacyStatusComponent } from '../../modals/share-modal/privacy-status';
import { SelectedUserOrTeamOption } from '@/features/modals/share-modal/share-with-select/selected-user-or-team-option';
import { ShareWithUserOrTeamOption } from '@/features/modals/share-modal/share-with-select/share-with-user-or-team-option';
import { prepareSafeInput } from '@/helpers/prepare-safe-input';
import { fetchTeams } from '@/api/v4/team.api';
import { fetchUsers } from '@/api/v4/user.api';
import { mapUsersToOptions } from '@/api/v4/mappers/user-option.mapper';
import { mapTeamsToBasicTeamOptions } from '@/api/v4/mappers/team-basic-option';
import type { ShareWithData } from '@/features/modals/share-modal/share-with-select/share-with';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import { PERMISSION } from '@/user/permissions/permission.type';

type DraftOptions = (UserOption | TeamBasicOption)[];

interface FileShareWithProps extends Pick<AsyncSelectProps, 'searchInputSize'> {
  separateDefaultValue?: boolean;
  privacyDefaultValue?: boolean;
  privacyPublicLabel?: string | ReactNode;
  privacyPrivateLabel?: string | ReactNode;
  onChange: (options: ShareWithData) => void;
  shareWithData: ShareWithData;
  entitiesWithAccess: (UserOption | TeamBasicOption)[];
  isLoading: boolean;
}

export function FileShareWith({
  privacyDefaultValue,
  privacyPublicLabel,
  privacyPrivateLabel,
  searchInputSize,
  separateDefaultValue = true,
  onChange,
  shareWithData,
  entitiesWithAccess,
  isLoading,
}: FileShareWithProps) {
  const { t } = useTranslation('default');

  const { hasRequiredPermission } = useUserPermissions();

  const setOptions: AsyncSelectProps['setOptions'] = async (
    originalSearchPhrase,
    page,
  ) => {
    const safeSearchPhrase = prepareSafeInput(originalSearchPhrase);

    const [teams, users] = await Promise.all([
      fetchTeams({ page, search: safeSearchPhrase }),
      fetchUsers({ page, search: safeSearchPhrase }),
    ]);

    const options: Array<UserOption | TeamBasicOption> = [
      ...mapUsersToOptions(users),
      ...mapTeamsToBasicTeamOptions(teams),
    ];

    return options;
  };

  const handleOnChange: CustomAsyncSelectProps['onChange'] = (
    newValue,
    actionMeta,
  ) => {
    if (actionMeta.action === 'select-option') {
      onChange({ ...shareWithData, draftOptions: newValue as DraftOptions });
    }

    if (actionMeta.action === 'remove-value') {
      if (
        isUserOption(actionMeta.removedValue) ||
        isTeamBasicOption(actionMeta.removedValue)
      ) {
        const removedOption = actionMeta.removedValue;

        onChange({
          ...shareWithData,
          draftOptions: shareWithData.draftOptions.filter(
            o => o.value !== removedOption.value,
          ),
          removedOptions: [...shareWithData.removedOptions, removedOption],
        });
      }
    }
  };

  if (isLoading) return <Loader size="small" />;

  return (
    <>
      <ShareContainerList>
        <AsyncSelectWithList
          defaultValue={entitiesWithAccess}
          setOptions={setOptions}
          placeholder={t`share.placeholder`}
          shouldLoadMore={() => false}
          components={{ Option: ShareWithUserOrTeamOption }}
          SelectedOptionsListComponent={
            <SelectedUserOrTeamOption
              isDisabled={!hasRequiredPermission(PERMISSION.SHARE_FILE)}
            />
          }
          onChange={handleOnChange}
          searchInputSize={searchInputSize}
          separateDefaultValue={separateDefaultValue}
          isDisabled={!hasRequiredPermission(PERMISSION.SHARE_FILE)}
        />
      </ShareContainerList>

      <PrivacyStatus
        defaultValue={privacyDefaultValue}
        publicLabel={privacyPublicLabel}
        privateLabel={privacyPrivateLabel}
        onChange={isPublic => onChange({ ...shareWithData, isPublic })}
      />
    </>
  );
}

const ShareContainerList = styled('div')`
  padding-bottom: 12px;
`;

const PrivacyStatus = styled(PrivacyStatusComponent)`
  margin-bottom: -20px;
  z-index: 1;
`;
