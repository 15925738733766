import { useState } from 'react';
import { styled } from 'goober';

import type {
  IAlign,
  Row,
  RowPadding,
  IVerticalAlign,
} from '@/ui/table/table.types';

export const TableRow = ({
  id,
  row,
  isLast,
  borderColor,
  bgColor,
  bgColorHover,
  rowCursor,
}: {
  id?: string;
  row: Row;
  isLast?: boolean;
  borderColor?: string;
  bgColor?: string;
  bgColorHover?: string;
  rowCursor?: string;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Tr
      id={id}
      data-testid={id}
      rowCursor={rowCursor}
      borderColor={borderColor}
      isLast={isLast || false}
      rowHover={row.rowHover}
      rowHeight={row.rowHeight}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      bgColor={bgColor}
      bgColorHover={bgColorHover}
      onClick={row.onClick}
    >
      {row.cells.map((cell, cellIndex) => (
        <Td
          key={cellIndex}
          isHeader={cell.isHeader}
          align={cell.align}
          verticalAlign={cell.verticalAlign}
          breakWord={cell.breakWord}
          showOnHover={Boolean(cell.hoverOnly)}
          padding={cell.padding}
          width={cell.width}
          minWidth={cell.minWidth}
          rowPadding={row.rowPadding}
          isHovered={isHovered}
        >
          {cell.value}
        </Td>
      ))}
    </Tr>
  );
};

const Tr = styled('tr')<{
  borderColor?: string;
  rowHover?: boolean;
  rowCursor?: string;
  rowHeight?: string;
  bgColor?: string;
  bgColorHover?: string;
  isLast: boolean;
}>`
  height: ${({ rowHeight = '76px' }) => rowHeight};
  border-bottom: ${({ isLast = false }) => (!isLast ? '1px solid' : 'none')};
  border-color: ${({ theme, borderColor }) =>
    borderColor ?? theme.colors.gray.c7};

  background-color: ${({ theme, bgColor }) =>
    bgColor ? bgColor : theme.colors.basics.white};

  ${({ rowHover = false, rowCursor, bgColorHover }) =>
    rowHover &&
    `
      &:hover {
        background-color: ${bgColorHover ?? '#edf4ff'};
        cursor: ${rowCursor ?? 'pointer'};
      }
    `}
`;

export const Td = styled('td')<{
  isHeader?: boolean;
  align?: IAlign;
  verticalAlign?: IVerticalAlign;
  width?: string;
  minWidth?: string;
  breakWord?: boolean;
  showOnHover?: boolean;
  padding?: string;
  isHovered?: boolean;
  rowPadding?: RowPadding;
}>`
  ${({ verticalAlign = 'middle' }) => `vertical-align: ${verticalAlign}`};
  ${({ align = 'left' }) => `text-align: ${align};`}
  ${({ padding = '0 20px' }) => `padding: ${padding};`}
  ${({ width }) => Boolean(width) && `width: ${width};`}
  ${({ minWidth }) => Boolean(minWidth) && `min-width: ${minWidth};`}
  ${({ breakWord }) => Boolean(breakWord) && 'word-break: break-word;'}

  ${({ rowPadding }) =>
    rowPadding &&
    `
      &:first-child {
        padding-left: ${rowPadding.left};
      }

      &:last-child {
        padding-right: ${rowPadding.right};
      }
  `};

  & * {
    ${({ showOnHover }) =>
      Boolean(showOnHover) && 'visibility: hidden; pointer-events: none;'}

    ${({ showOnHover, isHovered }) =>
      Boolean(showOnHover) &&
      isHovered &&
      'visibility: visible; pointer-events: auto;'}
  }
`;
