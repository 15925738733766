import { useCurrentUser } from '../use-current-user.query';

import type { ResourceAccess } from './resource-access.type';

export const useUserResourceAccess = () => {
  const { data: user } = useCurrentUser();
  const userResources = user?.resourceAccess ?? [];

  const hasAccessToResource = (resourceName: ResourceAccess): boolean =>
    userResources.includes(resourceName);

  return {
    userResources,
    hasAccessToResource,
  };
};
