import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilValue } from 'recoil';

import { RelatedProjectsCount } from '@/features/companies/related-projects/related-projects-count';
import { colors } from '@/theme/colors';
import { Button } from '@/ui/button/button';
import { SavvyIconButtonCrunchbase } from '@/ui/button/savvy-icon-button-crunchbase';
import { SavvyIconButtonOrganizationURL } from '@/ui/button/savvy-icon-button-orgurl';
import { SavvyIconButtonWebURL } from '@/ui/button/savvy-icon-button-weburl';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Stack } from '@/ui/line/line';
import { SavvyLogoCompany } from '@/ui/logo/savvy-logo-company';
import { Tooltip } from '@/ui/tooltip/tooltip';

import { organizationSearchResultsAddedCompaniesState } from '../../features/companies/search-results/organization-search-results.state';

export type AddCompanyEvent = (data: {
  crunchbaseId?: string;
  organizationId?: string;
  website?: string;
  name?: string;
  logoUrl?: string;
  fromCB: boolean;
}) => void;

export type OrganizationSearchResultsRowData = {
  crunchbaseId?: string;
  imageUrl?: string;
  isAdded: boolean;
  isBusy: boolean;
  isButtonHidden?: boolean;
  listingCount?: number;
  name: string;
  organizationId?: string;
  permalink?: string;
  shortDescription: string;
  websiteUrl?: string;
  companyPageUrl?: string;
  rowClickRedirectsToCompanyPage?: boolean;
  isDomainUse?: boolean;
};

type SearchResultTableRowProps = OrganizationSearchResultsRowData & {
  buttonAddedText: string;
  buttonAddText: string;
  fromCB: boolean;
  onAddCompany?: AddCompanyEvent;
};

export const SearchResultTableRow = ({
  buttonAddedText,
  buttonAddText,
  crunchbaseId,
  fromCB = false,
  imageUrl,
  isAdded,
  isBusy,
  isButtonHidden,
  listingCount,
  name,
  onAddCompany,
  organizationId,
  permalink,
  shortDescription,
  websiteUrl,
  companyPageUrl,
  rowClickRedirectsToCompanyPage: rowClickRedirectsToWebsite,
  isDomainUse,
}: SearchResultTableRowProps) => {
  const { t } = useTranslation('projects');
  const addedCompanies = useRecoilValue(
    organizationSearchResultsAddedCompaniesState,
  );

  if (!organizationId && !crunchbaseId)
    throw new Error('No unique row id provided');
  if (fromCB && !crunchbaseId)
    throw new Error('Crunchbase ID is required if fromCB is true');

  const handleOnRowClick = () => {
    if (rowClickRedirectsToWebsite && companyPageUrl) {
      window.open(companyPageUrl, '_blank');
    }
  };

  return (
    <TR
      id={organizationId ?? crunchbaseId}
      onClick={handleOnRowClick}
      rowIsClickable={rowClickRedirectsToWebsite}
    >
      <TD className="company-logo-column">
        <SavvyLogoCompany name={name} size="20px" logoUrl={imageUrl} />
      </TD>
      <TD className="company-info-column">
        <Stack dir={'column'} gap="2px">
          <Flexbox name="company-info" alignItems="center">
            <CompanyName>{name}</CompanyName>
            {fromCB && websiteUrl && (
              <SavvyIconButtonWebURL
                url={websiteUrl}
                color={colors.basics.black}
              />
            )}
            {!fromCB && organizationId && (
              <SavvyIconButtonOrganizationURL companyId={organizationId} />
            )}
            {isDomainUse && (
              <Tooltip content={t`addCompany.domainInUse`} maxWidth="700px">
                <DomainUsedIcon
                  icon="Alert"
                  height="15"
                  color={colors.system.lowFit}
                />
              </Tooltip>
            )}
          </Flexbox>
          <CompanyInfo>{shortDescription}</CompanyInfo>
        </Stack>
      </TD>
      <TD className="stats-column">
        {fromCB && permalink && (
          <SavvyIconButtonCrunchbase hideTooltip={true} permalink={permalink} />
        )}
        {!fromCB && organizationId && !!listingCount && (
          <RelatedProjectsCount
            companyId={organizationId}
            value={listingCount}
          />
        )}
      </TD>
      <TD className="actions-column">
        <Flexbox name="sss" justify="end">
          {isAdded ||
          addedCompanies.includes(organizationId || '') ||
          addedCompanies.includes(crunchbaseId || '') ? (
            <ButtonAdded startIcon="Check" color={colors.accent.green.c3}>
              {buttonAddedText}
            </ButtonAdded>
          ) : (
            <>
              {
                <ButtonAddCompany
                  startIcon={isBusy ? 'Loader' : 'Plus'}
                  hideOnHover={!isBusy}
                  hidden={isButtonHidden}
                  onClick={() =>
                    !isBusy &&
                    onAddCompany &&
                    onAddCompany({
                      crunchbaseId,
                      organizationId,
                      fromCB,
                      name,
                      website: websiteUrl,
                      logoUrl: imageUrl,
                    })
                  }
                >
                  {buttonAddText}
                </ButtonAddCompany>
              }
            </>
          )}
        </Flexbox>
      </TD>
    </TR>
  );
};

const TR = styled('tr')<{ rowIsClickable?: boolean }>`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray.c3}`};
  ${({ rowIsClickable }) => rowIsClickable && `cursor: pointer;`}

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue.c9};
  }
`;

const TD = styled('td')`
  padding-top: 11px;
  padding-bottom: 11px;

  &.company-logo-column {
    padding-left: 8px;
    width: 42px;
    vertical-align: top;
  }

  &.company-info-column {
    padding-top: 13px;
    width: 680px;
    vertical-align: top;
  }

  &.stats-column {
    width: 20px;
    vertical-align: middle;
  }
  &.actions-column {
    padding-right: 18px;
    width: 120px;
    vertical-align: middle;
    text-align: end;
  }
`;

const CompanyName = styled('div')`
  padding-right: 4px;
  ${({ theme }) => theme.typography.widget.smallText}
  color: ${({ theme }) => theme.colors.basics.black};
`;

const CompanyInfo = styled('div')`
  ${({ theme }) => theme.typography.companyInfo.tableHeader}
  color: ${({ theme }) => theme.colors.gray.c13};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 662px;
`;

const ButtonAddCompany = styled(Button)<{ hideOnHover?: boolean }>`
  opacity: ${({ hideOnHover }) => (hideOnHover ? 0 : 1)};
  display: inline-flex;
  ${({ theme }) => theme.mixins.transition('opacity')}

  tr:hover &,
  &.always-on {
    opacity: 1;
  }

  ${({ hidden }) => hidden && 'display: none;'}
`;

const ButtonAdded = styled(Button)`
  display: inline-flex;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.accent.green.c1};
`;

const DomainUsedIcon = styled(Icon)`
  margin: 0 3px;
`;
