import { useCurrentUser } from '../use-current-user.query';

import type { PERMISSION } from './permission.type';

export const useUserPermissions = () => {
  const { data } = useCurrentUser();
  const userPermissions = data?.permissions ?? [];

  const hasRequiredPermission = (permissionName: PERMISSION): boolean =>
    userPermissions.includes(permissionName);

  const hasNotRequiredPermission = (permissionName: PERMISSION): boolean =>
    !hasRequiredPermission(permissionName);

  const hasRequiredPermissions = (permissionNames: PERMISSION[]): boolean =>
    permissionNames.every(requiredPermission =>
      hasRequiredPermission(requiredPermission),
    );

  const hasAtLeastOnePermission = (permissionNames: PERMISSION[]): boolean =>
    permissionNames.some(requiredPermission =>
      hasRequiredPermission(requiredPermission),
    );

  return {
    userPermissions,
    hasAtLeastOnePermission,
    hasRequiredPermission,
    hasRequiredPermissions,
    hasNotRequiredPermission,
  };
};
