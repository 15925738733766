import type { APIRequestQueryParams, APIResponse } from './base-fetch';
import { get } from './base-fetch';

type GenAiRequestHistoryParams = APIRequestQueryParams<'/api/v4/genai-request'>;

export type GenAiRequest = APIResponse<'/api/v4/genai-request'>[number];

export type GenAiRequestHistory =
  APIResponse<'/api/v4/genai-request/{requestId}'>;

export type GenAiRequestHistoryResult = GenAiRequestHistory['result'][number];

export const fetchGenAiRequestHistory = async (
  params: GenAiRequestHistoryParams,
) => get<GenAiRequest[]>('/v4/genai-request', { params });

export const fetchGenAiRequest = async (requestId: string) =>
  get<GenAiRequestHistory>(`/v4/genai-request/${requestId}`);
