import { getAbbreviateNumber } from '@/helpers/format-number';
import type { MinMaxRange } from '@/types';

export const formatCapitalRaisedLabel = (range: MinMaxRange) => {
  let label = '';

  if (!range.max) label += '> ';
  if (!range.min) label += '< ';

  if (range.min) label += formatCurrency(range.min);

  if (range.min && range.max) label += ' - ';

  if (range.max) label += formatCurrency(range.max);

  return label;
};

const formatCurrency = (number: number) =>
  getAbbreviateNumber(number.toString()) + '$';
