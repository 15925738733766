import type { APIRequestBody, APIResponse } from './base-fetch';
import { get, post } from './base-fetch';

export type SearchSuggestion = APIResponse<'/api/v4/search'>[number];

export type UserRecentSearchRecord =
  APIResponse<'/api/v4/search/user-recent-records'>[number];

export type AddUserRecentSearchRecordPayload =
  APIRequestBody<'/api/v4/search/user-recent-records/add'>;

type Highlights = SearchSuggestion['highlights'];
export type HighlightKeys = keyof Highlights;

export const fetchSearchSuggestions = async (searchPhrase: string) =>
  get<SearchSuggestion[]>('/v4/search?search=' + searchPhrase);

export const fetchUserRecentSearchRecords = async () =>
  get<UserRecentSearchRecord[]>('/v4/search/user-recent-records');

export const addUserRecentSearchRecord = async (
  data: AddUserRecentSearchRecordPayload,
) =>
  post<UserRecentSearchRecord, AddUserRecentSearchRecordPayload>(
    '/v4/search/user-recent-records/add',
    data,
  );
