import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '@/config/query-client';
import { fetchProjectUpdates } from '@/api/v4/project-updates.api';
import { useRecoilValue } from 'recoil';
import { projectIdState } from '../../project.state';

export const useProjectUpdates = () => {
  const projectId = useRecoilValue(projectIdState);

  return useQuery(
    [QueryKey.ProjectUpdates, projectId],
    () => (projectId ? fetchProjectUpdates(projectId) : undefined),
    {
      enabled: !!projectId,
    },
  );
};
