type SearchResultClickedMetadata = {
  id: string;
  type: 'company' | 'team' | 'project';
  isRecent: boolean;
};

export const trackSearchResultClicked = (
  metadata: SearchResultClickedMetadata,
) => {
  window.pendo?.track('SearchResultClicked', metadata);
};
