import type { QueryFunctionContext } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchCompanies } from '@/api/v4/organization.api';
import { QueryKey } from '@/config/query-client';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import {
  appliedCompaniesFiltersState,
  companiesSortState,
} from '../companies.state';
import { prepareCompanyApiFilters } from '../filters/company-filters-helpers';

export const useCompanies = () => {
  const { hasRequiredPermission } = useUserPermissions();

  const appliedFilters = useRecoilValue(appliedCompaniesFiltersState);

  const sortState = useRecoilValue(companiesSortState);

  const filters = prepareCompanyApiFilters(appliedFilters);

  return useInfiniteQuery(
    [QueryKey.Companies, sortState, filters],
    ({ pageParam = 1 }: QueryFunctionContext) => {
      const params = {
        sort: sortState?.sort,
        direction: sortState?.direction,
        page: pageParam,
        items: 20,
        ...filters,
      };
      return fetchCompanies(params);
    },
    {
      enabled: hasRequiredPermission(PERMISSION.VIEW_ORGANIZATION_LIST),
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = lastPage.length ? allPages.length + 1 : undefined;
        return nextPage;
      },
      staleTime: 1000 * 60 * 5,
    },
  );
};
