import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { ReactComponent as NoAssetsIllustration } from '@/assets/svg/no-assets-illustration.svg';
import { colors } from '@/theme/colors';
import { Stack } from '@/ui/line/line';
import { ParagraphMedium } from '@/ui/typography/widgets';

export const EmptyState = () => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'sharedAssets',
  });

  return (
    <Container fullWidth>
      <Content centered fullWidth>
        <NoAssetsIllustration />
        <ParagraphMedium
          color={colors.gray.c10}
        >{t`noAssetsShared`}</ParagraphMedium>
      </Content>
    </Container>
  );
};

const Container = styled(Stack)`
  position: relative;
  height: 260px;
  background-color: ${({ theme }) => theme.colors.basics.white};
`;

const Content = styled(Stack)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
