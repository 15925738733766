import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { QueryKey } from '@/config/query-client';
import { useHandleDeleteMeeting } from '@/features/action-panel/meetings/hooks/use-handle-delete-meeting';
import { MeetingsTab } from '@/features/action-panel/meetings/meetings-tab';
import { teamIdState } from '@/features/teams/team.state';

import { useTeamMeetings } from './use-team-meetings.query';

export const TeamMeetingsTab = () => {
  const queryClient = useQueryClient();

  const { data: meetings, isFetching } = useTeamMeetings();
  const teamId = useRecoilValue(teamIdState);

  const refreshData = async () => {
    await queryClient.invalidateQueries([QueryKey.TeamMeetings, teamId]);
  };

  const { onDeleteHandler, isDeleting } = useHandleDeleteMeeting({
    onRefreshData: refreshData,
  });

  return (
    <MeetingsTab
      meetingsData={meetings ?? []}
      refreshHandler={refreshData}
      isLoading={isFetching || isDeleting}
      deleteMeetingHandler={onDeleteHandler}
      newMeetingFormDefaultValues={{
        relatedTeamId: teamId ?? undefined,
      }}
      showReadOnlyTeam={false}
      withCompanyField
      withProjectField
      context="team"
    />
  );
};
