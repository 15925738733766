import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import errorIllustration from '@/assets/images/404-illustration.svg';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { colors } from '@/theme/colors';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { Header, ParagraphSmall, SubHeader } from '@/ui/typography/widgets';

export interface OnExit {
  label: string;
  action: () => void;
}

export const ErrorScreen = ({ onExit }: { onExit?: OnExit }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'errorScreen',
  });
  const navigateTo = useNavigateTo();

  return (
    <Flexbox
      name="error-screen"
      fullHeight
      fullWidth
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Stack centered>
        <Illustration src={errorIllustration} alt={t`errorIllustration`} />
        <Header>{t`opps`}</Header>
        <SubHeader>{t`somethingWentWrong`}</SubHeader>
        <ParagraphSmall
          color={colors.system.disable}
        >{t`sorryNotFound`}</ParagraphSmall>
        <ActionButton
          variant="underlined"
          onClick={onExit ? onExit.action : () => navigateTo.home}
        >
          {onExit?.label ?? t`takeMeHome`}
        </ActionButton>
      </Stack>
    </Flexbox>
  );
};

const Illustration = styled('img')`
  margin-left: 150px;
`;

const ActionButton = styled(Button)`
  margin-top: 30px;
`;
