import {
  isInvitedUserOption,
  isTeamBasicOption,
  isUserOption,
} from '@/helpers/other';
import type { TeamBasicOption, UserOption } from '@/types';

export const getLabel = (data: UserOption | TeamBasicOption | unknown) => {
  if (isUserOption(data) && !Boolean(data.firstName) && !Boolean(data.lastName))
    return data.email;
  if (
    isUserOption(data) ||
    isTeamBasicOption(data) ||
    isInvitedUserOption(data)
  )
    return data.label ?? '';
  return '';
};
