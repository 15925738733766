import { useCallback, useState } from 'react';

interface UseBooleanActions {
  toggle: () => void;
  on: () => void;
  off: () => void;
  set: (value: boolean) => void;
}

export const useBoolean = (
  initialValue: boolean,
): [boolean, UseBooleanActions] => {
  const [value, setValue] = useState(initialValue);

  const toggle = useCallback(() => {
    setValue(oldValue => !oldValue);
  }, []);

  const on = useCallback(() => {
    setValue(true);
  }, []);

  const off = useCallback(() => {
    setValue(false);
  }, []);

  const set = useCallback((newValue: boolean) => {
    setValue(newValue);
  }, []);

  return [value, { toggle, on, off, set }];
};
