import type { TogglePinnedItemPayload } from '@/api/v4/pinned-items.api';
import { queryClient, QueryKey } from '@/config/query-client';
import type { Project } from '@/api/v4/projects.api';
import type { PinnedItemsContext } from '@/features/home/recent-items/pinned-items/use-toggle-pinned-item.mutation';

export const useOptimisticPinProjectUpdate = () => {
  const updateProject = (newProjectData: Project) => {
    queryClient.setQueryData(
      [QueryKey.Project, newProjectData.id],
      newProjectData,
    );
  };

  const handleOptimisticChange = async (data: TogglePinnedItemPayload) => {
    const itemId = data.id;

    await queryClient.cancelQueries({ queryKey: [QueryKey.Project, itemId] });

    const prevProjectData = queryClient.getQueryData<Project>([
      QueryKey.Project,
      itemId,
    ]) as Project;

    const newProjectData = {
      ...prevProjectData,
      isPinned: !prevProjectData.isPinned,
    };
    updateProject(newProjectData);

    return {
      prevProjectData,
    };
  };

  const rollbackToPreviousData = (
    prevData: PinnedItemsContext,
    itemId?: string,
  ) => {
    prevData.prevProjectData &&
      itemId &&
      queryClient.setQueryData(
        [QueryKey.Project, itemId],
        prevData.prevProjectData,
      );
  };

  return {
    handleOptimisticChange,
    rollbackToPreviousData,
  };
};
