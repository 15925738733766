/* eslint-disable react/display-name */
import type { ComponentType } from 'react';

import type { PERMISSION } from '../user/permissions/permission.type';
import { useUserPermissions } from '../user/permissions/use-user-permissions';

export const withPermission = <ComponentProps extends object>(
  Component: ComponentType<ComponentProps>,
  permission: PERMISSION,
) => {
  return (props: ComponentProps) => {
    const { hasRequiredPermission } = useUserPermissions();

    if (!hasRequiredPermission(permission)) {
      return null;
    }
    return <Component {...props} />;
  };
};
