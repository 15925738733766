import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useSetRecoilState } from 'recoil';

import imgDivider from '@/assets/svg/ai-enhancement-header-divider.svg';
import imgAIIntro from '@/assets/svg/ai-enhancement-intro.svg';
import { modalState } from '@/state/modal.state';
import { colors } from '@/theme/colors';
import { Button } from '@/ui/button/button';
import { Checkbox } from '@/ui/checkbox/checkbox';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Inline, Stack } from '@/ui/line/line';
import { Modal as ModalComponent } from '@/ui/modal/modal';

import { useProjectListingSuggestionState } from '../use-project-listing-suggestion.state';

export const EnhanceProjectIntroModal = () => {
  const state = useProjectListingSuggestionState();
  const [isAgreementChecked, setAgreementChecked] = useState(false);
  const { t } = useTranslation(['projects', 'default']);
  const setModalState = useSetRecoilState(modalState);

  const handleNext = () => {
    if (!state.hasAcceptedTermsOfUse) state.acceptTermsOfUsage();
    setModalState({ state: 'enhanceProjectForm' });
  };

  return (
    <Modal
      CustomHeaderComponent={Header}
      header={t`enhanceIntroHeader`}
      outsideClose={false}
    >
      <Stack>
        <img src={imgDivider} />
        <Flexbox name="enhance-project-suggestion-intro-body" height="270px">
          <Stack margin="30px 0px 0px 0px" gap="30px" maxWidth="540px">
            <Text>{t`enhanceText1`}</Text>
            <Text>{t`enhanceText2`}</Text>
          </Stack>
          <IntroLogo src={imgAIIntro} />
        </Flexbox>
        <Footer name="enhance-footer" justify="space-between">
          <Inline>
            <Checkbox
              id="project-listing-suggestion-toa-checkbox"
              label=""
              checked={isAgreementChecked}
              onChange={() => setAgreementChecked(!isAgreementChecked)}
            />
            <Link
              href="https://www.heysavvy.io/legal/terms-of-service"
              target="_blank"
              rel="noreferrer"
            >
              {t`enhanceAgreement`}
            </Link>
          </Inline>

          <Button
            data-testid="project-listing-suggestion-next-button"
            variant="primary"
            endIcon="ArrowRight"
            height={32}
            disabled={!isAgreementChecked}
            iconColor={colors.basics.white}
            onClick={handleNext}
          >{t`default:next`}</Button>
        </Footer>
      </Stack>
    </Modal>
  );
};

const Modal = styled(ModalComponent)`
  padding: 44px 60px;
  min-width: 987px;
  min-height: 530px;
`;

const Header = styled('h1')`
  ${({ theme }) => theme.typography.enhance.header}
  margin-top: 26px;
  position: relative;

  &:after {
    content: '';
    background-image: url('/src/assets/svg/ai-enhancement-header.svg');
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: calc(-40px - 10px);
    width: 40px;
    height: 58px;
  }
`;

const Text = styled('p')`
  ${({ theme }) => theme.typography.enhance.text}
`;

const Link = styled('a')`
  ${({ theme }) => theme.typography.acrossPlatform.tiny};
  width: 490px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = styled(Flexbox)`
  width: 887px;
`;

const IntroLogo = styled('img')`
  width: 290px;
  height: 250px;
  position: absolute;
  top: 150px;
  right: 60px;
`;
