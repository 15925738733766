import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import type { DragObjectWithType } from 'react-dnd-cjs';
import { useDrop } from 'react-dnd-cjs';
import type { CSSAttribute } from 'goober';
import { styled } from 'goober';

import { colorIcon } from '../icons/functions';
import type { IconVariant } from '../icons/icon';
import { Icon } from '../icons/icon';

type Variant = 'primary' | 'secondary';

interface DragDropProps<TItem> {
  variant: Variant;
  onDrop: (item: TItem) => void;
  children?: ReactNode;
  icon?: IconVariant;
  accept: string;
  className?: string;
  borderRadius?: CSSAttribute['borderRadius'];
}

export const Dropzone = <TItem extends DragObjectWithType>({
  variant,
  onDrop,
  icon,
  children,
  accept,
  className,
  borderRadius,
}: DragDropProps<TItem>) => {
  const [{ isDragging, isOver }, dropRef] = useDrop({
    accept,
    drop(item: TItem) {
      onDrop(item);
      return;
    },
    collect: monitor => ({
      isDragging: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  });

  return (
    <DropzoneField
      variant={variant}
      ref={dropRef}
      isOver={isOver}
      isDragging={isDragging}
      className={className}
      borderRadius={borderRadius}
    >
      {!!icon && <Icon icon={icon} />}
      {children}
    </DropzoneField>
  );
};

const DropzoneField = styled('div', forwardRef)<{
  variant: Variant;
  isOver: boolean;
  isDragging: boolean;
  borderRadius?: CSSAttribute['borderRadius'];
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12.25px;

  ${({ theme }) => theme.typography.atoms.dragDrop};

  color: ${({ theme, variant }) =>
    theme.palettes.component.dragDrop[variant].isStatic.main};
  background-color: ${({ theme, variant }) =>
    theme.palettes.component.dragDrop[variant].isStatic.background};

  ${({ theme, variant }) =>
    colorIcon(theme.palettes.component.dragDrop[variant].isStatic.main)};

  ${({ theme, variant, borderRadius }) =>
    theme.mixins.pseudoBorder({
      color: theme.palettes.component.dragDrop[variant].isStatic.border,
      type: 'dashed',
      radius: borderRadius,
      length: 2,
    })};

  ${({ theme, isDragging, variant, borderRadius }) =>
    Boolean(isDragging) &&
    `
      color: ${theme.palettes.component.dragDrop[variant].isDragging.main};
      background: ${
        theme.palettes.component.dragDrop[variant].isDragging.background
      };
      ${theme.mixins.pseudoBorder({
        color: theme.palettes.component.dragDrop[variant].isDragging.border,
        type: 'dashed',
        radius: borderRadius,
        length: 2,
      })}
      ${colorIcon(theme.palettes.component.dragDrop[variant].isDragging.main)}
    `};

  ${({ theme, isOver, variant, borderRadius }) =>
    Boolean(isOver) &&
    `
      color: ${theme.palettes.component.dragDrop[variant].isOver.main};
      background: ${
        theme.palettes.component.dragDrop[variant].isOver.background
      };
      ${theme.mixins.pseudoBorder({
        color: theme.palettes.component.dragDrop[variant].isOver.border,
        type: 'dashed',
        radius: borderRadius,
        length: 2,
      })}

      ${colorIcon(theme.palettes.component.dragDrop[variant].isOver.main)}
    `};
`;
