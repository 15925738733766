import { useTranslation } from 'react-i18next';
import type { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';
import { debounce } from 'lodash-es';

import { api } from '@/api';
import { fetchPptFile } from '@/api/v4/export.api';
import { notify } from '@/ui/snackbar/notify';

import { useProject } from '../overview/use-project.query';

export const useExportHandlers = () => {
  const { t } = useTranslation('projects', {
    keyPrefix: 'projectOverview',
  });
  const { data: project } = useProject();

  const saveFile = (
    response: AxiosResponse<ArrayBuffer, unknown>,
    extension: 'xlsx' | 'pdf' | 'ppt',
  ) => {
    if (!project) return;
    const { data, headers } = response;
    const blob = new Blob([data], {
      type: headers['content-type'],
    });
    FileSaver.saveAs(blob, `${project.name}.${extension}`);
  };

  const handleStreamExportToXLS = async () => {
    if (!project) return;
    const response = await api.fetchStreamXLS(project.id);
    saveFile(response, 'xlsx');
  };

  const handleExportToPpt = async () => {
    if (!project) return;

    try {
      const response: any = await fetchPptFile(project.id);

      const data = Uint8Array.from(response.data);
      const content = new Blob([data.buffer], {
        type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      });

      const encodedUri = window.URL.createObjectURL(content);
      const link = document.createElement('a');

      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${project.name}.pptx`);

      link.click();

      debounce(() => link.remove(), 300);
    } catch (e) {
      notify({ message: t`exportErrors.ppt` });
    }
  };

  return {
    handleStreamExportToXLS,
    handleExportToPpt,
  };
};
