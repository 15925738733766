import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useSetRecoilState } from 'recoil';

import { TopbarWrapper } from '@/features/topbar/topbar';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { colors } from '@/theme/colors';
import { Button } from '@/ui/button/button';
import { colorIcon } from '@/ui/icons/functions';
import { Icon } from '@/ui/icons/icon';
import { MinimizedHeader } from '@/ui/typography/across-platform';

import { isExploreRequestHistoryPaneOpenState } from '../../explore/explore.state';
import { useRequestHistorySuggestions } from '../../explore/history/use-request-history-suggestions';
import { useExploreSuggestions } from '../../explore/use-explore-suggestions';
import { NavButtonDefault } from '../../navbar/navbar.styles';

export const ExploreResultsHeader = () => {
  const { t } = useTranslation('explore');
  const setIsExploreHistoryPaneOpen = useSetRecoilState(
    isExploreRequestHistoryPaneOpenState,
  );
  const { data: suggestionsResults } = useExploreSuggestions();
  const { data: historyResults } = useRequestHistorySuggestions(
    suggestionsResults?.requestId,
  );
  const navigateTo = useNavigateTo();

  const shouldShowHistoryButton = useMemo(() => {
    return (
      !!suggestionsResults?.results?.length || !!historyResults?.result?.length
    );
  }, [suggestionsResults, historyResults]);

  return (
    <TopbarWrapper
      name="explore-results-header"
      justify="space-between"
      alignItems="center"
      gap="16px"
      bgColor="white"
      shadow
    >
      <BackButton onClick={() => navigateTo.explore()}>
        <Icon icon="ArrowLeftBack" color={colors.basics.black} />
        <MinimizedHeader>{t`newExplore`}</MinimizedHeader>
      </BackButton>
      {shouldShowHistoryButton && (
        <Button
          variant="ghost"
          startIcon="Clock"
          onClick={() => setIsExploreHistoryPaneOpen(true)}
        >
          {t`History`}
        </Button>
      )}
    </TopbarWrapper>
  );
};

const BackButton = styled('div')`
  ${({ theme }) => NavButtonDefault(theme)}
  ${({ theme }) => theme.typography.acrossPlatform.buttonText}

  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  gap: 4px;
  padding: 8px;
  flex-grow: 0;

  &:hover {
    ${({ theme }) =>
      `
      background-color: ${theme.colors.blue.c9};
      ${colorIcon(theme.colors.blue.primaryB)};

      h2 {
        color: ${theme.colors.blue.primaryB};
      }
    `};
  }
`;
