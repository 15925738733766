import { useTranslation } from 'react-i18next';

import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { APP_USER_ROLES } from '@/types';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { MinimizedHeader } from '@/ui/typography/across-platform';
import { useUserRole } from '@/user/use-user-role';

import { AskXButton } from '../../ask-x/ask-x-button';
import { TopbarWrapper } from '../topbar';

export const ProjectsHeader = () => {
  const navigateTo = useNavigateTo();
  const { t } = useTranslation('projects');
  const role = useUserRole();
  const isAppUserGuest = role?.includes(APP_USER_ROLES.GUEST);

  return (
    <TopbarWrapper
      name="projects-listing"
      justify="space-between"
      alignItems="center"
      gap="16px"
      bgColor="white"
      shadow
    >
      <MinimizedHeader>{t`projects.allProjects`}</MinimizedHeader>

      <Flexbox name="projects-header-actions" gap="28px">
        <AskXButton />

        {!isAppUserGuest ? (
          <Button
            variant="secondary"
            startIcon="Plus"
            onClick={() => navigateTo.createProject()}
          >{t`projects.createProject`}</Button>
        ) : undefined}
      </Flexbox>
    </TopbarWrapper>
  );
};
