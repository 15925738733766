export const downloadLinkAutomatically = async (
  link: string,
  fileName: string,
) => {
  const linkElement = document.createElement('a');

  linkElement.setAttribute('href', link);
  linkElement.setAttribute('target', '_blank');
  linkElement.setAttribute('download', fileName);

  document.body.appendChild(linkElement);
  linkElement.click();

  document.body.removeChild(linkElement);
  linkElement.remove();
};
