import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash-es';

import { DROPDOWN_ITEMS_PER_PAGE } from '@/constants';
import type { IAttendeeOption, PlaceholderOption } from '@/types';
import type { CustomAsyncSelectProps } from '@/ui/select/async/use-select-type';
import { useCurrentUser } from '@/user/use-current-user.query';

import type { MeetingFormFields } from './use-meeting-form';
import type { Attendee, AttendeesQueryParams } from '@/api/v4/attendees.api';
import { fetchAttendees } from '@/api/v4/attendees.api';

export const useAttendeesOptions = ({
  context,
}: {
  context: AttendeesQueryParams['context'];
}) => {
  const { t } = useTranslation('default', { keyPrefix: 'actionPanel' });
  const { watch } = useFormContext<MeetingFormFields>();

  const relatedCompanyId = watch('relatedCompanyId');
  const relatedTeamId = watch('relatedTeamId');
  const { data: user } = useCurrentUser();

  const filterAttendeesParams = useMemo(() => {
    const params: AttendeesQueryParams = {
      context: context === 'company' ? undefined : context,
    };

    if (relatedTeamId) {
      params.teamIds = [relatedTeamId];
    }

    if (relatedCompanyId) {
      params.organizationIds = [relatedCompanyId];
    }

    return params;
  }, [relatedCompanyId, relatedTeamId, context]);

  const sortDefaultAttendeesSet = (attendees: IAttendeeOption[]) =>
    orderBy(
      attendees,
      [
        attendee => {
          if (context === 'company') {
            if (
              attendee.type === 'organization' ||
              attendee.type === 'crunchbase'
            )
              return 1;

            if (attendee.entity?.id === user?.team?.id) {
              if (attendee.type === 'user') return 2;
              if (attendee.type === 'team_contact') return 3;
            }

            if (attendee.type === 'user') return 4;
            if (attendee.type === 'team_contact') return 5;

            return 6;
          }

          if (context === 'project') {
            if (
              attendee.type === 'organization' ||
              attendee.type === 'crunchbase'
            )
              return 1;

            if (attendee.entity?.id === user?.team?.id) {
              if (attendee.type === 'user') return 4;
              if (attendee.type === 'team_contact') return 5;
            }

            if (attendee.type === 'user') return 2;
            if (attendee.type === 'team_contact') return 3;

            return 6;
          }

          if (context === 'team') {
            if (attendee.entity?.id === user?.team?.id) {
              if (attendee.type === 'user') return 4;
              if (attendee.type === 'team_contact') return 5;
            }

            if (attendee.type === 'user') return 1;
            if (attendee.type === 'team_contact') return 2;
            if (
              attendee.type === 'organization' ||
              attendee.type === 'crunchbase'
            )
              return 3;

            return 6;
          }
          return 7;
        },
        'name',
      ],
      ['asc', 'asc'],
    );

  const setOptions: CustomAsyncSelectProps['setOptions'] = async (
    search,
    page,
  ) => {
    const response = await fetchAttendees({
      search,
      page: search.length > 0 ? page : undefined,
      items: search.length > 0 ? DROPDOWN_ITEMS_PER_PAGE : undefined,
      ...filterAttendeesParams,
    });

    const options: Array<IAttendeeOption> = response.map(mapAttendee);

    if (search.length === 0 && options.length === 0) {
      const placeholderOption: PlaceholderOption = {
        label: t`meetings.keepTyping`,
        value: '',
        type: 'placeholder',
      };

      return [placeholderOption];
    }

    return search.length > 0 ? options : sortDefaultAttendeesSet(options);
  };

  return {
    setOptions,
  };
};

const mapAttendee = (attendee: Attendee): IAttendeeOption => ({
  label: attendee.firstName + ' ' + attendee.lastName,
  value: attendee.id,
  entity:
    attendee.entityId && attendee.entityName
      ? { id: attendee.entityId, name: attendee.entityName }
      : null,
  type: attendee.type,
});
