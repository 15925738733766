/* eslint-disable react/display-name */
import type { ComponentType } from 'react';
import { DndProvider } from 'react-dnd-cjs';
import HTML5Backend from 'react-dnd-html5-backend-cjs';
import TouchBackend from 'react-dnd-touch-backend-cjs';

const draggableBackend = {
  html5: HTML5Backend,
  touch: TouchBackend,
};

type DraggableProviderType = keyof typeof draggableBackend;

export const withDraggableProvider = <ComponentProps extends object>(
  Component: ComponentType<ComponentProps>,
  type: DraggableProviderType = 'html5',
) => {
  return (props: ComponentProps) => {
    return (
      // @ts-ignore: Property 'children' does not exist on type 'IntrinsicAttributes & DndProviderProps<any, any>'.
      // DndProvider is built on React 17, where FC type includes "children" property by default, while in React 18 it is not included
      // Therefore we can ignore this error as "children" is handled by DndProvider anyway.
      <DndProvider
        context={window}
        backend={draggableBackend[type]}
        options={{ enableMouseEvents: true }}
      >
        <Component {...props} />
      </DndProvider>
    );
  };
};
