import { styled } from 'goober';

import { colorIcon } from '../icons/functions';

import type { TabVariant } from './types';

interface TabControlProps {
  onClick: () => void;
  title: React.ReactNode;
  isActive: boolean;
  variant: TabVariant;
}

export const TabControl = ({
  onClick,
  title,
  isActive,
  variant,
}: TabControlProps) => (
  <TabControlWrapper isActive={isActive} onClick={onClick} variant={variant}>
    {title}
  </TabControlWrapper>
);

const TabControlWrapper = styled('div')<{
  isActive?: boolean;
  variant: TabVariant;
}>`
  margin-bottom: -1px;
  display: inline-flex;
  align-items: center;
  line-height: 20.3px;
  padding: 10px 0;

  color: ${({ theme, variant }) => theme.palettes.component.tabs[variant].main};

  font-size: 14px;
  line-height: 145%;
  ${({ theme, variant }) =>
    colorIcon(theme.palettes.component.tabs[variant].main)};
  border-bottom: 1px solid transparent;

  ${({ isActive, variant, theme }) =>
    isActive &&
    `
      border-bottom: 2px solid ${theme.palettes.component.tabs[variant].active};
      font-weight: 500;
      color: ${theme.palettes.component.tabs[variant].active};
      ${colorIcon(theme.palettes.component.tabs[variant].active)}
    `}

  &:hover {
    cursor: pointer;
    ${({ isActive, variant, theme }) =>
      !isActive &&
      `
        border-color: ${theme.palettes.component.tabs[variant].main};
      `};
  }
`;
