import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchMeetings } from '@/api/v4/meetings.api';
import { queryClient, QueryKey } from '@/config/query-client';
import { reorderMeetings } from '@/features/action-panel/meetings/helpers/reorder-meetings';
import { editedMeetingIdState } from '@/features/action-panel/meetings/meetings.state';
import { notify } from '@/ui/snackbar/notify';

import { companyIdState } from '../../company.state';

export const useCompanyMeetings = () => {
  const { t } = useTranslation('default');

  const companyId = useRecoilValue(companyIdState);
  const editedMeetingId = useRecoilValue(editedMeetingIdState);

  const fetchCompanyMeetings = async () => {
    if (!companyId) return [];

    return fetchMeetings({ organizationId: companyId });
  };

  return useQuery([QueryKey.CompanyMeetings, companyId], fetchCompanyMeetings, {
    select: meetings =>
      editedMeetingId ? reorderMeetings(meetings, editedMeetingId) : meetings,

    onError: () =>
      notify({
        message: t`unknownError`,
      }),
    enabled: !!companyId,
    staleTime: 1000 * 60 * 5,
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        QueryKey.CompanyActionsInfo,
        companyId,
      ]);
    },
  });
};
