import type { MouseEventHandler, ReactElement } from 'react';
import React, { forwardRef, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { usePopper } from 'react-popper';
import { styled } from 'goober';

import { classList } from '@/helpers/class-list';
import { Z_INDEX_MODAL } from '@/theme/z-index';

import { Portal } from '../portal/portal';

interface WithDropdownContentProps {
  disabled?: boolean;
  testId?: string;
  content: ReactElement;
  children: React.ReactNode;
  options?: Parameters<typeof usePopper>[2];
  fullWidth?: boolean;
  setIsDropdownOpened?: (isOpened: boolean) => void;
  maxWidth?: string;
}
const DEFAULT_MAX_WIDTH = '210px';

export function WithDropdownContent({
  disabled,
  testId,
  children,
  options,
  fullWidth = false,
  setIsDropdownOpened,
  maxWidth = DEFAULT_MAX_WIDTH,
  content,
}: WithDropdownContentProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    options,
  );

  useEffect(() => {
    setIsDropdownOpened?.(isOpen);
  }, [isOpen, setIsDropdownOpened]);

  const toggleDropdown: MouseEventHandler<HTMLDivElement> = event => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  if (!content) {
    return null;
  }

  if (disabled) return <>{children}</>;

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <MenuButtonContainer
        ref={setReferenceElement}
        onClick={toggleDropdown}
        className={classList(isOpen ? 'is-open' : 'is-closed')}
      >
        {children}
      </MenuButtonContainer>
      <Portal>
        <ContentContainer
          data-testid={testId}
          className={classList(!isOpen && 'hidden')}
          ref={setPopperElement}
          style={{
            ...styles.popper,
            ...(fullWidth
              ? { width: `${referenceElement?.offsetWidth}px` }
              : { maxWidth }),
          }}
          {...attributes.popper}
        >
          {content}
        </ContentContainer>
      </Portal>
    </OutsideClickHandler>
  );
}

const ContentContainer = styled('div', forwardRef)`
  z-index: ${Z_INDEX_MODAL};
  &.hidden {
    display: none;
  }
`;

const MenuButtonContainer = styled('div', forwardRef)`
  &:hover {
    cursor: pointer;
  }
`;
