import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import {
  BaseExceptions,
  getMessageFromErrorResponse,
  isBaseError,
} from '@/api/v4/base-fetch';
import type { ShareOrganizationBody } from '@/api/v4/organization.api';
import { shareOrganization } from '@/api/v4/organization.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

import { companyIdState } from '../../../companies/overview/company.state';

export const useShareCompany = () => {
  const { t } = useTranslation('default');
  const queryClient = useQueryClient();

  const companyId = useRecoilValue(companyIdState);

  const shareCompanyMutation = useMutation(
    async (payload: ShareOrganizationBody) => {
      if (!companyId) {
        return;
      }
      return shareOrganization(companyId, payload);
    },
    {
      onSuccess: async () => {
        notify({ message: t`share.shareOrganizationSuccess`, withIcon: true });

        await queryClient.invalidateQueries([QueryKey.TeamOptions]);
        await queryClient.invalidateQueries([QueryKey.Users, companyId]);
        queryClient.removeQueries([QueryKey.TeamOptions]);
        queryClient.removeQueries([QueryKey.Users, companyId]);
      },

      onError: error => {
        if (isBaseError(error)) {
          if (error.response.status === BaseExceptions.BAD_REQUEST) {
            const message = getMessageFromErrorResponse(error);
            notify({ message: message ?? t`unknownError` });
            return;
          }
        }
        notify({ message: t`unknownError` });
      },
    },
  );

  const shareCompany = (payload: ShareOrganizationBody) =>
    shareCompanyMutation.mutateAsync(payload);

  return {
    shareCompany,
    isError: shareCompanyMutation.isError,
  };
};
