import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchProjectComments } from '@/api/v4/project-comments.api';
import { queryClient, QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

import { projectIdState } from '../../project.state';

export const useProjectComments = () => {
  const { t } = useTranslation('default');
  const projectId = useRecoilValue(projectIdState);

  const handleFetchProjectComments = async () => {
    if (!projectId) return [];

    return fetchProjectComments(projectId);
  };

  return useQuery(
    [QueryKey.ProjectComments, projectId],
    handleFetchProjectComments,
    {
      onError: () =>
        notify({
          message: t`default:unknownError`,
        }),
      enabled: !!projectId,
      staleTime: 1000 * 60 * 5,

      onSuccess: async () => {
        await queryClient.invalidateQueries([
          QueryKey.ProjectActionsInfo,
          projectId,
        ]);
      },
    },
  );
};
