import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { OrganizationSearchInitial } from '@/components/organization-search-results/organization-search-initial';
import { OrganizationSearchResults } from '@/components/organization-search-results/organization-search-results';
import { useDebounce } from '@/hooks/use-debounce';
import { modalState } from '@/state/modal.state';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Input } from '@/ui/input/input';
import { Modal } from '@/ui/modal/modal';
import { ParagraphMedium, SubHeaderBold } from '@/ui/typography/widgets';

import { companiesSearchPhraseState } from '../../companies/overview/company.state';
import { organizationSearchResultsAddedCompaniesState } from '../../companies/search-results/organization-search-results.state';
import { useOrganizationsResults } from '../../companies/search-results/use-organizations-results';

import { useAddCompanyToExploreForm } from './use-add-company-to-explore-form';

export const AddExampleCompaniesToExploreFormModal = () => {
  const { t } = useTranslation('explore');
  const [modalConfig, setModalConfig] = useRecoilState(modalState);
  const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState('');
  const ref = useRef<HTMLInputElement>(null);
  const [companiesSearchPhrase, setCompaniesSearchPhrase] = useRecoilState(
    companiesSearchPhraseState,
  );
  const setExampleCompanies =
    modalConfig?.state === 'addExampleCompaniesToExploreForm'
      ? modalConfig.setExampleCompanies
      : undefined;
  const exampleCompanies =
    modalConfig?.state === 'addExampleCompaniesToExploreForm'
      ? modalConfig.exampleCompanies
      : [];
  const handleAddCompany = useAddCompanyToExploreForm(setExampleCompanies);
  const [addedCompanies, setAddedCompanies] = useRecoilState(
    organizationSearchResultsAddedCompaniesState,
  );

  const {
    savvyCompaniesResults,
    crunchbaseCompaniesResults,
    isCrunchbaseCompaniesResultsLoading,
    isSavvyCompaniesResultsLoading,
  } = useOrganizationsResults({
    checkIsAdded: id => addedCompanies.includes(id),
  });

  const isInitialView = companiesSearchPhrase.trim().length === 0;
  const isEmptyView =
    companiesSearchPhrase.length > 0 &&
    savvyCompaniesResults?.length === 0 &&
    crunchbaseCompaniesResults?.length === 0 &&
    !isSavvyCompaniesResultsLoading &&
    !isCrunchbaseCompaniesResultsLoading;

  useEffect(() => {
    debounceCompanySearchPhrase(debouncedSearchPhrase);
  }, [debouncedSearchPhrase]);

  useEffect(() => {
    setDebouncedSearchPhrase('');
    setCompaniesSearchPhrase('');
  }, []);

  useEffect(() => {
    setAddedCompanies(exampleCompanies.map(company => company.id));
  }, []);

  const debounceCompanySearchPhrase = useDebounce((searchPhrase: string) => {
    setCompaniesSearchPhrase(searchPhrase);
  }, 1000);

  const onClose = () => {
    setModalConfig(null);
  };

  if (modalConfig?.state !== 'addExampleCompaniesToExploreForm') return null;

  return (
    <Modal
      onClose={onClose}
      CustomHeaderComponent={SubHeaderBold}
      header={t`selectCompanies`}
    >
      <ContentContainer
        name="add-company-modal-container"
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        gap="28px"
        width="987px"
        height="575px"
      >
        <Flexbox name="modal-input" direction="column" gap="18.5px">
          <Input
            value={debouncedSearchPhrase}
            placeholder={t`searchByCompanyName`}
            onChange={event => setDebouncedSearchPhrase(event.target.value)}
            autoFocus
            ref={ref}
          />
        </Flexbox>

        <Content
          name="explore-example-companies"
          fullWidth
          justify={isEmptyView ? 'center' : 'flex-start'}
          grow={1}
          data-testid="search-companies-results"
        >
          {isInitialView ? (
            <OrganizationSearchInitial
              addCompanyText={t`searchForCompaniesByName`}
            />
          ) : isEmptyView ? (
            <Flexbox
              name="explore-example-companies-empty"
              justify="center"
              alignItems="center"
            >
              <ParagraphMedium>{t`noCompaniesFound`}</ParagraphMedium>
            </Flexbox>
          ) : (
            <OrganizationSearchResults
              buttonAddText={t`select`}
              buttonAddedText={t`added`}
              savvyResultsHeader={t`selectFromYourSavvyAccount`}
              crunchbaseResultsHeader={t`selectFromCrunchbase`}
              onAddSavvyCompanyClick={handleAddCompany}
              onAddCrunchbaseCompanyClick={handleAddCompany}
              savvyCompaniesData={savvyCompaniesResults}
              isSavvyCompaniesLoading={isSavvyCompaniesResultsLoading}
              crunchbaseCompaniesData={crunchbaseCompaniesResults}
              isCrunchbaseCompaniesLoading={isCrunchbaseCompaniesResultsLoading}
            />
          )}
        </Content>

        <Flexbox name="modal-footer">
          <CompaniesCounter>
            {t('companiesSelected', { count: addedCompanies.length })}
          </CompaniesCounter>
        </Flexbox>
      </ContentContainer>
    </Modal>
  );
};

const ContentContainer = styled(Flexbox)<{ width: string }>`
  width: ${({ width }) => width};
  margin-top: 16px;
`;

const CompaniesCounter = styled('div')`
  &::first-letter {
    ${({ theme }) => theme.typography.acrossPlatform.minimizedHeader}
    vertical-align: baseline;
  }
`;

const Content = styled(Flexbox)`
  ${({ theme }) => theme.mixins.scrollbar}
  overflow: auto;
  & > div {
    width: 100%;
    ${({ theme }) => theme.mixins.scrollbar}
  }
`;
