import type { ReactNode } from 'react';
import { styled } from 'goober';

import { colors } from '@/theme/colors';

import { Flexbox } from '../../ui/flexbox/flexbox';

interface ActionPanelEmptyProps {
  image?: string;
  label: string;
  children?: ReactNode;
}

export function ActionPanelEmpty({
  children,
  image,
  label,
}: ActionPanelEmptyProps) {
  return (
    <Container
      name="action-panel-empty"
      grow={1}
      gap="29px"
      direction="column"
      alignItems="center"
      justify="center"
    >
      {image && <img src={image} />}
      <Label color={colors.basics.black}>{label}</Label>
      {children}
    </Container>
  );
}

const Container = styled(Flexbox)`
  background-color: ${({ theme }) => theme.colors.basics.white};
`;

const Label = styled('div')`
  ${({ theme }) => theme.typography.acrossPlatform.small}
`;
