import { useTranslation } from 'react-i18next';

import type { Meeting } from '@/api/v4/meetings.api';
import { formatDate } from '@/helpers/format-date';

import { Small } from './meeting-collapsible-item';

export const MeetingFooter = ({ meeting }: { meeting: Meeting }) => {
  const { t } = useTranslation('default', { keyPrefix: 'actionPanel' });

  const getUserFullName = (user: Meeting['creator']) =>
    `${user.firstName} ${user.lastName}`;

  return (
    <Small>
      {meeting.lastEditor && meeting.lastEditedDate
        ? t('meetings.editedBy', {
            editorName: getUserFullName(meeting.lastEditor),
            date: formatDate(meeting.lastEditedDate),
          })
        : t('meetings.loggedBy', {
            creatorName: getUserFullName(meeting.creator),
            date: formatDate(meeting.createdDate),
          })}
    </Small>
  );
};
