import { useRecoilValue } from 'recoil';
import { useProjectSearchResults } from './use-project-search-results.query';
import { addCompanyInProgressState } from '@/features/companies/overview/company.state';
import ProjectSearchResultsTable from './project-search-results-table';
import { ReactComponent as SearchIllustration } from '@/assets/svg/no-results-add-company.svg';

import { Loader } from '@/components/loader/loader';
import { AddCompanyToProjectsModalNoResults } from './add-company-to-projects-no-results';

export const ProjectSearchContent = ({
  isEmpty,
  onReset,
  searchPhrase,
}: {
  isEmpty: boolean;
  onReset: () => void;
  searchPhrase?: string;
}) => {
  const { data: projects, isFetching: isFetchingProjectSearchResults } =
    useProjectSearchResults(searchPhrase);
  const addCompanyInProgress = useRecoilValue(addCompanyInProgressState);
  const isLoading = isFetchingProjectSearchResults || addCompanyInProgress;
  const hasNoResults = projects?.length === 0;

  if (isLoading) return <Loader />;

  if (isEmpty && !isLoading) return <SearchIllustration />;

  if (hasNoResults && searchPhrase)
    return <AddCompanyToProjectsModalNoResults onClick={onReset} />;

  return <ProjectSearchResultsTable projects={projects ?? []} />;
};
