import { Trans } from 'react-i18next';

export const AddCompanySuccessMessage = ({
  companyName,
  projectName,
  companiesCount,
}: {
  companyName?: string;
  projectName: string;
  companiesCount: number;
}) => {
  return (
    <Trans
      ns="companies"
      i18nKey={
        companyName ? 'company.addedToProject' : 'companies.addedToProject'
      }
      values={{
        ...(companyName
          ? { companyName: companyName }
          : { count: companiesCount }),
        projectName,
      }}
      components={{ u: <u /> }}
    />
  );
};
