import { useSetRecoilState } from 'recoil';

import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { modalState } from '@/state/modal.state';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

export const useCreateActions = () => {
  const navigateTo = useNavigateTo();
  const { hasRequiredPermission } = useUserPermissions();
  const setModalState = useSetRecoilState(modalState);

  const onAddTeam = () => {
    if (!hasRequiredPermission(PERMISSION.MANAGE_TEAMS)) {
      return;
    }
    navigateTo.createTeam();
  };

  const onAddProject = () => {
    if (!hasRequiredPermission(PERMISSION.ADD_PROJECT)) {
      return;
    }
    navigateTo.createProject();
  };

  const onAddOrganization = () => {
    if (!hasRequiredPermission(PERMISSION.ADD_ORGANIZATION)) {
      return;
    }
    setModalState({ state: 'createNewCompany' });
  };

  return { onAddTeam, onAddOrganization, onAddProject };
};
