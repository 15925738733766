import React from 'react';
import { styled } from 'goober';

import { theme } from '@/theme/setupTheme';
import { Z_INDEX_HIDDEN_INPUT } from '@/theme/z-index';
import type { IconVariant } from '@/ui/icons/icon';
import { Icon } from '@/ui/icons/icon';

interface CheckBoxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  id: string;
  label: string | JSX.Element;
  icon?: IconVariant;
  name?: string;
}

export const Checkbox = ({
  id,
  label,
  icon,
  name,
  ...props
}: CheckBoxProps) => {
  return (
    <Label htmlFor={id} data-testid={`${id}-label`}>
      <CheckboxInput id={id} type="checkbox" data-name={name} {...props} />
      <CheckMark data-name={name}>
        <Icon
          icon={icon ?? 'CheckMark'}
          color={theme.colors.blue.primaryA}
          height="10px"
        />
      </CheckMark>
      {label}
    </Label>
  );
};

const Label = styled('label')`
  display: flex;
  align-items: flex-start;
  gap: 6px;
  cursor: pointer;
  ${({ theme }) => theme.typography.widget.smallText}
  color: ${({ theme }) => theme.colors.basics.black};
`;

const CheckboxInput = styled('input')`
  position: absolute;
  z-index: ${Z_INDEX_HIDDEN_INPUT};
  height: 0;
  width: 0;
  opacity: 0;
  pointer-events: none;
  user-select: none;
`;

const CheckMark = styled('div')`
  display: grid;
  place-items: center;
  min-width: 18px;
  min-height: 18px;
  background-color: ${({ theme }) => theme.colors.basics.white};
  border: 1px solid ${({ theme }) => theme.colors.gray.c6};
  border-radius: 4px;

  ${CheckboxInput}:checked + & {
    border: 2px solid ${({ theme }) => theme.colors.blue.primaryA};
    & > div {
      display: flex;
    }
  }

  ${CheckboxInput}:not(:checked) + & > div {
    visibility: hidden;
  }
`;
