import type { APIRequestBody } from './base-fetch';
import { type APIResponse, post, remove } from './base-fetch';

export type CreateProjectListingSuggestionPayload =
  APIRequestBody<'/api/v4/projects/{projectId}/listings/suggestions'>;
export type CreateProjectListingSuggestionResponse =
  APIResponse<'/api/v4/projects/{projectId}/listings/suggestions'>;

export type EnhanceProjectListingSuggestion =
  APIResponse<'/api/v4/projects/{projectId}/listings/suggestions'>[number];

type RejectProjectListingSuggestion =
  APIResponse<'/api/v4/projects/{projectId}/listings/suggestions/reject'>;

type RejectProjectListingSuggestionPayload =
  APIRequestBody<'/api/v4/projects/{projectId}/listings/suggestions/reject'>;

type UndoRejectProjectListingSuggestion =
  APIResponse<'/api/v4/projects/{projectId}/listings/suggestions/reject'>;

export const createProjectListingSuggestions = async (
  projectId: string,
  data: CreateProjectListingSuggestionPayload,
): Promise<CreateProjectListingSuggestionResponse> =>
  post<
    EnhanceProjectListingSuggestion[],
    CreateProjectListingSuggestionPayload
  >(`/v4/projects/${projectId}/listings/suggestions`, data);

export const rejectProjectListingSuggestions = async (
  projectId: string,
  data: RejectProjectListingSuggestionPayload,
) =>
  post<RejectProjectListingSuggestion, RejectProjectListingSuggestionPayload>(
    `/v4/projects/${projectId}/listings/suggestions/reject`,
    data,
  );

export const undoRejectProjectListingSuggestions = async (
  projectId: string,
  companyName: string,
) =>
  remove<UndoRejectProjectListingSuggestion>(
    `/v4/projects/${projectId}/listings/suggestions/reject/${encodeURIComponent(
      companyName,
    )}`,
  );
