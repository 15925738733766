import type { SearchOption } from '@/types';

import type { SearchResultsResponse } from '../types';

export const mapSearchApiToProjects = (
  response: SearchResultsResponse,
): SearchOption[] =>
  response.projects.data.map(org => ({
    value: org.id,
    label: org.attributes.name,
    type: 'project',
  }));
