import { useQuery } from '@tanstack/react-query';

import { fetchListingStages } from '@/api/v4/project-listings.api';
import { QueryKey } from '@/config/query-client';
import { getTimeInMs } from '@/helpers/get-time-in-ms';

export const useListingStages = () => {
  const query = useQuery([QueryKey.ListingStages], () => fetchListingStages(), {
    cacheTime: getTimeInMs(1, 'day'),
    staleTime: getTimeInMs(1, 'day'),
  });

  return {
    listingStages: query.data ?? [],
    ...query,
  };
};
