import { atom } from 'recoil';

export type SearchResultsFilter =
  | 'all'
  | 'project'
  | 'company'
  | 'team'
  | 'tag';

export const searchResultsFilters: SearchResultsFilter[] = [
  'all',
  'company',
  'project',
  'team',
  'tag',
];

export const searchResultsFilterState = atom<SearchResultsFilter>({
  key: 'searchResultsFilter',
  default: 'all',
});
