import { forwardRef, useImperativeHandle } from 'react';
import { styled } from 'goober';

import { Stack } from '@/ui/line/line';
import { TableRow } from '@/ui/table/infinite-table/table-row';

import type { UseNewCompanyTableConfigurationProps } from '../modals/add-company-modal/use-new-company-table-configuration';
import { useNewCompanyTableConfiguration } from '../modals/add-company-modal/use-new-company-table-configuration';

export type CreateCompanyTableProps = {
  defaultWebsite?: string;
} & UseNewCompanyTableConfigurationProps;

export type CreateCompanyTableRef = {
  reset(): void;
};

export const CreateCompanyTable = forwardRef<
  CreateCompanyTableRef,
  CreateCompanyTableProps
>(({ defaultWebsite, ...props }, ref) => {
  const { newCompanyRow, resetCompanyForm } = useNewCompanyTableConfiguration({
    defaultWebsite,
    ...props,
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        reset() {
          resetCompanyForm();
        },
      };
    },
    [],
  );

  return (
    <Stack margin="2px 0 0 0">
      <Table>
        <tbody>
          <TableRow row={newCompanyRow} />
        </tbody>
      </Table>
    </Stack>
  );
});

CreateCompanyTable.displayName = 'CreateCompanyTable';

const Table = styled('table')`
  background-color: ${({ theme }) => theme.colors.basics.white};
  width: calc(100% - 30px);
  height: fit-content;
  table-layout: fixed;
  margin-left: 15px;

  td {
    background-color: transparent;
  }
`;
