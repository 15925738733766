import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import type {
  GenAIRequestResultBody,
  GenAIRequestResultParams,
} from '@/api/v4/genai-request';
import { updateGenAIRequestResult } from '@/api/v4/genai-request';
import { notify } from '@/ui/snackbar/notify';

export const useGenAIRequestResultMutation = () => {
  const { t } = useTranslation('default');

  return useMutation({
    mutationFn: async (config: {
      params: GenAIRequestResultParams;
      body: GenAIRequestResultBody;
    }) => {
      return await updateGenAIRequestResult(config.params, config.body);
    },
    onError: (error: Error) => {
      notify({
        message: t`unknownError`,
      });
      console.error(error.message);
    },
  });
};
