import type { IGetRefs } from '@/hooks/use-multi-refs';
import { Flexbox } from '@/ui/flexbox/flexbox';
import type { AsyncSelectProps } from '@/ui/select/async/async-select';

import type { Comment, EditCommentCallback } from './comment.type';
import { CommentsList } from './comments-list';
import { InsertCommentSection } from './insert-comment-section';
import { actionPanelScrollToParamState } from '../action-panel.state';
import { useSetRecoilState } from 'recoil';
import { useActionPanelSearchParams } from '../use-action-panel-search-params';

export const CommentsTab = ({
  onSubmit,
  onClickDelete,
  onEditSubmit,
  comments,
  loading,
  dropdown,
}: {
  onSubmit: (comment: string, mentionedUsers: number[]) => Promise<string>;
  onClickDelete: (commentId: string) => void;
  onEditSubmit: EditCommentCallback<string>;
  comments: Comment[];
  loading: boolean;
  dropdown?: AsyncSelectProps;
}) => {
  const setIsScrolled = useSetRecoilState(actionPanelScrollToParamState);
  const { commentParam, setCommentParam } = useActionPanelSearchParams();

  const scrollToLastModifiedTag: IGetRefs = refs => {
    if (!commentParam) return;

    const commentNode = refs[commentParam];

    if (!commentNode) return;

    commentNode.focus();
    commentNode.scrollIntoView({
      block: 'nearest',
      behavior: 'smooth',
      inline: 'nearest',
    });

    setIsScrolled(true);
  };

  const handleSubmit = async (comment: string, mentionedUsers: number[]) => {
    const commentId = await onSubmit(comment, mentionedUsers);
    setCommentParam(commentId);
  };

  const handleEdit: EditCommentCallback<void> = async (id, data) => {
    const commentId = await onEditSubmit(id, data);
    setCommentParam(commentId);
  };

  return (
    <Flexbox name="comments-container" direction="column" grow={1}>
      <CommentsList
        comments={comments}
        onClickDelete={onClickDelete}
        onEditSubmit={handleEdit}
        getRefs={scrollToLastModifiedTag}
        loading={loading}
        dropdown={dropdown}
      />
      <InsertCommentSection onSubmit={handleSubmit} />
    </Flexbox>
  );
};
