import { getSessionToken } from '@descope/react-sdk';
import { useQueryClient } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';

import { descope } from '@/config/descope';

import { jwtTokenState } from '../auth.state';

export const useRemoveSession = () => {
  const setToken = useSetRecoilState(jwtTokenState);
  const queryClient = useQueryClient();
  const descopeToken = getSessionToken();

  return async () => {
    if (descopeToken) {
      await descope.logout(descopeToken);
    }

    queryClient.removeQueries();
    setToken(null);
  };
};
