import { styled } from 'goober';

import { Inline } from '@/ui/line/line';

export const SkeletonSearchItem = () => {
  return (
    <SkeletonContainer gap="16px" templateColumns={'24px 50%'}>
      <SkeletonBox />
      <SkeletonBox />
    </SkeletonContainer>
  );
};

const SkeletonContainer = styled(Inline)`
  padding: 20px 8px;
`;

const SkeletonBox = styled('div')`
  height: 14px;
  border-radius: 4px;
  width: 100%;
  ${({ theme }) => theme.animations.skeletonAnimation()};
`;
