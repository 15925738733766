import { useRef } from 'react';

import { isEllipsis, isOption } from '@/helpers/other';
import { CustomOptionWrapper } from '@/ui/select/async/components/option/custom-option-wrapper';
import type { AsyncSelectOptionProps } from '@/ui/select/async/components/option/option';

import { SearchOptionItem } from './search-option-item';

export const SearchCustomOption = ({ ...props }: AsyncSelectOptionProps) => {
  const ref = useRef<HTMLDivElement>(null);

  if (!isOption(props.data)) {
    return <></>;
  }

  return (
    <CustomOptionWrapper
      tooltipContent={props.data.label}
      isTooltipVisible={isEllipsis(ref.current)}
      {...props}
    >
      <SearchOptionItem ref={ref} {...props} />
    </CustomOptionWrapper>
  );
};
