import {
  get,
  type APIRequestQueryParams,
  type APIResponse,
} from './base-fetch';

export type SharedProject = APIResponse<'/api/v4/shared-projects'>[number];
export type SharedProjectsParams =
  APIRequestQueryParams<'/api/v4/shared-projects'>;

export const fetchSharedProjects = (params: SharedProjectsParams) => {
  return get<SharedProject[]>('/v4/shared-projects', {
    params,
  });
};
