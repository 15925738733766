import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteProjectComment } from '@/api/v4/project-comments.api';
import { notify } from '@/ui/snackbar/notify';
import { QueryKey } from '@/config/query-client';
import { useRecoilValue } from 'recoil';
import { projectIdState } from '../../project.state';

export const useDeleteProjectComment = () => {
  const { t } = useTranslation('default');
  const projectId = useRecoilValue(projectIdState);
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteProjectComment, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([QueryKey.Project, projectId]),
        queryClient.invalidateQueries([QueryKey.ProjectComments, projectId]),
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);
    },
    onError: () => {
      notify({
        message: t`unknownError`,
      });
    },
  });

  const deleteComment = (commentId: string) => mutation.mutateAsync(commentId);

  return {
    deleteComment,
    isError: mutation.isError,
    isDeleteInProgress: mutation.isLoading,
  };
};
