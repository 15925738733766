import { useQueryClient } from '@tanstack/react-query';

import { checkOrganizationDomainUsage } from '@/api/v4/organization.api';
import { QueryKey } from '@/config/query-client';

export const useCheckCompanyDomainAvailability = () => {
  const queryClient = useQueryClient();

  return (url: string) =>
    queryClient.ensureQueryData([QueryKey.CompanyDomainAvailability, url], () =>
      checkOrganizationDomainUsage(url),
    );
};
