import { atom, atomFamily } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import type { ISort } from '@/ui/table/table.types';

import type { ProjectListingsDisplayMode } from './project-listings/project-listings';
import { type ProjectCompaniesSort } from '@/api/v4/projects.api';

const { persistAtom } = recoilPersist();

export const projectListingSortState = atom<ISort<ProjectCompaniesSort>>({
  key: 'projectListingSortState',
  default: {
    sort: 'createdDate',
    direction: 'desc',
  },
});

export const projectListingsDisplayModeState = atomFamily<
  ProjectListingsDisplayMode,
  string[]
>({
  key: 'projectListingsDisplayModeState',
  default: 'table',
  effects_UNSTABLE: [persistAtom],
});
