import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { deleteProjectFile as deleteProjectFileAPI } from '@/api/v4/project-files.api';
import { QueryKey } from '@/config/query-client';

import { projectIdState } from '../../projects/project.state';

export const useDeleteProjectFile = () => {
  const queryClient = useQueryClient();
  const projectId = useRecoilValue(projectIdState);

  const mutation = useMutation(deleteProjectFileAPI, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([QueryKey.ProjectFiles]),
        queryClient.invalidateQueries([QueryKey.ProjectActionsInfo]),
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);
    },
  });

  const deleteProjectFile = (fileId: string) => {
    if (!projectId) return;

    return mutation.mutateAsync({ projectId, fileId });
  };

  return {
    deleteProjectFile,
    isError: mutation.isError,
  };
};
