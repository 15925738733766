import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { QueryKey } from '@/config/query-client';

import { useAllUsers } from '../../app-users/hooks/use-all-users.query';
import {
  settingsInvitedUsersSearchPhraseState,
  settingsInvitedUsersSortState,
} from '../settings.state';
import type { AllUsersQueryParams } from '@/api/v4/user.api';

export const useInvitedUsers = () => {
  const { sort, direction } = useRecoilValue(settingsInvitedUsersSortState);
  const search = useRecoilValue(settingsInvitedUsersSearchPhraseState);

  const queryClient = useQueryClient();

  const params: AllUsersQueryParams = {
    sort,
    direction,
    ...(search && {
      search,
    }),
    statuses: ['invited'],
  };

  const { data, isLoading } = useAllUsers(params);

  const refreshUsers = async () => {
    await queryClient.invalidateQueries([QueryKey.UsersCount]);
    await queryClient.invalidateQueries([QueryKey.UsersAll, params]);
  };

  return {
    invitedUsers: data ?? [],
    isLoading,
    refreshUsers,
  };
};
