import { useQuery } from '@tanstack/react-query';

import { fetchUsers } from '@/api/v4/user.api';
import { QueryKey } from '@/config/query-client';

import { useTeamOptions } from '@/features/teams/use-team-options.query';
import { mapUserToOption } from '@/features/modals/share-modal/helpers/map-users-and-teams-to-options';
import { useRecoilValue } from 'recoil';
import { companyIdState } from '@/features/companies/overview/company.state';
import { teamIdState } from '@/features/teams/team.state';

export const useUsersAndTeamsWithAccessToFile = (fileId: string) => {
  const companyId = useRecoilValue(companyIdState);
  const teamId = useRecoilValue(teamIdState);

  const params = {
    withAccessToOrganizationFile: companyId ? fileId : undefined,
    withAccessToTeamFile: teamId ? fileId : undefined,
  };

  const { data: users = [], isFetching: loadingUsers } = useQuery(
    [QueryKey.Users, params],
    async () => {
      if (!fileId) return [];

      return fetchUsers(params);
    },
    {
      enabled: !!fileId,
      select: users => users.map(mapUserToOption),
      staleTime: 1000 * 10,
    },
  );

  const { data: teams = [], isFetching: loadingTeams } = useTeamOptions(params);

  return {
    usersAndTeamsWithAccess: [...users, ...teams],
    isLoading: loadingTeams || loadingUsers,
  };
};
