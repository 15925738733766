import { styled } from 'goober';

import { Stack } from '@/ui/line/line';

const DEFAULT_SEARCH_MODAL_HEIGHT = '514px';
const MODAL_HORIZONTAL_PADDING = '38px';
const GAP_BETWEEN_INPUT_AND_SEARCH_CONTENT = '10px';
const INPUT_HEIGHT = '35px';

export const SearchContentContainer = styled(Stack)`
  position: relative;
  height: calc(
    ${DEFAULT_SEARCH_MODAL_HEIGHT} - ${MODAL_HORIZONTAL_PADDING} -
      ${MODAL_HORIZONTAL_PADDING} - ${INPUT_HEIGHT} -
      ${GAP_BETWEEN_INPUT_AND_SEARCH_CONTENT}
  );
`;
