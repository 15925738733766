import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import type { ProjectOption, ProjectOptionsQuery } from '@/api/v4/projects.api';
import { fetchProjectOptions } from '@/api/v4/projects.api';

export const useProjectOptions = (params: ProjectOptionsQuery) => {
  const { t } = useTranslation('auth');

  return useQuery(
    [QueryKey.ProjectOptions, params],
    () => fetchProjectOptions(params),
    {
      select: data => mapProjectOptions(data),
      onError: () =>
        notify({
          message: t`unknownError`,
        }),
      staleTime: 1000 * 10 * 5,
    },
  );
};

export const mapProjectOptions = (projects: ProjectOption[]) =>
  projects.map(project => ({
    value: project.id,
    label: project.name,
    createdFor: project.team ? project.team.id : null,
  }));
