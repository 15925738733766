import { styled } from 'goober';

import { Button } from '../button/button';
import { colorIcon } from '../icons/functions';

import { MenuButton } from './menu-button';

export const MenuButtonWithVisibilitySwitch = ({
  isVisible,
}: {
  isVisible: boolean;
}) => (
  <VisibleControlButton
    variant="text"
    isVisible={isVisible}
    data-testid="menu-button"
  >
    <MenuButton />
  </VisibleControlButton>
);

const VisibleControlButton = styled(Button)<{ isVisible: boolean }>`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  ${({ theme }) => theme.mixins.transition('visibility')};
  border-radius: 50%;
  padding: 0;
  &:hover {
    background-color: ${({ theme }) => theme.colors.basics.white};
    ${({ theme }) => colorIcon(theme.colors.blue.primaryA)};
  }
`;
