import { forwardRef, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { styled } from 'goober';

import { useOutsideWindowMeasure } from '@/hooks/use-outside-window-measure';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Portal } from '@/ui/portal/portal';
import { EllipsisTab } from '@/ui/typography/action-panel';

interface UseCaseFitVotesDropdownProps {
  items: { name: string; description: string }[];
  children: React.ReactElement;
  id: string;
}

const DROPDOWN_BACKGROUND_ID = 'use-case-votes-dropdown-background';

export function UseCaseFitVotesDropdown({
  items,
  children,
  id,
}: UseCaseFitVotesDropdownProps) {
  const [reference, setReference] = useState<HTMLElement | null>(null);
  const [popper, setPopper] = useState<HTMLElement | null>(null);

  const [isHovered, setIsHovered] = useState(false);
  const { isElementOutsideWindow } = useOutsideWindowMeasure(popper, isHovered);

  const { styles, attributes } = usePopper(reference, popper, {
    placement: isElementOutsideWindow ? 'left' : 'right',
  });

  useEffect(() => {
    function handleResize(e: MouseEvent) {
      if (!isHovered) return;

      const element = e.target as Element;

      const parent = element.closest(`#${id}`);
      const background = element.closest(`#${DROPDOWN_BACKGROUND_ID}`);

      if (!parent && !background) {
        setIsHovered(false);
      }
    }
    window.addEventListener('mousemove', handleResize);
    return () => {
      window.removeEventListener('mousemove', handleResize);
    };
  }, [isHovered, id]);

  return (
    <>
      <div
        ref={setReference}
        onMouseEnter={e =>
          items.length > 0 &&
          !(e.target as Element).closest("div[class*='singleValue']") &&
          setIsHovered(true)
        }
      >
        {children}
      </div>

      <Portal>
        <ListBackground
          id={DROPDOWN_BACKGROUND_ID}
          visible={isHovered}
          align={isElementOutsideWindow ? 'right' : 'left'}
          ref={setPopper}
          style={styles.popper}
          {...attributes.popper}
        >
          <DropdownList>
            {items.map(item => (
              <DropdownItem key={item.name}>
                <Flexbox
                  name="votes-dropdown-item-label"
                  gap="1px"
                  direction="column"
                  justify="center"
                  fullWidth
                >
                  <EllipsisTab label={item.name} />
                  <GreyEllipsisTab label={item.description} />
                </Flexbox>
              </DropdownItem>
            ))}
          </DropdownList>
        </ListBackground>
      </Portal>
    </>
  );
}

const ListBackground = styled('div', forwardRef)<{
  visible: boolean;
  align: 'left' | 'right';
}>`
  background-color: transparent;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  display: block;
  ${({ align }) => `padding-${align}: 14px`};
  height: max-content;
  width: calc(max-content + 14px);
  position: absolute;
  top: 0;
  right: 0;
`;

const DropdownList = styled('ul', forwardRef)`
  max-width: 210px;
  background-color: ${({ theme }) => theme.colors.basics.white};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadow.actionsPanel};

  padding: 7px 2px;
  border: 1px solid ${props => props.theme.colors.gray.c2};
`;

const DropdownItem = styled('li', forwardRef)`
  color: ${props => props.theme.colors.basics.black};
  border-bottom: 1px solid ${props => props.theme.colors.gray.c2};
  height: 42px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  white-space: nowrap;

  &:last-child {
    border-bottom: none;
  }

  padding-left: 14px;
  padding-right: 14px;
`;

const GreyEllipsisTab = styled(EllipsisTab)`
  color: ${({ theme }) => theme.colors.gray.c11};
`;
