import type { FC } from 'react';
import { styled } from 'goober';

import type { FlexBoxProps } from '@/ui/flexbox/flexbox';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { SmallText } from '@/ui/typography/action-panel';

interface WithLabelProps extends FlexBoxProps {
  labelText: string;
}

export const WithLabel: FC<WithLabelProps> = ({
  children,
  name,
  labelText,
  ...props
}) => {
  return (
    <Container name={name} direction="column" gap="6px" {...props}>
      {children}
      <Label>{labelText}</Label>
    </Container>
  );
};

const Container = styled(Flexbox)`
  position: relative;
`;

const Label = styled(SmallText)`
  margin-left: 10px;
`;
