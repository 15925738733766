export const colorIcon = (color: string) => `
  svg {
    [stroke] {
      stroke: ${color};
    }
    [fill] {
      fill: ${color};
    }
  }
`;

export const strokeColorIcon = (color: string, bgColor?: string) => `
  svg {
    stroke: ${color};

    [stroke] {
      stroke: ${color};
    }
    
    [fill] {
      fill: ${bgColor ?? color};
    }
  }
`;
