import type { MouseEventHandler } from 'react';
import { useEffect, useState } from 'react';
import { styled } from 'goober';

import { getInitials } from '@/helpers/get-initials';
import { getTeamNameInitials } from '@/helpers/get-team-name-initials';
import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';

import { strokeColorIcon } from '../icons/functions';
import { Icon } from '../icons/icon';

export interface LogoProps {
  name: string;
  logoUrl?: string | null;
  className?: string;
  fallback?: React.ReactNode;
  singleLetter?: boolean;
  bgColor?: string;
  border?: string;
  borderRadius?: string;
  isVerified?: boolean;
  verificationIconColor?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  size?: string;
  fontSize?: string;
}

export const Logo = ({
  name,
  logoUrl,
  onClick,
  className,
  fallback,
  bgColor,
  singleLetter = false,
  isVerified = false,
  verificationIconColor,
  size,
  border,
  borderRadius,
  fontSize,
}: LogoProps) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!logoUrl) return;
    setError(false);
    const img = new Image();

    const handleLoadOrError = (loadFailed = false) => {
      if (loadFailed) console.warn(`Failed to load image: ${logoUrl}`);
      setError(loadFailed);
    };

    img.onload = () => handleLoadOrError(false);
    img.onerror = () => handleLoadOrError(true);

    img.src = logoUrl;

    // Cleanup function
    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [logoUrl]);

  const initials = getInitials(name, singleLetter).toUpperCase();
  const iconColor = logoUrl && !error ? colors.basics.white : bgColor;
  const iconBorder = logoUrl && !error ? border : undefined;
  return (
    <LogoWrapper>
      <Container
        className={className}
        name="company-logo-container"
        justify="center"
        alignItems="center"
        onClick={onClick}
        bgColor={iconColor}
        size={size}
        border={iconBorder}
        borderRadius={borderRadius}
        fontSize={fontSize}
      >
        {logoUrl && !error ? (
          <Img
            src={logoUrl}
            alt={name}
            onError={error => {
              if (error instanceof Error) console.warn(error.message);
              setError(true);
            }}
          />
        ) : (
          fallback ?? initials
        )}
      </Container>
      {isVerified && (
        <VerificationIcon
          icon="Logo"
          width="29.67"
          height="29.67"
          bgColor={verificationIconColor}
        />
      )}
    </LogoWrapper>
  );
};
const Container = styled(Flexbox)<{
  bgColor?: string;
  border?: string;
  borderRadius?: string;
  size?: string;
  fontSize?: string;
}>`
  position: relative;
  border-radius: ${({ borderRadius }) => borderRadius ?? '50%'};
  overflow: hidden;
  width: ${({ size }) => size ?? '30px'};
  height: ${({ size }) => size ?? '30px'};
  background-color: ${({ theme, bgColor }) =>
    bgColor ?? theme.colors.blue.primaryB};
  color: ${({ theme }) => theme.colors.basics.white};
  font-size: ${({ fontSize }) => fontSize ?? '14px'};
  border: ${({ border, bgColor }) =>
    border ?? `1px solid ${bgColor ?? 'transparent'}`};
`;

const Img = styled('img')`
  width: 100%;
  object-fit: cover;
`;

export const SquareLogo = styled(Logo)<{ size?: string; radius?: string }>`
  ${({ theme }) => theme.typography.companyInfo.filter}
  background-color: ${({
    theme,
    logoUrl,
    bgColor = theme.colors.accent.purple.c2,
  }) => (!logoUrl ? bgColor : theme.colors.basics.white)};
  border-radius: ${({ radius = '4px' }) => radius};
  color: ${({ theme }) => theme.colors.basics.white};
  width: ${({ size }) => size ?? '30px'};
  height: ${({ size }) => size ?? '30px'};
`;

export const TeamLogo = ({
  name = '',
  type = 'square',
  size,
  className,
}: {
  name?: string;
  type?: 'square' | 'round';
  size?: string;
  className?: string;
}) => {
  const initials = getTeamNameInitials(name);
  if (type === 'square')
    return <SquareLogo name={initials} size={size} className={className} />;

  return <Logo name={initials} className={className} />;
};

const LogoWrapper = styled('div')`
  position: relative;
  width: fit-content;
`;

const VerificationIcon = styled(Icon)<{ bgColor?: string }>`
  position: absolute;
  stroke: ${({ theme }) => theme.colors.basics.white};
  stroke-width: 2px;
  right: -8px;
  top: 0;

  ${({ theme, bgColor }) =>
    strokeColorIcon(theme.colors.basics.white, bgColor)};
`;
