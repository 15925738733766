import {
  ADOBE_EXTENSIONS,
  AUDIO_EXTENSIONS,
  DOC_EXTENSIONS,
  IMAGE_EXTENSIONS,
  MAX_FILE_SIZE,
  VIDEO_EXTENSIONS,
  ZIP_EXTENSIONS,
} from '../features/action-panel/files/constants';

export interface FileDelete {
  id: string;
  name: string;
}

export const getMaxFileSize = () => {
  if (typeof MAX_FILE_SIZE === 'string') {
    return parseInt(MAX_FILE_SIZE.replace('m', ''), 10) * 1048576;
  }
  return 1048576;
};

export const extractFileExtension = (filename: string) =>
  filename.split('.')?.slice(-1)?.[0].toLowerCase() || '';

export const getFileIcon = (name: string) => {
  const fileExtension = extractFileExtension(name);
  switch (true) {
    case ADOBE_EXTENSIONS.includes(fileExtension):
      return 'FilePdf';
    case DOC_EXTENSIONS.includes(fileExtension):
      return 'FileDoc';
    case IMAGE_EXTENSIONS.includes(fileExtension):
      return 'FileImage';
    case AUDIO_EXTENSIONS.includes(fileExtension):
      return 'FileAudio';
    case VIDEO_EXTENSIONS.includes(fileExtension):
      return 'FileVideo';
    case ZIP_EXTENSIONS.includes(fileExtension):
      return 'FileZip';
    default:
      return null;
  }
};

export const isAllowedExtension = (extensionType: string) =>
  ['image/png', 'image/jpeg'].some(type => type === extensionType);

export const isSizeCorrect = (size: number) => size * 1.4 < 3145728;
