import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { recentItemsTypeState } from '../recent-items.state';

import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';
import {
  togglePinnedItem,
  type TogglePinnedItemPayload,
} from '@/api/v4/pinned-items.api';
import { type LastActivity } from '@/api/v4/last-activities.api';
import type { CompanyData } from '@/api/v4/organization.api';
import type { Project } from '@/api/v4/projects.api';
import { isBaseError } from '@/api/v4/base-fetch';

export interface PinnedItemsContext {
  prevAllLastActivities?: LastActivity[];
  prevCompanyData?: CompanyData;
  prevProjectData?: Project;
}

export const useTogglePinnedItem = ({
  onMutate,
  onRollback,
}: {
  onMutate: (data: TogglePinnedItemPayload) => Promise<PinnedItemsContext>;
  onRollback: (context: PinnedItemsContext, itemId?: string) => void;
}) => {
  const { t } = useTranslation('default');
  const selectedRecentItemsType = useRecoilValue(recentItemsTypeState);
  const queryClient = useQueryClient();

  const handleErrorNotification = (error: Error) => {
    if (
      isBaseError(error) &&
      error.response.data.message === 'Max pinned items limit reached'
    ) {
      notify({
        message: t`maxPinnedItemsLimitReached`,
      });
    } else {
      notify({
        message: t`unknownError`,
      });
    }
  };

  const { isLoading, mutateAsync } = useMutation(
    async (data: TogglePinnedItemPayload) => await togglePinnedItem(data),
    {
      onMutate: async variables => {
        const context = await onMutate(variables);

        return context;
      },
      onError: (error: Error, variables, context) => {
        handleErrorNotification(error);

        context && onRollback(context, variables.id);
        console.error(error.message);
      },
      onSettled: async (_data, _error, variables) => {
        const itemType = variables.type;
        const itemId = variables.id;

        const invalidationQueryKey =
          itemType === 'company' ? QueryKey.Company : QueryKey.Project;

        await Promise.all([
          queryClient.invalidateQueries(
            [QueryKey.LastActivities, selectedRecentItemsType],
            {
              refetchType: 'inactive',
            },
          ),
          queryClient.invalidateQueries([invalidationQueryKey, itemId]),
        ]);
      },
    },
  );

  const toggleItem = (data: TogglePinnedItemPayload) => mutateAsync(data);

  return {
    toggleItem,
    isLoading,
  };
};
