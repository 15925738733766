import { forwardRef } from 'react';
import { styled } from 'goober';
import { RouteLink } from './route-link';

export const Link = styled(RouteLink, forwardRef)<{ color?: string }>`
  ${({ theme }) => theme.typography.widget.smallText}
  color: ${({ theme, color }) => color ?? theme.colors.blue.primaryA};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
