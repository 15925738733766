import type { ChangeEvent, MouseEvent } from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { styled } from 'goober';

import { TextArea } from '@/ui/text-editable/text-editable.styles';

export const BorderedTextArea = ({
  id,
  value,
  onChange,
  error,
  placeholder,
  height,
  maxHeight,
  maxLength,
  resize = 'none',
  resizeOnContentGrowth = false,
  className,
}: {
  id?: string;
  value: string | null;
  onChange?: (newValue: string) => void;
  placeholder?: string;
  error?: boolean;
  height?: number;
  maxHeight?: number;
  maxLength?: number;
  resize?: 'both' | 'horizontal' | 'vertical' | 'none';
  resizeOnContentGrowth?: boolean;
  className?: string;
}) => {
  const [fixedHeight, setFixedHeight] = useState(height ?? maxHeight);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (resizeOnContentGrowth && textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${
        textareaRef.current.scrollHeight + 5
      }px`;
      textareaRef.current.style.flexGrow = 'inherit';
    }
  }, [value]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(e.target.value);
  };

  const handleMouseUp = (e: MouseEvent<HTMLTextAreaElement>) => {
    if (
      resize !== 'none' &&
      Math.abs(e.currentTarget.clientHeight - (fixedHeight ?? 0)) <= 4
    )
      setFixedHeight(e.currentTarget.clientHeight);
  };

  return (
    <StyledTextArea
      id={id}
      data-testid={id}
      ref={textareaRef}
      value={value ?? undefined}
      border
      placeholder={placeholder}
      height={fixedHeight ? `${fixedHeight}px` : '100%'}
      maxHeight={maxHeight ? `${maxHeight}px` : undefined}
      resize={resize}
      onChange={handleChange}
      onMouseUp={handleMouseUp}
      flexGrow="inherit"
      error={error}
      maxLength={maxLength}
      className={className}
    />
  );
};

const StyledTextArea = styled(TextArea, forwardRef)<{
  disabled?: boolean;
  error?: boolean;
  placeholder?: string;
  height?: string;
  maxHeight?: string;
}>`
  ${({ theme }) => theme.typography.widget.paragraphSmall};
  width: 100%;
  box-sizing: border-box;
  height: ${({ height }) => height};
  max-height: ${({ maxHeight }) => maxHeight ?? 'unset'};
  cursor: text;
  padding: 16px 20px;
  background-color: ${({ theme }) => theme.colors.basics.white};
  border: solid 2px
    ${({ error, theme }) =>
      error ? theme.colors.system.lowFit : theme.colors.gray.c2};
  border-radius: 10px;
  &:focus {
    ${({ error, theme }) =>
      !error && `border-color: ${theme.colors.blue.primaryA}`};
  }
`;
