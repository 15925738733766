import { styled } from 'goober';

import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { ParagraphMedium } from '@/ui/typography/widgets';

type OrganizationSearchResultsEmptyProps = {
  addCompanyText: string;
  noResultText: string;
  onAddCustomCompanyClick: () => void;
};

export const OrganizationSearchResultsEmpty = ({
  addCompanyText,
  noResultText,
  onAddCustomCompanyClick,
}: OrganizationSearchResultsEmptyProps) => {
  return (
    <NoResultsContainer
      direction="column"
      name="d"
      justify="center"
      alignItems="center"
      gap="30px"
    >
      <ParagraphMedium>{noResultText}</ParagraphMedium>
      <Button
        startIcon="Plus"
        variant="navy"
        onClick={() => onAddCustomCompanyClick()}
      >
        {addCompanyText}
      </Button>
    </NoResultsContainer>
  );
};

const NoResultsContainer = styled(Flexbox)`
  width: 100%;
  height: 100%;
`;
