import { useMutation, useQueryClient } from '@tanstack/react-query';

import { MutationKey, QueryKey } from '@/config/query-client';
import type { ShareFilePayload } from '@/features/action-panel/files/edit-file';
import { teamIdState } from '@/features/teams/team.state';
import { useRecoilValue } from 'recoil';
import { shareTeamFile } from '@/api/v4/team-files.api';

export const useShareTeamFile = () => {
  const queryClient = useQueryClient();
  const teamId = useRecoilValue(teamIdState);

  const mutation = useMutation(
    async ({ fileId, ...payload }: ShareFilePayload) => {
      if (!teamId) {
        return;
      }
      return shareTeamFile(teamId, fileId, payload);
    },
    {
      mutationKey: [MutationKey.TeamFileShare],
    },
  );

  return {
    shareTeamFile: (payload: ShareFilePayload) =>
      mutation.mutateAsync(payload, {
        onSuccess: async (_data, variables) => {
          const params = {
            withAccessToTeamFile: variables.fileId,
          };

          await queryClient.invalidateQueries([QueryKey.TeamFiles]);
          await queryClient.invalidateQueries([QueryKey.Users, params]);
          await queryClient.invalidateQueries([QueryKey.TeamOptions, params]);
        },
      }),
  };
};
