import { Flexbox } from '../flexbox/flexbox';
import { Icon } from '../icons/icon';

export const MenuButton = ({ onClick }: { onClick?: () => void }) => (
  <Flexbox
    name="menu-button"
    direction="column"
    gap="3px"
    justify="space-between"
    onClick={onClick}
  >
    {Array.from(Array(3).keys()).map(key => (
      <Icon key={key} icon="Dot" width="9px" height="3px" />
    ))}
  </Flexbox>
);
