type TimeUnit = 'minute' | 'hour' | 'day';

export const getTimeInMs = (
  amount: number,
  unit: TimeUnit = 'minute',
): number => {
  switch (unit) {
    case 'minute':
      return amount * 1000 * 60;
    case 'hour':
      return amount * 1000 * 60 * 60;
    case 'day':
      return amount * 1000 * 60 * 60 * 24;
    default:
      throw new Error('Invalid time unit');
  }
};
