import type { ChangeEvent } from 'react';
import { forwardRef, useState } from 'react';
import { styled } from 'goober';

import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import type { IconVariant } from '@/ui/icons/icon';
import { Icon } from '@/ui/icons/icon';

import { colorIcon } from '../icons/functions';
import { Tooltip } from '../tooltip/tooltip';

import { inputContainerMixin, inputMixin } from './input-mixin';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  fontSize?: string;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: string;
  errorJustifyContent?: string;
  errorFontSize?: string;
  errorAlign?: 'left' | 'right';
  isErrorAbsolute?: boolean;
  width?: string;
  autoFocus?: boolean;
  hideErrorMessage?: boolean;
  rightIcon?: IconVariant;
  leftIcon?: IconVariant;
  iconColor?: string;
  iconSize?: string;
  rightIconSeparator?: boolean;
  rightIconTooltip?: string;
  padding?: string;
  gap?: string;
  noBorder?: boolean;
  onClearInput?: () => void;
  showClearIcon?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    value,
    fontSize,
    placeholder,
    onChange,
    disabled,
    type,
    error,
    errorAlign,
    isErrorAbsolute,
    width,
    autoFocus,
    hideErrorMessage,
    leftIcon,
    rightIcon,
    rightIconTooltip,
    iconColor,
    iconSize,
    rightIconSeparator,
    padding,
    errorJustifyContent,
    errorFontSize,
    readOnly,
    gap,
    className,
    onClearInput,
    showClearIcon,
    ...rest
  },
  inputRef,
) {
  const [inputType, setInputType] = useState(type);

  return (
    <Wrapper
      className={className}
      fullWidth
      name="input"
      direction="column"
      gap="8px"
    >
      <Label
        htmlFor={rest.name}
        aria-label={rest.name}
        error={error}
        width={width}
        disabled={disabled}
        padding={padding}
        readOnly={readOnly}
        gap={gap}
      >
        {!!leftIcon && (
          <Icon
            icon={leftIcon}
            color={iconColor ?? colors.gray.c2}
            width={iconSize ?? '18px'}
            height={iconSize ?? '18px'}
          />
        )}
        <InputComponent
          ref={inputRef}
          id={rest.name}
          name={rest.name}
          placeholder={placeholder}
          value={value}
          fontSize={fontSize}
          onChange={onChange}
          disabled={disabled}
          type={inputType}
          autoFocus={autoFocus}
          readOnly={readOnly}
          {...rest}
        />
        {type === 'password' ? (
          <Icon
            icon={inputType === 'password' ? 'Eye' : 'EyeCrossed'}
            onClick={() =>
              setInputType(currentType =>
                currentType === 'password' ? 'text' : 'password',
              )
            }
          />
        ) : rightIcon ? (
          <RightIconWrapper withSeparator={rightIconSeparator}>
            <Tooltip content={rightIconTooltip}>
              <Icon
                icon={rightIcon}
                color={iconColor ?? colors.gray.c2}
                width={iconSize ?? '18px'}
                height={iconSize ?? '18px'}
              />
            </Tooltip>
          </RightIconWrapper>
        ) : null}

        {Boolean(onClearInput) && showClearIcon && (
          <ClearIcon icon="CloseCircle" onClick={onClearInput} />
        )}
      </Label>
      {!!error && !hideErrorMessage && (
        <Error
          absolute={isErrorAbsolute}
          errorAlign={errorAlign}
          fontSize={errorFontSize ?? fontSize}
          justifyContent={errorJustifyContent}
        >
          {error}
        </Error>
      )}
    </Wrapper>
  );
});

const Wrapper = styled(Flexbox)`
  position: relative;
`;

const Label = styled('label')<
  Pick<InputProps, 'error' | 'width' | 'disabled' | 'padding' | 'gap'> & {
    readOnly?: boolean;
    noBorder?: boolean;
  }
>`
  ${({ theme, disabled }) => inputContainerMixin(theme, disabled)}
  ${({ error, theme }) =>
    error && `border-color: ${theme.colors.system.lowFit};`}

  ${({ width }) => Boolean(width) && `width: ${width};`}
  ${({ padding }) => Boolean(padding) && `padding: ${padding};`}
  ${({ readOnly }) => Boolean(readOnly) && 'pointer-events: none;'}
  ${({ gap }) => Boolean(gap) && `gap: ${gap};`}

  ${({ noBorder }) =>
    noBorder &&
    `
    border: unset;
    outline: none;
    `}
`;

const InputComponent = styled('input', forwardRef)<{
  disabled?: boolean;
  fontSize?: string;
}>`
  ${({ theme, disabled }) => inputMixin(theme, disabled)}
  width: 100%;
  height: 100%;
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')};
  background-color: ${({ theme }) => theme.colors.basics.white};
`;

export const Error = styled('span')<{
  absolute?: boolean;
  errorAlign?: 'left' | 'right';
  fontSize?: string;
  justifyContent?: string;
}>`
  ${({ theme }) => theme.typography.widget.smallText};
  color: ${({ theme }) => theme.colors.system.lowFit};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}
  display: flex;

  ${({ absolute = false, errorAlign = 'left' }) =>
    absolute &&
    `
      position: absolute;
      top: calc(100% + 2.5px);
      ${errorAlign === 'left' ? 'left: 15px;' : 'right: 0;'}
    `}
`;

const RightIconWrapper = styled('div')<{ withSeparator?: boolean }>`
  position: relative;
  padding-left: 12px;

  ${({ theme, withSeparator = true }) =>
    withSeparator &&
    `&:before {
    content: '';
    position: absolute;
    transform: translate3d(0, -50%, 0);
    top: 50%;
    left: 0;
    width: 2px;
    height: calc(100% + 4px);
    background-color: ${theme.colors.gray.c2};
  }`}
`;

const ClearIcon = styled(Icon)`
  &:hover {
    ${({ theme }) => colorIcon(theme.colors.blue.primaryA)};
  }
`;
