import type { ReactNode } from 'react';

import { Flexbox } from '../flexbox/flexbox';

import { Radio } from './radio';

export interface RadioOptions<OValue = string> {
  label?: string | ReactNode;
  value: OValue;
}

interface RadioGroupProps<Value> {
  value: Value | null;
  onSelect: (value: Value) => void;
  options: RadioOptions<Value>[];
  disabled?: boolean;
}

export const RadioGroup = <Value extends string>({
  value,
  onSelect,
  options,
  disabled,
}: RadioGroupProps<Value>) => {
  return (
    <Flexbox name="radio-group-container" justify="space-between">
      {options.map(option => (
        <Radio
          inputHidden
          key={option.value}
          name={option.value}
          checked={option.value === value}
          label={option.label}
          onChange={() => onSelect(option.value)}
          disabled={disabled}
        />
      ))}
    </Flexbox>
  );
};
