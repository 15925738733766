import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchTeamTags } from '@/api/v4/team-tags.api';
import { QueryKey } from '@/config/query-client';

import { teamIdState } from '../../team.state';

export const useTeamTags = () => {
  const teamId = useRecoilValue(teamIdState);
  return useQuery(
    [QueryKey.TeamTags],
    () => {
      if (!teamId) return null;

      return fetchTeamTags(teamId);
    },
    {
      enabled: !!teamId,
    },
  );
};
