import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import askXEN from './locales/en/askx.en.json';
import authEN from './locales/en/auth.en.json';
import companiesEN from './locales/en/companies.en.json';
import contactsEN from './locales/en/contacts.json';
import datesEN from './locales/en/dates.en.json';
import defaultEN from './locales/en/default.en.json';
import exploreEN from './locales/en/explore.en.json';
import homepageEN from './locales/en/homepage.en.json';
import projectsEN from './locales/en/projects.en.json';
import settingsEN from './locales/en/settings.en.json';
import tagsEN from './locales/en/tags.json';
import teamsEN from './locales/en/teams.en.json';

export const translations = {
  en: {
    askX: askXEN,
    auth: authEN,
    companies: companiesEN,
    contacts: contactsEN,
    dates: datesEN,
    default: defaultEN,
    explore: exploreEN,
    homepage: homepageEN,
    projects: projectsEN,
    settings: settingsEN,
    teams: teamsEN,
    tags: tagsEN,
  },
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n.use(initReactI18next).init({
  resources: translations,
  ns: [
    'askX',
    'auth',
    'companies',
    'contacts',
    'default',
    'dates',
    'explore',
    'homepage',
    'projects',
    'settings',
    'teams',
    'tags',
  ],
  lng: 'en',
  nsSeparator: ':',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
