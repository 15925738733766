import { z } from 'zod';

import i18n from '@/lib/i18n/i18n';

const { t } = i18n;

export const commentValidationSchema = z.object({
  comment: z.string().nonempty(t`default:required`),
  projectListingId: z.string().nullable().optional(),
  mentionedUsers: z.array(z.number()).optional(),
});
