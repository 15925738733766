import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import type { CompanyOfficeLocation } from '@/api/v4/organization.api';
import { deleteCompanyOfficeLocation } from '@/api/v4/organization.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

import { companyIdState } from '../../company.state';
import { showOfficeLocationsState } from '../office-locations.state';

export const useDeleteOfficeLocation = () => {
  const companyId = useRecoilValue(companyIdState);
  const { t } = useTranslation('default');
  const queryClient = useQueryClient();

  const setOfficeLocationsVisible = useSetRecoilState(showOfficeLocationsState);

  const deleteOfficeLocationMutation = useMutation(
    async (officeLocationId: string) => {
      if (!companyId) {
        return;
      }
      return deleteCompanyOfficeLocation(companyId, officeLocationId);
    },
    {
      onError: (error: Error) => {
        console.error(error.message);
        notify({ message: t`default:unknownError` });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          QueryKey.CompanyOfficeLocations,
          companyId,
        ]);

        const officeLocations = queryClient.getQueryData<
          CompanyOfficeLocation[]
        >([QueryKey.CompanyOfficeLocations, companyId]);

        const isLastLocation = officeLocations?.length === 1;

        if (isLastLocation) {
          setOfficeLocationsVisible(false);
        }
      },
    },
  );

  const deleteOfficeLocation = async (officeLocationId: string) =>
    await deleteOfficeLocationMutation.mutateAsync(officeLocationId);

  return {
    deleteOfficeLocation,
    isLoading: deleteOfficeLocationMutation.isLoading,
    isError: deleteOfficeLocationMutation.isError,
    isSuccess: deleteOfficeLocationMutation.isSuccess,
  };
};
