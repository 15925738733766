import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { revokeUserInvitation } from '@/api/v4/user.api';
import { notify } from '@/ui/snackbar/notify';

import { useInvitedUsers } from './use-invited-users';

export const useRevokeInvitation = () => {
  const { t } = useTranslation(['settings', 'default']);

  const { refreshUsers } = useInvitedUsers();

  const revokeInvitationMutation = useMutation(
    async (userId: number) => {
      await revokeUserInvitation(userId);
    },
    {
      onError: (error: Error) => {
        console.error(error.message);
        notify({ message: t`default:unknownError` });
      },
      onSuccess: async () => {
        await refreshUsers();
        notify({ message: t`invitationRevoked` });
      },
    },
  );

  const revokeInvitation = async (userId: number) =>
    await revokeInvitationMutation.mutateAsync(userId);

  return {
    revokeInvitation,
    isLoading: revokeInvitationMutation.isLoading,
    isError: revokeInvitationMutation.isError,
  };
};
