import { Modal } from '@/ui/modal/modal';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { modalState } from '@/state/modal.state';
import { LoadCompaniesToProject } from '../projects/load-companies-to-project/load-companies-to-project';

export const LoadCompaniesToProjectModal = () => {
  const { t } = useTranslation('projects');
  const [modal, setModalState] = useRecoilState(modalState);

  if (modal?.state !== 'loadCompaniesToProject') return null;

  const close = () => {
    setModalState(null);
  };

  return (
    <Modal
      data-testid="project-listing-status-deal-modal"
      header={t`loadCompanies`}
      onClose={close}
      variant="info"
    >
      <LoadCompaniesToProject projectId={modal.projectId} />
    </Modal>
  );
};
