import { forwardRef, useEffect, useRef, useState } from 'react';
import { styled } from 'goober';

import { isEllipsis } from '@/helpers/other';
import { colors } from '@/theme/colors';
import type { InvitedUserOption, TeamBasicOption, UserOption } from '@/types';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { SquareLogo } from '@/ui/logo/logo';
import { Tooltip } from '@/ui/tooltip/tooltip';

import {
  OptionsListItem,
  OptionsListItemLabel,
} from '../selected-options-list';

interface SelectedBasicTeamOptionProps {
  isListExpanded?: boolean;
  onRemove?: (item: UserOption | TeamBasicOption | InvitedUserOption) => void;
  isDisabled?: boolean;
  option: TeamBasicOption;
  index: number;
}

export const OPTION_ITEM_HEIGHT = 60;

export function SelectedBasicTeamOption({
  onRemove,
  isDisabled,
  option,
  index,
}: SelectedBasicTeamOptionProps) {
  const [isReady, setIsReady] = useState(false);
  const refItemLabel = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    refItemLabel.current && setIsReady(true);
  }, [refItemLabel]);

  return (
    <OptionsListUsersTeamsItem
      key={option.value}
      name="options-list-item"
      justify="space-between"
      alignItems="center"
    >
      <Tooltip
        placement="left"
        content={option.label}
        visible={isReady && isEllipsis(refItemLabel.current[index])}
      >
        <Flexbox name="option-content" gap="8px" alignItems="center">
          <SquareLogo name={option.label} />
          <ItemUsersTeamsLabel
            ref={element => {
              refItemLabel.current[index] = element;
            }}
          >
            {option.label}
          </ItemUsersTeamsLabel>
        </Flexbox>
      </Tooltip>
      <Actions name="actions" gap="20px" alignItems="center">
        {!isDisabled && onRemove && (
          <Icon
            icon="Close"
            width="10px"
            height="10px"
            color={colors.gray.c13}
            onClick={() => onRemove?.(option)}
          />
        )}
      </Actions>
    </OptionsListUsersTeamsItem>
  );
}

const Actions = styled(Flexbox)`
  font-size: 14px;
`;

const OptionsListUsersTeamsItem = styled(OptionsListItem)`
  min-height: ${OPTION_ITEM_HEIGHT}px;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.c2};

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue.c8};
  }
`;

const ItemUsersTeamsLabel = styled(OptionsListItemLabel, forwardRef)`
  ${({ theme }) => theme.typography.acrossPlatform.userName}

  [data-name='share-modal-container'] & {
    max-width: 560px;
  }

  &::after {
    content: attr(data-team);
    display: block;
    clear: left;
    margin-top: 2px;
    ${({ theme }) => theme.typography.widget.smallText}
    ${({ theme }) => theme.mixins.ellipsis}
  }
`;
