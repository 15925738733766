import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import { ActionButton } from '@/components/actions-button/actions-button';
import { modalState } from '@/state/modal.state';
import { Button } from '@/ui/button/button';
import type { DropdownOption } from '@/ui/dropdown/dropdown';
import { WithDropdown } from '@/ui/dropdown/dropdown';
import { Inline } from '@/ui/line/line';
import { MenuButton } from '@/ui/menu-button/menu-button';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { useActionPanel } from '../../action-panel/use-action-panel';
import { AskXButton } from '../../ask-x/ask-x-button';
import { useTeamActionsInfo } from '../../teams/overview/action-panel/use-team-actions-info.query';
import { useTeam } from '../../teams/overview/use-team.query';
import { TopbarWrapper } from '../topbar';

export const TeamOverviewHeader = () => {
  const { t } = useTranslation('teams');
  const { data: teamActionsInfo } = useTeamActionsInfo();
  const { hasRequiredPermission } = useUserPermissions();

  const setModalState = useSetRecoilState(modalState);
  const handleAddMember = () => setModalState({ state: 'addTeamMember' });
  const { toggleActionPanel } = useActionPanel();

  const { data: team } = useTeam();

  if (!team) return null;

  const menu: DropdownOption[] = [
    {
      label: t`deleteTeam`,
      isVisible: hasRequiredPermission(PERMISSION.MANAGE_TEAMS),
      onClick: () =>
        setModalState({
          state: 'deleteTeam',
          teamId: team.id,
          teamName: team.name,
        }),
      icon: 'Bin',
    },
  ];

  const onToggleActionPanelClick = () => {
    toggleActionPanel({ context: 'team', title: team.name });
  };

  return (
    <TopbarWrapper
      name="team-overview"
      justify="flex-end"
      bgColor="transparent"
    >
      <Inline alignItems="center" gap="20px">
        <AskXButton />

        {hasRequiredPermission(PERMISSION.MANAGE_TEAMS) && (
          <Button
            onClick={handleAddMember}
            startIcon="Plus"
            variant="secondary"
          >
            {t`addMember`}
          </Button>
        )}
        {hasRequiredPermission(PERMISSION.VIEW_TEAM_NOTES) && (
          <ActionButton
            totalValue={teamActionsInfo?.total || 0}
            onClick={onToggleActionPanelClick}
          />
        )}
        <WithDropdown items={menu} options={{ placement: 'bottom-end' }}>
          <Button variant="text">
            <MenuButton />
          </Button>
        </WithDropdown>
      </Inline>
    </TopbarWrapper>
  );
};
