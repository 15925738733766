import type { ProjectFiltersTeamOptionAPI } from '@/api/v4/project-filters.api';
import type { User } from '@/api/v4/user.api';
import type { TeamBasicOption, UserOption } from '@/types';

export const mapUserToOption = (user: User): UserOption => ({
  label:
    user.firstName && user.lastName
      ? user.firstName + ' ' + user.lastName
      : user.email,
  value: user.id,
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  team: user.team,
  role: user.role,
  status: user.status,
});

export const mapTeamToOption = (
  team: ProjectFiltersTeamOptionAPI,
): TeamBasicOption => ({
  label: team.name,
  value: team.id,
  type: 'team',
});
