import type { ListingStage } from '@/api/v4/project-listings.api';

export const getListingStageById = (
  stageId: string,
  listingStages: ListingStage[],
) => {
  const stage = listingStages.find(stage => stage.id === stageId);

  if (!stage) {
    throw new Error(`Unknown stage: ${stageId}`);
  }

  return stage;
};
