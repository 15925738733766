import { forwardRef, useLayoutEffect, useRef, useState } from 'react';
import { styled } from 'goober';

import { Loader } from '@/components/loader/loader';
import { isEllipsis } from '@/helpers/other';
import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Tooltip } from '@/ui/tooltip/tooltip';

import type { File } from './file.interface';
import { FileIcon } from './file-icon';

export function UploadingFileCard({ file }: { file: File }) {
  const ref = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useLayoutEffect(() => {
    setShowTooltip(isEllipsis(ref.current));
  }, [ref]);

  return (
    <Flexbox name="file-scanned-container" justify="space-between" grow={1}>
      <Tooltip
        placement="left"
        content={file.name}
        visible={showTooltip}
        maxWidth="none"
      >
        <Flexbox name="file-scanned-name" alignItems="center" gap="4px">
          <FileIcon filename={file.name} color={colors.gray.c8} />
          <FileName ref={ref}>{file.name}</FileName>
        </Flexbox>
      </Tooltip>
      <Loader size="small" />
    </Flexbox>
  );
}

const FileName = styled('div', forwardRef)`
  ${({ theme }) => theme.typography.actionsPanel.files.fileName}
  ${({ theme }) => theme.mixins.ellipsis}
  color: ${({ theme }) => theme.colors.gray.c8};
  max-width: 380px;
  cursor: default;
`;
