import { useRef } from 'react';

import { isEllipsis, isOption } from '@/helpers/other';
import type { LogoProps } from '@/ui/logo/logo';
import { SquareLogo } from '@/ui/logo/logo';

import { OptionLabel } from '../../async-select.styles';

import { CustomOptionWrapper } from './custom-option-wrapper';
import type { AsyncSelectOptionProps } from './option';

interface OptionWithLogoProps extends AsyncSelectOptionProps {
  logoProps?: Partial<LogoProps>;
  dataTestId?: string;
}

export const OptionWithLogo = ({
  logoProps,
  dataTestId,
  ...props
}: OptionWithLogoProps) => {
  const ref = useRef<HTMLDivElement>(null);

  if (!isOption(props.data)) {
    return <></>;
  }

  return (
    <CustomOptionWrapper
      tooltipContent={props.data.label}
      isTooltipVisible={isEllipsis(ref.current)}
      dataTestId={dataTestId}
      {...props}
    >
      {'logo' in props.data && (
        <SquareLogo
          name={props.data.label ?? ''}
          logoUrl={
            typeof props.data.logo === 'string' ? props.data.logo : undefined
          }
          {...logoProps}
        />
      )}
      <OptionLabel ref={ref}>{props.data.label}</OptionLabel>
    </CustomOptionWrapper>
  );
};
