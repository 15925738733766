import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import { styled } from 'goober';

import { colors } from '@/theme/colors';

import { colorIcon } from '../icons/functions';
import { Icon } from '../icons/icon';

import type { TagVariant } from './variants';

type TagSize = 'xs' | 's' | 'm' | 'l';

export interface GeneralTagProps {
  variant: TagVariant;
  children?: ReactNode;
  onRemove?: () => void;
  onClick?: () => void;
  size?: TagSize;
  width?: string;
  smallScreenMaxWidth?: string;
  maxWidth?: string;
  className?: string;
}

export const GeneralTag = forwardRef<HTMLDivElement, GeneralTagProps>(
  function GeneralTag(
    {
      variant,
      children,
      onClick,
      size = 's',
      width,
      smallScreenMaxWidth,
      maxWidth,
      onRemove,
      className,
    },
    ref,
  ) {
    return (
      <TagWrapper
        data-name="general-tag"
        data-testid="general-tag"
        variant={variant}
        size={size}
        width={width}
        maxWidth={maxWidth}
        smallScreenMaxWidth={smallScreenMaxWidth}
        clickable={!!onClick}
        onClick={e => {
          e.stopPropagation();
          onClick?.();
        }}
        ref={ref}
        className={className}
      >
        {children}
        {!!onRemove && (
          <Icon
            icon="Close"
            color={colors.gray.c11}
            onClick={e => {
              e?.stopPropagation();
              onRemove();
            }}
            width={iconSize[size]}
            height={iconSize[size]}
          />
        )}
      </TagWrapper>
    );
  },
);

const TagWrapper = styled('div', forwardRef)<{
  variant: TagVariant;
  size: TagSize;
  width?: string;
  maxWidth?: string;
  clickable: boolean;
  smallScreenMaxWidth?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 4px;

  ${({ size }) => tagMixins[size]};

  ${({ width }) => width && `width: ${width};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ smallScreenMaxWidth }) =>
    smallScreenMaxWidth &&
    `
  @media (max-width: 1600px) {
  max-width: ${smallScreenMaxWidth};
  
  }
  
  `}

  background-color: ${({ theme, variant }) =>
    theme.palettes.component.tag[variant].main};
  color: ${({ theme, variant }) => theme.palettes.component.tag[variant].text};

  ${({ theme, size }) => theme.typography.atoms.tags.general[size]}

  ${({ theme, variant }) =>
    colorIcon(theme.palettes.component.tag[variant].text)}

  ${({ clickable }) => clickable && `cursor: pointer;`}

  &:hover {
    color: ${({ theme, variant }) =>
      theme.palettes.component.tag[variant].text};
  }
`;

const iconSize = {
  xs: '10px',
  s: '10px',
  m: '12px',
  l: '12px',
};

const tagMixins = {
  xs: `
    padding: 2px 8px;
  `,
  s: `
    padding: 0 10px;
    height: 28px;
  `,

  m: `
    padding: 0 12px;
    height: 39px;
  `,
  l: `
    padding: 0 12px;
    height: 44px;
  `,
};
