import { Loader } from '@/components/loader/loader';
import { UploadFileInput } from '@/features/action-panel/files/upload-file-input';
import { FilesDragDrop } from '@/features/files-drag-drop/files-drag-drop';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { styled } from 'goober';
import { useDrag } from 'react-dnd-cjs';
import { NativeTypes } from 'react-dnd-html5-backend-cjs';
import SvgFiles from '@/assets/widgets/empty-state-files.svg';

type LoadCompaniesToProjectDragDropProps = {
  isLoading: boolean;
  onDrop: (files: globalThis.File[]) => void;
};

export const LoadCompaniesToProjectDragDrop = ({
  onDrop,
  isLoading,
}: LoadCompaniesToProjectDragDropProps) => {
  const [{ draggingItems }] = useDrag({
    canDrag: true,
    item: {
      type: NativeTypes.FILE,
    },
    collect: monitor => ({
      draggingItems: monitor.getItem(),
    }),
  });

  if (isLoading) {
    return <Loader />;
  }
  return Boolean(draggingItems) ? (
    <Flexbox
      name="action-panel-button-container"
      justify="right"
      alignItems="center"
      height="56px"
    >
      <FilesDragDrop onDrop={onDrop} />
    </Flexbox>
  ) : (
    <Flexbox
      name="action-panel-button-container"
      justify="right"
      alignItems="center"
    >
      <Container
        name="action-panel-empty"
        grow="1"
        gap="29px"
        direction="column"
        alignItems="center"
        justify="center"
      >
        <img src={SvgFiles} />
        <UploadFileInput
          onSubmit={onDrop}
          variant="primary"
          acceptedFiles={['xlsx', 'xls']}
        />
      </Container>
    </Flexbox>
  );
};

const Container = styled(Flexbox)`
  background-color: ${({ theme }) => theme.colors.basics.white};
`;
