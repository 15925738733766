import { useOutsideWindowMeasure } from '@/hooks/use-outside-window-measure';

import type { DropdownOption } from './dropdown';
import { DropdownSubList } from './dropdown.styles';
import { DropdownListItem } from './dropdown-list-item';

interface DropdownItemSubListProps {
  items: DropdownOption[];
  onClick: (callback?: () => void) => void;
}

export const DropdownItemSubList = ({
  items,
  onClick,
}: DropdownItemSubListProps) => {
  const { isElementOutsideWindow, elementRef } = useOutsideWindowMeasure();
  return (
    <DropdownSubList
      ref={elementRef}
      float={isElementOutsideWindow ? 'right' : 'left'}
    >
      {items.map((subItem, index) => (
        <DropdownListItem
          key={`${index}`}
          item={subItem}
          onClick={() => onClick(subItem.onClick)}
        />
      ))}
    </DropdownSubList>
  );
};
