export const getTeamNameInitials = (name: string) => {
  if (!name) return name;
  const words = name.split(' ');
  if (words.length === 1) {
    return words[0].substring(0, 2).toUpperCase();
  }
  return (
    words[0].substring(0, 1).toUpperCase() +
    words[1].substring(0, 1).toUpperCase()
  );
};
