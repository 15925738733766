import { useRef } from 'react';
import type { SingleValueProps } from 'react-select';
import { components } from 'react-select';

import { isEllipsis } from '@/helpers/other';
import { Tooltip } from '@/ui/tooltip/tooltip';

import { SingleValueContainer } from '../../async-select.styles';

export function AsyncSelectSingleValue({
  children,
  ...props
}: SingleValueProps<unknown, boolean>) {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <components.SingleValue {...props}>
      <Tooltip
        placement="bottom"
        content={children}
        visible={isEllipsis(ref.current)}
      >
        <SingleValueContainer ref={ref}>{children}</SingleValueContainer>
      </Tooltip>
    </components.SingleValue>
  );
}
