import { useTranslation } from 'react-i18next';

import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { MinimizedHeader } from '@/ui/typography/across-platform';

import { AskXButton } from '../../ask-x/ask-x-button';
import { TopbarWrapper } from '../topbar';

export const TeamsHeader = () => {
  const navigateTo = useNavigateTo();
  const { t } = useTranslation('teams');

  return (
    <TopbarWrapper
      name="teams-header-wrapper"
      justify="space-between"
      alignItems="center"
      gap="16px"
      bgColor="white"
      shadow
    >
      <MinimizedHeader>{t`allTeams`}</MinimizedHeader>

      <Flexbox name="teams-header-actions" gap="28px">
        <AskXButton />

        <Button
          variant="secondary"
          startIcon="Plus"
          onClick={() => navigateTo.createTeam()}
        >{t`createTeam`}</Button>
      </Flexbox>
    </TopbarWrapper>
  );
};
