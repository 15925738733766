import { useTranslation } from 'react-i18next';

import { getMaxFileSize } from '@/helpers/files';
import { notify } from '@/ui/snackbar/notify';

import { AVAILABLE_EXTENSIONS, MAX_FILE_SIZE } from './constants';

export const useValidateUploadedFiles = () => {
  const { t } = useTranslation('default');

  const validateUploadedFiles = (fileItems: File[] | FileList) => {
    const files = Array.from(fileItems);

    const exceedAllowedFileSize = files.some(
      file => file.size * 1.1 > getMaxFileSize(),
    );

    if (exceedAllowedFileSize) {
      notify({
        message: t('actionPanel.files.overMaximumSize', {
          max: MAX_FILE_SIZE.replace('m', 'MB'),
        }),
      });

      return;
    }

    const containNotAllowedFileExtension = files
      .map(getExtensionFromFileName)
      .some(extension => {
        return extension && !AVAILABLE_EXTENSIONS.includes(extension);
      });

    if (containNotAllowedFileExtension) {
      notify({
        message: t('actionPanel.files.notAllowedExtension', {
          extensions: AVAILABLE_EXTENSIONS.join(', '),
        }),
      });

      return;
    }

    return files;
  };

  return {
    validateUploadedFiles,
  };
};

export const getExtensionFromFileName = (file: File) =>
  file.name.toLowerCase().split('.').pop();
