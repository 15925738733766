import { generatePath } from 'react-router-dom';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mapValues } from 'lodash-es';

import { axiosInstance } from '../api';

const V3_PREFIX = '/v3';

const PROJECT_API = {
  projectsCompanies: '/projects/:projectId/companies',
  useCaseFits: '/project_listing_use_case_fit',
  useCaseFit: '/project_listing_use_case_fit/:projectListingId',
} as const;

const ORGANIZATIONS_API = {
  company: '/organizations/:orgId',
  createNewCompany: '/organizations',
};

const V3_API = mapValues(
  {
    ...PROJECT_API,
    ...ORGANIZATIONS_API,
  },
  route => V3_PREFIX + route,
);

export const v3paths = mapValues(V3_API, route => {
  return (param?: object) => generatePath(route, param);
});

interface IV3Response<T> {
  data: T;
}

type ApiResponse<T = unknown> = Promise<AxiosResponse<IV3Response<T>>>;

const get = <T>(url: string, config?: AxiosRequestConfig): ApiResponse<T> =>
  axiosInstance.get<IV3Response<T>>(url, config);

const remove = (url: string, config?: AxiosRequestConfig): ApiResponse =>
  axiosInstance.delete(url, config);

const post = <T, Data = unknown>(
  url: string,
  data?: Data,
  config?: AxiosRequestConfig,
): ApiResponse<T> => axiosInstance.post<IV3Response<T>>(url, data, config);

const put = <T>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig,
): ApiResponse<T> => axiosInstance.put<IV3Response<T>>(url, data, config);

const patch = <T>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig,
): ApiResponse<T> => axiosInstance.patch<IV3Response<T>>(url, data, config);

export const v3api = {
  get,
  remove,
  post,
  put,
  patch,
};
