import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { MeetingEditPayload } from '@/api/v4/meetings.api';
import { editMeeting as editMeetingAPI } from '@/api/v4/meetings.api';
import { notify } from '@/ui/snackbar/notify';
import { QueryKey } from '@/config/query-client';

export const useEditMeeting = () => {
  const { t } = useTranslation('default');
  const queryClient = useQueryClient();

  const editMeetingMutation = useMutation(editMeetingAPI, {
    onSuccess: async meeting => {
      if (meeting.projectId) {
        await queryClient.invalidateQueries([
          QueryKey.Project,
          meeting.projectId,
        ]);
      }
      if (meeting.organizationId) {
        await queryClient.invalidateQueries([
          QueryKey.Company,
          meeting.organizationId,
        ]);
      }
      if (meeting.teamId) {
        await queryClient.invalidateQueries([QueryKey.Team, meeting.teamId]);
      }

      await Promise.all([
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);
    },
    onError: (error: Error) => {
      notify({
        message: t`unknownError`,
      });
      console.error(error.message);
    },
  });

  const editMeeting = (payload: MeetingEditPayload) =>
    editMeetingMutation.mutateAsync(payload);

  return {
    editMeeting,
    isError: editMeetingMutation.isError,
  };
};
