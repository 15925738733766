import type { TogglePinnedItemPayload } from '@/api/v4/pinned-items.api';
import { queryClient, QueryKey } from '@/config/query-client';
import type { PinnedItemsContext } from '@/features/home/recent-items/pinned-items/use-toggle-pinned-item.mutation';
import type { CompanyData } from '@/api/v4/organization.api';

export const useOptimisticPinCompanyUpdate = () => {
  const updateCompany = (newCompanyData: CompanyData) => {
    queryClient.setQueryData(
      [QueryKey.Company, newCompanyData.id],
      newCompanyData,
    );
  };

  const handleOptimisticChange = async (data: TogglePinnedItemPayload) => {
    const itemId = data.id;

    await queryClient.cancelQueries({ queryKey: [QueryKey.Company, itemId] });

    const prevCompanyData = queryClient.getQueryData<CompanyData>([
      QueryKey.Company,
      itemId,
    ]) as CompanyData;

    const newCompanyData = {
      ...prevCompanyData,
      isPinned: !prevCompanyData.isPinned,
    };
    updateCompany(newCompanyData);

    return {
      prevCompanyData,
    };
  };

  const rollbackToPreviousData = (
    prevData: PinnedItemsContext,
    itemId?: string,
  ) => {
    prevData.prevCompanyData &&
      itemId &&
      queryClient.setQueryData(
        [QueryKey.Company, itemId],
        prevData.prevCompanyData,
      );
  };

  return {
    handleOptimisticChange,
    rollbackToPreviousData,
  };
};
