import { atom } from 'recoil';

import type { CompanySortOptions } from '@/api/v4/organization.api';
import type { CapitalRaisedOption, INumberOption, IOption } from '@/types';

import type { ISort } from '../../ui/table/table.types';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const companiesSortState = atom<ISort<CompanySortOptions | undefined>>({
  key: 'companiesSortState',
  default: undefined,
});

/* filters */

export interface CompanyFilters {
  tags: IOption[] | null;
  listingStatuses: IOption[] | null;
  yearFounded: {
    start: IOption | null;
    end: IOption | null;
  };
  fundingStages: IOption[] | null;
  hqCities: IOption[] | null;
  verified: boolean | null;
  capitalRaised: CapitalRaisedOption[] | null;
  employeesRange: IOption[] | null;
  ranking: INumberOption[] | null;
  createdBy: INumberOption[] | null;
  search: string | null;
}

interface CompanyFiltersOptions {
  tags: IOption[];
  listingStatuses: IOption[];
  yearFounded: IOption[];
  fundingStages: IOption[];
  hqCities: IOption[];
  verified: boolean | null;
  capitalRaised: CapitalRaisedOption[];
  employeesRange: IOption[];
  ranking: INumberOption[];
  createdBy: INumberOption[];
}

export const defaultAppliedCompaniesFilters: CompanyFilters = {
  tags: null,
  listingStatuses: null,
  yearFounded: {
    start: null,
    end: null,
  },
  fundingStages: null,
  hqCities: null,
  verified: null,
  capitalRaised: null,
  employeesRange: null,
  ranking: null,
  createdBy: null,
  search: null,
};

export const defaultCompanyFiltersOptions: CompanyFiltersOptions = {
  tags: [],
  listingStatuses: [],
  yearFounded: [],
  fundingStages: [],
  hqCities: [],
  verified: null,
  capitalRaised: [],
  employeesRange: [],
  ranking: [],
  createdBy: [],
};

export function isCompanyFilterKeyContainingPredefinedOptions(
  key: string,
): key is keyof CompanyFiltersOptions {
  return key in defaultCompanyFiltersOptions;
}

export const companiesFilterOptionsState = atom<CompanyFiltersOptions>({
  key: 'companiesFilterOptionsState',
  default: defaultCompanyFiltersOptions,
  effects: [persistAtom],
});

export const appliedCompaniesFiltersState = atom<CompanyFilters>({
  key: 'appliedCompaniesFilters',
  default: defaultAppliedCompaniesFilters,
});

export const selectedCompaniesFiltersState = atom<CompanyFilters>({
  key: 'selectedCompaniesFilters',
  default: defaultAppliedCompaniesFilters,
});

export const selectedCompaniesState = atom<
  { crunchbaseId?: string; organizationId?: string }[]
>({
  key: 'selectedCompaniesState',
  default: [],
});
