import { useEffect } from 'react';

export const useKeyPressListener = ({
  onKeyPress,
  key,
}: {
  onKeyPress: (event?: KeyboardEvent) => void;
  key: KeyboardEvent['key'];
}) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === key) onKeyPress(event);
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [onKeyPress, key]);
};
