import { getFileIcon } from '@/helpers/files';
import { Icon } from '@/ui/icons/icon';

interface FileIconProps {
  filename: string;
  color?: string;
}

export const FileIcon = ({ filename, color }: FileIconProps) => {
  const icon = getFileIcon(filename);

  return icon ? (
    <Icon icon={icon} color={color} width="18" height="18" />
  ) : null;
};
