import { uploadCompaniesToProject } from '@/api/v4/projects.api';
import { QueryKey } from '@/config/query-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useLoadCompaniesToProject = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ projectId, file }: { projectId: string; file: globalThis.File }) =>
      await uploadCompaniesToProject(projectId, file),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries([QueryKey.ProjectCompanies, projectId]),
          queryClient.invalidateQueries([QueryKey.ProjectListings, projectId]),
        ]);
      },
    },
  );
};
