import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useSetRecoilState } from 'recoil';

import { modalState } from '@/state/modal.state';
import { Modal } from '@/ui/modal/modal';

import { useKeyPressListener } from '../../hooks/use-key-press-listener';

export const ConfirmDeleteModal = ({
  title,
  description,
  onConfirm,
  confirmLabel,
  isLoading,
  className,
}: {
  title?: string;
  description?: string;
  onConfirm: () => void;
  confirmLabel?: string;
  isLoading?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation('default');
  const setModal = useSetRecoilState(modalState);

  const close = () => {
    setModal(null);
  };

  const confirm = async () => {
    onConfirm();
    close();
  };

  useKeyPressListener({ onKeyPress: confirm, key: 'Enter' });

  return (
    <Modal
      className={className}
      header={title ?? t`areYouSure`}
      onClose={close}
      mainAction={{
        label: confirmLabel ?? t`delete`,
        onClick: confirm,
      }}
      secondaryAction={{
        label: t`cancel`,
        onClick: close,
      }}
      variant="danger"
      loading={isLoading}
    >
      <Container>
        <Description>{description ?? t`cannotUndo`}</Description>
      </Container>
    </Modal>
  );
};

const Container = styled('div')`
  height: 100px;
`;

const Description = styled('p')`
  ${({ theme }) => theme.typography.acrossPlatform.small};
  color: ${({ theme }) => theme.colors.gray.c12};
`;
