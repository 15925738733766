import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { savvyLogoAuthPage } from '@/assets/images';
import { Z_INDEX_AUTH_PAGE_WHITE_BOX } from '@/theme/z-index';
import { Flexbox } from '@/ui/flexbox/flexbox';

import { InlineBoxes } from './inline-boxes';
import { LocationBox } from './location-box';
import { Navigate, Outlet } from 'react-router-dom';

export const AuthPageLayout = ({
  isUserAuthorized,
}: {
  isUserAuthorized: boolean;
}) => {
  const { t } = useTranslation('auth');

  if (isUserAuthorized) {
    return <Navigate to="/home" />;
  }

  return (
    <Wrapper
      name="auth-layout-container"
      alignItems="center"
      padding="98px 100px"
    >
      <Main>
        <WhiteBox>
          <Outlet />
        </WhiteBox>
        <Flexbox name="logo-locations-wrapper" direction="column" gap="68px">
          <LogoWrapper>
            <img
              src={savvyLogoAuthPage}
              width="100%"
              height="100%"
              alt="Savvy logo"
            />
          </LogoWrapper>
          <LocationBox
            name={t`layout.topLocation.name`}
            address={t`layout.topLocation.address`}
            icon="AuthPageTopLocationIcon"
            margin="0 0 16px 154px"
          />
          <InlineBoxes />
        </Flexbox>
      </Main>
    </Wrapper>
  );
};

const Wrapper = styled(Flexbox)`
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  background-color: ${({ theme }) => theme.colors.blue.primaryB};
`;

const Main = styled('main')`
  display: flex;
`;

const WhiteBox = styled('div')`
  z-index: ${Z_INDEX_AUTH_PAGE_WHITE_BOX};
  width: 540px;
  min-height: 644px;
  background-color: ${({ theme }) => theme.colors.basics.white};
  border-radius: 20px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoWrapper = styled('div')`
  width: 483.98px;
  height: 181.66px;
  margin-left: -10px;
`;
