import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { brandPalette } from '@/theme/colors';
import { shadow } from '@/theme/shadow';
import { Flexbox } from '@/ui/flexbox/flexbox';

import { LocationBox } from './location-box';

export const InlineBoxes = () => {
  const { t } = useTranslation('auth');

  return (
    <Flexbox name="inline-boxes-wrapper">
      <PinkBox
        marginLeft="-36px"
        backgroundColor={brandPalette.accent.pink.c2}
        boxShadow={shadow.authPage.pinkBoxLeft}
      />
      <PinkBox
        marginLeft="-116px"
        backgroundColor={brandPalette.accent.pink.c4}
        boxShadow={shadow.authPage.pinkBoxMiddle}
      />
      <PinkBox
        marginLeft="-145px"
        backgroundColor={brandPalette.accent.pink.c5}
        boxShadow={shadow.authPage.pinkBoxRight}
      />
      <LocationBox
        name={t`layout.bottomLocation.name`}
        address={t`layout.bottomLocation.address`}
        icon="AuthPageBottomLocationIcon"
        margin="0 0 0 -177px"
      />
    </Flexbox>
  );
};

const PinkBox = styled('div')<{
  marginLeft: string;
  backgroundColor: string;
  boxShadow: string;
}>`
  width: 290.6px;
  height: 123.61px;
  border-radius: 43.5817px;
  margin-left: ${({ marginLeft }) => marginLeft};
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: ${({ boxShadow }) => boxShadow};
`;
