import { useTranslation } from 'react-i18next';

import type { StreamCopyPayload } from '@/api';
import { api } from '@/api';
import { notify } from '@/ui/snackbar/notify';

export const useDuplicateProject = () => {
  const { t } = useTranslation('default');

  return async (payload: StreamCopyPayload) => {
    try {
      const { data } = await api.copyStream(payload);

      return data.data;
    } catch (err) {
      notify({ message: t`unknownError` });
    }
  };
};
