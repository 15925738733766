import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { CompanyProjectsListings } from '@/api/v4/organization-projects-listings.api';
import { fetchCompanyProjectListings } from '@/api/v4/organization-projects-listings.api';
import { QueryKey } from '@/config/query-client';

export const useCompanyProjectsListings = (
  organizationId: string | null,
  options?: UseQueryOptions<
    CompanyProjectsListings,
    unknown,
    CompanyProjectsListings,
    (string | null)[]
  >,
) => {
  return useQuery(
    [QueryKey.CompanyProjectsListings, organizationId],
    () => {
      if (!organizationId) {
        return {
          otherProjectsCount: 0,
          listings: [],
        };
      }

      return fetchCompanyProjectListings({ organizationId });
    },
    { enabled: !!organizationId, ...options },
  );
};
