import { fetchOrganizationOptionsCount } from '@/api/v4/organization.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import type { OrganizationOption as APIOrganizationOption } from '@/api/v4/organization.api';
import type { CompanyOption } from '@/types';

export const useCountCompanyOptions = (projectId?: string) => {
  const { t } = useTranslation('auth');

  const getCompanyOptions = async () =>
    await fetchOrganizationOptionsCount({
      ...(projectId && { projectId }),
    });

  return useQuery(
    [QueryKey.CompanyOptionsCount, projectId],
    getCompanyOptions,
    {
      onError: () =>
        notify({
          message: t`unknownError`,
        }),
      staleTime: 1000 * 10 * 5,
    },
  );
};

export const mapOrganizationOptions = (
  organizations: APIOrganizationOption[],
): CompanyOption[] =>
  organizations.map(organization => ({
    label: organization.name,
    value: organization.id,
    logo: organization.logoUrl,
    domain: organization.domain,
  }));
