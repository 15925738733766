export const swapDynamicLoadErrorPage = async () => {
  if (document) {
    const rootElement = document.getElementById('root');
    if (rootElement) {
      try {
        const pageResponse = await fetch(
          '/src/dynamic-load-error-page/dynamic-load-error-page.html',
        );
        const htmlContent = await pageResponse.text();
        rootElement.innerHTML = htmlContent;
      } catch (error) {
        console.error('Error loading the HTML file:', error);
      }
    }
  }
};
