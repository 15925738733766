import type { ReactNode } from 'react';
import { styled } from 'goober';

import { Topbar, TOPBAR_HEIGHT } from './features/topbar/topbar';

const MAIN_LAYOUT_ID = 'main-layout';

export const scrollMainLayoutTo = ({
  top,
  behavior,
}: {
  top: number;
  behavior: ScrollBehavior;
}) => {
  document.getElementById(MAIN_LAYOUT_ID)?.scrollTo({
    top,
    left: 0,
    behavior,
  });
};

export const PageLayout = ({
  children,
  withTopbar = true,
  className,
}: {
  children: ReactNode;
  withTopbar?: boolean;
  className?: string;
}) => {
  return (
    <>
      {withTopbar && <Topbar />}
      <MainLayout
        id={MAIN_LAYOUT_ID}
        fullHeight={!withTopbar}
        className={className}
      >
        {children}
      </MainLayout>
    </>
  );
};

const MainLayout = styled('div')<{ fullHeight?: boolean }>`
  height: ${({ fullHeight }) =>
    fullHeight ? '100vh' : `calc(100vh - ${TOPBAR_HEIGHT})`};
  overflow-y: auto;
  flex-grow: 1;
`;
