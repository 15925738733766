import type {
  APIRequestBody,
  APIRequestQueryParams,
  APIResponse,
} from './base-fetch';
import { get, post, remove } from './base-fetch';

export type UsersListQueryParams = APIRequestQueryParams<'/api/v4/users'>;
export type UsersListSort = NonNullable<UsersListQueryParams['sort']>;

export type User = APIResponse<'/api/v4/users'>[number];

export const fetchUsers = (params: UsersListQueryParams) =>
  get<User[]>('/v4/users', { params });

export type AllUsersQueryParams = APIRequestQueryParams<'/api/v4/users/all'>;

export const fetchAllUsers = (params: AllUsersQueryParams) =>
  get<User[]>('/v4/users/all', { params });

type UsersCount = APIResponse<'/api/v4/users/count'>;
export const fetchUsersCount = () => get<UsersCount>('/v4/users/count');

export type InviteUsersPayload = APIRequestBody<'/api/v4/users/invite-users'>;
export type InvitedUser = APIResponse<'/api/v4/users/invite-users'>[number];

export const inviteUsers = (data: InviteUsersPayload) =>
  post<InvitedUser[]>('/v4/users/invite-users', data);

export const revokeUserInvitation = async (userId: number) =>
  remove<void>(`/v4/users/invited/revoke/${userId}`);

export const resendInvitationEmail = async (userId: number) =>
  post<void>(`/v4/users/${userId}/resend-invitation`);

type UsersToMentionListParams = APIRequestQueryParams<'/api/v4/users/mentions'>;
export type UserToMention = APIResponse<'/api/v4/users/mentions'>[number];

export const fetchUsersToMentionList = (params: UsersToMentionListParams) =>
  get<UserToMention[]>('/v4/users/mentions', { params });

export type ChangeUserStatusPayload =
  APIRequestBody<'/api/v4/users/{userId}/change-status'> & { userId: number };

export const changeUserStatus = ({
  userId,
  ...payload
}: ChangeUserStatusPayload) =>
  post<void[]>(`/v4/users/${userId}/change-status`, payload);

export type EditMyProfilePayload =
  APIRequestBody<'/api/v4/users/edit-my-profile'>;

export const editMyProfile = (payload: EditMyProfilePayload) =>
  post<void>(`/v4/users/edit-my-profile`, payload);

export type EditUserRolePayload =
  APIRequestBody<'/api/v4/users/{userId}/change-role'> & {
    userInAccountId: number;
  };

export const editUserRole = ({
  userInAccountId,
  ...payload
}: EditUserRolePayload) =>
  post<void>(`/v4/users/${userInAccountId}/change-role`, payload);
