import { type MouseEvent } from 'react';
import { styled } from 'goober';

import { rgba } from '@/helpers/rgba';
import { Z_INDEX_PROJECTS_TABLE_HEAD } from '@/theme/z-index';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import type {
  IAlign,
  IHeaderCell,
  ITableSort,
  IVerticalAlign,
} from '@/ui/table/table.types';

import { colorIcon } from '../../icons/functions';

export const TableHeaderCell = <T,>({
  cell,
  sort,
  sticky = true,
}: {
  cell: IHeaderCell<T>;
  sort?: ITableSort<T>;
  sticky?: boolean;
}) => {
  const onHeaderClick = (
    event: MouseEvent<HTMLDivElement>,
    cell: IHeaderCell<T>,
  ) => {
    const target = event.target as HTMLDivElement;

    if (target.closest('[data-name="select-all-checkbox"]')) {
      return;
    }

    if (!sort || !cell.sortKey) return;

    if (sort.sort === cell.sortKey) {
      sort.onSort({
        direction: sort.direction === 'desc' ? 'asc' : 'desc',
        sort: cell.sortKey as T,
      });

      return;
    }

    sort.onSort({
      direction: cell.defaultSortDirection ?? 'desc',
      sort: cell.sortKey as T,
    });
  };

  return (
    <Td
      key={cell.columnKey}
      align={cell.align}
      width={cell.width}
      padding={cell.padding}
      bgColor={cell.bgColor}
      sticky={sticky}
      colSpan={cell.colSpan}
    >
      <Cell
        height={cell.height}
        name="table-header-cell"
        gap="8px"
        isClickable={Boolean(sort) && Boolean(cell.sortKey)}
        onClick={event => onHeaderClick(event, cell)}
        isSortActive={sort?.sort === cell.sortKey && cell.withSort !== false}
        alignItems="center"
        justify={
          cell.align === 'right'
            ? 'flex-end'
            : cell.align === 'center'
            ? 'center'
            : 'flex-start'
        }
      >
        {!cell.hideLabel && (
          <>
            {cell.checkboxCount ? (
              <>
                {cell.value} ({cell.checkboxCount})
              </>
            ) : (
              cell.value
            )}
            {!!sort && !!cell.sortKey && (
              <SortArrow
                name="sort-arrow-box"
                sortDirection={
                  sort.direction === 'asc' && sort.sort === cell.sortKey
                    ? 'asc'
                    : 'desc'
                }
              >
                <Icon icon="SortArrowDown" />
              </SortArrow>
            )}
          </>
        )}
        {cell.checkboxActions}
      </Cell>
    </Td>
  );
};

const Td = styled('td')<{
  align?: IAlign;
  verticalAlign?: IVerticalAlign;
  width?: string;
  breakWord?: boolean;
  padding?: string;
  rowPadding?: string;
  bgColor?: string;
  sticky?: boolean;
}>`
  ${({ sticky }) =>
    sticky &&
    `
    position: sticky;
    top: 0;
`};

  z-index: ${Z_INDEX_PROJECTS_TABLE_HEAD};

  padding: ${({ padding = '30px 20px 20px 20px' }) => padding};
  ${({ align = 'left' }) => `text-align: ${align};`}
  ${({ width }) => Boolean(width) && `width: ${width};`}

    ${({ rowPadding }) =>
    rowPadding &&
    `
    &:first-child {
      padding-left: 60px;
    }

    &:last-child {
      padding-right: 60px;
    }
  `};

  text-align: ${({ align = 'left' }) => align};
  ${({ width }) => !!width && `width: ${width};`}
  ${({ breakWord }) => !!breakWord && 'word-break: break-word;'}
  background-color: ${({ theme, bgColor }) =>
    bgColor ?? theme.colors.basics.white};
`;

const Cell = styled(Flexbox)<{
  isClickable?: boolean;
  isSortActive?: boolean;
}>`
  ${({ isClickable }) => Boolean(isClickable) && 'cursor: pointer'};
  ${({ theme }) => theme.typography.companyInfo.tableHeader}
  opacity: 1;

  ${({ isSortActive, theme }) => {
    const alpha = isSortActive ? 1 : 0.5;
    const color = isSortActive
      ? theme.colors.blue.primaryA
      : theme.colors.basics.black;
    const rgbaColor = rgba(color, alpha);

    return `
      color: ${rgbaColor};
      ${colorIcon(rgbaColor)}
    `;
  }}
`;

const SortArrow = styled(Flexbox)<{ sortDirection: 'asc' | 'desc' }>`
  ${({ sortDirection }) =>
    sortDirection === 'asc' && 'transform: rotate3d(0, 0, 1, 180deg);'}
`;
