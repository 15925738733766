import type { Comment } from '@/features/action-panel/comments/comment.type';

import type { APIRequestBody, APIResponse } from './base-fetch';
import { get, post, put, remove } from './base-fetch';

export const fetchOrganizationComments = async ({
  organizationId,
}: {
  organizationId: string;
}) => get<Comment[]>(`/v4/organization-comments/${organizationId}`);

type CreateOrganizationCommentResponse =
  APIResponse<'/api/v4/organization-comments/create'>;

export type CreateOrganizationCommentPayload =
  APIRequestBody<'/api/v4/organization-comments/create'>;

export const createOrganizationComment = async (
  data: CreateOrganizationCommentPayload,
) =>
  post<CreateOrganizationCommentResponse, CreateOrganizationCommentPayload>(
    '/v4/organization-comments/create',
    data,
  );

type EditOrganizationCommentResponse =
  APIResponse<'/api/v4/organization-comments/edit'>;

export type EditOrganizationCommentPayload =
  APIRequestBody<'/api/v4/organization-comments/edit'>;

export const editOrganizationComment = async (
  data: EditOrganizationCommentPayload,
) =>
  put<EditOrganizationCommentResponse, EditOrganizationCommentPayload>(
    '/v4/organization-comments/edit',
    data,
  );

export const deleteOrganizationComment = async (commentId: string) =>
  remove<void>(`/v4/organization-comments/delete/${commentId}`);
