import { useCallback, useEffect } from 'react';
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useResetRecoilState,
} from 'recoil';

import type { CompanyFreeTextField } from '@/api/v4/organization.api';
import { useLoadableData } from '@/hooks/use-loadable-results';

import {
  companyFreeTextFields,
  companyFreeTextFieldsEditModeState,
  companyFreeTextFieldsState,
} from './free-text-fields.state';

const MAX_FREE_TEXT_FIELDS_COUNT = 5;

export const useFreeTextFields = () => {
  const [fields, setFields] = useRecoilState(companyFreeTextFields);
  const [editModeFieldId, setEditModeFieldId] = useRecoilState(
    companyFreeTextFieldsEditModeState,
  );
  const { data, isLoading } = useLoadableData<CompanyFreeTextField>(
    companyFreeTextFieldsState,
  );
  const refreshTextFieldsData = useRecoilRefresher_UNSTABLE(
    companyFreeTextFieldsState,
  );
  const resetTextFieldsState = useResetRecoilState(companyFreeTextFields);
  const refresh = useCallback(() => {
    resetTextFieldsState();
    refreshTextFieldsData();
  }, [refreshTextFieldsData, resetTextFieldsState]);
  const isEditing = !!editModeFieldId;

  useEffect(() => {
    data.length > 0 &&
      setFields(data.map(field => ({ ...field, synced: true })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const add = () => {
    if (isEditing) {
      return;
    }

    const tempId = `${fields.length}`;
    setEditModeFieldId(tempId);

    setFields(fields => [
      ...fields,
      { id: `${tempId}`, title: '', content: '', synced: false },
    ]);
  };

  const edit = (id: string) => setEditModeFieldId(id);

  const deleteItem = (id: string) => {
    setEditModeFieldId(null);
    setFields(fields => fields.filter(field => field.id !== id));
  };

  const onSync = (tempId: string, persistedValue: CompanyFreeTextField) => {
    setFields(fields =>
      fields.map(field => {
        if (field.id === tempId) {
          return {
            ...persistedValue,
            synced: true,
          };
        }

        return field;
      }),
    );
  };

  const canAddExtraField = fields.length < MAX_FREE_TEXT_FIELDS_COUNT;

  const isFreeTextFieldAdded = Boolean(fields?.length);

  return {
    fields,
    isLoading,
    add,
    edit,
    onSync,
    deleteItem,
    refresh,
    canAddExtraField,
    isFreeTextFieldAdded,
  };
};
