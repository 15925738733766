import { useTranslation } from 'react-i18next';

import { ReactComponent as SearchIllustration } from '@/assets/svg/search-illustration.svg';
import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Small } from '@/ui/typography/across-platform';

import { SearchContentContainer } from './content-container';

export const InitialState = () => {
  const { t } = useTranslation('default', { keyPrefix: 'globalSearch' });

  return (
    <SearchContentContainer fullWidth>
      <Flexbox
        name="search-initial-state"
        direction="column"
        justify="center"
        alignItems="center"
      >
        <SearchIllustration />
        <Small color={colors.gray.c12}>{t`searchInitialText`}</Small>
      </Flexbox>
    </SearchContentContainer>
  );
};
