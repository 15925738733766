import { useCallback, useState } from 'react';
import type { ReactDatePickerProps } from 'react-datepicker';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CALENDAR_DATE_FORMAT } from '@/constants';
import {
  formatCalendarDateAsOptionValue,
  formatDate,
  parseNullishStringToDate,
} from '@/helpers/format-date';
import { CustomInputAsyncSelect } from '@/ui/date-picker/custom-input/custom-input-async-select';
import { DatePicker } from '@/ui/date-picker/date-picker';
import { getCustomSharedProps } from '@/ui/select/select-shared';

import type { MeetingFormFields } from '../hooks/use-meeting-form';
import { FieldError } from '../ui/field-error';
import { WithLabel } from '../ui/with-label';

export const MeetingDateSelect = () => {
  const { t } = useTranslation('default');
  const {
    setValue,
    getFieldState,
    formState,
    getValues,
    formState: { defaultValues },
  } = useFormContext<MeetingFormFields>();
  const dateState = getFieldState('date', formState);
  const [isOpen, setIsOpen] = useState(false);

  const { components, styles } = getCustomSharedProps({
    variant: 'small',
    withError: dateState.invalid,
    dropdownIndicatiorTransform: isOpen ? 'rotate3d(0, 0, 1, 180deg)' : 'unset',
  });

  const handleDateChange = useCallback<ReactDatePickerProps['onChange']>(
    newDate => {
      if (!newDate) {
        return;
      }

      setValue('date', formatCalendarDateAsOptionValue(newDate) ?? '');
      handleMenuClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultValues?.date],
  );

  const handleMenuClose = useCallback(() => {
    const date = getValues('date');
    const formattedDefaultValue = defaultValues?.date
      ? formatDate(defaultValues?.date, {
          format: CALENDAR_DATE_FORMAT,
        })
      : '';

    const isDefaultValue = date === formattedDefaultValue;
    setValue('date', date, {
      shouldDirty: !isDefaultValue,
      shouldValidate: true,
    });
    setIsOpen(false);
  }, [defaultValues?.date, getValues, setValue]);

  return (
    <WithLabel
      name="meeting-date"
      labelText={t`actionPanel.meetings.meetingDate`}
    >
      <DatePicker
        open={isOpen}
        selected={parseNullishStringToDate(getValues('date'))}
        onInputClick={() => setIsOpen(!isOpen)}
        onChange={handleDateChange}
        onCalendarClose={handleMenuClose}
        placeholderText={t`actionPanel.meetings.meetingDatePlaceholder`}
        customInput={
          <CustomInputAsyncSelect
            components={{ ...components }}
            styles={styles}
          />
        }
      />
      {dateState.invalid && (
        <FieldError absolute fontSize="12px" errorAlign="right">
          {dateState.error?.message}
        </FieldError>
      )}
    </WithLabel>
  );
};
