import type { FC } from 'react';
import { styled } from 'goober';

import { colors } from '@/theme/colors';
import type { FlexBoxProps } from '@/ui/flexbox/flexbox';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { SmallText } from '@/ui/typography/widgets';

interface WithLabelProps extends FlexBoxProps {
  labelText: string;
}

export const WithLabel: FC<WithLabelProps> = ({
  children,
  name,
  labelText,
  ...props
}) => {
  return (
    <Flexbox name={name} direction="column" gap="8px" {...props}>
      {children}
      <Label color={colors.gray.c13}>{labelText}</Label>
    </Flexbox>
  );
};

const Label = styled(SmallText)`
  margin-left: 10px;
`;
