import { colors } from '@/theme/colors';
import type { AsyncSelectProps } from '@/ui/select/async/async-select-with-list';
import { AsyncSelectWithList } from '@/ui/select/async/async-select-with-list';
import { sharedProps } from '@/ui/select/select-shared';

import { SearchCustomOption } from './search-custom-option';

export const SearchEntitySelect = ({
  defaultValue,
  ...props
}: Partial<AsyncSelectProps>) => {
  return (
    <AsyncSelectWithList
      {...sharedProps}
      defaultValue={defaultValue}
      components={{ Option: SearchCustomOption }}
      styles={{
        menu: base => ({
          ...base,
          padding: '12px 28px',
        }),
        groupHeading: base => ({
          ...base,
          textTransform: 'capitalize',
          color: colors.gray.c9,
          fontWeight: 400,
          marginBottom: '12px',
        }),
      }}
      {...props}
    />
  );
};
