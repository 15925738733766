import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import type { CreateProjectListingSuggestionPayload } from '@/api/v4/project-enhance-listing-suggestions.api';
import { createProjectListingSuggestions } from '@/api/v4/project-enhance-listing-suggestions.api';
import { notify } from '@/ui/snackbar/notify';
import { useProjectListingSuggestionState } from './use-project-listing-suggestion.state';

export const useCreateProjectListingSuggestions = (projectId?: string) => {
  const { t } = useTranslation('default');
  const state = useProjectListingSuggestionState();

  return useMutation({
    mutationFn: async (payload: CreateProjectListingSuggestionPayload) => {
      if (!projectId) return [];
      return await createProjectListingSuggestions(projectId, payload);
    },
    onError: (error: Error) => {
      state.setCurrentPageStep('error');
      notify({
        message: t`unknownError`,
      });
      console.error(error.message);
    },
  });
};
