import type { UserOption } from '@/types';

import type { User } from '../user.api';

export const mapUsersToOptions = (users: User[]): UserOption[] =>
  users.map(user => ({
    value: user.id,
    label: getUserOptionLabel(user),
    firstName: user.firstName,
    lastName: user.lastName,
    role: user.role,
    team: user.team,
    status: user.status,
    email: user.email,
  }));

const getUserOptionLabel = (user: User) =>
  user.firstName && user.lastName
    ? `${user.firstName} ${user.lastName}`
    : user.email;
