import { styled } from 'goober';

import { formatEmpty } from '@/helpers/format-empty';
import { formatRanking } from '@/helpers/format-number';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { colorIcon } from '@/ui/icons/functions';
import { Icon } from '@/ui/icons/icon';

export const Rating = ({ ranking }: { ranking: string | null }) => (
  <RankingWrapper name="ranking-wrapper" gap="8px" alignItems="center">
    {formatEmpty(formatRanking(ranking))}
    <Icon icon="Star" />
  </RankingWrapper>
);

const RankingWrapper = styled(Flexbox)`
  color: ${({ theme }) => theme.colors.blue.primaryA};
  ${({ theme }) => colorIcon(theme.colors.blue.primaryA)};
`;
