import { useQuery } from '@tanstack/react-query';

import { useAuthorization } from '@/auth/hooks/use-authorization';
import { cookies } from '@/config/cookies';
import { COOKIES } from '@/constants';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';

import type { userLoader } from './user-loader';
import { useLoaderData } from 'react-router-dom';
import { fetchWhoAmI } from '@/api/v4/auth.api';
import { QueryKey } from '@/config/query-client';
import { getTimeInMs } from '@/helpers/get-time-in-ms';

export const currentUserQuery = () => ({
  queryKey: [QueryKey.WhoAmI],
  queryFn: fetchWhoAmI,
  staleTime: getTimeInMs(15, 'minute'),
  cacheTime: getTimeInMs(1, 'day'),
});

export const useCurrentUser = () => {
  const { isUserAuthorized } = useAuthorization();
  const navigateTo = useNavigateTo();
  const initialData = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof userLoader>>
  >;

  return useQuery({
    ...currentUserQuery(),
    onSuccess: appUser => {
      if (!appUser) {
        return;
      }

      if (!cookies.get(COOKIES.INTERCOM_HASH)) {
        cookies.set(COOKIES.INTERCOM_HASH, appUser.intercomHash);
      }
    },
    onError: navigateTo.signIn,
    enabled: isUserAuthorized,
    networkMode: 'offlineFirst',
    initialData,
  });
};
