import type { APIRequestQueryParams, APIResponse } from './base-fetch';
import { get } from './base-fetch';

type LocationQueryParams = APIRequestQueryParams<'/api/v4/locations/hq'>;
export type HQLocation = APIResponse<'/api/v4/locations/hq'>[number];

type FetchLocationsQueryParams = APIRequestQueryParams<'/api/v4/locations'>;
export type Location = APIResponse<'/api/v4/locations'>[number];

export const fetchHQLocations = async (params: LocationQueryParams) =>
  get<HQLocation[]>('/v4/locations/hq', { params });

export const fetchLocations = async (params: FetchLocationsQueryParams) =>
  get<Location[]>('/v4/locations', { params });
