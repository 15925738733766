import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { deleteUseCaseFit } from '@/api/v3/use-case-fit-api';
import { notify } from '@/ui/snackbar/notify';

import { useInvalidateProjectListings } from '../../use-invalidate-project-listings';

export const useDeleteUseCaseFit = () => {
  const { t } = useTranslation('default');
  const invalidateProjectListings = useInvalidateProjectListings();

  const mutation = useMutation(deleteUseCaseFit, {
    onSuccess: async () => await invalidateProjectListings(),
    onError: () => {
      notify({
        message: t('unknownError'),
      });
    },
  });

  return (projectListingId: string) => mutation.mutateAsync(projectListingId);
};
