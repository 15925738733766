export enum COOKIES {
  INTERCOM_HASH = 'intercomHash',
}

export const FETCH_BUSINESS_UNIT_DELAY = 300;

export const DROPDOWN_ITEMS_PER_PAGE = 20;

export const CALENDAR_DATE_FORMAT = 'yyyy-MM-dd';
/**
 * General app date format for date-fns
 */
export const GENERAL_DATE_FORMAT = 'MMM d, yyyy';

export const SEARCH_INPUT_MAX_CHAR = 320;
