import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { UpdateProjectCommentPayload } from '@/api/v4/project-comments.api';
import { updateProjectComment } from '@/api/v4/project-comments.api';
import { notify } from '@/ui/snackbar/notify';
import { QueryKey } from '@/config/query-client';
import { useRecoilValue } from 'recoil';
import { projectIdState } from '../../project.state';

export const useUpdateProjectComment = () => {
  const { t } = useTranslation('default');
  const projectId = useRecoilValue(projectIdState);

  const queryClient = useQueryClient();

  const mutation = useMutation(updateProjectComment, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([QueryKey.Project, projectId]),
        queryClient.invalidateQueries([QueryKey.ProjectComments, projectId]),
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);
    },
    onError: () => {
      notify({
        message: t`unknownError`,
      });
    },
  });

  const updateComment = (payload: UpdateProjectCommentPayload) =>
    mutation.mutateAsync(payload);

  return {
    updateComment,
    isError: mutation.isError,
    isUpdateInProgress: mutation.isLoading,
  };
};
