import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CreateProjectCommentPayload } from '@/api/v4/project-comments.api';
import { createProjectComment } from '@/api/v4/project-comments.api';
import { notify } from '@/ui/snackbar/notify';
import { QueryKey } from '@/config/query-client';
import { projectIdState } from '../../project.state';
import { useRecoilValue } from 'recoil';

export const useCreateProjectComment = () => {
  const { t } = useTranslation('default');
  const projectId = useRecoilValue(projectIdState);

  const queryClient = useQueryClient();

  const mutation = useMutation(createProjectComment, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([QueryKey.Project, projectId]),
        queryClient.invalidateQueries([QueryKey.ProjectComments, projectId]),
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);
    },
    onError: () => {
      notify({
        message: t`unknownError`,
      });
    },
  });

  const createComment = (payload: CreateProjectCommentPayload) =>
    mutation.mutateAsync(payload);

  return {
    createComment,
    isError: mutation.isError,
  };
};
