import type { APIResponse } from './base-fetch';
import { get } from './base-fetch';

export type OrganizationActionResponse =
  APIResponse<'/api/v4/organizations/{organizationId}/actions'>;

export const fetchOrganizationActionsInfo = async (organizationId: string) =>
  get<OrganizationActionResponse>(
    `/v4/organizations/${organizationId}/actions`,
  );
