import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { uploadTeamFile as uploadTeamFileAPI } from '@/api/v4/team-files.api';
import { QueryKey } from '@/config/query-client';

import { teamIdState } from '../../../team.state';

export const useUploadTeamFile = () => {
  const queryClient = useQueryClient();
  const teamId = useRecoilValue(teamIdState);

  const mutation = useMutation(uploadTeamFileAPI, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.TeamFiles, teamId]);
      await queryClient.invalidateQueries([QueryKey.TeamActionsInfo, teamId]);
    },
    onError: async () => {
      await queryClient.invalidateQueries([QueryKey.TeamFiles, teamId]);
      await queryClient.invalidateQueries([QueryKey.TeamActionsInfo, teamId]);
    },
  });

  const uploadTeamFile = (files: File[]) => {
    if (!teamId) return;

    return mutation.mutateAsync({ teamId, files });
  };

  return {
    uploadTeamFile,
    isError: mutation.isError,
  };
};
