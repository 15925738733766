import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchCompanyOfficeLocations } from '@/api/v4/organization.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

import { companyIdState } from '../../company.state';

export const useOfficeLocationsQuery = () => {
  const { t } = useTranslation('default');
  const companyId = useRecoilValue(companyIdState);

  const fetchLocations = async () => {
    if (!companyId) return [];

    return fetchCompanyOfficeLocations(companyId);
  };

  return useQuery(
    [QueryKey.CompanyOfficeLocations, companyId],
    fetchLocations,
    {
      onError: () =>
        notify({
          message: t`unknownError`,
        }),
      enabled: !!companyId,
      staleTime: 1000 * 60 * 5,
    },
  );
};
