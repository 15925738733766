import { useQuery } from '@tanstack/react-query';

import { fetchRoles } from '@/api/v4/role.api';
import { QueryKey } from '@/config/query-client';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

export const useRoles = () => {
  const { hasAtLeastOnePermission } = useUserPermissions();

  return useQuery([QueryKey.Roles], fetchRoles, {
    onError: () => console.error('Error when fetching roles'),
    enabled: hasAtLeastOnePermission([
      PERMISSION.MANAGE_TEAMS,
      PERMISSION.SHARE_A_COMPANY_TO_EXISTING_Q_USERS,
      PERMISSION.SHARE_A_STREAM_TO_EXISTING_Q_USERS,
    ]),
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};
