import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchOrganizationTags } from '@/api/v4/organization-tags.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

import { companyIdState } from '../company.state';

export const useCompanyTags = () => {
  const companyId = useRecoilValue(companyIdState);
  const { t } = useTranslation('default');

  return useQuery(
    [QueryKey.CountEntitiesByTag, companyId],
    () =>
      companyId
        ? fetchOrganizationTags({ organizationId: companyId })
        : undefined,
    {
      onError: () =>
        notify({
          message: t`unknownError`,
        }),
      staleTime: 1000 * 10,
      enabled: !!companyId,
    },
  );
};
