import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Flexbox } from '@/ui/flexbox/flexbox';
import { TableHeader } from '@/ui/typography/company-info';
import { SmallText } from '@/ui/typography/widgets';

export const SuggestionContent = ({
  name,
  type,
  listingsCount,
  highlights,
}: {
  name: string;
  type: string;
  listingsCount?: number;
  highlights?: string[];
}) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'globalSearch',
  });

  return (
    <Flexbox
      name="suggestion-content-container"
      justify="space-between"
      fullWidth
      gap="15px"
    >
      <Flexbox
        name="suggestion-name-and-highlights"
        direction="column"
        gap="4px"
      >
        <Flexbox name="suggestion-name-and-type-container" gap="8px">
          <Header>{name}</Header>
          {listingsCount ? (
            <ListingsCount>
              {listingsCount} {t('companies', { count: listingsCount })}
            </ListingsCount>
          ) : null}
        </Flexbox>
        {highlights
          ? highlights.map(h => (
              <Highlight key={h}>
                {remapEmphasizedTextToDOMElement(h)}
              </Highlight>
            ))
          : null}
      </Flexbox>
      <SuggestionType>{t(type)}</SuggestionType>
    </Flexbox>
  );
};

const SuggestionType = styled(SmallText)`
  color: ${({ theme }) => theme.colors.gray.c10};
`;

const ListingsCount = styled(TableHeader)`
  text-wrap: nowrap;
  width: max-content;
`;

const Header = styled(SmallText)`
  color: ${({ theme }) => theme.colors.basics.black};
`;

const Highlight = styled(TableHeader)`
  ${({ theme }) => theme.mixins.ellipsis};
  max-width: 570px;

  em {
    font-weight: bold;
  }
`;

const remapEmphasizedTextToDOMElement = (text: string) => {
  const parts = text.split(/(<em>.*?<\/em>)/);

  return parts.map((part, index) => {
    if (part.startsWith('<em>') && part.endsWith('</em>')) {
      const content = part.slice(4, -5);
      return <em key={index}>{content}</em>;
    }
    return part;
  });
};
