import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import type { usePopper } from 'react-popper';

import type { DropdownOption } from '@/ui/dropdown/dropdown';
import { WithDropdown } from '@/ui/dropdown/dropdown';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { useCreateActions } from '../../../hooks/use-create-actions';

interface DropdownCreateProps {
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
  triggerButton: JSX.Element;
  options?: Parameters<typeof usePopper>[2];
}

export const DropdownCreate = ({
  setIsDropdownOpen,
  triggerButton,
  options,
}: DropdownCreateProps) => {
  const { onAddOrganization, onAddProject, onAddTeam } = useCreateActions();
  const { hasRequiredPermission } = useUserPermissions();

  const { t } = useTranslation(['teams', 'projects']);
  const createItems: DropdownOption[] = [
    {
      label: t`projects:addCompany.createCompany`,
      isVisible: hasRequiredPermission(PERMISSION.ADD_ORGANIZATION),
      onClick() {
        onAddOrganization();
      },
    },
    {
      label: t`projects:projects.createProject`,
      isVisible: hasRequiredPermission(PERMISSION.ADD_PROJECT),
      onClick() {
        onAddProject();
      },
    },
    {
      label: t`teams:createTeam`,
      isVisible: hasRequiredPermission(PERMISSION.MANAGE_TEAMS),
      onClick() {
        onAddTeam();
      },
    },
  ];

  return (
    <WithDropdown
      items={createItems}
      options={{
        placement: 'right-start',
        modifiers: [{ name: 'offset', options: { offset: [0, 22] } }],
        ...options,
      }}
      setIsDropdownOpened={setIsDropdownOpen}
    >
      {triggerButton}
    </WithDropdown>
  );
};
