import { Flexbox } from '@/ui/flexbox/flexbox';
import type { IconVariant } from '@/ui/icons/icon';
import { Icon } from '@/ui/icons/icon';
import { Tab } from '@/ui/typography/action-panel';

export const TagsTabControl = ({
  name,
  icon,
}: {
  name: string;
  icon?: IconVariant;
}) => {
  return (
    <Flexbox
      name="tags-tab-control"
      alignItems="center"
      justify="center"
      height="100%"
      gap="5px"
    >
      <Tab>{name}</Tab>
      {icon && <Icon icon={icon} />}
    </Flexbox>
  );
};
