import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const showOfficeLocationsState = atom({
  key: 'showOfficeLocationsState',
  default: true,
  effects: [persistAtom],
});

export const officeLocationsEditModeState = atom({
  key: 'officeLocationsEditModeState',
  default: false,
});
