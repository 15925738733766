import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Loader } from '@/components/loader/loader';
import { companiesSearchPhraseState } from '@/features/companies/overview/company.state';
import { companiesAddedState } from '@/features/companies/overview/company.state';
import { hasProperty } from '@/helpers/has-property';
import { useCheckCompanyDomainAvailability } from '@/hooks/queries/use-company-domain-availability.query';
import { useCreateCompany } from '@/hooks/queries/use-create-company.mutation';
import { useDebounce } from '@/hooks/use-debounce';
import { useZodForm } from '@/hooks/use-zod-form';
import { Input } from '@/ui/input/input';
import type { Row } from '@/ui/table/table.types';

import { schemaNewCompany } from './new-company-zod-schema';
import {
  ButtonAddCompany,
  useCompaniesTableConfiguration,
} from './use-companies-table-configuration';
import type {
  CreateCompanyPayload,
  CreatedCompany,
} from '@/api/v4/organization.api';

// -1 => new company not saved (still in edit mode)
export const NEW_COMPANY_ID = '-1';

export type UseNewCompanyTableConfigurationProps = {
  defaultWebsite?: string;
  onClose?: (createdCompanies: string[]) => Promise<void>;
  onCompanyCreated: (company: CreatedCompany) => void;
};

export function useNewCompanyTableConfiguration({
  defaultWebsite,
  onCompanyCreated,
}: UseNewCompanyTableConfigurationProps) {
  const { t } = useTranslation('projects');
  const [isInProgress, setIsInProgress] = useState(false);
  const [createdCompany, setCreatedCompany] = useState<CreatedCompany>();
  const setCompaniesAdded = useSetRecoilState(companiesAddedState);
  const { createOwnCompanyProfile } = useCreateCompany();
  const [isDomainAvailable, setIsDomainAvailable] = useState<boolean | null>(
    true,
  );

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useZodForm({
    schema: schemaNewCompany,
    defaultValues: {
      website: defaultWebsite,
    },
    mode: 'onChange',
  });

  const companiesSearchPhrase = useRecoilValue(companiesSearchPhraseState);

  const websiteFieldValue = watch('website');
  const nameFieldValue = watch('name');

  const isEmptyForm = !(nameFieldValue && websiteFieldValue);

  const checkCompanyDomainAvailability = useCheckCompanyDomainAvailability();

  const doDomainCheck = useDebounce(async (url: string) => {
    const { isUsed } = await checkCompanyDomainAvailability(url);

    setIsDomainAvailable(!isUsed);
  });

  const resetCompanyForm = () => {
    reset();
    setCreatedCompany(undefined);
    setIsDomainAvailable(true);
  };

  useEffect(() => {
    const isWebsiteURLValid =
      websiteFieldValue?.length > 0 && !hasProperty('website', errors);

    if (!isWebsiteURLValid) return;

    doDomainCheck(websiteFieldValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websiteFieldValue, errors.website, doDomainCheck]);

  const { rows } = useCompaniesTableConfiguration(
    createdCompany
      ? [
          {
            id: createdCompany.id,
            name: createdCompany.name,
            domain: createdCompany.domain,
            locations: [],
            averageRating: null,
            listingsCount: 0,
            yearEstablished: createdCompany.yearEstablished,
          },
        ]
      : [],
  );

  const onSubmit = (data: CreateCompanyPayload) => {
    setIsInProgress(true);
    createOwnCompanyProfile.mutate(data, {
      onSuccess: createdCompany => {
        onCompanyCreated(createdCompany);
        setCreatedCompany(createdCompany);
        setCompaniesAdded(currentCompanies => [
          ...currentCompanies,
          createdCompany,
        ]);
        setIsInProgress(false);
      },
      onError: () => {
        setIsInProgress(false);
      },
    });
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 && !isEmptyForm) event.preventDefault();
  };

  const handleOnKeyUp = async (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.keyCode === 13 && !isEmptyForm) {
      event.preventDefault();
      await handleSubmit(onSubmit)();
    }
  };

  const newCompanyRow: Row = {
    id: NEW_COMPANY_ID,
    rowPadding: {
      left: '20px',
      right: '20px',
    },
    rowHeight: '108px',
    cells: [
      {
        value: (
          <Input
            defaultValue={companiesSearchPhrase}
            {...register('name')}
            onKeyUp={handleOnKeyUp}
            onKeyDown={handleOnKeyDown}
            error={errors?.name?.message}
            placeholder={t`projects:addCompany.companyName`}
            isErrorAbsolute
            autoFocus
          />
        ),
      },
      {
        padding: '0 20px 0 0',
        value: (
          <Input
            {...register('website')}
            onKeyDown={handleOnKeyDown}
            error={errors?.website?.message}
            isErrorAbsolute
            placeholder={t`projects:addCompany.pasteUrlHere`}
            iconColor={'red'}
            rightIcon={!isDomainAvailable ? 'Alert' : undefined}
            rightIconTooltip={t`addCompany.domainInUse`}
            rightIconSeparator={false}
          />
        ),
      },
      {
        width: '75px',
        padding: '0 20px 0 0',
        value: isInProgress ? (
          <Loader size="small" />
        ) : (
          <ButtonAddCompany
            type="submit"
            startIcon="Plus"
            className="always-on"
            disabled={isEmptyForm}
            onClick={handleSubmit(onSubmit)}
          >
            {t`projects:addCompany.create`}
          </ButtonAddCompany>
        ),
      },
    ],
  };

  return {
    newCompanyRow: createdCompany ? rows[0] : newCompanyRow,
    resetCompanyForm,
  };
}
