import { isUserOption } from '@/helpers/other';
import type { InvitedUserOption, UserOption } from '@/types';
import type { AsyncSelectProps } from '@/ui/select/async/async-select-with-list';
import { AsyncSelectWithList } from '@/ui/select/async/async-select-with-list';
import type { CustomAsyncSelectProps } from '@/ui/select/async/use-select-type';

import { SelectedUserOption } from '../../app-users/invite-users/selected-user-option';
import { useInvitedUsersOptions } from '../../app-users/invite-users/use-invited-users-options';

import { InviteNewUserOption } from './invite-new-user-option';

interface InviteFormProps extends Pick<AsyncSelectProps, 'searchInputSize'> {
  onChange: (options: (UserOption | InvitedUserOption)[]) => void;
  placeholder: string;
  invitedUsersList: Array<UserOption | InvitedUserOption>;
}

export function InviteForm({
  onChange,
  placeholder,
  invitedUsersList,
}: InviteFormProps) {
  const { setOptions } = useInvitedUsersOptions({
    invitedUsersList,
  });

  const handleOnChange: CustomAsyncSelectProps['onChange'] = newValue => {
    if (Array.isArray(newValue)) onChange(newValue);
  };

  return (
    <AsyncSelectWithList
      setOptions={setOptions}
      placeholder={placeholder}
      shouldLoadMore={() => false}
      components={{ Option: InviteNewUserOption }}
      SelectedOptionsListComponent={<SelectedUserOption />}
      isOptionDisabled={option => isUserOption(option)}
      onChange={handleOnChange}
    />
  );
}
