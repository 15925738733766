import { useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

import { Z_INDEX_POPPER_CONTENT } from '@/theme/z-index';
import { AmountIndicator } from '@/ui/amount-indicator/amount-indicator';

import { RelatedProjects } from './related-projects';

interface RelatedProjectsCountProps {
  value: number;
  companyId: string;
}

export const RelatedProjectsCount = ({
  value,
  companyId,
}: RelatedProjectsCountProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const referenceElement = useRef<HTMLDivElement | null>(null);
  const popperElement = useRef<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement: 'bottom',
    },
  );

  const handleMouseEnter = useCallback(() => setIsVisible(true), []);
  const handleMouseLeave = useCallback(() => setIsVisible(false), []);

  if (!value) {
    return null;
  }

  return (
    <div>
      <div
        ref={referenceElement}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-id={companyId}
        data-name="related-projects-amount-indicator"
      >
        <AmountIndicator amount={value} />
      </div>

      <div
        ref={popperElement}
        style={{
          ...styles.popper,
          visibility: isVisible ? 'visible' : 'hidden',
          zIndex: Z_INDEX_POPPER_CONTENT,
        }}
        {...attributes.popper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <RelatedProjects companyId={companyId} isVisible={isVisible} />
      </div>
    </div>
  );
};
