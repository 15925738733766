import { fetchTags } from '@/api/v4/tags.api';
import { QueryKey } from '@/config/query-client';
import { useQuery } from '@tanstack/react-query';

export const useTags = () => {
  const query = useQuery([QueryKey.Tags], fetchTags, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return {
    tags: query.data ?? [],
    ...query,
  };
};
