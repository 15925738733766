import { abbreviateNumber } from 'js-abbreviation-number';

export const getAbbreviateNumber = (value: string | null = '0') =>
  abbreviateNumber(Number(value), 2, {
    symbols: ['', 'K', 'M', 'B', 'T', 'P'],
    padding: false,
  });

export function formatRanking(value: Nullable<string>) {
  return typeof value === 'string' && value.length
    ? Number(value).toFixed(1).toString()
    : value;
}
