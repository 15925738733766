import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import aiIntroVideo from '@/assets/videos/AI.mp4';
import { Stack } from '@/ui/line/line';
import { LoadingDots } from '@/ui/loading-dots/loading-dots';
import { Header, ParagraphMedium } from '@/ui/typography/widgets';

import { useProjectListingSuggestionState } from '../use-project-listing-suggestion.state';

export const EnhanceProjectLoadingView = () => {
  const { t } = useTranslation('projects');
  const state = useProjectListingSuggestionState();

  useEffect(() => {
    if (state.currentPageStep !== 'analyzing')
      state.setCurrentPageStep('analyzing');
  }, []);

  return (
    <Stack
      data-testid="project-listing-suggestion-loading-screen-container"
      centered
    >
      <video width="400" autoPlay loop muted>
        <source src={aiIntroVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Stack gap="36px" centered margin="0px auto 2px auto">
        <Stack gap="0px" centered width="355px">
          <Header>{t`enhanceLoading1`}</Header>
          <ParagraphMedium>{t`enhanceLoading2`}</ParagraphMedium>
        </Stack>
        <LoadingDots />
      </Stack>
    </Stack>
  );
};
