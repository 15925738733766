import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { styled } from 'goober';

import BorderLine from '@/assets/images/404-border.png';
import { PageLayout } from '@/page-layout';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Header, PilotHeader } from '@/ui/typography/widgets';
import { paths } from './helpers/paths';

export function PageNotFound() {
  const { t } = useTranslation('default');

  return (
    <PageLayout withTopbar={false}>
      <Flexbox
        name="page-not-found"
        justify="center"
        alignItems="center"
        fullWidth
        fullHeight
      >
        <Flexbox name="page-not-found-content" direction="column" gap="12px">
          <PilotHeader>404</PilotHeader>
          <Header>{t`pageNotFound`}</Header>
          <Info>
            {t`pageNotFoundInfo`}
            <br />

            <Link to={paths.home()}>{t`pageNotFoundActionHome`}</Link>
          </Info>
          <BorderLineImage src={BorderLine} />
        </Flexbox>
      </Flexbox>
    </PageLayout>
  );
}

const Info = styled('div')`
  ${({ theme }) =>
    theme.typography.actionsPanel.acrossPanel.searchOrCommentText}

  a {
    color: ${({ theme }) => theme.colors.basics.black};
  }
`;

const BorderLineImage = styled('img')`
  margin-top: 11px;
`;
