import { useEffect } from 'react';

import { useCreateProjectListingSuggestions } from '../use-create-project-listing-suggestion.mutation';
import { useProjectListingSuggestionState } from '../use-project-listing-suggestion.state';

import { EnhanceProjectCompleteView } from './enhance-project-complete-view';
import { EnhanceProjectErrorView } from './enhance-project-error-view';
import { EnhanceProjectLoadingView } from './enhance-project-loading-view';
import { EnhanceProjectNoResultsView } from './enhance-project-no-results-view';

export const EnhanceProjectResults = () => {
  const state = useProjectListingSuggestionState();
  const unselectedCompanies = state.projectListings
    ?.filter(
      listing => !state.companyFilterSelected.includes(listing.organization.id),
    )
    .map(listing => listing.organization.id);
  const { data, isLoading, isError, mutate } =
    useCreateProjectListingSuggestions(state.projectId || undefined);

  useEffect(() => {
    if (state.currentPageStep === 'analyzing' && !isLoading)
      mutate({
        problemDescription: state.problemStatement,
        desiredSolution: state.desiredSolution,
        locations:
          state.locations.length > 0
            ? state.locations.map(location => JSON.parse(location.value))
            : undefined,
        filterCompanies:
          !!unselectedCompanies && unselectedCompanies.length > 0
            ? unselectedCompanies
            : undefined,
      });
  }, [state.currentPageStep]);

  if (isLoading) return <EnhanceProjectLoadingView />;

  if (!data && !isError) return null;

  if (data?.length === 0) return <EnhanceProjectNoResultsView />;

  if (isError) return <EnhanceProjectErrorView />;

  return <EnhanceProjectCompleteView data={data} />;
};
