import { atom } from 'recoil';

import type { IOption } from '@/types';

import type { ExampleCompany } from '../explore.state';

export const regenerateExploreFormDescriptionState = atom<string>({
  key: 'regenerateExploreFormDescriptionState',
  default: '',
});

export const regenerateExploreFormLocationsState = atom<IOption[]>({
  key: 'regenerateExploreFormLocationsState',
  default: [],
});

export const regenerateExploreFormExampleCompaniesState = atom<
  ExampleCompany[]
>({
  key: 'regenerateExploreFormExampleCompaniesState',
  default: [],
});

export const isRegeneratePanelOpenState = atom<boolean>({
  key: 'isRegeneratePanelOpenState',
  default: false,
});
