import { useQuery } from '@tanstack/react-query';

import { fetchGenAiRequest } from '@/api/v4/genai-request-history.api';
import { QueryKey } from '@/config/query-client';
import { sortByShortDescriptionAndRelevancy } from '../sort-by-short-description-and-result-number';

export const useRequestHistorySuggestions = (
  requestId?: string,
  enabled?: boolean,
) => {
  const fetchRequestData = async () => {
    if (!requestId) {
      return undefined;
    }
    return await fetchGenAiRequest(requestId);
  };

  return useQuery(
    [QueryKey.ExploreRequestHistorySuggestions, requestId],
    fetchRequestData,
    {
      enabled: !!requestId && enabled,
      select: data => {
        if (!data) {
          return undefined;
        }

        return {
          ...data,
          result: sortByShortDescriptionAndRelevancy(data.result),
        };
      },
    },
  );
};
