import { styled } from 'goober';
import { useSetRecoilState } from 'recoil';

import { modalState } from '@/state/modal.state';

import { Button } from '../button/button';
import { Inline } from '../line/line';

import type { Action, ModalVariant } from './modal';

export interface FooterProps {
  variant: ModalVariant;
  mainAction?: Action;
  secondaryAction?: Pick<Action, 'label'> & Partial<Pick<Action, 'onClick'>>;
  loading?: boolean;
  disabled?: boolean;
}

export const Footer = ({
  variant,
  mainAction,
  secondaryAction,
  loading,
  disabled,
}: FooterProps) => {
  const setModalState = useSetRecoilState(modalState);

  if (!mainAction && !secondaryAction) return null;

  return (
    <Container gap="20px">
      {secondaryAction && (
        <Button
          variant="underlined"
          onClick={secondaryAction.onClick || (() => setModalState(null))}
          disabled={loading}
        >
          {secondaryAction.label}
        </Button>
      )}

      {mainAction && (
        <Button
          variant={variant === 'danger' ? 'danger' : 'primary'}
          onClick={mainAction.onClick}
          disabled={mainAction?.isDisabled || disabled || loading}
        >
          {mainAction.label}
        </Button>
      )}
    </Container>
  );
};

const Container = styled(Inline)`
  align-self: flex-end;
  width: 100%;
  justify-content: flex-end;
`;
