import { ToastContainer } from 'react-toastify';
import { styled } from 'goober';

import { ActionPanel } from '@/features/action-panel/action-panel';

import { Navbar } from '../../features/navbar/navbar';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'tippy.js/dist/tippy.css';
import { Modals } from '../../modals';
import { Navigate, Outlet } from 'react-router-dom';
import { PageLayout } from '../../page-layout';

export const ProtectedAppLayout = ({
  isUserAuthorized,
  withTopbar = true,
}: {
  isUserAuthorized: boolean;
  withTopbar?: boolean;
}) => {
  const isFullHeightPage = !withTopbar;

  if (!isUserAuthorized) {
    return <Navigate to="/auth/signin" />;
  }

  return (
    <LayoutContainer>
      <Navbar />
      <MainLayoutWrapper>
        <Layout withTopbar={withTopbar} isFullHeightPage={isFullHeightPage}>
          <Outlet />
        </Layout>

        <ToastContainer />
        <Modals />
      </MainLayoutWrapper>
      <ActionPanel />
    </LayoutContainer>
  );
};

const Layout = styled(PageLayout)<{ isFullHeightPage?: boolean }>`
  ${({ isFullHeightPage }) => isFullHeightPage && 'overflow-y: hidden;'}
`;

const LayoutContainer = styled('div')`
  display: flex;
`;

const MainLayoutWrapper = styled('div')`
  flex-grow: 1;
`;
