import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { fetchMeetings } from '@/api/v4/meetings.api';
import { queryClient, QueryKey } from '@/config/query-client';
import { reorderMeetings } from '@/features/action-panel/meetings/helpers/reorder-meetings';
import { editedMeetingIdState } from '@/features/action-panel/meetings/meetings.state';
import { notify } from '@/ui/snackbar/notify';

import { teamIdState } from '../../../team.state';

export const useTeamMeetings = () => {
  const { t } = useTranslation('default');
  const teamId = useRecoilValue(teamIdState);
  const editedMeetingId = useRecoilValue(editedMeetingIdState);

  const fetchTeamMeetings = async () => {
    if (!teamId) return [];

    return fetchMeetings({ teamId });
  };

  return useQuery([QueryKey.TeamMeetings, teamId], fetchTeamMeetings, {
    select: meetings =>
      editedMeetingId ? reorderMeetings(meetings, editedMeetingId) : meetings,

    onError: () =>
      notify({
        message: t`unknownError`,
      }),
    enabled: !!teamId,
    staleTime: 1000 * 60 * 5,
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.TeamActionsInfo, teamId]);
    },
  });
};
