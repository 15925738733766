import { useRecoilState, useSetRecoilState } from 'recoil';

import { useDebounce } from '@/hooks/use-debounce';
import { APP_USER_ROLES } from '@/types';
import { useUserRole } from '@/user/use-user-role';

import { getCompanyHeadquarterLocationOptionList } from '../../helpers/get-company-headquarter-location-option-list';
import { scrollToSection } from '../../helpers/scroll-to-section';
import {
  CompanySection,
  selectedSectionState,
} from '../sections-pane/sections-pane.state';

import { useAddOfficeLocation } from './api/use-add-office-location';
import { useDeleteOfficeLocation } from './api/use-delete-office-location';
import { useOfficeLocationsQuery } from './api/use-office-locations-query';
import {
  officeLocationsEditModeState,
  showOfficeLocationsState,
} from './office-locations.state';

const MAX_OFFICE_LOCATIONS_NUMBER = 6;

export const useOfficeLocations = () => {
  const userRole = useUserRole();
  const { data: officeLocations } = useOfficeLocationsQuery();
  const { addOfficeLocation } = useAddOfficeLocation();
  const [isOfficeLocationsWidgetAdded, setOfficeLocationsVisible] =
    useRecoilState(showOfficeLocationsState);
  const setOfficeLocationsEditMode = useSetRecoilState(
    officeLocationsEditModeState,
  );
  const [isEditMode, setEditMode] = useRecoilState(
    officeLocationsEditModeState,
  );
  const setSelectedSection = useSetRecoilState(selectedSectionState);
  const { deleteOfficeLocation } = useDeleteOfficeLocation();
  const hasUserEditPermission = userRole
    ? [APP_USER_ROLES.ADMIN, APP_USER_ROLES.MANAGER].includes(userRole)
    : false;

  const addOfficeLocationsWidget = () => {
    setTimeout(() => {
      scrollToSection(CompanySection.OFFICE_LOCATIONS);
    }, 1000);

    setOfficeLocationsVisible(true);
    setOfficeLocationsEditMode(true);
    setSelectedSection(CompanySection.OFFICE_LOCATIONS);
  };

  const getFilteredLocationOptionsList = async (search: string) => {
    const locations = await getCompanyHeadquarterLocationOptionList(search);
    return locations.filter(
      location =>
        !officeLocations
          ?.map(officeLocation => officeLocation.city?.id)
          .includes(location.value.cityId ?? ''),
    );
  };

  const setLocationOptions = async (search: string) =>
    await getFilteredLocationOptionsList(search);

  const onAdd = useDebounce(addOfficeLocation);

  const onOutsideClick = () => {
    if (!officeLocations?.length) {
      setOfficeLocationsVisible(false);
    }

    return setEditMode(!isEditMode);
  };

  const canAddExtraOfficeLocation =
    Number(officeLocations?.length) < MAX_OFFICE_LOCATIONS_NUMBER;

  const deleteWidget = async () => {
    setOfficeLocationsVisible(false);
    setOfficeLocationsEditMode(false);

    if (officeLocations?.length) {
      const officeLocationsIds = officeLocations.map(location => location.id);
      await Promise.all(
        officeLocationsIds.map(async id => await deleteOfficeLocation(id)),
      );
    }
  };

  return {
    setLocationOptions,
    officeLocations,
    hasUserEditPermission,
    addOfficeLocationsWidget,
    isOfficeLocationsWidgetAdded,
    onAdd,
    deleteOfficeLocation,
    deleteWidget,
    onOutsideClick,
    canAddExtraOfficeLocation,
  };
};
