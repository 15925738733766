import type { PERMISSION } from '@/user/permissions/permission.type';
import type { PRODUCT } from '@/user/products/product.type';
import { ProtectedRoute } from './protected-route';

interface Required {
  permissions?: PERMISSION[];
  products?: PRODUCT[];
}

export default function protect(
  component: JSX.Element,
  required: Required,
  withFallback?: boolean,
) {
  return (
    <ProtectedRoute required={required} withFallback={withFallback}>
      {component}
    </ProtectedRoute>
  );
}
