import type { FundingStage } from '@/api/v4/funding-stages.api';
import type {
  CompaniesFilters,
  CompanySortOptions,
} from '@/api/v4/organization.api';
import type { ListingStage } from '@/api/v4/project-listings.api';
import type { CompanyFilters } from '@/features/companies/companies.state';
import { defaultAppliedCompaniesFilters } from '@/features/companies/companies.state';
import type { DateRange, ExtractedFilters } from '@/helpers/filters-helpers';
import { stringifyFilter } from '@/helpers/filters-helpers';
import {
  extractArrayFilters,
  filterOutEmptyFilterValues,
} from '@/helpers/filters-helpers';
import { isValidYear } from '@/helpers/format-date';
import { isDateRangeOption } from '@/helpers/other';
import { stringifySort } from '@/helpers/stringify-sort';
import type { DateRangeOption } from '@/types';

import type { ISort } from '@/ui/table/table.types';
import { formatCapitalRaisedLabel } from '../helpers/format-capital-raised-label';

export const stringifyCompanyFilters = ({
  sort,
  filters = defaultAppliedCompaniesFilters,
}: {
  filters?: CompanyFilters;
  sort?: ISort<CompanySortOptions | undefined>;
}): string => {
  // Keys of filters that have the same property name (keyof ICompanyFilters) as apiKey
  const keys: Array<Partial<keyof CompanyFilters>> = [
    'tags',
    'listingStatuses',
    'fundingStages',
    'hqCities',
    'ranking',
    'createdBy',
    'employeesRange',
    'verified',
    'search',
  ];

  const common = keys
    .map(key => stringifyFilter({ ...filters }, key))
    .filter(Boolean)
    .flat();

  const yearFoundedStart = filters.yearFounded?.start
    ? `yearFounded[min]=${filters.yearFounded.start.value}`
    : '';
  const yearFoundedEnd = filters.yearFounded?.end
    ? `yearFounded[max]=${filters.yearFounded.end.value}`
    : '';

  const capitalRaisedParams = filters.capitalRaised
    ? filters.capitalRaised.map(
        range => `capitalRaised=${range.value.min}-${range.value.max}`,
      )
    : [];

  const sortParams = sort ? stringifySort(sort) : '';

  return [
    ...common,
    yearFoundedStart,
    yearFoundedEnd,
    ...capitalRaisedParams,
    sortParams,
  ]
    .filter(filterOutEmptyFilterValues)
    .join('&')
    .replace(/&$/, '');
};

export const isValidDateRangeFilter = (
  dateRange: DateRangeOption | DateRange,
): boolean => {
  if (!isDateRangeOption(dateRange)) {
    return false;
  }

  const start = Number(dateRange.start?.value ?? dateRange.start);
  const end = Number(dateRange.end?.value ?? dateRange.end);

  return !isNaN(start) && !isNaN(end);
};

export const prepareCompanyApiFilters = (filters: CompanyFilters) => {
  let params: CompaniesFilters = {};

  if (filters.tags) {
    params = {
      ...params,
      tags: filters.tags.map(item => item.value),
    };
  }

  if (filters.listingStatuses) {
    params = {
      ...params,
      listingStatuses: filters.listingStatuses.map(item => item.value),
    };
  }

  if (
    (filters.yearFounded.start || filters.yearFounded.end) &&
    isValidDateRangeFilter(filters.yearFounded)
  ) {
    const { start, end } = filters.yearFounded;
    params = {
      ...params,
      ...(start && { 'yearFounded[min]': start.value }),
      ...(end && { 'yearFounded[max]': end.value }),
    };
  }

  if (filters.capitalRaised) {
    params = {
      ...params,
      capitalRaised: filters.capitalRaised.map(
        // results in e.g 'capitalRaised=-500001-200000' or 'capitalRaised=null-500000' if min/max is not defined
        range => range.value.min + '-' + range.value.max,
      ),
    };
  }

  if (filters.fundingStages) {
    params = {
      ...params,
      fundingStages: filters.fundingStages.map(item => item.value),
    };
  }

  if (filters.hqCities) {
    params = {
      ...params,
      hqCities: filters.hqCities.map(item => item.value),
    };
  }

  if (filters.employeesRange) {
    params = {
      ...params,
      employeesRange: filters.employeesRange.map(item => item.value),
    };
  }

  if (filters.ranking) {
    params = {
      ...params,
      ranking: filters.ranking.map(item => item.value),
    };
  }

  if (filters.createdBy) {
    params = {
      ...params,
      createdBy: filters.createdBy.map(item => item.value),
    };
  }

  if (filters.verified) {
    params = {
      ...params,
      verified: filters.verified,
    };
  }

  if (filters.search) {
    params = {
      ...params,
      search: filters.search,
    };
  }
  return params;
};

export const extractCompanySearchFilters = (url: string): ExtractedFilters => {
  const searchParams = new URLSearchParams(url.split('?')[1]);
  const filters: ExtractedFilters = {};

  let yearFoundedStart: string | null = null;
  let yearFoundedEnd: string | null = null;

  for (const [key, value] of searchParams.entries()) {
    if (!key) {
      continue;
    }

    // Handle special cases - booleans
    if (key === 'verified' && value === 'true') {
      filters.verified = true;
      continue;
    }
    if (key === 'search') {
      filters.search = value;
      continue;
    }

    if (key === 'yearFounded[min]') {
      if (isValidYear(value)) {
        yearFoundedStart = value;
      } else {
        // If invalid, reset both start and end
        yearFoundedStart = null;
        yearFoundedEnd = null;
        break;
      }
      continue;
    }
    if (key === 'yearFounded[max]') {
      if (isValidYear(value)) {
        yearFoundedEnd = value;
      } else {
        // If invalid, reset both start and end
        yearFoundedStart = null;
        yearFoundedEnd = null;
        break;
      }
      continue;
    }

    const allowedParameters = Object.keys(
      defaultAppliedCompaniesFilters,
    ).filter(parameter => parameter !== 'yearFounded');

    if (!allowedParameters.includes(key)) {
      continue;
    }

    // Handle default cases - arrays of strings
    filters[key] = extractArrayFilters(filters[key], value);
  }

  // Assign yearFounded filters if valid
  if (yearFoundedStart !== null || yearFoundedEnd !== null) {
    filters.yearFounded = {
      start: yearFoundedStart,
      end: yearFoundedEnd,
    };
  }

  return filters;
};

export const mapCompanyStatusFilter = (
  status: Optional<ListingStage, 'displayName'>,
) => ({
  value: status.id,
  label: status.displayName ?? status.name,
});

export const mapCompanyFundingStageFilter = (stage: FundingStage) => ({
  label: stage.name,
  value: stage.id,
});

export const getRankingOptions = () => {
  const start = 0.5;
  const end = 5;
  const increment = 0.5;

  const ratings = Array.from(
    { length: (end - start) / increment + 1 },
    (_, index) => start + index * increment,
  );

  return ratings.map(ranking => ({
    label: ranking.toString(),
    value: ranking,
  }));
};

export const getCapitalRaisedOptions = () => {
  const capitalRaisedRanges = [
    { min: null, max: 500_000 },
    { min: 500_001, max: 5_000_000 },
    { min: 5_000_001, max: 20_000_000 },
    { min: 20_000_001, max: null },
  ];

  return capitalRaisedRanges.map(range => ({
    label: formatCapitalRaisedLabel(range),
    value: {
      min: range.min,
      max: range.max,
    },
  }));
};
