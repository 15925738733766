import { Logo } from '@/ui/logo/logo';

import { SuggestionContainer } from './suggestion-container';
import { SuggestionContent } from './suggestion-content';

export const OrganizationSuggestion = ({
  onClick,
  name,
  logoUrl,
  type,
  highlights,
  isSelected,
  id,
}: {
  onClick?: () => void;
  name: string;
  logoUrl?: string;
  type: string;
  highlights?: string[];
  isSelected?: boolean;
  id: string;
}) => {
  return (
    <SuggestionContainer
      id={id}
      onClick={onClick}
      label={name}
      isSelected={isSelected}
    >
      <Logo
        border="none"
        name={name}
        logoUrl={logoUrl}
        size="24px"
        singleLetter
        fontSize="12px"
      />
      <SuggestionContent name={name} type={type} highlights={highlights} />
    </SuggestionContainer>
  );
};
