import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CreateProjectListingsPayload } from '@/api/v4/project-listings.api';
import { createProjectListings } from '@/api/v4/project-listings.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

import { useInvalidateProjectListings } from './use-invalidate-project-listings';

export const useCreateProjectListings = () => {
  const { t } = useTranslation('default');
  const queryClient = useQueryClient();
  const invalidateListings = useInvalidateProjectListings();

  const createProjectListingsMutation = useMutation(
    async ({
      projectId,
      payload,
    }: {
      projectId: string;
      payload: CreateProjectListingsPayload;
    }) => await createProjectListings(projectId, payload),
    {
      onSuccess: async (_data, { payload: { companyIds }, projectId }) => {
        companyIds.forEach(async companyId => {
          await queryClient.invalidateQueries([
            QueryKey.CompanyProjectsListings,
            companyId,
          ]);
        });

        await invalidateListings(projectId);

        await queryClient.invalidateQueries([QueryKey.Projects]);
        await queryClient.invalidateQueries([QueryKey.ProjectOptions]);
        await queryClient.invalidateQueries([QueryKey.CompanyOptionsCount]);
        await queryClient.invalidateQueries([QueryKey.ActiveProjects]);
        await queryClient.invalidateQueries([QueryKey.Snapshot]);
        await queryClient.invalidateQueries([QueryKey.Funnel]);
        await queryClient.invalidateQueries([QueryKey.LastActivities]);
        await queryClient.invalidateQueries([QueryKey.LastActivitiesCount]);
      },
      onError: (error: Error) => {
        notify({
          message: t`unknownError`,
        });
        console.error(error.message);
      },
    },
  );

  const createProjectListing = async (
    projectId: string,
    payload: CreateProjectListingsPayload,
  ) => createProjectListingsMutation.mutateAsync({ projectId, payload });

  return {
    createProjectListing,
    isLoading: createProjectListingsMutation.isLoading,
  };
};
