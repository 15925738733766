import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { Modal } from '@/ui/modal/modal';

import { modalState } from '../../state/modal.state';
import { AskX } from '../ask-x/ask-x';

export const AskXModal = () => {
  const { t } = useTranslation('askX');

  const [modal, setModal] = useRecoilState(modalState);

  const closeModal = () => setModal(null);

  if (!modal || modal.state !== 'askXModal') return null;

  return (
    <StyledModal
      header={!modal.showResult ? t`modal.title` : t`modal.resultTitle`}
      onClose={closeModal}
      closeIcon={false}
      variant="info"
    >
      <AskX
        onCancel={closeModal}
        onSuccess={() => {
          setModal({
            state: 'askXModal',
            showResult: true,
          });
        }}
        showResult={modal.showResult}
      />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  width: 320px;
  background-color: ${({ theme }) => theme.colors.basics.black};
  color: ${({ theme }) => theme.colors.basics.white};

  .header {
    color: ${({ theme }) => theme.colors.basics.white};
  }
`;
