import type { APIRequestBody } from './base-fetch';
import { type APIResponse, get, put } from './base-fetch';

type CompanyTagAPIResponse =
  APIResponse<'/api/v4/organization/{organizationId}/tags'>;

export type UpdateCompanyTagsPayload =
  APIRequestBody<'/api/v4/organization/{organizationId}/tags/update'>;

export type OrganizationTag = CompanyTagAPIResponse['tags'][0];

export const fetchOrganizationTags = async ({
  organizationId,
}: {
  organizationId: string;
}) => get<CompanyTagAPIResponse>(`/v4/organization/${organizationId}/tags`);

export const updateOrganizationTags = async (
  organizationId: string,
  payload: UpdateCompanyTagsPayload,
) => {
  return put<CompanyTagAPIResponse, UpdateCompanyTagsPayload>(
    `/v4/organization/${organizationId}/tags/update`,
    payload,
  );
};
