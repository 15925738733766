import type { ProjectOnList } from '@/api/v4/projects.api';
import { AddCompanySuccessMessage } from './add-company-success-message';
import { notify } from '@/ui/snackbar/notify';
import { useCallback } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { modalState } from '@/state/modal.state';
import { selectedCompaniesState } from '@/features/companies/companies.state';
import { useCompany } from '@/features/companies/overview/use-company.query';
import { useCompanies } from '@/features/companies/hooks/use-companies.query';
import { useProjectsForCompanyTableConfiguration } from '@/features/projects/projects-list/use-projects-for-company-table-configuration';
import { TableRow } from '@/ui/table/infinite-table/table-row';
import { styled } from 'goober';

export default function ProjectSearchResultsTable({
  projects,
}: {
  projects: ProjectOnList[];
}) {
  const { refetch: refreshCompanies } = useCompanies();
  const { data: company } = useCompany();
  const resetSelectedCompanies = useResetRecoilState(selectedCompaniesState);
  const setModalState = useSetRecoilState(modalState);

  const handleOnSelect = useCallback(
    async (projectName: string, successCount: number) => {
      notify({
        message: (
          <AddCompanySuccessMessage
            projectName={projectName}
            companiesCount={successCount}
            companyName={company?.name.value}
          />
        ),
        withIcon: true,
      });

      setModalState(null);
      resetSelectedCompanies();
      await refreshCompanies();
    },
    [company, refreshCompanies, resetSelectedCompanies, setModalState],
  );

  const handleOnLinkClick = () => {
    setModalState(null);
  };
  const { mapProjectToRow } = useProjectsForCompanyTableConfiguration(
    handleOnSelect,
    handleOnLinkClick,
  );
  return (
    <Table>
      <tbody>
        {projects?.map(project => {
          const row = mapProjectToRow(project);
          return <TableRow key={row.id} row={row} />;
        })}
      </tbody>
    </Table>
  );
}

const Table = styled('table')`
  background-color: ${({ theme }) => theme.colors.basics.white};
  width: calc(100% - 30px);
  height: fit-content;
  table-layout: fixed;
  margin-left: 15px;

  td {
    background-color: transparent;
  }
`;
