import type { DefaultTheme } from 'goober';

export const inputContainerMixin = (
  theme: DefaultTheme,
  disabled?: boolean,
) => `
  align-items: center;
  background-color: ${theme.colors.basics.white};
  border: 2px solid ${theme.colors.gray.c2};
  border-radius: 10px;
  display: flex;
  opacity: ${disabled ? '0.5' : 'unset'};
  
  gap: 12px;
  line-height: 1.4;
  padding: 8px 12px;

  &:hover {
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  }
  &:focus-within {
    border-color: ${
      disabled ? theme.colors.gray.c2 : theme.colors.blue.primaryA
    };
  }
`;

export const inputMixin = (theme: DefaultTheme, disabled?: boolean) => `
${theme.typography.widget.paragraphSmall}
  border: 0;
  outline: 0;
  font-size: inherit;
  cursor: ${disabled ? 'not-allowed' : 'pointer'};

  &::placeholder {
    color: ${theme.colors.gray.c7};
  }
`;
