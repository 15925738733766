import type { APIResponse } from './base-fetch';
import { get } from './base-fetch';

export type Tag = APIResponse<'/api/v4/tags'>[number];

export const fetchTags = async () => get<Tag[]>('/v4/tags');

type CountEntitiesByTagResponse =
  APIResponse<'/api/v4/tags/{tagId}/count-entities'>;

export const fetchCountEntitiesByTag = async (tagId: string) =>
  get<CountEntitiesByTagResponse>(`/v4/tags/${tagId}/count-entities`);
